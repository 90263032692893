import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeApiDispatchers.ts";

const ENTITY_QUERY_KEY = "employmentType";

export const All_EMPLOYMENT_STATUSES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllEmploymentTypes = (options?: QueriesOptions<any>) => useQuery(
	All_EMPLOYMENT_STATUSES_QUERY_KEY,
	() => getAllEmploymentTypes().then(getDataFromResponse),
	options,
);