import Button from "components/buttons/Button";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import CreateClientContactPersonForm from "~/modules/client/components/contact/forms/CreateClientContactPersonForm";

type ClientViewSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId?: string;
	companyId: string;
};

const CreateClientContactPersonSidebar: React.FunctionComponent<ClientViewSidebarProps> = ({
	isOpen,
	setOpen,
	companyId,
}) => {
	const [contactPersonCreated, setContactPersonCreated] = useState(false);
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY });
		setOpen(false);
	}, [setOpen, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			<>
				{!contactPersonCreated && (
					<CreateClientContactPersonForm
						companyId={companyId}
						onSuccess={handleSuccess}
						onCancel={() => setOpen(false)}
					/>
				)}

				{contactPersonCreated && (
					<div className="px-8 py-6">
						<div className="mb-4 text-center text-xl font-bold text-gray-800">
							Neuer Kontakt erfolgreich erstellt!
						</div>
						<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
						<div className="flex flex-col space-y-4">
							<Button className="justify-center" onClick={() => setContactPersonCreated(false)}>
								Weiteren Kontakt erstellen
							</Button>
							<Button
								className="justify-center"
								onClick={() => {
									setOpen(false);
								}}
							>
								Fenster schliessen
							</Button>
						</div>
					</div>
				)}
			</>
		</Sidebar>
	);
};

export default CreateClientContactPersonSidebar;
