import type { ReactElement } from "react";

export const truncate = (str: string, n: number) => (str.length > n ? str.slice(0, n - 1) + "..." : str);

export function nl2br<T>(input: T | string): (string | ReactElement)[] | T {
	if (typeof input === "string") {
		const newlineRegex = /\r\n|\n|\r/g;
		return input
			.split(newlineRegex)
			.flatMap((part, index, array) =>
				index < array.length - 1 ? [part, <br key={index} />] : [part],
			);
	}
	return input;
}
