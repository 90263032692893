import { yupResolver } from "@hookform/resolvers/yup";
import ProjectTag
	from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useAllDeliverableTags } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import {
	useDeliverableTagInputOptions,
} from "~/modules/project/api/deliverableTags/hooks/useDeliverableTagInputOptions.ts";
import { updateProjectsDeliverableTags } from "~/modules/project/api/project/projectApiDispatchers.ts";
import type { FormInputOption } from "~/types/form.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateProductTagsFormData {
	deliverableTags: { displayName: string }[];
}

type UpdateProductTagsFormProps = {
	onSuccess: (newTagsAdded: boolean) => void;
	onClose: () => void;
	deliverableTagIds: string[];
	projectId: string;
};

const UpdateDeliverableTagsForm: FunctionComponent<UpdateProductTagsFormProps> = ({
	onSuccess,
	onClose,
	deliverableTagIds,
	projectId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const { data: allDeliverableTags } = useAllDeliverableTags();
	const deliverableTagOptions = useDeliverableTagInputOptions();

	const schema = useMemo(() => {
		return yup.object({
			deliverableTags: yup.array().of(yup.object({
				displayName: yup.string().default(""),
			})).required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const productTagDisplayNames: UpdateProductTagsFormData["deliverableTags"] = [];
		if (allDeliverableTags) {
			deliverableTagIds.forEach(deliverableTagId => {
				const productTag = allDeliverableTags.find(deliverableTag => deliverableTag.id === deliverableTagId);
				if (productTag) {
					productTagDisplayNames.push({ displayName: productTag.displayName });
				}
			});
		}

		return { deliverableTags: productTagDisplayNames.sort() };
	}, [deliverableTagIds, allDeliverableTags]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateProductTagsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProductTagsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "deliverableTags",
	});

	const handleAddTag = useCallback((value: string) => {
		if (value && !fields.find(field => field.displayName === value)) {
			append({ displayName: value });
		}
	}, [fields, append]);

	const onSubmit = useCallback(async (data: UpdateProductTagsFormData) => {
		if (!allDeliverableTags) return;

		try {
			setIsBusy(true);
			const updateData = data.deliverableTags.map(tag => tag.displayName);

			await updateProjectsDeliverableTags({ projectId, tags: updateData });
			const newTagsAdded = !updateData.every(tag => allDeliverableTags.find(existingTag => existingTag.displayName === tag));
			onSuccess(newTagsAdded);
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten");
		}
	}, [onSuccess, projectId, allDeliverableTags]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">Projekt-Deliverable Tags bearbeiten</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<ComboBoxPlain value=""
							   autoFocus={true}
							   immediate={false}
							   placeholder="Deliverable Tag hinzufügen"
							   optionsData={deliverableTagOptions}
							   onChange={handleAddTag as (value: string | FormInputOption | null) => void}
				/>
				<div className="mt-4 flex flex-wrap gap-2 rounded-lg border p-2">
					{fields.length === 0 && <ProjectTag displayName="Keine Tags ausgewählt"
														theme="neutral" />}
					{fields.map((field, index) => {
						return <ProjectTag key={index}
										   theme="deliverable"
										   displayName={field.displayName}
										   onDeleteClick={() => remove(index)} />;
					})}
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onClose}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateDeliverableTagsForm;
