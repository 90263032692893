import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const hourCountVariants = tv({
	base: "flex h-full items-center justify-center rounded-r-md p-2 font-bold",
	variants: {
		theme: {
			project: "bg-primary-500 bg-opacity-100 text-white group-hover:bg-primary-700",
			internal:"bg-gray-500 bg-opacity-80 text-white group-hover:bg-gray-600",
			sales:"bg-gray-700 text-white group-hover:bg-gray-800",
			default: "bg-gray-200 text-gray-500 group-hover:bg-gray-300",
			completed:"bg-green-500 text-white group-hover:bg-green-600",
			absence:"",
			partTime:"bg-success-dark-800 text-white group-hover:bg-success-dark-900"
		},
	},
	defaultVariants: {
		theme: "default",
	},
});


export type HourCountVariants = VariantProps<typeof hourCountVariants>;
export type HourCountThemes = "project" | "internal" | "sales" | "default"|"completed"|"absence" | "partTime";

