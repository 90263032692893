import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Decimal from "decimal.js-light";
import type React from "react";
import { Bar } from "react-chartjs-2";

import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

type ChartProps = {
	reportData: YearlyUtilisationReport;
	projectOnly: boolean;
};

const MonthlyUtilizationChart: React.FC<ChartProps> = ({ reportData, projectOnly }) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

	const prepareDatasets = (reportData: YearlyUtilisationReport, projectOnly: boolean) => {
		let typeIds = [TimeTrackingTypeId.Project];
		if (!projectOnly) {
			typeIds = [TimeTrackingTypeId.Project, TimeTrackingTypeId.Sales, TimeTrackingTypeId.Internal];
		}
		return Array.from(typeIds).map((typeId) => {
			let label = "";
			let backgroundColor = "";
			switch (typeId) {
				case TimeTrackingTypeId.Project:
					label = "Projekt";
					backgroundColor = "rgb(30,53,96)"
					break;
				case TimeTrackingTypeId.Sales:
					label = "Sales";
					backgroundColor = "rgba(67, 152, 238)"
					break;
				case TimeTrackingTypeId.Internal:
					label = "Intern";
					backgroundColor = "rgba(151,209,249)"
					break;
			}
			return {
				label,
				data: reportData.monthlyBreakdown.map(monthData => {
					const minutes = monthData.timeTrackingTypeBreakdown.find(type => type.timeTrackingTypeId === typeId)?.minutesTracked || 0;
					const totalMinutes = new Decimal(monthData.totalWorkDaysTarget).mul(480).toNumber();
					return parseFloat(((minutes / totalMinutes) * 100).toFixed(2));
				}),
				backgroundColor,
			};
		}).filter(Boolean);
	};

	const chartData = {
		labels: months,
		datasets: prepareDatasets(reportData, projectOnly),
	};

	const options = {
		responsive: true,
		animation:false,
		maintainAspectRatio: false,
		scales: {
			x: { stacked: true },
			y: {
				stacked: true,
				suggestedMax: 100,
				ticks: {
					callback: (value: number) => `${value}%`,
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: (context: any) => `${context.dataset.label}: ${context.parsed.y.toFixed(2)}%`,
				},
			},
			legend: {
				position: "top" as const,
			},
			datalabels: {
				color: 'white',
				font: {
					weight: 'bold'
				},
				formatter: (value: number) => {
					return `${value.toFixed(1)}%`;
				},
				display: (context: any) => {
					const dataset = context.dataset;
					const value = dataset.data[context.dataIndex];
					return value > 5; // Only display label if value is greater than 5%
				},
				anchor: 'center',
				align: 'center',
			}
		},
	};


	return (
		<Bar data={chartData}
			/*@ts-expect-error libary type definition is wrong*/
			 options={options} />
	);
};

export default MonthlyUtilizationChart;