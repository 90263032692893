import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	StaffMemberSpecialAgreement, UpdateStaffMemberSpecialAgreementData,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementTypes.ts";


const specialAgreementsBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/special-agreements`;

export function indexStaffMembersSpecialAgreements(staffMemberId: string): AxiosApiResponsePromise<StaffMemberSpecialAgreement> {
	return axiosApi.get(specialAgreementsBasePath(staffMemberId));
}

export function updateStaffMemberSpecialAgreement({ staffMemberId, specialAgreementId, updateData }: {
	staffMemberId: string;
	specialAgreementId: string;
	updateData: UpdateStaffMemberSpecialAgreementData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${specialAgreementsBasePath(staffMemberId)}/${specialAgreementId}`, updateData);
}
