import type { ElementType, PropsWithChildren } from "react";

import type {
	AbsenceTypeVariantProps} from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeBadge/absenceTypeBadgeVariantsVariants.ts";
import {
	absenceTypeBadgeVariantsVariants
} from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeBadge/absenceTypeBadgeVariantsVariants.ts";

type BaseStatusProps = AbsenceTypeVariantProps & {
	as?: ElementType;
}

type StatusProps = BaseStatusProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const AbsenceTypeBadge: React.FC<PropsWithChildren<StatusProps>> = ({
	borderRadius = 'default',
	children,
	theme = 'default',
	size = 'default',
	as = 'div',
	...rest
}) => {
	const Component: ElementType = as;

	return (
		<Component
			className={absenceTypeBadgeVariantsVariants({ borderRadius, size, theme })}
			{...rest}
		>
			{children}
		</Component>
	);
};

export default AbsenceTypeBadge;
