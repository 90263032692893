import type React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { appRoutes } from "~/constants/appRoute.ts";
import { deleteProject } from "~/modules/project/api/project/projectApiDispatchers.ts";

type DeleteProjectModalProps = {
	isOpen: boolean;
	projectId: string;
	projectTitle: string;
	onCloseClick: () => void;
};

const DeleteProjectModal: React.FunctionComponent<DeleteProjectModalProps> = ({
	isOpen,
	projectId,
	projectTitle,
	onCloseClick,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const useNavigation = useNavigate();

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteProject({ projectId });
			useNavigation(appRoutes.projects().path, { replace: true });
			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "projectIsDeletableRule",
					)
				) {
					setError("Das Projekt kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Projekt "${projectTitle}" löschen`,
		bodyText: `Möchtest Du dieses Projekt wirklich löschen?`,
		deleteButtonText: `Projekt löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteProjectModal;
