import clsx from "clsx";
import { NavLink } from "react-router-dom";

import Headline from "~/components/Headline";

interface Props {
	displayName: string;
	city?: string;
	href: string;
}

const ClientItem: React.FC<Props> = ({ displayName, city = "", href }) => {
	return <NavLink to={href}
					className={clsx(
						"col-span-1 transform-gpu divide-y divide-gray-200 rounded-lg shadow transition-transform duration-200",
						"overflow-hidden bg-white",
						"hover:shadow-md",
					)}
	>
		<div className="group flex w-full justify-between space-x-6 bg-white p-6">
			<div className="flex flex-1 flex-col gap-y-1 truncate">
				<div className="flex items-center space-x-3">
					<Headline type="h5"
							  className="truncate group-hover:text-secondary-500">
						{displayName}
					</Headline>
				</div>
				<div className="-mt-1 flex flex-row items-center gap-1 truncate text-sm text-gray-500">
					{city}
				</div>
			</div>
			{/*			<div className="flex flex-row items-center self-start">
				<CompanyLogo width="w-12"
							 height="h-12"
							 displayName={shortName} />
			</div>*/}
		</div>
	</NavLink>;


};

export default ClientItem;
