import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const absenceTypeBadgeVariantsVariants = tv({
	base: "inline-flex  whitespace-nowrap font-medium",
	variants: {
		theme: {
			vacation: "bg-primary-400 text-white",
			default: "bg-accent-500 text-white",
			longTerm: "bg-primary-300 text-white",
			sick: "bg-red-500 text-white",
		},
		size: {
			timelineBar: "h-6 w-full",
			default: "px-2.5 py-0.5 text-xs",
			lg: "px-4 py-2.5 text-base",
		},
		borderRadius: {
			left: "rounded-l",
			right: "rounded-r",
			default: "rounded",
		},
		muted:{
			true: "opacity-30",
			false: "",
		},
	},
	defaultVariants: {
		borderRadius: "default",
		theme: "default",
		muted:false
	},
});


export type AbsenceTypeVariantProps = VariantProps<typeof absenceTypeBadgeVariantsVariants>;
export type AbsenceTypeThemeVariants = "vacation" | "default" | "longTerm" | "sick";
export type AbsenceTypeSizeVariants = "default" | "lg";
export type AbsenceTypeBorderRadiusVariants = "default" | "left" | "right";


/*
switch (absence.absenceTypeId) {
	case AbsenceTypeId.Vacation:
		bgColorClass = "bg-primary-400";
		break;
	case AbsenceTypeId.Sick:
		bgColorClass = "bg-red-500";
		break;
	case AbsenceTypeId.Sabbatical:
	case AbsenceTypeId.MaternityLeave:
	case AbsenceTypeId.ParentalLeave:
		bgColorClass = "bg-primary-300";
		break;
	default:
		bgColorClass = "bg-accent-500";
		break;

}*/
