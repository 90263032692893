import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";

const utilisationBasePath = "reports/utilisation";


export function getYearlyUtilisationReport(year: string): AxiosApiResponsePromise<YearlyUtilisationReport> {
	return axiosApi.get(`${utilisationBasePath}/${year}`);
}

export function getStaffMembersUtilisationReport(year: number,
	staffMemberId: string): AxiosApiResponsePromise<YearlyUtilisationReport> {
	return axiosApi.get(`${utilisationBasePath}/${year}/staff-members/${staffMemberId}`);
}