import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	ClientContactPersonType,
	CreateClientContactPersonData,
	UpdateClientContactPersonData,
} from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";

export function getAllClientContactPersons(): AxiosApiResponsePromise<ClientContactPersonType[]> {
	return axiosApi.get("client-contact-persons");
}

export function getClientContactPerson(clientContactPersonId: string): AxiosApiResponsePromise<ClientContactPersonType> {
	return axiosApi.get(`client-contact-persons/${clientContactPersonId}`);
}

export function getClientContactPersonsByClient(clientId: string): AxiosApiResponsePromise<ClientContactPersonType[]> {
	return axiosApi.get(`clients/${clientId}/contact-persons`);
}

export function createClientContactPerson(
	clientId: string,
	data: CreateClientContactPersonData,
): AxiosApiResponsePromise<ClientContactPersonType> {
	return axiosApi.post(`clients/${clientId}/contact-persons`, { ...data });
}

export function updateClientContactPerson(
	clientId: string,
	contactPersonId: string,
	data: UpdateClientContactPersonData,
): AxiosApiResponsePromise<ClientContactPersonType> {
	return axiosApi.put(`clients/${clientId}/contact-persons/${contactPersonId}`, { ...data });
}

export function deleteClientContactPerson({ clientId, contactPersonId }: {
	clientId: string,
	contactPersonId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`clients/${clientId}/contact-persons/${contactPersonId}`);
}