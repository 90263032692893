import type { FunctionComponent } from "react";

import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";

type Props = {};

const TableRowLoadingSpinner: FunctionComponent<Props> = ({}) => {
	return <tr><td colSpan={10} className="h-12"><LoadingSpinnerFull theme="neutral" /></td></tr>
};

export default TableRowLoadingSpinner;