import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersFtePercentages,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageApiDispatchers.ts";

export const STAFF_MEMBER_FTE_PERCENTAGE_BASE_QUERY_KEY = "staffMembersFtePercentage";

export const STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_FTE_PERCENTAGE_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersFtePercentages = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY(staffMemberId),
	() => indexStaffMembersFtePercentages(staffMemberId).then(getDataFromResponse),
	options,
);