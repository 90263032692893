import clsx from "clsx";
import { format, isAfter, isSameDay, isWeekend } from "date-fns";
import { de } from "date-fns/locale";
import TimeTrackingBar from "modules/timeTracking/components/components/Day/components/TimeTrackingBar";
import DeleteTimeTrackingModal from "modules/timeTracking/components/DeleteTimeTrackingModal";
import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import type { Absence } from "~/modules/absence/api/absence/absenceTypes.ts";
import type { AbsenceType } from "~/modules/absence/api/absenceType/absenceTypeTypes.ts";
import type { Holiday } from "~/modules/absence/api/holiday/holidayTypes.ts";
import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import HourCount from "~/modules/timeTracking/components/components/Day/components/HourCount";
import type {
	TimeTrackingBarThemes,
} from "~/modules/timeTracking/components/components/Day/components/TimeTrackingBar/timeTrackingBarVariants.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { isDateBetween } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { sumUp } from "~/utils/sumUp.ts";

import Details from "./components/Details.tsx";

type DayProps = {
	allAbsenceTypes?: AbsenceType[];
	date: Date;
	holidays?: Holiday[];
	monthIsClosed: boolean;
	setScrollToElementRef?: (ref: HTMLDivElement) => void;
	setSelectedDate: (date: Date) => void;
	setSelectedTimeTrackingId: (id: string | null) => void;
	setTimeTrackingIdToUpdate: (id: string | null) => void;
	selectedDate: Date | null;
	selectedTimeTrackingId: string | null;
	showOpenDaysOnly: boolean;
	timeTrackingData?: TimeTrackingExtendedType[];
	userAbsencesData?: Absence[];
	users: User[];
	visible: boolean;
};

const maxMinutes = 480; // 12 hours
const today = new Date();
const Day: React.FC<DayProps> = ({
	allAbsenceTypes,
	date,
	holidays,
	monthIsClosed,
	selectedDate,
	selectedTimeTrackingId,
	setScrollToElementRef,
	setSelectedDate,
	setSelectedTimeTrackingId,
	setTimeTrackingIdToUpdate,
	showOpenDaysOnly,
	timeTrackingData,
	userAbsencesData,
	visible,
}) => {
	const { t } = useTranslation();

	if (undefined === timeTrackingData) return null;

	const isWeekendDay = isWeekend(date);
	const holiday = holidays?.filter((h) => isSameDay(Date.parse(h.date), date));
	const absence = userAbsencesData?.filter(({ startDate, endDate }) => {
		return isDateBetween(date, new Date(startDate), new Date(endDate)) && !isWeekendDay;
	});
	const [timeTrackingIdToDelete, setTimeTrackingIdToDelete] = useState<string | null>(null);
	const className = `${!visible && isWeekendDay ? "hidden" : ""}`;

	let absenceName = undefined;
	if (undefined !== absence && 0 < absence.length) {
		absenceName = allAbsenceTypes?.find((at) => at.id === absence[0].absenceTypeId)?.name;
	}
	const hasAbsence = undefined !== absence && 0 < absence.length;
	const isHalfDay =
		hasAbsence &&
		((isSameDay(Date.parse(absence[0].startDate), date) && absence[0].firstDayIsHalf) ||
			(isSameDay(Date.parse(absence[0].endDate), date) && absence[0].lastDayIsHalf));

	const totalMinutes = sumUp(timeTrackingData, "minutes");
	let totalHours = totalMinutes / 60;
	if (hasAbsence && isHalfDay) {
		totalHours += 4;
	}
	const timeTrackingDataToDelete = useMemo(() => {
		if (timeTrackingData && timeTrackingIdToDelete) {
			return timeTrackingData.find((data) => data.id === timeTrackingIdToDelete) || null;
		}
		return null;
	}, [timeTrackingData, timeTrackingIdToDelete]);

	const isToday = isSameDay(date, new Date());
	const isTodayAndSelected = selectedDate && isSameDay(date, selectedDate);

	const dayTextColorClass = isTodayAndSelected ? "text-white" : isToday ? "text-secondary-500" : "text-gray-400";

	const hoursToFulfill = hasAbsence && !isHalfDay ? 0 : 8;
	const hoursFulfilled = (timeTrackingData.length > 0 && totalHours >= hoursToFulfill) || hoursToFulfill === 0;

	if (showOpenDaysOnly && (isAfter(date, today) || hoursFulfilled)) return null;

	return (
		<div className={clsx(className, "flex flex-row border border-gray-50 py-4 pr-4 ", selectedDate && isSameDay(date, selectedDate) ? "bg-gray-400 text-white" : "")}
			ref={isToday ? setScrollToElementRef : null}>
			<div className="flex w-16 shrink-0 cursor-pointer flex-col">
				<div
					className={clsx("text-center font-bold", dayTextColorClass, isToday ? "text-2xl" : "text-xl")}>{format(date, "dd", { locale: de })}</div>
				<div
					className={clsx("text-center", dayTextColorClass)}>{format(date, "eee", { locale: de })}</div>
			</div>

			<div className="flex w-full flex-row rounded-md bg-gray-100 text-gray-500">
				<div className="flex grow gap-2 p-2">
					{undefined !== holiday && 0 < holiday.length &&
						<TimeTrackingBar width={20}>{holiday[0].displayName}</TimeTrackingBar>}
					{undefined !== absenceName && (
						<TimeTrackingBar theme="absence"
							width={isHalfDay ? 50 : 100}>{t(normalizeTKey("entities:absenceType." + absenceName))}</TimeTrackingBar>
					)}
					{timeTrackingData.map((data) => {
						let width = 100;
						if (data.id !== selectedTimeTrackingId) {
							const base = totalMinutes > maxMinutes ? totalMinutes : maxMinutes;
							width = (data.minutes / base) * 100;
						}


						let barTheme: TimeTrackingBarThemes;
						let title;
						switch (true) {
							case "1" === data.timeTrackingTypeId:
								barTheme = "project";
								title = data.projectTitle;
								break;
							case "2" === data.timeTrackingTypeId:
								barTheme = "internal";
								title = "Intern";
								break;
							case "3" === data.timeTrackingTypeId:
								barTheme = "sales";
								title = "Sales";
								break;
							case "4" === data.timeTrackingTypeId:
								barTheme = "partTime";
								title = "Teilzeit";
								break;
							default:
								barTheme = "default";
						}

						return (
							<TimeTrackingBar
								key={"time-tracking-" + data.id}
								hours={formatNumberWithComma(data.minutes / 60, 1, false)}
								theme={barTheme}
								width={width}
								onClick={data.id === selectedTimeTrackingId ? () => setSelectedTimeTrackingId(null) : () => setSelectedTimeTrackingId(data.id)}
							>
								{data.id === selectedTimeTrackingId ? (
									<Details
										data={data}
										monthIsClosed={monthIsClosed}
										onClickDelete={() => setTimeTrackingIdToDelete(data.id)}
										onClickEdit={() => setTimeTrackingIdToUpdate(data.id)}
										onClickClose={() => setSelectedTimeTrackingId(null)}
									/>
								) : (
									width > 5 && (
										<span className="font-bold">
											{title}
										</span>
									)
								)}
							</TimeTrackingBar>
						);
					})}
					{!monthIsClosed && <div className="flex grow">
						<ButtonNewItem
							size="sm"
							onClick={() => setSelectedDate(date)}
						></ButtonNewItem>
					</div>}
				</div>
				{timeTrackingData.length > 0 && (
					<div className="ml-auto h-full">
						<HourCount theme={hoursFulfilled ? "completed" : undefined}>{formatNumberWithComma(totalHours, 1, false)}
						</HourCount>
					</div>
				)}

			</div>
			<DeleteTimeTrackingModal
				isOpen={!!timeTrackingDataToDelete}
				onClose={() => setTimeTrackingIdToDelete(null)}
				timeTrackingData={timeTrackingDataToDelete} />
		</div>
	);
};

export default Day;
