import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllPrograms, getProgram, indexProgramsByClientId } from "~/modules/project/api/program/programApiDispatchers.ts";

const ENTITY_QUERY_KEY = "program";

export const ALL_PROGRAMS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const PROGRAM_QUERY_KEY = (programId: string) => [ENTITY_QUERY_KEY, programId];
export const PROGRAMS_BY_CLIENT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];

export const useProgram = (clientId: string, programId: string, options?: QueriesOptions<any>) => useQuery(
	PROGRAM_QUERY_KEY(programId),
	() => getProgram(clientId, programId).then(getDataFromResponse),
	options,
);

export const useAllPrograms = (options?: QueriesOptions<any>) => useQuery(
	ALL_PROGRAMS_QUERY_KEY,
	() => getAllPrograms().then(getDataFromResponse),
	options,
);

export const useClientsPrograms = (clientId: string, options?: QueriesOptions<any>) => useQuery(
	PROGRAMS_BY_CLIENT_QUERY_KEY(clientId),
	() => indexProgramsByClientId(clientId).then(getDataFromResponse),
	options,
);