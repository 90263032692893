import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { setMonth } from "date-fns";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import Card from "~/components/Card";
import SearchInput from "~/components/formElements/SearchInput";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import MonthlyUtilisationTable
	from "~/modules/reports/components/UtilisationReportsView/components/TotalUtilisationTableSection/MonthlyUtilisationTable";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { utilisationReportData: YearlyUtilisationReport, year: string };

const TotalUtilisationTableSection: FunctionComponent<Props> = ({ utilisationReportData }) => {
	const [selectedMonth, setSelectedMonth] = useState<string>("1");
	const [search, setSearch] = useState<string>("")
	const [monthSelectOptions, maxMonth] = useMemo(() => {

		const monthSelectOptions = utilisationReportData.monthlyBreakdown.map((month) => {
			const label = setMonth(new Date(), month.month - 1).toLocaleString("de-DE", { month: "long" });
			return { label, value: month.month.toString() };
		}).sort(byObjectProperty("value"));

		const maxMonth = parseInt(monthSelectOptions[monthSelectOptions.length - 1].value);

		return [monthSelectOptions, maxMonth];
	}, [utilisationReportData.monthlyBreakdown]);

	const handleGoToPrevMonthClick = useCallback(() => {
		const prevMonthNumber = parseInt(selectedMonth) - 1;
		if (prevMonthNumber > 0) {
			setSelectedMonth((prevMonthNumber).toString());
		}
	}, [selectedMonth]);


	const handleGoToNextMonthClick = useCallback(() => {
		const nextMonthNumber = parseInt(selectedMonth) + 1;
		if (nextMonthNumber <= maxMonth) {
			setSelectedMonth(nextMonthNumber.toString());
		}
	}, [maxMonth, selectedMonth]);

	const prevButtonDisabled = parseInt(selectedMonth) === 1;
	const nextButtonDisabled = parseInt(selectedMonth) === maxMonth;

	return <div className="grid h-full grid-rows-[auto_1fr] gap-y-2 overflow-hidden">
		<div className="z-50">
			<Card>
				<div className="flex justify-between">
					<div className="flex items-center gap-x-2">
						<Button size="sm"
								theme="none"
								onClick={handleGoToPrevMonthClick}
								disabled={prevButtonDisabled}>
							<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
						</Button>
						<div className="w-28">
							<SelectPlain optionsData={monthSelectOptions}
										 onChange={setSelectedMonth}
										 value={selectedMonth.toString()} />
						</div>
						<Button size="sm"
								theme="none"
								onClick={handleGoToNextMonthClick}
								disabled={nextButtonDisabled}>
							<ChevronRightIcon className="size-5 hover:fill-accent-600" />
						</Button>
					</div>
					<SearchInput value={search} onChange={setSearch} placeholder="Mitarbeiter:in"/>
				</div>
			</Card>
		</div>
		<div className="max-h-full overflow-hidden">
			<MonthlyUtilisationTable reportData={utilisationReportData}
									 search={search}
									 selectedMonth={parseInt(selectedMonth)} /></div>
	</div>;
};

export default TotalUtilisationTableSection;