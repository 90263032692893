import { useMemo } from "react";

import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData, getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useMentorSelectOptions = (excludedStaffMemberId: string): FormInputOption[] => {
	const { data: staffMembers } = useAllStaffMembers();
	return useMemo(() => {
		if (staffMembers) {
			return [getInputDefaultOptionsData("-- Keine/r --", "0"), ...staffMembers
				.filter(staffMember => staffMember.id !== excludedStaffMemberId && staffMember.currentEmploymentTypeId !== EmploymentTypeId.Left)
				.sort(byObjectProperty("fullName"))
				.map((staffMember) => {
					return createInputOptionData(staffMember.fullName, staffMember.id);
				})];
		}
		return [];
	}, [staffMembers, excludedStaffMemberId]);
};

export default useMentorSelectOptions;

