import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import type { ForwardedRef } from "react";
import { forwardRef, useEffect, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { InputPlain } from "~/components/formElements/Input/Input.tsx";
import Label from "~/components/formElements/Label";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";

type SharedProps =
	Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "onChange" | "onBlur">
	& FormInputBaseVariants
	& {
	errorIconOnly?: boolean;
	name: string;
	label?: string;
	error?: string;
	maxValue?: number;
}

type CurrencyInputProps = SharedProps & {
	value: number;
	onChange: (value: number) => void;
	onBlur?: () => void;
}

export const CurrencyInputPlain = forwardRef<HTMLInputElement, CurrencyInputProps>(
	({
		error,
		errorIconOnly,
		label,
		maxValue,
		name,
		onBlur,
		onChange,
		value,
		...props
	}: CurrencyInputProps, ref: ForwardedRef<HTMLInputElement>) => {
		const [displayValue, setDisplayValue] = useState<string>(formatNumberToCurrency(value));

		useEffect(() => {
			setDisplayValue(formatNumberToCurrency(value));
		}, [value]);

		function handleKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
			// keyCode 38 and 40 correspond to arrow up and arrow down, respectively
			if (e.key === "ArrowUp" || e.key === "ArrowDown") {
				e.preventDefault();  // Prevent scrolling
				let numberValue = value;
				const incrementValue = e.shiftKey ? 1000 : 100;  // If shift is pressed, increase/decrease by 1000, else 100
				if (e.key === "ArrowUp") {  // arrow up key
					numberValue += incrementValue;  // Increase
					if (maxValue !== undefined && numberValue > maxValue) {
						numberValue = maxValue;
					}
				} else if (e.key === "ArrowDown") {  // arrow down key
					numberValue -= incrementValue;  // Decrease
					if (numberValue < 0) {
						numberValue = 0;
					}
				}
				onChange(numberValue);
			}
		}

		function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
			e.target.select();  // Select input value
		}

		function intelligentParse(input: string): number {
			// Remove all non-numeric characters except commas and dots
			let cleaned = input.replace(/[^\d,.]*/g, '');

			// Remove leading zeros, but keep at least one digit if it's all zeros
			cleaned = cleaned.replace(/^0+(?=\d)/, '');

			// Find the last separator (comma or dot)
			const lastSeparatorIndex = Math.max(cleaned.lastIndexOf(','), cleaned.lastIndexOf('.'));

			let integerPart: string;
			let decimalPart: string = '00';

			if (lastSeparatorIndex !== -1) {
				// If there's a separator, split the string
				integerPart = cleaned.slice(0, lastSeparatorIndex).replace(/[,.]*/g, '');
				decimalPart = cleaned.slice(lastSeparatorIndex + 1).padEnd(2, '0').slice(0, 2);
			} else {
				// If there's no separator, treat the entire input as integer
				integerPart = cleaned;
			}

			// Combine integer and decimal parts
			return parseInt(integerPart + decimalPart, 10);
		}

		function handleBlur() {
			let numberValue = intelligentParse(displayValue);

			if (maxValue !== undefined && numberValue > maxValue) {
				numberValue = maxValue;
			}

			onChange(numberValue);
			setDisplayValue(formatNumberToCurrency(numberValue));
			onBlur && onBlur();
		}

		function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
			setDisplayValue(e.target.value);
		}


		return (
			<FormInput>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<InputPlain
						name={name}
						max={maxValue}
						type="text"
						value={displayValue}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						onKeyDown={handleKeydown}
						ref={ref}
						{...props}
					/>
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="size-5 text-red-500"
												   aria-hidden="true" />
						</div>
					)}
				</div>
				{error && !errorIconOnly && (
					<p className="mt-2 text-sm text-red-600"
					   id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);

	},
);

CurrencyInputPlain.displayName = "CurrencyInputPlain";

type CurrencyInputControllerProps = SharedProps & {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const CurrencyInput: React.FC<CurrencyInputControllerProps> = ({
	name,
	control,
	maxValue,
	useControllerError,
	error,
	rules,
	...rest
}) => {
	const { field, fieldState: { error: errorFromRHF } } = useController({
		name,
		control,
		rules,
	});

	const errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <CurrencyInputPlain {...field} {...rest} error={errorMessage}
							   maxValue={maxValue} />;
};


export default CurrencyInput;
