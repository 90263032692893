import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllOrders, getAllOrdersByProjectId, getOrder } from "~/modules/project/api/order/orderApiDispatchers.ts";
import type { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

interface UseOrderPropsType extends UseQueryBaseParams {
	orderId: string;
}

interface UseProjectsOrdersPropsType extends UseQueryBaseParams {
	projectId: string;
}

const ORDER_BASE_QUERY_KEY = "orders";

export const ORDER_QUERY_KEY = (orderId: string) => [ORDER_BASE_QUERY_KEY, orderId];
export const ALL_ORDERS_QUERY_KEY = [ORDER_BASE_QUERY_KEY, "all"];
export const PROJECTS_ORDERS_QUERY_KEY = (projectId: string) => [ORDER_BASE_QUERY_KEY, { projectId }];


export const useOrder = ({ orderId, options }: UseOrderPropsType) => useQuery(
	ORDER_QUERY_KEY(orderId),
	() => getOrder(orderId).then(getDataFromResponse),
	options,
);

export const useAllOrders = (options?: QueriesOptions<any>) => useQuery(
	ALL_ORDERS_QUERY_KEY,
	() => getAllOrders().then(getDataFromResponse),
	options,
);


export const useProjectsOrders = ({ projectId, options }: UseProjectsOrdersPropsType) => useQuery(
	PROJECTS_ORDERS_QUERY_KEY(projectId),
	() => getAllOrdersByProjectId(projectId).then(getDataFromResponse),
	options,
);

