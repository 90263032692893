import { PencilIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProjectTag from "components/projectTags/ProjectTag";
import ProjectTagWithUsage from "components/projectTags/ProjectTagWithUsage";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardWrapper from "~/components/Card/components/CardWrapper";
import ContentWrapper from "~/components/ContentWrapper";
import SearchInput from "~/components/formElements/SearchInput";
import CreateProductTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminProductTagsSection/components/CreateProductTagSidebar";
import DeleteProductTagModal
	from "~/modules/admin/components/AdminView/components/AdminProductTagsSection/components/DeleteProductTagModal";
import UpdateProductTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminProductTagsSection/components/UpdateProductTagSidebar";
import TaggedProjectListItem from "~/modules/admin/components/AdminView/components/TaggedProjectListItem";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllProductTags, useProductTagUsageReport } from "~/modules/project/api/productTags/productTagsQueries.ts";
import type { ProductTag } from "~/modules/project/api/productTags/productTagsTypes.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import type { ProductTagWithProjectId } from "~/modules/project/types/adminProjectTagsViewTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	loadingPercentage: number;
}

const AdminProductTagsSection: FunctionComponent<Props> = ({
	loadingPercentage,
}) => {
	const [search, setSearch] = useState<string>("");
	const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
	const [selectedTagData, setSelectedTagData] = useState<ProductTagWithProjectId | null>(null);
	const [showCreateSidebar, setShowCreateSidebar] = useState<boolean>(false);
	const [productTagDataToUpdate, setProductTagDataToUpdate] = useState<ProductTag | null>(null);
	const [productTagDataToDelete, setProductTagDataToDelete] = useState<ProductTag | null>(null);

	const { data: allClients } = useAllClients();
	const { data: allProjects } = useAllProjects();
	const { data: allProductTags } = useAllProductTags();
	const { data: usageReport } = useProductTagUsageReport();

	const enhancedSortedTags: ProductTagWithProjectId[] = useMemo(() => {
		if (allProductTags && usageReport) {
			return allProductTags.map((tag) => {
				const projectIds = usageReport.find((report) => report.productTagId === tag.id)?.projectIds || [];
				return {
					...tag,
					projectIds,
				};
			});
		}
		return [];
	}, [allProductTags, usageReport]);

	const filteredTags = useMemo(() => {
		if (enhancedSortedTags) {
			const productTagsSorted = enhancedSortedTags.sort(byObjectProperty("displayName"));
			if (search) {
				return productTagsSorted.filter((tag) => tag.displayName.toLowerCase().includes(search.toLowerCase()));
			}
			return productTagsSorted;
		}
		return undefined;
	}, [enhancedSortedTags, search]);


	useEffect(() => {
		if (enhancedSortedTags && selectedTagId) {
			const productTagData = enhancedSortedTags.find((tag) => tag.id === selectedTagId);

			if (productTagData) {
				setSelectedTagData(productTagData);
			}
		}

		if (!selectedTagId) {
			setSelectedTagData(null);
		}
	}, [selectedTagId, enhancedSortedTags]);

	if (loadingPercentage !== 100) {
		return <LoadingPage pcent={loadingPercentage} />;
	}

	return <ContentWrapper className="size-full overflow-hidden pb-10 pt-4">
		<div className="grid h-full grid-cols-5 gap-x-8">
			<div className="col-span-2 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max" className="flex flex-col">
					<CardHeader>
						<div className="mr-2 max-w-full grow">
							<SearchInput value={search}
										 onChange={setSearch} />
						</div>
						<Button onClick={() => setShowCreateSidebar(true)}>Neuer Tag</Button>
					</CardHeader>
					<CardContent hasTitle={true} className="grow overflow-y-auto">
						<div className="flex flex-col gap-y-2 ">
							{filteredTags?.map((tag) => {
								return <div key={tag.id}><ProjectTagWithUsage
									onClick={() => setSelectedTagId(tag.id)}
									size="sm"
									theme="product"
									selected={selectedTagData?.id === tag.id}
									displayName={tag.displayName}
									numberUsages={tag.projectIds.length} /></div>;

							})}
						</div>
					</CardContent>
				</CardWrapper>
			</div>
			<div className="col-span-3 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max" className="flex flex-col">
					<CardHeader>
						<div className="w-1/2 grow-0">
							{selectedTagData ? <ProjectTag theme="product"
														   size="md"
														   displayName={selectedTagData.displayName} /> :
								<ProjectTag size="md"
											displayName="Kein Tag ausgewählt"
											theme="neutral" />}
						</div>
						<div className="ml-2 flex justify-end gap-x-2 self-end">
							<Button theme="danger"
									disabled={!selectedTagData}
									onClick={() => setProductTagDataToDelete(selectedTagData)}
							>
								<TrashIcon className="size-4" />
								Löschen</Button>
							<Button theme="primary"
									disabled={!selectedTagData}
									onClick={() => setProductTagDataToUpdate(selectedTagData)}>
								<PencilIcon className="size-4" />Bearbeiten</Button>
						</div>
					</CardHeader>
					<CardContent hasTitle={true} className="grow overflow-y-auto">
						<div className="flex flex-col gap-y-2">
							{selectedTagData && selectedTagData.projectIds.map((projectId) => {
								const projectData = allProjects?.find((project) => project.id === projectId);
								let clientDisplayName = "";
								if (projectData && allClients) {
									clientDisplayName = allClients.find((client) => client.id === projectData.clientId)?.displayName || "";
								}

								return projectData ? <TaggedProjectListItem key={projectId}
																			projectData={projectData}
																			clientDisplayName={clientDisplayName} /> : null;
							})}
							{selectedTagData && selectedTagData.projectIds.length === 0 &&
								<div className="text-gray-500">Dieser Tag ist keinem Projekt zugeordnet.</div>}
							{!selectedTagData &&
								<div className="text-gray-500">Tag auswählen, um dessen Details anzuzeigen</div>}
						</div>
					</CardContent>
				</CardWrapper>
			</div>
		</div>
		<CreateProductTagSidebar isOpen={showCreateSidebar}
								 onClose={() => setShowCreateSidebar(false)} />
		<UpdateProductTagSidebar isOpen={!!productTagDataToUpdate}
								 onClose={() => setProductTagDataToUpdate(null)}
								 productTagData={productTagDataToUpdate} />
		<DeleteProductTagModal isOpen={!!productTagDataToDelete}
							   onClose={() => setProductTagDataToDelete(null)}
							   productTagData={productTagDataToDelete}
							   setSelectedTagId={setSelectedTagId}
		/>
	</ContentWrapper>;
};

export default AdminProductTagsSection;