import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";


type TableBodyCellProps = PropsWithChildren & {
	className?: string;
	colSpan?: number;
	hasWidthRestriction?: boolean;
	onClick?: () => void;
	textAlign?: "left" | "center" | "right";
};

const TableBodyCell: FunctionComponent<TableBodyCellProps> = ({
	children,
	className,
	colSpan,
	hasWidthRestriction,
	onClick,
	textAlign,
}) => {
	let textAlignClass = "text-center";
	if (textAlign === "left") {
		textAlignClass = "text-left";
	}
	if (textAlign === "right") {
		textAlignClass = "text-right";
	}

	return <td onClick={onClick}
			   colSpan={colSpan}
			   className={clsx("px-4 py-3 align-middle text-sm", className, hasWidthRestriction ? "truncate" : "whitespace-nowrap",
				   textAlignClass)}>{children}</td>;
};

export default TableBodyCell;