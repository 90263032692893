import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllLocations, getUsersLocationsForAvailableStaffings } from "~/modules/location/api/location/locationApiDispatchers.ts";

export const LOCATION_BASE_QUERY_KEY = "location";

export const All_LOCATIONS_QUERY_KEY = [LOCATION_BASE_QUERY_KEY, "all"];
export const USER_LOCATIONS_QUERY_KEY = (userId: string) => [LOCATION_BASE_QUERY_KEY, { userId }];

export const useAllLocations = (options?: QueriesOptions<any>) =>
	useQuery(All_LOCATIONS_QUERY_KEY, () => getAllLocations().then(getDataFromResponse), options);

export const useUsersAvailableLocations = ({
											   userId,
											   options,
										   }: {
	userId: string;
	options?: QueriesOptions<any>;
}) =>
	useQuery(
		USER_LOCATIONS_QUERY_KEY(userId),
		() => getUsersLocationsForAvailableStaffings({ userId }).then(getDataFromResponse),
		options,
	);
