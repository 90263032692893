import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { startOfDay } from "date-fns";
import { t } from "i18next";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createVacationLedgerEntry,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";
import VacationLedgerEntryFormSection from "~/modules/absence/components/formSections/VacationLedgerEntryFormSection";
import useVacationLedgerEntryTypeOptions from "~/modules/absence/hooks/useVacationLedgerEntryTypeOptions.tsx";
import { EntryType } from "~/modules/absence/types/vacationLedgerEnums.ts";
import type { VacationLedgerFormData } from "~/modules/absence/types/vacationLedgerFormTypes.ts";
import { convertVacationLedgerEntryFormDataToApiData } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateVacationLedgerEntryFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberId: string,
};

const CreateVacationLedgerEntryForm: React.FunctionComponent<CreateVacationLedgerEntryFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberId,
}) => {
	const [busy, setBusy] = useState(false);
	const schema = useMemo(() => {
		return yup.object({
			entryTypeId: yup.string().required(),
			validFrom: yup.date().required(),
			comment: yup.string().required().default(""),
			workDays: yup.number().required().default(0),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			entryTypeId: EntryType.Credit,
			validFrom: startOfDay(new Date()),
			comment: "",
			workDays: 0,
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
	} = useForm<VacationLedgerFormData>({
		mode: "onSubmit",
		defaultValues: defaultValues,
		resolver: yupResolver<VacationLedgerFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
	});

	const onSubmit = useCallback(async (data: VacationLedgerFormData) => {
		try {
			setBusy(true);
			await createVacationLedgerEntry({
				staffMemberId,
				data: convertVacationLedgerEntryFormDataToApiData(data),
			});
			onSuccess();
		} catch (error) {
			console.error(error);
		}
	}, [onSuccess, staffMemberId]);

	const entryTypeSelectOptions = useVacationLedgerEntryTypeOptions();

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neuen Eintrag erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<VacationLedgerEntryFormSection
					control={control}
					entryTypeSelectOptions={entryTypeSelectOptions}
					errors={errors} />
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey('form:buttonTexts.save'))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey('form:buttonTexts.cancel'))}
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateVacationLedgerEntryForm;
