import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const timeTrackingBarVariants = tv({
	slots: {
		barWrapper: "group flex overflow-hidden rounded-md text-gray-500",
		barInner: "flex w-full min-w-0 flex-shrink content-center justify-start overflow-hidden overflow-ellipsis whitespace-nowrap p-3 text-sm",
	},
	variants: {
		theme: {
			project: {
				barInner: "bg-primary-400 text-white group-hover:bg-primary-500",
			},
			internal: {
				barInner: "bg-gray-400 text-white group-hover:bg-gray-500",
			},
			sales: {
				barInner: "bg-gray-600 text-white group-hover:bg-gray-700",
			},
			absence: {
				barInner: "bg-success-dark-700 font-bold text-white",
			},
			partTime: {
				barInner: "bg-success-dark-700 font-bold text-white group-hover:bg-success-dark-800",
			},
			default: {
				barInner: "bg-gray-100",
			},
		},

	},
	defaultVariants: {
		theme: "default",
	},
});


export type TimeTrackingBarVariants = VariantProps<typeof timeTrackingBarVariants>;
export type TimeTrackingBarThemes = "project" | "internal" | "sales" | "default" | "absence" | "partTime";

