import Button from "components/buttons/Button";
import type React from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";

import Sidebar from "~/components/Sidebar";
import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import { All_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { ALL_PROJECTS_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import { PROJECTS_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import CreateProjectForm from "~/modules/project/components/forms/CreateProjectForm";

type CreateProjectSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
};

const CreateProjectSidebar: React.FunctionComponent<CreateProjectSidebarProps> = ({ isOpen, setOpen }) => {
	const [newProjectData, setNewProjectData] = useState<Project | null>(null);

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async (project: Project) => {
			queryClient.invalidateQueries(All_LOCATIONS_QUERY_KEY);
			queryClient.invalidateQueries(PROJECTS_STAFFINGS_QUERY_KEY(project.id));
			await queryClient.invalidateQueries(ALL_PROJECTS_QUERY_KEY);
			setNewProjectData(project);
		},
		[queryClient],
	);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			{!newProjectData && <CreateProjectForm onSuccess={handleSuccess} onCancel={() => setOpen(false)} />}
			{newProjectData && (
				<div className="px-8 py-6">
					<div className="mb-4 text-center text-xl font-bold text-gray-800">
						Neues Project &quot;{newProjectData.title}&quot; erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						<Button
							className="justify-center"
							to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId: newProjectData.id })}
						>
							Projektseite öffnen
						</Button>
						<Button className="justify-center" onClick={() => setOpen(false)}>
							Fenster schliessen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateProjectSidebar;
