import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getDeliverableTagsUsageReport,
	indexDeliverableTags,
} from "~/modules/project/api/deliverableTags/deliverableTagsApiDispatchers.ts";

export const DELIVERABLE_TAG_BASE_QUERY_KEY = "deliverable-tags";

export const ALL_DELIVERABLE_TAGS_QUERY_KEY = [DELIVERABLE_TAG_BASE_QUERY_KEY, "all"];

export const useAllDeliverableTags = (options?: QueriesOptions<any>) => useQuery(
	ALL_DELIVERABLE_TAGS_QUERY_KEY,
	() => indexDeliverableTags().then(getDataFromResponse),
	options,
);

export const DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY = [DELIVERABLE_TAG_BASE_QUERY_KEY, "usage-report"];

export const useDeliverableTagUsageReport = (options?: QueriesOptions<any>) => useQuery(
	DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY,
	() => getDeliverableTagsUsageReport().then(getDataFromResponse),
	options,
);