import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	requestPasswordResetEndpoint,
	validatePasswordResetTokenEndpoint,
} from "~/modules/user/api/passwordReset/passwordResetApiEndpoints.ts";

export function requestPasswordRequest(email: string): AxiosApiResponsePromise<string> {
	return axiosApi.post(requestPasswordResetEndpoint, { email });
}

export function validatePasswordResetToken(token: string): AxiosApiResponsePromise<string> {
	return axiosApi.get(validatePasswordResetTokenEndpoint(token));
}