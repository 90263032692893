import { EnvelopeIcon, PencilIcon, PhoneIcon, StarIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Headline from "~/components/Headline";

interface Props {
	displayName: string;
	email: string | null;
	onDeleteClick: () => void;
	onEditClick: () => void;
	supportContact: string | null;
	supportContactPhone: string | null;
}

const InvoiceRecipientContactCard: React.FC<Props> = ({
	displayName,
	email,
	onDeleteClick,
	onEditClick,
	supportContact,
	supportContactPhone,
}) => {
	return (
		<div
			className={clsx(
				"isolate col-span-1 transform-gpu divide-y divide-gray-200 rounded-lg shadow transition-transform duration-200",
				"bg-white",
				"hover:shadow-md",
			)}
		>
			<div className="group flex w-full items-center justify-between space-x-6 rounded-lg bg-white p-6">
				<div className="flex-1">
					<div className="flex items-center justify-between space-x-3">
						<Headline type="h5"
								  className="group-hover:text-secondary-500">
							{displayName}
						</Headline>
						<div className="relative z-50">
							<ButtonWithPopover theme="dark"
											   items={[
												   {
													   label: "bearbeiten",
													   onClick: onEditClick,
													   icon: PencilIcon,
												   },
												   {
													   label: "löschen",
													   onClick: onDeleteClick,
													   icon: TrashIcon,
												   },
											   ]} />
						</div>
					</div>
					{supportContact && (
						<div className="mt-1 flex flex-row items-center gap-1 truncate text-sm text-gray-500">
							<StarIcon className="size-4" />
							{supportContact}
						</div>
					)}
				</div>
			</div>
			{(email || supportContactPhone) && (
				<div>
					<div className="-mt-px flex divide-x divide-gray-200">
						{email && (
							<div className="flex w-0 flex-1">
								<a
									href={`mailto:${email}`}
									className={clsx(
										"relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 py-4",
										"rounded-bl-lg border border-transparent",
										"text-sm font-semibold text-gray-700",
										"hover:bg-secondary-500 hover:text-white",
										"transition-all duration-200",
									)}
								>
									<EnvelopeIcon className="size-5 text-current"
												  aria-hidden="true" />
									Email
								</a>
							</div>
						)}
						{supportContactPhone && (
							<div className="-ml-px flex w-0 flex-1">
								<a
									href={`tel:${supportContactPhone}`}
									className={clsx(
										"relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 py-4",
										"rounded-br-lg border border-transparent",
										"text-sm font-semibold text-gray-900",
										"hover:bg-secondary-500 hover:text-white",
										"transition-all duration-200",
									)}
								>
									<PhoneIcon className="size-5 text-current"
											   aria-hidden="true" />
									Anrufen
								</a>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default InvoiceRecipientContactCard;
