import { create } from "zustand";

type State = {
	isVisible: boolean;
	setVisibility: (isVisible: boolean) => void;

};

export const useStaffMemberCompensationVisibilityStore = create<State>((set) => ({
	isVisible: false,
	setVisibility: (isVisible: boolean) => set({ isVisible }),
}));