import clsx from "clsx";
import type { PropsWithChildren } from "react";

type GridSegmentTextProps = PropsWithChildren & {
	highlighted?: boolean;
	widthPx: number;
	onClick?: (startDate: Date) => void;
	startDate: Date;
}

const GridSegmentText: React.FunctionComponent<GridSegmentTextProps> = ({
	children,
	highlighted,
	onClick,
	startDate,
	widthPx,
}) => {
	if (onClick && startDate) {
		return <button type="button"
					   onClick={() => onClick(startDate)}
					   className={clsx("sticky flex items-center justify-center text-sm hover:font-bold", highlighted && "font-bold text-secondary-500 hover:text-secondary-700")}
					   style={{ width: `${widthPx}px` }}>{children}</button>;
	}

	return <div className={clsx("sticky flex items-center justify-center text-sm", highlighted && "font-bold text-secondary-500")}
				style={{ width: `${widthPx}px` }}>{children}</div>;
};

export default GridSegmentText;