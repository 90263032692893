import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { Absence } from "~/modules/absence/api/absence/absenceTypes.ts";
import type {
	CreateHolidayData,
	Holiday,
	UpdateHolidayData,
} from "~/modules/absence/api/holiday/holidayTypes.ts";

export function indexHolidaysByYear({ year }: { year: string }): AxiosApiResponsePromise<Holiday[]> {
	return axiosApi.get(`holidays/years/${year}`);
}

export function indexHolidays(): AxiosApiResponsePromise<Holiday[]> {
	return axiosApi.get("holidays");
}

export function createHoliday({ data }: {
	data: CreateHolidayData
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.post("holidays", data);
}

export function updateHoliday({ holidayId, data }: {
	holidayId: string,
	data: UpdateHolidayData
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.post(`holidays/${holidayId}`, data);
}

export function deleteHoliday({ holidayId }: {
	holidayId: string,
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`holidays/${holidayId}`);
}