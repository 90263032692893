import type { Control, FieldErrors, FieldValues, Path, UseFormWatch } from "react-hook-form";

import CurrencyInput from "~/components/formElements/CurrencyInput";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Label from "~/components/formElements/Label";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type StaffingBudgetFormSectionProps<T extends FieldValues> = {
	watch:UseFormWatch<T>;
	control: Control<T>;
	errors: FieldErrors<T>;
	placeholder?: string;
};

type StaffingBudgetFormSectionType = {
	manDays: number;
	dailyRateCents: number;
};

const StaffingBudgetFormSection = <T extends StaffingBudgetFormSectionType>({
																				control,
																				errors,
																				watch,
																			}: StaffingBudgetFormSectionProps<T>) => {
	const manDays = watch("manDays" as Path<T>);
	const dailyRateCents = watch("dailyRateCents" as Path<T>);
	const totalCents = manDays * dailyRateCents;
	return (
		<FormSection title="Kommerzielle Daten">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<Input
						name="manDays"
						control={control}
						label="Personentage"
						type={"number"}
					/>
				</div>
				<div className="col-span-3">
					<CurrencyInput
						control={control}
						name="dailyRateCents"
						label="Tagessatz (EUR)"
						error={errors.dailyRateCents?.message as string}
					/>
				</div>
				<div className="col-span-6">
					<Label>Summe</Label>
					<div>{formatCentsToCurrency(totalCents)}</div>
				</div>
			</div>
		</FormSection>
	);
};

export default StaffingBudgetFormSection;
