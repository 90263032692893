import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import type React from "react";
import { useCallback, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import * as yup from "yup";

import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import Logo from "~/components/Logo";
import { appRoutes } from "~/constants/appRoute.ts";
import { validatePasswordResetToken } from "~/modules/user/api/passwordReset/passwordResetApiDispatchers";
import { resetPassword } from "~/modules/user/api/user/userApiDispatchers";

type FormData = {
	password: string;
	passwordConfirmation: string;
};

const ResetPassword: React.FC = () => {

	const schema = yup.object().shape({
		password: yup.string().min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.").required(),
		passwordConfirmation: yup.string().oneOf([yup.ref("password")], "Die Passwörter stimmen nicht überein.").required(),
	});

	const {
		control,
		handleSubmit,
		watch,
	} = useForm<FormData>({
		defaultValues: { password: "", passwordConfirmation: "" },
		resolver: yupResolver<FormData>(schema),
	});
	const { token } = useParams<{ token: string }>();
	const [busy, setBusy] = useState(false);

	const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
	const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
	const [apiError, setApiError] = useState<boolean | null>(null);

	useAsyncEffect(async () => {
		if (undefined === token) {
			setTokenIsValid(false);
			return;
		}
		try {
			await validatePasswordResetToken(token);
			setTokenIsValid(true);
		} catch (error) {
			setTokenIsValid(false);
		}
	}, [token]);

	const onSubmit: SubmitHandler<FormData> = useCallback(
		async (data) => {
			setBusy(true);
			if (undefined === token) {
				return;
			}
			try {
				await resetPassword(data.password, data.passwordConfirmation, token);
				setUpdateSuccess(true);
			} catch (error) {
				console.log(error);
				setApiError(true);
			}
			setBusy(false);
		},
		[token],
	);

	return (
		<div className="flex w-full max-w-md flex-col gap-4">
			<div className="ml-8 w-44 text-primary-500">
				<Logo />
			</div>
			<div className="rounded-md bg-white p-8 shadow-md">
				<Headline
					type="h4"
					className="mb-4 text-primary-500">
					Neues Passwort festlegen
				</Headline>
				{tokenIsValid === null && (
					<div className="flex justify-center">
						<LoadingSpinner />
					</div>
				)}
				{tokenIsValid === false &&
					<><p className="leading-6 text-gray-900">Dieser Link is leider nicht mehr gültig. Solltest du immer
						noch ein
						neues Passwort benötigen. Starte den Vorgang bitte erneut.</p>
						<Button className="mt-5"
								to={appRoutes.passwordResetRequest().path}>Passwort zurücksetzen</Button>
					</>}
				{tokenIsValid && !updateSuccess && (
					<>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="space-y-6">
							<div className="flex flex-col gap-4">
								<Input
									name="password"
									type="password"
									label="Neues Passwort (min. 8 Zeichen)"
									control={control}
								/>

								<Input
									name="passwordConfirmation"
									type="password"
									label="Passwort wiederholen"
									control={control}
									rules={{ validate: (value: string) => value === watch("password") }}
								/>

								<div className="flex items-center justify-between">
									<SubmitButton busy={busy}>Update</SubmitButton>
								</div>
								{apiError && <div className="mt-2 text-red-500">Speichern fehlgeschlagen.</div>}
							</div>
						</form>
					</>
				)}
				{updateSuccess && (
					<>
						<div className="mb-2 flex flex-row items-center gap-x-2">
							<CheckCircleIcon className="h-20 fill-success-500" /><span>Das Passwort wurde erfolgreich gespeichert.</span>
						</div>
						<Button theme="none"
								to={appRoutes.login().path}>Zurück zum Login</Button>
					</>
				)
				}
			</div>
		</div>
	);
};

export default ResetPassword;
