import {createHeadlessEditor} from "@lexical/headless";
import {$generateHtmlFromNodes} from "@lexical/html";
import type {CreateEditorArgs, LexicalEditor, SerializedEditorState} from "lexical";

import {editorBaseConfig} from "~/components/TextEditor/editorConfig.ts";

export const getHtmlFromEditorState = ({editor, editorState}: {
    editor?: LexicalEditor,
    editorState: SerializedEditorState | string | undefined
}): string => {
    const editorInstance = editor || createHeadlessEditor({...editorBaseConfig as CreateEditorArgs});
    if (!editorState) return "";
    const parsedState = editorInstance.parseEditorState(editorState);

    // sometimes the default empty editor state we are providing (see getEmptyEditorStateJson function in this file)
    // will fail the LexicalEditor's internal isEmpty() test(which is used in the editorInstance.setEditorState() method),
    // so we need to catch that error and return an empty string.
    // The test failing is due to a missing selection information in the default empty editor state. Strangely that error
    // does only happen sometimes (or maybe only in the headless editor? Needs to be investigated further)
    try {
        editorInstance.setEditorState(parsedState);
    } catch (error) {
        return "";
    }

    return editorInstance.getEditorState().read(() => {
        const rootNode = editorInstance.getEditorState()._nodeMap.get('root');
        //@ts-expect-error - the isEmpty() method is not part of the public API
        const isEmpty = rootNode?.getFirstChild().isEmpty() && rootNode?.getChildren().length === 1;

        return isEmpty ? "" : $generateHtmlFromNodes(editorInstance, null)
    });
};


export const getHeadlessEditorInstance = (editorState?: string | SerializedEditorState): LexicalEditor => {
    const editorInstance = createHeadlessEditor({...editorBaseConfig as CreateEditorArgs, editable: true});
    if (editorState) {
        try {
            const parsedState = editorInstance.parseEditorState(editorState);
            editorInstance.setEditorState(parsedState);
        } catch (error) {
            console.log(error);
        }
    }

    return editorInstance;
};

export const getEmptyEditorStateJson = (): string => {
    return JSON.stringify(createHeadlessEditor({...editorBaseConfig as CreateEditorArgs}).getEditorState().toJSON());
}