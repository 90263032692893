import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";

import CompanyLogo from "~/components/CompanyLogo";
import Status, { WorkingStatus } from "~/components/Status/Status.tsx";
import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { ProjectSummary } from "~/modules/project/api/project/projectTypes.ts";
import type { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { formatManDays, minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { AuthenticatedUser, User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

const MaxUsersVisible = 5;

interface ProjectsIndexSummeryPageProps {
	project: ProjectSummary;
	clients: Client[];
	users: User[];
	user?: AuthenticatedUser;
	workingStatuses: WorkingStatusType[];
}

const ProjectsIndexProjectSummary: FunctionComponent<ProjectsIndexSummeryPageProps> = ({
	project,
	clients,
	users,
	user,
	workingStatuses,
}) => {
	const { t } = useTranslation();

	const {
		id,
		clientId,
		manDaysPlanned,
		minutesTracked,
		title,
		projectNumber,
		isActive,
		isInternal,
		projectPhaseStatusesIds,
	} = project;
	const totalManDays = Math.round(parseInt(manDaysPlanned));
	const totalSpentDays = minutesToWorkdays(minutesTracked);
	const totalAvailableDays = totalManDays - totalSpentDays;

	const clientDisplayName = clients.find((client) => client.id === clientId)?.displayName;

	const workingStatusBatches = useMemo(() => {
		if (projectPhaseStatusesIds.length > 0) {
			return projectPhaseStatusesIds.map((id, index) => {
				const workingStatus = getOneOfCollection(workingStatuses, id);
				if (workingStatus) {
					return <WorkingStatus key={"status-badge" + index + workingStatus.id}
										  id={workingStatus.id}>
						{t(normalizeTKey("entities:workingStatus." + workingStatus.name))}
					</WorkingStatus>;
				}
			});
		}
		return null;
	}, [projectPhaseStatusesIds, t, workingStatuses]);

	let staffedUserIds = project.staffedUserIds;
	if (undefined !== user && project.staffedUserIds.includes(user.id)) {
		staffedUserIds = [user.id, ...project.staffedUserIds.filter((id) => user.id !== id)];
	}

	return (
		<NavLink to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId: id })}>
			<div className={clsx("group/infobox group/summary")}>
				<div className="mb-1 flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div>
						<div
							className={clsx("flex items-center gap-2",
								"text-[1.375rem] font-bold text-primary-500",
								"transition-colors duration-200 group-hover/summary:text-secondary-500",
							)}
						>
							{title}
							{isInternal && <Status theme="error">INTERN</Status>}
						</div>
					</div>
					<div className="flex shrink-0 justify-between gap-2">
						{workingStatusBatches}
						<Status>{clientDisplayName}</Status>
						<Status>{projectNumber}</Status>
					</div>
				</div>
				<div
					className={clsx(
						"group-hover/infobox:shadow-md",
						"flex w-full flex-row gap-4",
						"rounded-lg p-4 sm:px-6",
						"transition-colors duration-200",
						"bg-primary-500/5",
						"",
						isActive ? "border-l-8 border-success-500" : "border-l-8 border-danger-300",
					)}
				>
					<div className="flex items-center">
						<CompanyLogo width="w-12"
									 height="h-12"
									 image={project.companyLogo}
									 displayName={project.companyShortName} />
					</div>
					<div className="w-24 font-bold text-primary-500">
						<div className="flex w-full justify-center text-sm font-bold ">{t("project.summary.labels.totalAvailableDays", "Offen")}</div>
						<div
							className={clsx(
								"flex h-8 w-full items-center justify-center text-xl font-bold",
								0 > totalAvailableDays ? "text-red-500" : "text-primary-500",
							)}
						>
							{formatManDays(totalAvailableDays)} PT
						</div>
					</div>
					<div className="w-24 font-bold text-primary-500">
						<div
							className="flex w-full justify-center text-sm">{t("project.summary.labels.totalManDays", "Erfasst")}</div>
						<div
							className="flex h-8 w-full items-center justify-center text-xl">
							{formatManDays(totalSpentDays)} PT
						</div>
					</div>

					<div className="ml-4 flex grow flex-col">
						<small className="text-xs text-gray-400">
							{t("project.summary.labels.crew", "Team")} ({staffedUserIds.length})
						</small>
						<div className="mt-1 flex items-center justify-start gap-2">
							{staffedUserIds && (
								<>
									{staffedUserIds.map(
										(userId, index) =>
											MaxUsersVisible > index && (
												<div
													key={`staffing-user-${userId}`}
													className="rounded-lg bg-primary-500/5 p-2 text-xs font-bold"
												>
													{users.find((user) => userId === user.id)?.fullName}
												</div>
											),
									)}
									{MaxUsersVisible < staffedUserIds.length && (
										<div
											className="rounded-lg bg-primary-500/5 p-2 text-xs font-bold">
											+{staffedUserIds.length - MaxUsersVisible}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</NavLink>
	);
};

export default ProjectsIndexProjectSummary;
