import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = PropsWithChildren & {
	isStartItem?: boolean;
	date: Date;
	dotTheme?: "success" | "danger" | "neutral";
	isStopItem?: boolean;
	isOdd?: boolean;
};

const TableTimelineItem: FunctionComponent<Props> = ({
	children,
	date,
	dotTheme = "neutral",
	isOdd = false,
	isStartItem = false,
	isStopItem = false,
}) => {
	return <tr className={clsx("group relative", !isOdd && "bg-gray-100")}>
		<td className={clsx("flex items-center gap-x-4 py-3 pl-4")}>
			<div className={clsx(
				!isStartItem && !isStopItem && "-bottom-6 top-0",
				isStartItem && !isStopItem && "top-0 h-6",
				!isStartItem && isStopItem && "top-6 h-6",
				"absolute left-4 flex w-6 justify-center",
			)}>
				<div className="w-px bg-gray-200" />
			</div>
			<div className={clsx("relative flex size-6 flex-none items-center justify-center", !isOdd ? "bg-gray-100" : "bg-white")}>
				<div className={clsx("size-1.5 rounded-full ring-1",
					dotTheme === "success" && "bg-success-300 ring-success-500",
					dotTheme === "danger" && "bg-danger-300 ring-danger-500",
					dotTheme === "neutral" && "bg-gray-100 ring-gray-300",
				)} />
			</div>
			<time dateTime={date.toDateString()}
				  className="min-w-20 flex-none py-0.5 text-right text-xs leading-5 text-gray-500">
				{formatDateWithGermanMonth(date)}
			</time>
		</td>
		{children}
	</tr>;
};

export default TableTimelineItem;