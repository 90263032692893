import type { FunctionComponent } from "react";

import type {
	ProjectTagVariantsProps,
} from "~/components/projectTags/ProjectTag/projectTagVariants.ts";
import {
	projectTagVariants,
} from "~/components/projectTags/ProjectTag/projectTagVariants.ts";

type Props = ProjectTagVariantsProps & {
	displayName: string;
	onDeleteClick?: () => void;
};

const ProjectTag: FunctionComponent<Props> = ({ displayName, onDeleteClick, muted, size, theme }) => {

	const { container } = projectTagVariants({ size, theme, muted });

	return <div className={container()}>
		<span className="shrink truncate">{displayName}</span>
		{onDeleteClick && <button onClick={onDeleteClick}
								  type="button"
								  className="ml-2">
			<svg xmlns="http://www.w3.org/2000/svg"
				 className="size-4"
				 fill="none"
				 viewBox="0 0 24 24"
				 stroke="currentColor">
				<path strokeLinecap="round"
					  strokeLinejoin="round"
					  strokeWidth={2}
					  d="M6 18L18 6M6 6l12 12" />
			</svg>
		</button>}
	</div>;
};

export default ProjectTag;