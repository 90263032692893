import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberFtePercentageData,
	StaffMemberFtePercentage,
	UpdateStaffMemberFtePercentageData,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageTypes.ts";

const ftePercentageBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/fte-percentages`;

export function indexStaffMembersFtePercentages(staffMemberId: string): AxiosApiResponsePromise<StaffMemberFtePercentage[]> {
	return axiosApi.get(ftePercentageBasePath(staffMemberId));
}

export function createStaffMemberFtePercentage(staffMemberId: string,
	ftePercentageData: CreateStaffMemberFtePercentageData): AxiosApiResponsePromise<void> {
	return axiosApi.post(ftePercentageBasePath(staffMemberId), ftePercentageData);
}

export function updateStaffMemberFtePercentage({ staffMemberId, ftePercentageId, updateData }: {
	staffMemberId: string;
	ftePercentageId: string;
	updateData: UpdateStaffMemberFtePercentageData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${ftePercentageBasePath(staffMemberId)}/${ftePercentageId}`, updateData);
}

export function deleteStaffMemberFtePercentage(staffMemberId: string,
	ftePercentageId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${ftePercentageBasePath(staffMemberId)}/${ftePercentageId}`);
}