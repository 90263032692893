import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	Absence,
	CreateAbsenceData,
	UpdateAbsenceData,
} from "~/modules/absence/api/absence/absenceTypes.ts";

const staffMembersAbsencesBasePath = (staffMemberId:string)=>`/staff-members/${staffMemberId}/absences`;

export function indexAbsences({ startDate, endDate }: {
	startDate: string,
	endDate: string
}): AxiosApiResponsePromise<Absence[]> {
	return axiosApi.get(`absences`, { params: { startDate, endDate } });
}


export function indexUsersAbsences({ staffMemberId }: { staffMemberId: string }): AxiosApiResponsePromise<Absence[]> {
	return axiosApi.get(staffMembersAbsencesBasePath(staffMemberId));
}

export function createAbsence({ staffMemberId, data }: {
	staffMemberId: string,
	data: CreateAbsenceData
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.post(staffMembersAbsencesBasePath(staffMemberId), data);
}

export function updateAbsence({ staffMemberId, absenceId, data }: {
	staffMemberId: string,
	absenceId: string,
	data: UpdateAbsenceData
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.put(`${staffMembersAbsencesBasePath(staffMemberId)}/${absenceId}`, data);
}

export function deleteAbsence({ staffMemberId, absenceId }: {
	staffMemberId: string,
	absenceId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${staffMembersAbsencesBasePath(staffMemberId)}/${absenceId}`);
}