import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexTaxClasses } from "~/modules/humanResources/api/taxClass/taxClassApiDispatchers.ts";

export const TAX_CLASSES_BASE_QUERY_KEY = "taxClasses";

export const ALL_TAX_CLASSES_QUERY_KEY = [TAX_CLASSES_BASE_QUERY_KEY, "all"];

export const useAllTaxClasses = (options?: QueriesOptions<any>) => useQuery(
	ALL_TAX_CLASSES_QUERY_KEY,
	() => indexTaxClasses().then(getDataFromResponse),
	options,
);