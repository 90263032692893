import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { ADMIN_PATH_WITH_DEFAULT_TAB_ID, appRoutes, TIME_TRACKINGS_PATH } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

const LandingPage: FunctionComponent = () => {
	const { user, hasAnyPermission } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const options = { replace: true };

		if (hasAnyPermission(PermissionNames.CanManageProjects)) {
			navigate(appRoutes.projects().path, options);
		} else if (hasAnyPermission(PermissionNames.CanManageInvoices)) {
			navigate(appRoutes.monthlyBillingReport().path, options);
		} else if (hasAnyPermission(PermissionNames.CanManageUsers)) {
			navigate(ADMIN_PATH_WITH_DEFAULT_TAB_ID, options);
		} else {
			navigate(generatePath(TIME_TRACKINGS_PATH, { userId: user!.id }), options);
		}
	}, [hasAnyPermission, navigate, user]);
	return <LoadingPage />;
};

export default LandingPage;