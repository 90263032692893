import type { MouseEvent } from "react";
import { Fragment } from "react";

import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import TimeTrackingFormTag from "~/modules/timeTracking/components/components/TimeTrackingFormTag";


interface DeliverablesProps {
	currentDeliverablesString: string | null;
	data: UserAvailableDeliverableType[][];
	onClick: (displayName: string, event: MouseEvent) => void;
}

const Deliverables: React.FC<DeliverablesProps> = ({ currentDeliverablesString, data, onClick }) => {
	return data.map(
		(dGroup, dGroupIndex) =>
			0 < dGroup.length && (
				<Fragment key={dGroupIndex}>
					{dGroup.map(({ id, displayName, projectId }) => (
						<TimeTrackingFormTag
							selected={currentDeliverablesString?.indexOf(displayName) !== -1}
							key={`deliverable-${id}-${projectId}`}
							onClick={(event: MouseEvent) => onClick(displayName, event)}>
							{displayName}
						</TimeTrackingFormTag>
					))}
				</Fragment>
			),
	);
};

export default Deliverables;
