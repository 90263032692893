import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_SPECIAL_AGREEMENTS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementQueries.ts";
import type {
	StaffMemberSpecialAgreement,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementTypes.ts";
import UpdateSpecialAgreementsForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/SpecialAgreementsSection/components/UpdateSpecialAgreementsSidebar/components/UpdateSpecialAgreementsForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	specialAgreementsData: StaffMemberSpecialAgreement | null;
	staffMemberId: string;
};

const UpdateSpecialAgreementsSidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	specialAgreementsData,
	staffMemberId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(STAFF_MEMBERS_SPECIAL_AGREEMENTS_QUERY_KEY(staffMemberId));
		onClose();
	}, [queryClient, staffMemberId, onClose]);

	return (
		<Sidebar closeOnOutsideClick={false}

				 open={isOpen}
				 setOpen={() => onClose()}>
			{specialAgreementsData && <UpdateSpecialAgreementsForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				specialAgreementsData={specialAgreementsData} />}
		</Sidebar>
	);
};

export default UpdateSpecialAgreementsSidebar;
