import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_PRODUCT_TAGS_QUERY_KEY } from "~/modules/project/api/productTags/productTagsQueries.ts";
import CreateProductTagForm
	from "~/modules/admin/components/AdminView/components/AdminProductTagsSection/components/CreateProductTagSidebar/components/CreateProductTagForm";

type CreateProductTagSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
};

const CreateProductTagSidebar: FunctionComponent<CreateProductTagSidebarProps> = ({
	isOpen,
	onClose,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(ALL_PRODUCT_TAGS_QUERY_KEY);
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <CreateProductTagForm onSuccess={handleSuccess}
											 onCancel={onClose} />}
		</Sidebar>
	);
};

export default CreateProductTagSidebar;
