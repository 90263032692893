import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getProductTagsUsageReport,
	indexProductTags,
} from "~/modules/project/api/productTags/productTagsApiDispatchers.ts";

export const PRODUCT_TAG_BASE_QUERY_KEY = "product-tags";

export const ALL_PRODUCT_TAGS_QUERY_KEY = [PRODUCT_TAG_BASE_QUERY_KEY, "all"];

export const useAllProductTags = (options?: QueriesOptions<any>) => useQuery(
	ALL_PRODUCT_TAGS_QUERY_KEY,
	() => indexProductTags().then(getDataFromResponse),
	options,
);

export const PRODUCT_TAG_USAGE_REPORT_QUERY_KEY = [PRODUCT_TAG_BASE_QUERY_KEY, "usage-report"];

export const useProductTagUsageReport = (options?: QueriesOptions<any>) => useQuery(
	PRODUCT_TAG_USAGE_REPORT_QUERY_KEY,
	() => getProductTagsUsageReport().then(getDataFromResponse),
	options,
);