import UtilisationReportsView from "modules/reports/components/UtilisationReportsView";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import { useYearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportQueries.ts";
import { useAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const UtilisationReportsPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanViewReports);
	if (guard) return guard;
	const [searchParams, setSearchParams] = useSearchParams();

	const year = searchParams.get("year");

	useEffect(() => {
		if (!year) {
			setSearchParams({ year: new Date().getFullYear().toString() });
		}
	}, [setSearchParams, year]);

	const { data, options } = useAggregatedDataV2({
		careerLevels: useAllCareerLevels(),
		staffMembers: useAllStaffMembers(),
		timeTrackingTypes: useAllTimeTrackingTypes(),
		utilisationReport: useYearlyUtilisationReport({ year: year || "", options: { enabled: !!year } }),
	});

	const {
		careerLevels,
		staffMembers,
		timeTrackingTypes,
		utilisationReport,
	} = data;

	const { isLoading, loadingPercent } = options;

	if (!year) return null;

	return <UtilisationReportsView careerLevels={careerLevels}
								   isLoading={isLoading}
								   loadingPercent={loadingPercent}
								   staffMembers={staffMembers}
								   timeTrackingTypes={timeTrackingTypes}
								   utilisationReport={utilisationReport}
								   year={year} />;
};

export default UtilisationReportsPage;