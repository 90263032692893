import BreadcrumbsSection from "components/Breadcrumbs";
import Button from "components/buttons/Button";
import Decimal from "decimal.js-light";
import CreateWorkingScheduleSidebar
	from "modules/absence/components/WorkingSchedulesView/components/CreateWorkingScheduleSidebar";
import DeleteWorkingScheduleModal
	from "modules/absence/components/WorkingSchedulesView/components/DeleteWorkingScheduleModal";
import UpdateWorkingScheduleSidebar
	from "modules/absence/components/WorkingSchedulesView/components/UpdateWorkingScheduleSidebar";
import WorkingSchedulesTable from "modules/absence/components/WorkingSchedulesView/components/WorkingSchedulesTable";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentWrapper from "~/components/ContentWrapper";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import Headline from "~/components/Headline";
import MainContent from "~/components/mainContent/MainContent";
import { useAuth } from "~/contexts/AuthContext";
import type { VacationEntitlement } from "~/modules/absence/api/vacationEntitlement/vacationEntitlementTypes.ts";
import type { VacationLedgerReportType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsenceViewStaffMemberSelect from "~/modules/absence/components/AbsencePageUserSelect";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import DeleteVacationEntitlementModal
	from "~/modules/absence/components/VacationLedgerView/components/DeleteVacationEntitlementModal";
import CreateVacationEntitlementSidebar
	from "~/modules/absence/components/WorkingSchedulesView/components/CreateVacationEntitlementSidebar";
import UpdateVacationEntitlementSidebar
	from "~/modules/absence/components/WorkingSchedulesView/components/UpdateVacationEntitlementSidebar";
import VacationEntitlementsTable
	from "~/modules/absence/components/WorkingSchedulesView/components/VacationEntitlementsTable";
import VacationEntitlementsTableRow
	from "~/modules/absence/components/WorkingSchedulesView/components/VacationEntitlementsTable/components/VacationEntitlementsTableRow";
import WorkingScheduleTableRow
	from "~/modules/absence/components/WorkingSchedulesView/components/WorkingSchedulesTable/components/WorkingScheduleTableRow";
import { AbsenceTabName } from "~/modules/absence/types/absenceOverviewTypes.ts";
import type { RenderWorkingScheduleTableRowFunction } from "~/modules/absence/types/workingScheduleViewTypes.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type WorkingSchedulesViewProps = {
	staffMemberId: string,
	vacationEntitlements: VacationEntitlement[];
	vacationLedgerReport: VacationLedgerReportType;
	workingSchedules: WorkingSchedule[];
	workingSchedulesMinDate: Date;
};


const WorkingSchedulesView: React.FunctionComponent<WorkingSchedulesViewProps> = ({
	staffMemberId,
	vacationLedgerReport,
	vacationEntitlements,
	workingSchedules,
	workingSchedulesMinDate,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();

	const [showCreateWorkingScheduleSidebar, setShowCreateWorkingScheduleSidebar] = useState(false);
	const [showCreateVacationEntitlementSidebar, setShowCreateVacationEntitlementSidebar] = useState(false);
	const [workingScheduleDataToUpdate, setWorkingScheduleDataToUpdate] = useState<WorkingSchedule | null>(null);
	const [workingScheduleDataToDelete, setWorkingScheduleDataToDelete] = useState<WorkingSchedule | null>(null);
	const [vacationEntitlementDataToDelete, setVacationEntitlementDataToDelete] = useState<VacationEntitlement | null>(null);
	const [vacationEntitlementDataToUpdate, setVacationEntitlementDataToUpdate] = useState<VacationEntitlement | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));

	const renderTableRow: RenderWorkingScheduleTableRowFunction = useCallback(({
		workingScheduleData,
		userCanAdminVacations = false,
		compactView = false,
	}) => {
		const workingDays: boolean[] = [
			workingScheduleData.monday,
			workingScheduleData.tuesday,
			workingScheduleData.wednesday,
			workingScheduleData.thursday,
			workingScheduleData.friday,
		];

		return <WorkingScheduleTableRow key={"workingScheduleData-" + workingScheduleData.id}
										compactView={compactView}
										validFrom={formatDateWithGermanMonth(new Date(workingScheduleData.validFrom))}
										workingDays={workingDays}
										isEditable={userCanAdminVacations}
										isDeletable={userCanAdminVacations && !workingScheduleData.isDateLocked}
										onEditClick={() => setWorkingScheduleDataToUpdate(workingScheduleData)}
										onDeleteClick={() => setWorkingScheduleDataToDelete(workingScheduleData)}
		/>;
	}, []);

	const workingScheduleTableRows = useMemo(() => {
		if (workingSchedules.length > 0) {
			return workingSchedules.sort(byObjectProperty("validFrom", "desc")).map((schedule) => {
				return renderTableRow({
					workingScheduleData: schedule,
					userCanAdminVacations,
				});
			});
		}
		return [];
	}, [workingSchedules, userCanAdminVacations, renderTableRow]);

	const vacationEntitlementTableRows = useMemo(() => {
		if (vacationEntitlements) {
			return vacationEntitlements.sort(byObjectProperty("validFrom", "desc")).map((entitlement) => {
				return <VacationEntitlementsTableRow key={"vacationEntitlement-" + entitlement.id}
													 validFrom={formatDateWithGermanMonth(new Date(entitlement.validFrom))}
													 workingDays={new Decimal(entitlement.workDays)}
													 isEditable={userCanAdminVacations}
													 isDeletable={userCanAdminVacations && !entitlement.isDateLocked}
													 onEditClick={() => setVacationEntitlementDataToUpdate(entitlement)}
													 onDeleteClick={() => setVacationEntitlementDataToDelete(entitlement)} />;
			});
		}
		return [];
	}, [vacationEntitlements, userCanAdminVacations]);

	return (
		<MainContent>
			<BreadcrumbsSection pages={["Abwesenheiten"]}
								className="bg-white" />
			<PageHeading title="Anstellungsdetails" />
			<PageHeading.BottomBar>
				<div
					className="z-40 flex w-full items-center gap-2 gap-x-5 text-sm font-medium text-gray-700 hover:text-gray-900">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					<AbsenceViewStaffMemberSelect userIsVacationManager={userCanAdminVacations}
												  staffMemberId={staffMemberId}
												  currentSubPath={AbsenceTabName.workingSchedules} />
				</div>
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceTabName.workingSchedules} />
				</SectionHeading>
				<div className="mt-7 grid grid-cols-5 gap-x-10">
					<div className="col-span-3 flex flex-col">
						<div className="flex flex-row items-center justify-between">
							<Headline type="h4">Arbeitszeitmodelle</Headline>
							<Button size="sm"
									onClick={() => setShowCreateWorkingScheduleSidebar(true)}>
								{t("projects.btnNewProject", "Neues Arbeitszeitmodell")}
							</Button>
						</div>
						<WorkingSchedulesTable>
							{workingScheduleTableRows}
						</WorkingSchedulesTable>
					</div>
					<div className="col-span-2 flex flex-col">
						<div className="flex flex-row items-center justify-between">
							<Headline type="h4">Urlaubsanspruch</Headline>
							<Button size="sm"
									onClick={() => setShowCreateVacationEntitlementSidebar(true)}>
								Neuer Eintrag
							</Button>
						</div>
						<VacationEntitlementsTable>
							{vacationEntitlementTableRows}
						</VacationEntitlementsTable>
					</div>
				</div>
			</ContentWrapper>
			<CreateWorkingScheduleSidebar
				onClose={() => setShowCreateWorkingScheduleSidebar(false)}
				isOpen={showCreateWorkingScheduleSidebar}
				staffMemberId={staffMemberId}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>
			<UpdateWorkingScheduleSidebar onClose={() => setWorkingScheduleDataToUpdate(null)}
										  isOpen={workingScheduleDataToUpdate !== null}
										  workingScheduleData={workingScheduleDataToUpdate}
										  workingSchedulesMinDate={workingSchedulesMinDate} />

			<DeleteWorkingScheduleModal
				isOpen={workingScheduleDataToDelete !== null}
				onCloseClick={() => setWorkingScheduleDataToDelete(null)}
				renderTableRow={renderTableRow}
				workingScheduleData={workingScheduleDataToDelete} />

			<CreateVacationEntitlementSidebar onClose={() => setShowCreateVacationEntitlementSidebar(false)}
											  isOpen={showCreateVacationEntitlementSidebar}
											  staffMemberId={staffMemberId}
											  minDate={workingSchedulesMinDate} />

			<DeleteVacationEntitlementModal vacationEntitlement={vacationEntitlementDataToDelete}
											isOpen={!!vacationEntitlementDataToDelete}
											onCloseClick={() => setVacationEntitlementDataToDelete(null)} />

			<UpdateVacationEntitlementSidebar vacationEntitlementData={vacationEntitlementDataToUpdate}
											  isOpen={vacationEntitlementDataToUpdate !== null}
											  minDate={workingSchedulesMinDate}
											  onClose={() => setVacationEntitlementDataToUpdate(null)}
			/>
		</MainContent>
	);
};

export default WorkingSchedulesView;