import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getUsersMonthlyClosings } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingApiDispatchers.ts";
import type { GetUsersMonthlyClosingsFilterType } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingTypes.ts";

const ENTITY_QUERY_KEY = "monthlyClosing";

export const USERS_MONTHLY_CLOSINGS_QUERY_KEY = (userId: string, filter?: GetUsersMonthlyClosingsFilterType) => [ENTITY_QUERY_KEY, userId, filter];

export const useUsersMonthlyClosings = ({ userId, filter, options }: {
	userId: string,
	filter?: GetUsersMonthlyClosingsFilterType,
	options?: QueriesOptions<any>
}) => useQuery(
	USERS_MONTHLY_CLOSINGS_QUERY_KEY(userId, filter),
	() => getUsersMonthlyClosings(userId, filter).then(getDataFromResponse),
	options,
);
