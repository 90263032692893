import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	StaffMemberCompensationReportNote,
} from "~/modules/humanResources/api/staffMemberCompensationReportNote/staffMemberCompensationReportNoteTypes.ts";

export function getStaffMembersCompensationReportNotes(staffMemberId: string): AxiosApiResponsePromise<StaffMemberCompensationReportNote> {
	return axiosApi.get(`staff-members/${staffMemberId}/compensation-report-notes`);
}

export function updateStaffMemberCompensationReportNote(
	staffMemberId: string,
	note: string,
): AxiosApiResponsePromise<StaffMemberCompensationReportNote> {
	return axiosApi.post(`staff-members/${staffMemberId}/compensation-report-notes`, { note });
}