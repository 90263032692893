import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ComboBox from "~/components/formElements/ComboBox";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import Switch from "~/components/formElements/Switch";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import useUserSelectOptions from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import type { CreateStaffingData } from "~/modules/project/api/staffing/staffingTypes.ts";
import StaffingBudgetFormSection from "~/modules/project/components/forms/formSections/StaffingBudgetFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateStaffingFormProps = {
	onSuccess: (staffedUserId: string) => void;
	onCancel: () => void;
	onCreateUserClick: () => void;
	orderId: string;
	projectId: string;
	projectPhaseId: string;
	selectedUserId: string | null;
};

interface CreateStaffingFormData
	extends Omit<CreateStaffingData, "orderId" | "projectId" | "projectPhaseId"> {
}

const CreateStaffingForm: React.FC<CreateStaffingFormProps> = ({
	onSuccess,
	onCancel,
	orderId,
	onCreateUserClick,
	projectId,
	projectPhaseId,
	selectedUserId,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const schema = useMemo(() => {
		return yup.object({
			dailyRateCents: yup.number().required(),
			isActive: yup.boolean().required(),
			manDays: yup.number().required(),
			projectRoleName: yup.string().required(),
			userId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			dailyRateCents: 0,
			manDays: 0,
			isActive: false,
			projectRoleName: "",
			userId: selectedUserId || "",
		};
	}, [selectedUserId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty, isSubmitted },
		watch,
	} = useForm<CreateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateStaffingFormData>(schema),
	});

	/*const userSelectOptions = useUserSelectOptions({ filterFunction: staffableUsersFilterFn });*/
	const userSelectOptions = useUserSelectOptions();
	const projectRoleSelectOptions = useProjectsProjectRolesOptions({ projectId, valueProperty: "displayName" });
	const formIsSubmittable = useFormIsSubmittable({
		isValid,
		isSubmitted,
		isLoading: busy,
		isDirty,
	});

	const { data: projectsStaffingsData } = useProjectsStaffings({ projectId });

	const filteredUserSelectOptions = useMemo(() => {
		if (projectsStaffingsData) {
			const assignedUserIds = projectsStaffingsData
				.filter(staffing => staffing.orderId === orderId)
				.map((staffing) => staffing.userId);

			return userSelectOptions.filter((user) => !assignedUserIds.includes(user.value));
		}
		return userSelectOptions;
	}, [projectsStaffingsData, userSelectOptions, orderId]);
	const onSubmit: SubmitHandler<CreateStaffingFormData> = async (data: CreateStaffingFormData) => {

		setBusy(true);
		try {

			const staffingData = {
				...data,
				projectId: projectId,
				projectPhaseId: projectPhaseId,
				orderId: orderId,
			};
			await createStaffing({ projectId, staffingData });
			onSuccess(data.userId);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neues Staffing erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}

				<FormSectionGroup>
					<FormSection title="Projektmitarbeiter:in">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									placeholder="Mitarbeiter:in auswählen"
									allowNew={false}
									name={"userId"}
									control={control}
									optionsData={filteredUserSelectOptions}
								/>
							</div>
							<div className="col-span-6">
								{!selectedUserId && <ButtonNewItem size="sm"
																   theme="inline"
																   onClick={onCreateUserClick}>NORD.WORK User
									hinzufügen</ButtonNewItem>}
							</div>
						</div>
					</FormSection>
					<FormSection title="Zeiterfassung">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<Switch name={"isActive"}
										control={control}
										labelOn={"Aktiv"}
										labelOff={"Inaktiv"} />
							</div>
						</div>
					</FormSection>
					<FormSection title="Rolle im Projekt">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									placeholder="Name der Rolle"
									name={"projectRoleName"}
									control={control}
									optionsData={projectRoleSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<StaffingBudgetFormSection control={control}
											   errors={errors}
											   watch={watch} />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateStaffingForm;
