import type Decimal from "decimal.js-light";
import type { Control, FieldValues, UseFormWatch } from "react-hook-form";

import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormInputError from "~/components/formElements/FormInputError";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import Switch from "~/components/formElements/Switch";
import Hint from "~/components/Hint";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import VacationLedgerFormSection from "~/modules/absence/components/formSections/VacationLedgerFormSection";
import type { FormInputOption } from "~/types/form.ts";

type CreateOrUpdateAbsenceFormDataType = {
	absenceTypeId: string;
	comment: string | null;
	endDate: Date;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
	startDate: Date;
}

type CreateOrUpdateAbsenceFormSectionProps<T extends FieldValues> = {
	absenceTypeSelectOptions: FormInputOption[];
	dayCheckErrorMessage?: string;
	control: Control<T>;
	overlapError: boolean;
	remainingVacationDays: Decimal;
	showLongTermAbsenceWarning: boolean;
	watch: UseFormWatch<T>
	workingSchedules: WorkingSchedule[];
};

const CreateOrUpdateAbsenceFormSection = <T extends CreateOrUpdateAbsenceFormDataType>({
	absenceTypeSelectOptions,
	dayCheckErrorMessage,
	control,
	overlapError,
	remainingVacationDays,
	showLongTermAbsenceWarning,
	watch,
	workingSchedules,
}: CreateOrUpdateAbsenceFormSectionProps<T>) => {
	return <FormSectionGroup>
		<FormSection title="Abwesenheitsart">
			<Select
				name="absenceTypeId"
				optionsData={absenceTypeSelectOptions}
				control={control}
			/>
			{showLongTermAbsenceWarning && <div className="mt-4"><Hint size="sm" theme="warning">
				Einträge für die ausgwählte Abwesenheitsart kann nur von Mitarbeiter:innen des HR-Teams erstellt werden.
			</Hint></div>}
		</FormSection>
		<FormSection title="Titel">
			<Input name="comment"
				   control={control} />
		</FormSection>
		<FormSection title="Zeitraum">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<DatePickerInput
						clearable={false}
						name="startDate"
						label="Startdatum"
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						clearable={false}
						position="bottom-end"
						name="endDate"
						label="Enddatum"
						control={control}
					/>
				</div>
				<div className="col-span-3 mt-1">
					<Switch
						label={"Halber erster Tag"}
						name={"firstDayIsHalf"}
						control={control} />
				</div>
				<div className="col-span-3 mt-1">
					<Switch
						label={"Halber letzter Tag"}
						name={"lastDayIsHalf"}
						control={control} />
				</div>
				{dayCheckErrorMessage && <div className="col-span-6">
					<FormInputError>
						{dayCheckErrorMessage}
					</FormInputError>
				</div>
				}
				{overlapError && <div className="col-span-6">
					<FormInputError>
						{"Es existiert bereits eine Abwesenheit in diesem Zeitraum."}
					</FormInputError>
				</div>}
			</div>

		</FormSection>
		<VacationLedgerFormSection
			watch={watch}
			initialRemainingVacationDays={remainingVacationDays}
			workingSchedules={workingSchedules}
		/>
	</FormSectionGroup>;
};

export default CreateOrUpdateAbsenceFormSection;