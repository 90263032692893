import { yupResolver } from "@hookform/resolvers/yup";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateDeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsApiDispatchers.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import {
	useDeliverableTagValidationComparisonData,
} from "~/modules/project/api/deliverableTags/hooks/useDeliverableTagValidationComparisonData.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateDeliverableTagFormData {
	displayName: string;
}

type UpdateDeliverableTagFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	deliverableTagData: DeliverableTag;

};

const UpdateDeliverableTagForm: FunctionComponent<UpdateDeliverableTagFormProps> = ({
	onSuccess,
	onCancel,
	deliverableTagData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const validationComparisonData = useDeliverableTagValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			displayName: yup.string().required().unique(validationComparisonData, deliverableTagData.id),
		});
	}, [deliverableTagData.id, validationComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			displayName: deliverableTagData.displayName,
		};
	}, [deliverableTagData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateDeliverableTagFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateDeliverableTagFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
		enableInitially: false,
	});

	const onSubmit = useCallback(async (data: UpdateDeliverableTagFormData) => {
		try {
			setIsBusy(true);
			await updateDeliverableTag({ id: deliverableTagData.id, displayName: data.displayName });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.");
		}
	}, [deliverableTagData.id, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">Deliverable Tag bearbeiten</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<Input control={control}
					   name="displayName"
					   label="Name"
				/>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateDeliverableTagForm;
