import { t } from "i18next";
import { useMemo } from "react";

import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries.ts";
import { WorkingStatusNames } from "~/types/entityNames.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";


const useWorkingStatusSelectOptions = (limitToActiveAndInquiry: boolean = false): FormInputOption[] => {
	const { data: allWorkingStausesOptions } = useAllWorkingStatuses();
	return useMemo(() => {
		if (allWorkingStausesOptions) {

			let options = allWorkingStausesOptions;

			if (limitToActiveAndInquiry) {
				options = options.filter(option => [WorkingStatusNames.Active, WorkingStatusNames.Quotation].includes(option.name as WorkingStatusNames));
			}

			return options.map((option) => {
				const label = t(`entities:workingStatus.${option.name}` as unknown as TemplateStringsArray);
				return createInputOptionData(label, option.id);
			});
		}
		return [];
	}, [allWorkingStausesOptions, limitToActiveAndInquiry]);
};

export default useWorkingStatusSelectOptions;

