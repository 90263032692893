import { getISOWeek } from "date-fns";

import GridSegmentText
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/GridSegmentText";
import type { GridSegmentType } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getWorkDaysInterval } from "~/utils/dateAndTimeUtils.ts";

type WeekGridLinesProps = {
	containerWidthPx: number;
	dayWidthPx: number;
	dividerWidthPx: number;
	endDate: Date;
	onSegmentClick?: (date: Date) => void;
	startDate: Date;
	showCalendarWeeks: boolean;
}

const WeekGridLines: React.FunctionComponent<WeekGridLinesProps> = ({
	containerWidthPx,
	dayWidthPx,
	dividerWidthPx,
	endDate,
	onSegmentClick,
	startDate,
	showCalendarWeeks,
}) => {
	const segments: GridSegmentType[] = [];
	let remainingContainerWidth = containerWidthPx;
	const currentWeek = getISOWeek(new Date());
	getWorkDaysInterval(startDate, endDate).forEach((date, index) => {
		const dayOfWeek = date.getDay();
		let segmentNumberOfDays = 5;

		if (index === 0 || dayOfWeek === 1) {
			if(index === 0 && dayOfWeek !== 1 ){
				segmentNumberOfDays = 6 - dayOfWeek;
			}
			let width = segmentNumberOfDays * dayWidthPx;
			if (remainingContainerWidth < width) {
				width = remainingContainerWidth;
			} else {
				remainingContainerWidth -= width;
			}
			segments.push({
				offsetLeft: index * dayWidthPx,
				text: "KW " + getISOWeek(date).toString(),
				width,
				startDate: date,
				isCurrentDate: currentWeek === getISOWeek(date),
			});
		}
	});

	return segments.map((segment, index) => {
		return <div key={index}
					className="absolute top-0 h-full bg-gray-300"
					style={{ left: segment.offsetLeft, width: dividerWidthPx }}>
			{showCalendarWeeks && <GridSegmentText onClick={onSegmentClick}
												   startDate={segment.startDate}
												   highlighted={segment.isCurrentDate}
												   widthPx={segment.width}>{segment.text}</GridSegmentText>}
		</div>;
	});

};

export default WeekGridLines;