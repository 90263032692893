import clsx from "clsx";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

type FormInputProps = PropsWithChildren & {
	className?: string;
};

const FormInput = forwardRef((props: FormInputProps, ref: any) => {
	const { className, children } = props;
	return <div ref={ref}
				className={clsx("flex flex-col gap-y-1", className)}>{children}</div>;
});

FormInput.displayName = "FormInput";
export default FormInput;
