import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateUserPassword } from "~/modules/user/api/user/userApiDispatchers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdatePasswordFormData {
	password: string;
	passwordConfirmation: string;
}

type UpdatePasswordFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	userId: string;
	userName: string;
};

const UpdatePasswordForm: React.FunctionComponent<UpdatePasswordFormProps> = ({
	onSuccess,
	onCancel,
	userId,
	userName,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = yup.object().shape({
		password: yup.string().min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.").required(),
		passwordConfirmation: yup.string().oneOf([yup.ref("password")], "Die Passwörter stimmen nicht überein.").required(),
	});
	const defaultValues = useMemo(() => {
		return {
			password: "",
			passwordConfirmation: "",
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdatePasswordFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdatePasswordFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdatePasswordFormData) => {
		try {
			setIsBusy(true);
			await updateUserPassword({ userId, data });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [userId, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>{`${userName}: Passwort ändern`}</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Status update failed">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="flex flex-col gap-4">
					<Input
						name="password"
						type="password"
						label="Neues Passwort (min. 8 Zeichen)"
						control={control}
					/>

					<Input
						name="passwordConfirmation"
						type="password"
						label="Passwort wiederholen"
						control={control}
					/>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
					className="mr-2" />
				<SubmitButton busy={isBusy}
					disabled={!formIsSubmittable}>
					Passwort ändern
				</SubmitButton>
				<Button theme="white"
					onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdatePasswordForm;
