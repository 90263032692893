import { UserIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { FunctionComponent } from "react";

import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

interface Props {
	displayName?: string;
	image?: AvatarImage | null;
	width?: string;
	height?: string;
}

const CompanyLogo: FunctionComponent<Props> = ({ displayName, image, width = "w-12", height = "h-12" }) => {
	const elementClassName = clsx(
		`${width} ${height}`,
		"flex shrink-0 items-center justify-center",
		"select-none rounded-full",
		!image ? "bg-primary-500" : "bg-white"
	);

	if (!image) {
		if (undefined === displayName) {
			return (
				<div className={elementClassName}>
					<UserIcon className={clsx("w-4/6 text-primary-500")} />
				</div>
			);
		}
		return (
			<div className={clsx(elementClassName, "text-lg font-bold text-white")}>
				{displayName && displayName.substring(0, 3)}
			</div>
		);
	}
	return <img className={elementClassName}
				src={image.sizes.find((size) => size.size === "small")?.url || image.sizes[0].url}
				alt="" />;
};

export default CompanyLogo;
