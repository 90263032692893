import clsx from "clsx";
import BreadcrumbsSection from "components/Breadcrumbs";
import Button from "components/buttons/Button";
import { isBefore, startOfYear } from "date-fns";
import Decimal from "decimal.js-light";
import { t } from "i18next";
import CreateVacationLedgerEntrySidebar
	from "modules/absence/components/VacationLedgerView/components/CreateVacationLedgerEntrySidebar";
import DeleteVacationLedgerEntryModal
	from "modules/absence/components/VacationLedgerView/components/DeleteVacationLedgerEntryModal";
import UpdateVacationLedgerEntrySidebar
	from "modules/absence/components/VacationLedgerView/components/UpdateVacationLedgerEntrySidebar";
import VacationLedgerTable from "modules/absence/components/VacationLedgerView/components/VacationLedgerTable";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentWrapper from "~/components/ContentWrapper";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import MainContent from "~/components/mainContent/MainContent";
import { useAuth } from "~/contexts/AuthContext";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import { AbsenceTypeName } from "~/modules/absence/api/absence/absenceTypes.ts";
import type {
	VacationLedgerEntrySummaryType,
	VacationLedgerEntryType,
	VacationLedgerReportType,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsenceViewStaffMemberSelect from "~/modules/absence/components/AbsencePageUserSelect";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import VacationLedgerTableRow
	from "~/modules/absence/components/VacationLedgerView/components/VacationLedgerTable/components/VacationLedgerTableRow";
import { AbsenceTabName } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { formatVacationDays } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byMultipleObjectProperties } from "~/utils/sortFunctions.ts";

type VacationLedgerViewProps = {
	availableYears: string[];
	staffMemberId: string,
	users: User[];
	vacationLedgerEntries: VacationLedgerEntryType[];
	vacationLedgerReport: VacationLedgerReportType
};

const getVacationDayDecimal = (entry: VacationLedgerEntryType): Decimal => new Decimal(parseFloat(entry.workDays));

const entryIsVacation = (entry: VacationLedgerEntryType): boolean => !!entry.absenceId;

function getTypeNameFromEntryType(entry: VacationLedgerEntryType): string {
	if (entryIsVacation(entry)) {
		return t(normalizeTKey(`entities:absenceType.${AbsenceTypeName.Vacation}`));
	} else if (getVacationDayDecimal(entry).isNegative()) {
		return "Abzug";
	}
	return "Gutschrift";
}

const defaultPageState = {
	selectedYear: new Date().getFullYear().toString(),
};

const ALL_YEARS_VALUE = "all";

const VacationLedgerView: FunctionComponent<VacationLedgerViewProps> = ({
	availableYears,
	staffMemberId,
	users,
	vacationLedgerEntries,
	vacationLedgerReport,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();
	const { setPageState, pageState } = usePageStateStorage({
		pageKey: "vacation-ledger",
		defaultState: defaultPageState,
	});

	const [showCreateVacationLedgerForm, setShowCreateVacationLedgerForm] = useState(false);
	const [entryIdToUpdate, setEntryIdToUpdate] = useState<string | null>(null);
	const [entryIdToDelete, setEntryIdToDelete] = useState<string | null>(null);
	const [selectedYear, setSelectedYear] = useState<string>(availableYears.includes(pageState.selectedYear) ? pageState.selectedYear : ALL_YEARS_VALUE);

	const handleYearSelectChange = useCallback((year: string) => {
		setSelectedYear(year);
		setPageState({ selectedYear: year });
	}, [setPageState]);


	const yearSelectOptions = useMemo(() => {
		const selectOptions = [{ value: ALL_YEARS_VALUE, label: "Gesamt" }];
		if (availableYears) {
			return selectOptions.concat(availableYears.map(year => ({ value: year, label: year.toString() })));
		}
		return selectOptions;
	}, [availableYears]);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);

	const [vacationLedgerEntriesSelectedPeriod, balanceSelectedPeriod, balancePreviousYears, hasPreviousYearEntries] = useMemo(() => {
		let entriesPreviousYears: VacationLedgerEntryType[] = [];
		let vacationLedgerEntriesSelectedPeriod: VacationLedgerEntryType[] = [];
		let balanceSelectedPeriod = new Decimal(0);
		let balancePreviousYears = new Decimal(0);

		if (vacationLedgerEntries) {
			if (selectedYear === ALL_YEARS_VALUE) {
				vacationLedgerEntriesSelectedPeriod = vacationLedgerEntries;
			} else {
				vacationLedgerEntriesSelectedPeriod = vacationLedgerEntries.filter(entry => new Date(entry.validFrom).getFullYear().toString() === selectedYear);
			}

			balanceSelectedPeriod = vacationLedgerEntriesSelectedPeriod.reduce((balance, entry) => {
				const vacationDays = getVacationDayDecimal(entry);
				return balance.add(vacationDays);
			}, balanceSelectedPeriod);

			const startOfCurrentYear = startOfYear(new Date(parseInt(selectedYear), 0, 1));

			entriesPreviousYears = vacationLedgerEntries.filter(entry => isBefore(new Date(entry.validFrom), startOfCurrentYear));

			balancePreviousYears = entriesPreviousYears.reduce((balance, entry) => {
				const vacationDays = getVacationDayDecimal(entry);
				return balance.add(vacationDays);
			}, balancePreviousYears);
		}

		return [vacationLedgerEntriesSelectedPeriod, balanceSelectedPeriod, balancePreviousYears, entriesPreviousYears.length > 0];
	}, [vacationLedgerEntries, selectedYear]);

	const showBalance = selectedYear !== ALL_YEARS_VALUE;

	const vacationLedgerEntriesRows = useMemo(() => {
		if (vacationLedgerEntriesSelectedPeriod.length > 0) {
			return vacationLedgerEntriesSelectedPeriod.sort(byMultipleObjectProperties([{
				property: "validFrom",
				direction: "desc",
			}, { property: "workDays", direction: "asc" }])).map((ledgerEntry) => {
				const isVacation = entryIsVacation(ledgerEntry);
				const vacationDays = getVacationDayDecimal(ledgerEntry);
				const type = getTypeNameFromEntryType(ledgerEntry);


				const validFrom = formatDateWithGermanMonth(new Date(ledgerEntry.validFrom)) || "--";

				const isDeletable = !isVacation && userCanAdminVacations && !ledgerEntry.isEmploymentRelated;

				const isEditable = isDeletable && userCanAdminVacations;

				const createdBy = users.find(user => user.id === ledgerEntry.createdBy)?.fullName || "--";

				return <VacationLedgerTableRow key={"absence-" + ledgerEntry.id}
											   type={type}
											   createdBy={createdBy}
											   comment={ledgerEntry.comment}
											   isEmploymentRelated={ledgerEntry.isEmploymentRelated}
											   validFrom={validFrom}
											   vacationDays={vacationDays}
											   isDeletable={isDeletable}
											   isEditable={isEditable}
											   onEditClick={() => setEntryIdToUpdate(ledgerEntry.id)}
											   onDeleteClick={() => setEntryIdToDelete(ledgerEntry.id)}
				/>;
			});
		}
		return [];
	}, [vacationLedgerEntriesSelectedPeriod, userCanAdminVacations, setEntryIdToUpdate, users]);

	const selectedEntryLedgerToUpdateData = useMemo(() => {
		if (entryIdToUpdate) {
			return vacationLedgerEntries.find(ledgerEntry => ledgerEntry.id === entryIdToUpdate);
		}
		return null;
	}, [vacationLedgerEntries, entryIdToUpdate]);

	const selectedEntryLedgerToDeleteData: VacationLedgerEntrySummaryType | null = useMemo(() => {
		if (entryIdToDelete) {
			const entry = vacationLedgerEntries.find(ledgerEntry => ledgerEntry.id === entryIdToDelete);

			if (entry) {
				return {
					id: entry.id,
					comment: entry.comment,
					type: getTypeNameFromEntryType(entry),
					staffMemberId: entry.staffMemberId,
					validFrom: formatDateWithGermanMonth(new Date(entry.validFrom)) || "--",
					vacationDays: getVacationDayDecimal(entry),
				};
			}
		}
		return null;
	}, [vacationLedgerEntries, entryIdToDelete]);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));
	const showTotalBalance = hasPreviousYearEntries && selectedYear !== ALL_YEARS_VALUE;
	const currentTotalBalance = balancePreviousYears.add(balanceSelectedPeriod);

	return (
		<MainContent>
			<BreadcrumbsSection pages={["Abwesenheiten"]}
								className="bg-white" />
			<PageHeading title={t("timetracking.title", "Urlaubskonto")} />
			<PageHeading.BottomBar>
				<div
					className="z-40 flex w-full items-center gap-2 gap-x-5 text-sm font-medium text-gray-700 hover:text-gray-900">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					{userCanAdminVacations &&
						<AbsenceViewStaffMemberSelect
							userIsVacationManager={userCanAdminVacations}
							staffMemberId={staffMemberId}
							currentSubPath={AbsenceTabName.vacationLedger}
						/>}
					<div className="min-w-[6.5rem]">
						<SelectPlain optionsData={yearSelectOptions}
									 value={selectedYear}
									 onChange={handleYearSelectChange} />
					</div>
				</div>

				{userCanAdminVacations && <>
					<div className="w-5" />
					<Button onClick={() => setShowCreateVacationLedgerForm(true)}>
						{t("projects.btnNewProject", "Neuer Eintrag")}
					</Button></>}
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceTabName.vacationLedger} />
				</SectionHeading>
				<div className={clsx(showBalance ? "grid grid-cols-10 gap-x-8" : "")}>
					<div className="col-span-8">
						<VacationLedgerTable>
							{vacationLedgerEntriesRows}
						</VacationLedgerTable>
					</div>
					<div className="col-span-2">
						{showBalance && <div className="mb-4 mt-5 flex flex-col gap-y-1">
							<div className="flex flex-col">
								<span className="font-bold">Saldo {selectedYear}</span>
								<span className={clsx("font-bold", balanceSelectedPeriod.isNegative() ? "text-red-500" : "text-green-500")}>{formatVacationDays(balanceSelectedPeriod)}</span>
							</div>
							{showTotalBalance &&
								<>
									<div className="flex flex-col">
										<span className="text-sm font-bold text-gray-500">Saldo gesamt {selectedYear}</span>
										<span className={clsx("text-sm font-bold", currentTotalBalance.isNegative() ? "text-red-500" : "text-green-500")}>{formatVacationDays(currentTotalBalance)}</span>
									</div>
								</>}
						</div>}
					</div>
				</div>
			</ContentWrapper>
			<CreateVacationLedgerEntrySidebar
				isOpen={showCreateVacationLedgerForm}
				onClose={() => setShowCreateVacationLedgerForm(false)}
				staffMemberId={staffMemberId} />

			<UpdateVacationLedgerEntrySidebar
				isOpen={!!selectedEntryLedgerToUpdateData}
				onClose={() => setEntryIdToUpdate(null)}
				vacationLedgerEntryData={selectedEntryLedgerToUpdateData} />

			<DeleteVacationLedgerEntryModal
				isOpen={!!selectedEntryLedgerToDeleteData}
				vacationLedgerEntryData={selectedEntryLedgerToDeleteData}
				onCloseClick={() => setEntryIdToDelete(null)} />
		</MainContent>
	);
};

export default VacationLedgerView;