import { PlusIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import Button from "~/components/buttons/Button";

type Props = {
	onAddItemClick?: () => void;
	title: string;
};

const TableTimelineHeaderCell: FunctionComponent<Props> = ({  onAddItemClick, title }) => {
	return <th className="first:pl-2 last:pr-4">
		<div className="flex items-center justify-end">
			<span className="font-bold text-gray-600">{title}</span>
			{onAddItemClick && <Button size="auto"
									   theme="plain"
									   className="px-2 py-1 text-xs"
									   onClick={onAddItemClick}><PlusIcon className="size-6 text-gray-400 hover:text-danger-500"/></Button>}
		</div>
	</th>;
};

export default TableTimelineHeaderCell;