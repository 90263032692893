import type { Control, FieldValues } from "react-hook-form";

import CheckButton from "~/components/formElements/CheckButton";
import FormInputError from "~/components/formElements/FormInputError";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import MonthPickerInput from "~/components/formElements/MonthPickerInput";
import Hint from "~/components/Hint";
import type { WorkingScheduleFormData } from "~/modules/absence/types/workingScheduleViewTypes.ts";


type WorkingScheduleFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	dateIsLocked: boolean;
	minDate: Date;
	noWorkdaySelectedErrorMessage: string|null
};

const WorkingScheduleFormSection = <T extends WorkingScheduleFormData>({
	control,
	dateIsLocked,
	minDate,
	noWorkdaySelectedErrorMessage
}: WorkingScheduleFormSectionProps<T>) => {
	return <FormSectionGroup>
		<FormSection title="Arbeitstage">
			<div className="mt-2 flex flex-col gap-2">
				<CheckButton
					control={control}
					name={`monday`}
				>
					Montag
				</CheckButton>
				<CheckButton
					control={control}
					name={`tuesday`}
				>
					Dienstag
				</CheckButton>
				<CheckButton
					control={control}
					name={`wednesday`}
				>
					Mittwoch
				</CheckButton>
				<CheckButton
					control={control}
					name={`thursday`}
				>
					Donnerstag
				</CheckButton>
				<CheckButton
					control={control}
					name={`friday`}
				>
					Freitag
				</CheckButton>
			</div>
			{noWorkdaySelectedErrorMessage && <div className="pl-2"><FormInputError>{noWorkdaySelectedErrorMessage}</FormInputError></div>}
		</FormSection>

		<FormSection title="Gültig ab">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<MonthPickerInput
						minDate={minDate}
						disabled={dateIsLocked}
						name="validFrom"
						control={control}
					/>
				</div>
				<p className="col-span-6 mt-3 text-sm leading-6 text-gray-600">Das Gültigkeitsdatum bestimmt
					den
					Zeitpunkt, von dem an der Eintrag in die Berechnung des verbleibenden Urlaubsanspruchs
					einbezogen wird.</p>
				{dateIsLocked && <div className="col-span-6"><Hint size="sm" theme="warning">
					<p>Das Gültigkeitsdatum dieses Eintrags kann nicht geändert werden, da es sich um den initialen Eintrag handelt.
						Aus dem selben Grund kann dieser Eintrag nicht gelöscht werden.
					</p>
				</Hint></div>}
			</div>
		</FormSection>
		<FormSection title="Hinweis">
			<p className="col-span-6 text-sm leading-6 text-gray-600">Sobald ein neues Arbeitszeitmodell für einen
				Mitarbeiter erstellt oder bearbeitet wird, findet eine Neuberechnung der Urlaubstage für den
				betreffenden Zeitraum statt (i.e. Das Start- oder Enddatum liegt an oder nach dem Gültigkeitsdatum).</p>
		</FormSection>
	</FormSectionGroup>;
};

export default WorkingScheduleFormSection;