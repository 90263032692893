import type { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllCountries } from "~/modules/country/api/country/countryQueries.ts";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeQueries.ts";
import { useAllStaffMembers, useStaffMember } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import StaffMemberDetailsView from "~/modules/humanResources/components/StaffMemberDetailsView";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

const staffMemberDetailsPage: FunctionComponent = () => {
	const { tabId, staffMemberId } = useParams();
	const guard = userViewGuard(PermissionNames.CanManageStaff);
	if (guard) return guard;

	const checkedTabId = tabId as StaffMemberDetailsTabId || StaffMemberDetailsTabId.EmploymentDetails;

	const {
		data: staffMemberData,
		isLoading: staffMemberIsLoading,
	} = useStaffMember({ staffMemberId: staffMemberId || "", options: { enabled: !!staffMemberId } });

	useAllStaffMembers();
	useAllCareerLevels();

	const {
		data: allCountriesData,
		isLoading: allCountriesIsLoading,

	} = useAllCountries();

	const {
		data: allEmploymentTypes,
		isLoading: allEmploymentTypesIsLoading,
	} = useAllEmploymentTypes();

	if (allCountriesIsLoading || allEmploymentTypesIsLoading || staffMemberIsLoading) return <LoadingPage />;

	if (allCountriesData === undefined || allEmploymentTypes === undefined || staffMemberData === undefined) return <div>Something
		went wrong</div>;

	return <StaffMemberDetailsView
		countries={allCountriesData}
		employmentTypes={allEmploymentTypes}
		staffMemberCoreData={staffMemberData}
		activeTabId={checkedTabId}
	/>;
};


export default staffMemberDetailsPage;