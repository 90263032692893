import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import Button from "~/components/buttons/Button";

type Props = {
	tableIsVisible: boolean;
	setTableIsVisible: (value: boolean) => void;
};

const ToggleTableVisibilityButton: FunctionComponent<Props> = ({setTableIsVisible, tableIsVisible}) => {
	const buttonText = tableIsVisible ? "Gehaltsdaten verbergen" : "Gehaltsdaten anzeigen";
	return <Button theme="none" onClick={() => setTableIsVisible(!tableIsVisible)}>{!tableIsVisible ? <EyeSlashIcon className="size-5 opacity-60" /> :
		<EyeIcon className="size-5" />}{buttonText}</Button>
};

export default ToggleTableVisibilityButton;