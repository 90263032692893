import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateVacationEntitlementData,
	UpdateVacationEntitlementData, VacationEntitlement,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementTypes.ts";

const staffMembersVacationEntitlementsBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/vacation-entitlements`;

export function indexStaffMembersVacationEntitlements(staffMemberId: string): AxiosApiResponsePromise<VacationEntitlement[]> {
	return axiosApi.get(staffMembersVacationEntitlementsBasePath(staffMemberId));
}

export function createVacationEntitlement({ staffMemberId, data }: {
	staffMemberId: string,
	data: CreateVacationEntitlementData
}) {
	return axiosApi.post(staffMembersVacationEntitlementsBasePath(staffMemberId), data);
}

export function updateVacationEntitlement({ staffMemberId, vacationEntitlementId, data }: {
	staffMemberId: string,
	vacationEntitlementId: string,
	data: UpdateVacationEntitlementData
}) {
	return axiosApi.put(`${staffMembersVacationEntitlementsBasePath(staffMemberId)}/${vacationEntitlementId}`, data);
}

export function deleteUsersVacationEntitlement({ staffMemberId, vacationEntitlementId }: {
	staffMemberId: string,
	vacationEntitlementId: string,
}) {
	return axiosApi.delete(`${staffMembersVacationEntitlementsBasePath(staffMemberId)}/${vacationEntitlementId}`);
}