
import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi";
import {
	getAllTimeTrackingTypesEndpoint
} from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeApiEndpoints.ts";
import type { TimeTrackingType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";


export const getAllTimeTrackingTypes = (): AxiosApiResponsePromise<TimeTrackingType[]> => axiosApi.get(getAllTimeTrackingTypesEndpoint);
