import { useMemo } from "react";

import { useAllDeliverableTags } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

export function useDeliverableTagInputOptions(valuePropertyName: keyof DeliverableTag = "displayName",
	ignoreIds: string[] = []) {
	const { data: deliverableTags } = useAllDeliverableTags();

	return useMemo(() => {
		if (deliverableTags) {
			const filteredDeliverableTags = deliverableTags.filter((tag) => !ignoreIds.includes(tag.id));

			return mapDataToInputOptionsData(filteredDeliverableTags.sort(byObjectProperty("displayName")), valuePropertyName, "displayName");

		}
		return [];
	}, [deliverableTags, ignoreIds, valuePropertyName]);


}