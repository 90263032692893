import { yupResolver } from "@hookform/resolvers/yup";
import { isDate } from "date-fns";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import useEmploymentTypeSelectOptions, {
	freelanceEmploymentTypeOnlyFn,
	internalEmploymentTypesIncludingLeftFn,
} from "~/hooks/form/formOptionsData/useEmploymentTypeSelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { updateFreelanceUser } from "~/modules/user/api/user/userApiDispatchers.ts";
import type { UpdateFreelanceUserData, UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import UserPersonalInformationFormSection
	from "~/modules/user/components/forms/formSections/UserPersonalInformationFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	userData: UserWithConfidentialInformation;
};

type UpdateFreelanceUserFormData = Omit<UpdateFreelanceUserData, "dateOfBirth"> & {
	careerLevelId: string;
	locationId: string;
	dateOfBirth: Date | null;
}

const UpdateFreelanceUserForm: React.FC<Props> = ({ onSuccess, onCancel, userData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const { emailComparisonData } = useUserValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			locationId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			email: yup.string().email().required().uniqueEmail(emailComparisonData, userData.id),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			lastName: yup.string().required(),
			nickname: yup.string().default(""),
			phone: yup.string().default(""),
			shortName: yup.string().required(),
		});
	}, [emailComparisonData, userData]);

	const defaultValues = useMemo(() => {

		const dateOfBirth = userData.dateOfBirth ? new Date(userData.dateOfBirth) : null;

		return {
			careerLevelId: userData.careerLevelId || "",
			locationId: userData.locationId || "",
			dateOfBirth: dateOfBirth,
			email: userData.email,
			firstName: userData.firstName,
			genderId: userData.genderId,
			jobDescription: userData.jobDescription,
			lastName: userData.lastName,
			nickname: userData.nickname || "",
			phone: userData.phone || "",
			shortName: userData.shortName,
		};

	}, [userData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
		trigger,
	} = useForm<UpdateFreelanceUserFormData>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver<UpdateFreelanceUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		trigger();
	}, [trigger]);

	const careerLevelSelectOptions = useCareerLevelOptions();
	const companyLocationSelectOptions = useCompanyLocationSelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const employmentTypeFilterFunction = userData.employmentTypeId === EmploymentTypeId.Freelancer ? freelanceEmploymentTypeOnlyFn : internalEmploymentTypesIncludingLeftFn;
	const employmentTypeSelectOptions = useEmploymentTypeSelectOptions(employmentTypeFilterFunction);

	const onSubmit: SubmitHandler<UpdateFreelanceUserFormData> = async (data) => {
		setBusy(true);

		const dateOfBirth = isDate(data.dateOfBirth) ? formatDateToYYYYMMDD(data.dateOfBirth as Date) : null;

		const updatedUserData = { ...data, dateOfBirth };

		try {
			await updateFreelanceUser(userData.id, updatedUserData);
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Freelance-User &quot;{userData.fullName}&quot; bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<UserPersonalInformationFormSection<UpdateFreelanceUserFormData>
						control={control}
						genderSelectOptions={genderSelectOptions}
					/>
					<FormSection title="Mitarbeiterdaten">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-3">
								<SelectPlain
									disabled={true}
									name="employmentTypeId"
									label={"Anstellungsstatus"}
									optionsData={employmentTypeSelectOptions}
									value={EmploymentTypeId.Freelancer}
								/>
							</div>
							<div className="col-span-3">
								<Select
									name="careerLevelId"
									label={"Karrierestufe"}
									optionsData={careerLevelSelectOptions}
									control={control}
								/>
							</div>
							<div className="col-span-3">
								<Select
									name="locationId"
									label={"Standort"}
									optionsData={companyLocationSelectOptions}
									control={control}
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="shortName"
									placeholder="MM"
									label="Namenskürzel"
									control={control} />
							</div>
						</div>
					</FormSection>

				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={!isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!isDirty || !formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateFreelanceUserForm;
