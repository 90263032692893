import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_CHILDREN_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberChild/staffMemberChildQueries.ts";
import type { StaffMemberChild } from "~/modules/humanResources/api/staffMemberChild/staffMemberChildTypes.ts";
import UpdateStaffMembersChildrenForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateStaffMembersChildrenSidebar/components/UpdateStaffMembersChildrenForm";

type UpdateStaffMembersChildrenSidebarProps = {
	childrenData: StaffMemberChild[] | null;
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string;
};

const UpdateStaffMembersChildrenSidebar: FunctionComponent<UpdateStaffMembersChildrenSidebarProps> = ({
	childrenData,
	isOpen,
	onClose,
	staffMemberId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(STAFF_MEMBERS_CHILDREN_QUERY_KEY(staffMemberId));
		onClose();
	}, [queryClient, staffMemberId, onClose]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<UpdateStaffMembersChildrenForm childrenData={childrenData}
											onSuccess={handleSuccess}
											onCancel={onClose}
											staffMemberId={staffMemberId} />
		</Sidebar>
	);
};

export default UpdateStaffMembersChildrenSidebar;
