import clsx from "clsx";
import type { FunctionComponent } from "react";

import type {
	ProjectTagVariantsProps,
} from "~/components/projectTags/ProjectTag/projectTagVariants.ts";
import {
	projectTagVariants,
} from "~/components/projectTags/ProjectTag/projectTagVariants.ts";

type Props = ProjectTagVariantsProps & {
	displayName: string;
	onClick?: () => void;
	numberUsages?: number;
	selected?: boolean;
};

const ProjectTagWithUsage: FunctionComponent<Props> = ({
	displayName,
	numberUsages,
	onClick,
	selected,
	size,
	theme,
}) => {

	const { container } = projectTagVariants({ size, theme });

	return <button type="button"
				   onClick={onClick}
				   className={clsx(container(), "ring-offset-2 focus:ring focus:ring-blue-500", selected && "ring ring-primary-900 focus:ring-primary-900")}>
		<span className="shrink truncate">{displayName}</span>

		{numberUsages !== undefined &&
			<span className="ml-2 min-w-fit rounded-lg bg-white px-1.5 text-xs text-gray-500">{numberUsages}</span>}
	</button>;
};

export default ProjectTagWithUsage;