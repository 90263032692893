import clsx from "clsx";
import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";

type ContentWrapperProps = PropsWithChildren & {
	className?: string;
	scrollable?: boolean;
}


const Section = forwardRef(({ scrollable, className, children }: ContentWrapperProps, ref: ForwardedRef<any>) => {
		return <section ref={ref}
						className={clsx("w-full",
							scrollable && "overflow-y-auto", className)}>
			<div className="mx-auto w-full max-w-7xl px-2 sm:px-6 lg:px-8">{children}</div>
		</section>;
	},
);

Section.displayName = "Section";

export default Section;
