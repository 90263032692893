import clsx from "clsx";
import type {PropsWithChildren} from "react";

import type {HeadlineVariantProps} from "~/components/Headline/headlineVariants.ts";
import { headlineVariants} from "~/components/Headline/headlineVariants.ts";

type HeadlineProps = HeadlineVariantProps & {
	color?: string;
	colorClassName?: string;
	className?: string;
}

const Headline: React.FC<PropsWithChildren<HeadlineProps>> = ({
	children,
	colorClassName,
	type = "h1",
	color,
	weight,
	className
}) => {
	const Element = type;
	const colorName = colorClassName ? "none" : color;

	return <Element className={clsx(headlineVariants({type, color: colorName, weight}), colorClassName,
		className)}>{children}</Element>;
};

export default Headline;
