import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";

import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Logo from "~/components/Logo";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";


type FormData = {
	email: string;
	password: string;
};

const Login: FunctionComponent = () => {
	const { signIn } = useAuth();
	const navigate = useNavigate();
	const [busy, setBusy] = useState(false);

	const [loginFailed, setLoginFailed] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = useCallback(async (data) => {
		setBusy(true);
		try {
			await signIn(data.email, data.password);
			navigate(appRoutes.projects().path);
		} catch (error) {
			console.log(error, "Login failed");
			setLoginFailed(true);
		}
		setBusy(false);
	}, [navigate, signIn]);

	return (
		<div className="flex w-full max-w-md flex-col gap-4">
			<div className="ml-8 mt-[-10vh] w-44 text-primary-500">
				<Logo />
			</div>
			<div className="rounded-md bg-white p-8 shadow-md">
				<form onSubmit={handleSubmit(onSubmit)}
					className="space-y-6">
					<div className="flex flex-col gap-4">
						<Input
							name="email"
							control={control}
							label="Email"
							placeholder="name@domain.com"
							error={errors.email && "Bitte eine gültige Email-Adresse eingeben"}
						/>
						<Input
							name="password"
							control={control}
							type="password"
							label="Password"
							placeholder="******"
							error={errors.password && "Bitte ein Passwort eingeben"}
						/>

						<div className="flex items-center justify-between">
							<SubmitButton busy={busy}>Login</SubmitButton>
							<NavLink
								to={appRoutes.passwordResetRequest().path}
								className="text-sm text-primary-500 hover:text-primary-900"
							>
								Passwort vergessen?
							</NavLink>
						</div>
					</div>
				</form>

				{loginFailed && <div className="mt-2 text-red-500">Login fehlgeschlagen.</div>}
			</div>
		</div>
	);
};

export default Login;
