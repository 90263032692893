import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexSettings } from "~/modules/settings/api/settings/settingsApiDispatchers.ts";

export const ALL_SETTINGS_QUERY_KEY = ["settings"];

export const useAllSettings = (options?: QueriesOptions<any>) => useQuery(
	ALL_SETTINGS_QUERY_KEY,
	() => indexSettings().then(getDataFromResponse),
	options,
);