import { tv } from "tailwind-variants";

import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import type { VariantProps } from "~/types/tailwindVariants";

export const switchVariants = tv({
	slots: {
		container: "flex items-center",
		button: [
			"relative inline-flex flex-shrink-0 cursor-pointer rounded-full ",
			"border-2 border-transparent ",
			"transition-colors duration-200 ease-in-out",
		],
		indicator: "pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
		labelOff: "cursor-pointer",
		labelOn: "cursor-pointer",
	},
	variants: {
		textColor: {
			default: {
				container: "text-gray-900",
			},
			light: {
				container: "text-gray-700",
			},
		},
		inputSize: {
			xs: {
				button: "h-5 w-8",
				container: "text-xs",
				indicator: "size-4",
				labelOn: "pl-2",
				labelOff: "pr-2",
			},
			sm: {
				button: "h-6 w-11",
				container: "text-sm",
				indicator: "size-5",
				labelOn: "pl-3",
				labelOff: "pr-3",
			},
			md: {
				button: "h-6 w-11",
				container: "text-sm",
				indicator: "size-5",
				labelOn: "pl-3",
				labelOff: "pr-3",
			},
		},
		checked: {
			true: {
				button: "bg-primary-400",
				indicator: "translate-x-5",
				labelOn: "text-gray-900",
			},
			false: {
				button: "bg-gray-200",
				indicator: "translate-x-0",
				labelOff: "text-gray-900",
			},
		},
		focusRing: {
			true: {
				button: "focus:ring-2 focus:ring-blue-500",
			},
		},
		showAllLabels: {
			true: {
				labelOff: "",
				labelOn: "",
				container: "text-gray-400",
			},
			false: {
				labelOff: "hidden",
			},
		},
	},
	compoundSlots: [
		{
			slots: ["indicator"],
			inputSize: "xs",
			checked: true,
			className: "translate-x-3",
		},
		{
			slots: ["labelOn"],
			checked: false,
			showAllLabels: true,
			className: "text-gray-400",
		},
		{
			slots: ["labelOff"],
			checked: true,
			showAllLabels: true,
			className: "text-gray-400",
		},
	],
	defaultVariants: {
		checked: false,
		inputSize: "md",
		text: "left",
		textColor: "default",
		focusRing: true,
		showAllLabels: false,

	},
});

export type SwitchVariantsProps = VariantProps<typeof switchVariants> & FormInputBaseVariants;