import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const statusVariants = tv({
	base: "inline-flex  whitespace-nowrap font-medium ring-1 ring-inset",
	variants: {
		theme: {
			highlight: "bg-blue-50 text-blue-700 ring-blue-600/20",
			success: "bg-green-50 text-green-700 ring-green-600/20",
			default: "bg-gray-50 text-gray-600 ring-gray-500/10",
			info: "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
			error: "bg-red-50 text-red-700 ring-red-600/20",
		},
		size: {
			default: "px-2.5 py-0.5 text-xs",
			lg: "px-4 py-2.5 text-base",
		},
		borderRadius: {
			full: "rounded-full",
			default:"rounded-md"
		}
	},
	defaultVariants: {
		borderRadius: "default",
		theme: "default",
	},
});


export type StatusVariantProps = VariantProps<typeof statusVariants>;
export type StatusThemeVariants = "highlight" | "success" | "default" | "info" | "error";
export type StatusSizeVariants = "default" | "lg";
export type StatusBorderRadiusVariants = "full" | "default";
