import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersBonuses,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusApiDispatchers.ts";

export const STAFF_MEMBER_BONUS_BASE_QUERY_KEY = "staffMembersBonus";

export const STAFF_MEMBERS_BONUSES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BONUS_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersBonuses = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_BONUSES_QUERY_KEY(staffMemberId),
	() => indexStaffMembersBonuses(staffMemberId).then(getDataFromResponse),
	options,
);