import "dayjs/locale/en";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import type { PopoverProps } from "@mantine/core";
import { DatesProvider, MonthPickerInput as MantineMonthPickerInput } from "@mantine/dates";
import type React from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import FormInputError from "~/components/formElements/FormInputError";
import Label from "~/components/formElements/Label";

type SharedPropsType = FormInputBaseVariants & {
	clearable?: boolean;
	disabled?: boolean;
	error?: string;
	label?: string;
	maxDate?: Date;
	minDate?: Date;
	name: string;
	position?: PopoverProps["position"];
};

type MonthPickerInputPlainProps = SharedPropsType & {
	value: Date | null;
	onChange: (value: Date | null) => void;
};

export const MonthPickerInputPlain: React.FC<MonthPickerInputPlainProps> = ({
	clearable = true,
	disabled,
	error,
	inputSize,
	label,
	maxDate,
	minDate,
	name,
	onChange,
	position,
	theme,
	value,
}) => {

	return (
		<FormInput>
			{label && <Label htmlFor={name}>{label}</Label>}
			<div className="relative rounded-md shadow-sm">
				<DatesProvider settings={{ locale: "de" }}>
					<MantineMonthPickerInput
						classNames={{
							input: formInputBaseVariants({ inputSize, theme, disabled }),
							section: `absolute right-0 top-0 h-full 
                                        flex items-center 
                                        pr-2
                                        [&_button]:rounded-full [&_button]:hover:bg-gray-200 [&_button]:w-5 [&_button]:h-5
                                        [&_button]:flex [&_button]:items-center [&_button]:justify-center
                                        [&_svg]:text-gray-500`,
							calendarHeader: "flex justify-between h-10 bg-white",
							calendarHeaderLevel: "flex gap-1 justify-center items-center hover:bg-gray-100 rounded-full px-4",
							calendarHeaderControl: "flex items-center justify-center w-10 h-10 hover:bg-gray-100 rounded-full",
							calendarHeaderControlIcon: "text-gray-400 data-[direction='previous']:rotate-90 data-[direction='next']:-rotate-90",
							monthsListControl: `px-2 py-1 text-center hover:bg-primary-400 hover:text-white rounded-md text-sm py-2 w-full
                                                data-[disabled="true"]:hover:bg-white data-[disabled="true"]:text-gray-300
                                                data-[selected]:bg-accent-500`,
							monthsList: "w-full",
							yearsListControl: `px-2 py-1 text-center hover:bg-primary-400 hover:text-white rounded-md text-sm py-2 w-full
                            data-[disabled="true"]:hover:bg-white data-[disabled="true"]:text-gray-300`,
							yearsList: "w-full",
						}}
						popoverProps={{
							position: position || "bottom-start",
							classNames: {
								dropdown: "absolute z-50 border border-gray-200 bg-white rounded-md shadow-lg p-2 text-gray-800 min-w-[16rem]",
							},
						}}
						valueFormat="MMMM YYYY"
						name={name}
						//@ts-expect-error Mantine types are not up to date
						placeholder="Monat auswählen"
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
						value={value}
						onChange={onChange}
						clearable={clearable}
					/>
				</DatesProvider>
				{error && (
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
						<ExclamationCircleIcon className="size-5 text-red-500"
											   aria-hidden="true" />
					</div>
				)}
			</div>
			{error && <FormInputError>{error}</FormInputError>}
		</FormInput>
	);
};

type MonthPickerInputProps = SharedPropsType & {
	control: Control<any>;
};

const MonthPickerInput: React.FC<MonthPickerInputProps> = ({
	control,
	name,
	...rest
}) => {
	const {
		field: { onChange, value },
		fieldState: { error: controlError },
	} = useController({
		name,
		control,
	});
	return <MonthPickerInputPlain
		{...rest}
		name={name}
		value={value}
		onChange={onChange}
		error={controlError?.message}
	/>;
};

export default MonthPickerInput;