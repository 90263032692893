import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexBonusTypes } from "~/modules/humanResources/api/bonusType/bonusTypeApiDispatchers.ts";

export const STAFF_BONUS_TYPE_BASE_QUERY_KEY = "staffMembersBonus";

export const ALL_BONUS_TYPES_QUERY_KEY = () => [STAFF_BONUS_TYPE_BASE_QUERY_KEY, "all"];

export const useAllBonusTypes = (options?: QueriesOptions<any>) => useQuery(
	ALL_BONUS_TYPES_QUERY_KEY(),
	() => indexBonusTypes().then(getDataFromResponse),
	options,
);