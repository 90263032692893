import { format } from "date-fns";
import { de } from "date-fns/locale";
import ExcelJS from "exceljs";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";

import type { ExcelSheetData } from "./monthlyCompensationReportExcelDef.tsx";
import { monthlyReportTableColumnDefs } from "./monthlyCompensationReportExcelDef.tsx";

function DownloadMonthlyReportExcelButton({ data, downloadDisabled, month }: {
	data: ExcelSheetData[],
	downloadDisabled: boolean,
	month: Date
}) {
	const generateExcel = async () => {
		const workBookTitle = "Gehaltsreport " + format(month, "MMMM yyyy", { locale: de });
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(workBookTitle);

		// Add headers
		const headers = monthlyReportTableColumnDefs.map(col => col.header);
		const headerRow = worksheet.addRow(headers);

		// Style header row
		headerRow.font = { bold: true };
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };

		headerRow.height = 45; // Adjust this value as needed

		// Add data and apply formatting
		data.forEach((row) => {
			const excelRow = worksheet.addRow(
				monthlyReportTableColumnDefs.map(col => {
					const value = row[col.accessor as keyof ExcelSheetData];

					if (col.accessor === "bonusCents" && row.bonusCents === 0) return null;

					if (value === null) return null; // Handle null values

					if (col.type === "currency" && typeof value === "number") {
						return value / 100; // Convert cents to euros
					}

					if (col.type === "percentage" && typeof value === "number") {
						return value / 10000; // Convert basis points to decimal (100% = 10000 = 1.0)
					}

					if (col.type === "date" && typeof value === "string") {
						return new Date(value);
					}

					return value;
				}),
			);

			// Apply cell formatting
			monthlyReportTableColumnDefs.forEach((col, colIndex) => {
				const cell = excelRow.getCell(colIndex + 1);

				switch (col.type) {
					case "currency":
						cell.numFmt = "#,##0.00 \"€\"";
						break;
					case "percentage":
						cell.numFmt = "0.00%";
						break;
					case "date":
						cell.numFmt = "dd.mm.yyyy";
						break;
				}

				// Apply highlighting
				if (col.highlightCondition && col.highlightCondition(row)) {
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "FFFFFF00" }, // Yellow
					};
				}

				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Apply formula for totalPaymentCents
		const totalPaymentCol = monthlyReportTableColumnDefs.findIndex(col => col.accessor === "totalPaymentCents");
		if (totalPaymentCol !== -1) {
			const monthlySalaryCol = monthlyReportTableColumnDefs.findIndex(col => col.accessor === "monthlySalaryCents");
			const bonusCol = monthlyReportTableColumnDefs.findIndex(col => col.accessor === "bonusCents");
			const monthlyBonusCol = monthlyReportTableColumnDefs.findIndex(col => col.accessor === "monthlyBonusPaymentCents");

			if (monthlySalaryCol !== -1 && bonusCol !== -1 && monthlyBonusCol !== -1) {
				for (let rowIndex = 2; rowIndex <= data.length + 1; rowIndex++) {
					const cell = worksheet.getCell(rowIndex, totalPaymentCol + 1);
					cell.value = {
						formula: `(IFERROR(${String.fromCharCode(65 + monthlySalaryCol)}${rowIndex},0) + IFERROR(${String.fromCharCode(65 + bonusCol)}${rowIndex},0) + IFERROR(${String.fromCharCode(65 + monthlyBonusCol)}${rowIndex},0))`,
					};
					cell.numFmt = "\"€\"#,##0.00";
				}
			}
		}
		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;

			// @ts-expect-error - TS doesn't know about the includeEmpty option
			column.eachCell({ includeEmpty: true }, (cell) => {
				let cellLength = 0;
				if (cell.value) {
					if (cell.type === ExcelJS.ValueType.Date) {
						// For date cells, use the formatted string length (e.g. "dd.mm.yyyy")
						cellLength = 10;
					} else if (typeof cell.value === "string" && cell.value.includes("\n")) {
						// For multi-line cells, find the longest line
						const lines = cell.value.toString().split("\n");
						cellLength = Math.max(...lines.map(line => line.length));
					} else {
						cellLength = cell.value.toString().length;
					}
				} else {
					cellLength = 10; // Default length for empty cells
				}
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});

			column.width = Math.min(maxLength + 2, 30); // Set width to content, but max 30
		});
		// Ensure all columns are visible
		worksheet.views = [
			{ state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2", activeCell: "A2" },
		];

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = workBookTitle + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton disabled={downloadDisabled}
								buttonText="Excel-Report herunterladen"
								onClick={generateExcel} />;
}

export default DownloadMonthlyReportExcelButton;