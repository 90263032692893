import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import TableBodyCell from "~/components/Table/components/TableBodyCell";
import type { BonusTypeId } from "~/modules/humanResources/api/bonusType/bonusTypes.ts";
import { getBonusTypeDisplayNameById } from "~/modules/humanResources/utils/bonusTypeUtils.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	amountCents: number,
	bonusTypeId: string,
	description: string,
	onDeleteClick: () => void
	onEditClick: () => void,
	paidAt: Date,

};

const BonusTableRow: FunctionComponent<Props> = ({
	amountCents,
	bonusTypeId,
	description,
	onDeleteClick,
	onEditClick,
	paidAt,
}) => {
	const popoverItems = [];

	if (onEditClick) {
		popoverItems.push({
			label: "Bearbeiten",
			onClick: onEditClick,
			icon: PencilIcon,
		});
	}

	if (onDeleteClick) {
		popoverItems.push({
			label: "Löschen",
			onClick: onDeleteClick,
			icon: TrashIcon,
		});
	}

	return <tr>
		<TableBodyCell>
			{formatDateWithGermanMonth(paidAt)}
		</TableBodyCell>
		<TableBodyCell>
			{formatCentsToCurrency(amountCents)}
		</TableBodyCell>
		<TableBodyCell>
			{getBonusTypeDisplayNameById(bonusTypeId as BonusTypeId)}
		</TableBodyCell>
		<TableBodyCell>
			{description}
		</TableBodyCell>
		<td className="px-4 py-3">
			<div className="relative flex items-center justify-end whitespace-nowrap">
				{popoverItems.length > 0 && <ButtonWithPopover theme="dark"
															   items={popoverItems} />}
			</div>
		</td>
	</tr>;
};

export default BonusTableRow;