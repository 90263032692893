import { t } from "i18next";
import { useMemo } from "react";

import { useAllGenders } from "~/modules/humanResources/api/gender/genderQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData,getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";


const useGenderSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: genderData } = useAllGenders();
	return useMemo(() => {
		if (genderData) {
			const options = genderData.map((gender) => {
				const label = t(`entities:gender.${gender.name}` as unknown as TemplateStringsArray);
				return createInputOptionData(label, gender.id);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [genderData, includeCtaOption]);
};

export default useGenderSelectOptions;

