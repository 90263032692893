import { createColumnHelper } from "@tanstack/react-table";

import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import CompensationReportBaseCell
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/CompensationReportBaseCell";
import CompensationReportCurrencyCell
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/CompensationReportCurrencyCell";
import CompensationReportPercentageCell
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/CompensationReportPercentageCell";
import type { MonthlyReportTableData } from "~/modules/humanResources/types/monthlyReportsTableTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

const columnHelper = createColumnHelper<MonthlyReportTableData>();
export const monthlyReportTableColumnDefs = [
	columnHelper.accessor("staffMemberFullName", {
		header: "Mitarbeiter/in",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
		cell: (props) => <CompensationReportBaseCell
			fontBold={true}
			highlighted={props.row.original.currentPeriodStartedInCurrentMonth}>{props.getValue() || EMPTY_VALUE_PLACEHOLDER}</CompensationReportBaseCell>,
	}),
	columnHelper.accessor("careerLevelDisplayName", {
		header: "Karrierestufe",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
	}),
	columnHelper.accessor("currentPeriodStartDate", {
		header: "Vetragsbeginn aktuelle Anstellungsperiode",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportBaseCell
			highlighted={props.row.original.currentPeriodStartedInCurrentMonth}>{props.getValue() ? formatDateWithGermanMonth(new Date(props.getValue() as string)) : EMPTY_VALUE_PLACEHOLDER}</CompensationReportBaseCell>,

	}),
	columnHelper.accessor("currentPeriodStartTotalCompensationCents", {
		header: "Einstigesgehalt aktuelle Anstellungsperiode",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => props.getValue() ? formatCentsToCurrency(props.getValue() as number) : EMPTY_VALUE_PLACEHOLDER,
	}),
	columnHelper.accessor("ftePercentage", {
		header: "FTE Prozentsatz",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportPercentageCell value={props.getValue()}
														   highlighted={props.row.original.ftePercentageWasUpdated} />,
	}),
	columnHelper.accessor("totalCompensationFteCents", {
		header: "Total Compensation 100%",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()}
														 highlighted={props.row.original.totalCompensationWasUpdated} />,
	}),
	columnHelper.accessor("totalCompensationCents", {
		header: "Total Compensation (FTE-basiert)",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()} />,
	}),
	columnHelper.accessor("variableCompensationPercentage", {
		header: "Variabler Anteil %",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportPercentageCell value={props.getValue()}
														   highlighted={props.row.original.variableCompensationPercentageWasUpdated} />,
	}),
	columnHelper.accessor("yearlySalaryCents", {
		header: "Grundgehalt FTE-basiert",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()} />,
	}),
	columnHelper.accessor("variableCompensationCents", {
		header: "Variabler Anteil FTE-basiert",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()} />,
	}),
	columnHelper.accessor("monthlySalaryCents", {
		header: "Monatliches Grundgehalt FTE-basiert",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()} />,
	}),
	columnHelper.accessor("bonusCents", {
		header: "Bonuszahlungen",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => <CompensationReportCurrencyCell value={props.getValue()}
														 highlighted={props.row.original.bonusCentsWasUpdated} />,
	}),

	columnHelper.accessor("totalPaymentCents", {
		header: "Auszahlungsbetrag",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => props.getValue() ? formatCentsToCurrency(props.getValue() as number) : EMPTY_VALUE_PLACEHOLDER,
	}),
];