import type { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import MainNavigation from "~/components/MainNavigation";

const AppLayoutPlain: FunctionComponent = () => {
	return (
		<div className="font-normal">
			<MainNavigation />
			<main className="lg:pl-64">
				<Outlet />
			</main>
		</div>
	);
};

export default AppLayoutPlain;
