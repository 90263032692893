import type { ForwardedRef, MutableRefObject,RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

const setRef = <T>(ref: ForwardedRef<T>, value: T | null) => {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        (ref as MutableRefObject<T | null>).current = value;
    }
};

function useAutoFocus<T extends HTMLElement>(
    shouldFocus: boolean,
    shouldSelectText: boolean = false,
    externalRef?: ForwardedRef<T>
): RefObject<T> {
    const internalRef = useRef<T>(null);
    const [isComponentJustMounted, setIsComponentJustMounted] = useState(true);

    useEffect(() => {
        const currentElement = typeof externalRef === 'function' ? internalRef.current : (externalRef?.current || internalRef.current);

        if (shouldFocus && currentElement) {
            if (currentElement.focus && isComponentJustMounted) {
                currentElement.focus();
                // Select the text only on the initial render
                if (shouldSelectText && currentElement instanceof HTMLInputElement) {
                    currentElement.select();
                }
            }
        }

        // Set the external ref (if it's a function) to the internal ref's current element
        if (typeof externalRef === 'function') {
            setRef(externalRef, internalRef.current);
        }

        // After the initial focus, set this to false
        if (isComponentJustMounted) {
            setIsComponentJustMounted(false);
        }
    }, [shouldFocus, shouldSelectText, externalRef, isComponentJustMounted]); // Depend on shouldFocus, shouldSelectText, externalRef, and isComponentJustMounted

    return internalRef;
}

export default useAutoFocus;
