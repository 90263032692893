import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMembersEmergencyContact,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdatePersonalDetailsFormData = {
	firstName: string|null;
	lastName: string|null;
	email: string | null;
	phone: string | null;
	personalRelation: string | null;
}

type UpdatePersonalDetailsFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateEmergencyContactForm: React.FunctionComponent<UpdatePersonalDetailsFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = useMemo(() => {
		return yup.object({
			email: yup.string().nullable().email().default(null),
			firstName: yup.string().nullable().default(null),
			lastName: yup.string().nullable().default(null),
			personalRelation: yup.string().nullable().default(null),
			phone: yup.string().nullable().default(null),

		});
	}, []);
	const defaultValues = useMemo(() => {
		if (staffMemberCoreData.emergencyContact) {
			return {
				email: staffMemberCoreData.emergencyContact.email || null,
				firstName: staffMemberCoreData.emergencyContact.firstName || null,
				lastName: staffMemberCoreData.emergencyContact.lastName || null,
				personalRelation: staffMemberCoreData.emergencyContact.personalRelation || null,
				phone: staffMemberCoreData.emergencyContact.phone || null,
			};
		}
		return {
			email: null,
			firstName: null,
			lastName: null,
			personalRelation: null,
			phone: null,
		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdatePersonalDetailsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdatePersonalDetailsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdatePersonalDetailsFormData) => {

		try {
			setIsBusy(true);
			await updateStaffMembersEmergencyContact(staffMemberCoreData.id, data);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Notfallkontakt
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Input control={control}
						   name="firstName"
						   label="Vorname"
						   placeholder="Vorname"
					/>
					<Input control={control}
						   name="lastName"
						   label="Nachname"
						   placeholder="Nachname"
					/>
					<Input control={control}
						   name="personalRelation"
						   label="Beziehung zum Mitarbeiter"
						   placeholder="Ehepartner, Elternteil, Freund, etc."
					/>
					<Input control={control}
						   name="phone"
						   label="Telefon"
						   placeholder="+49 123 4567890"
					/>
					<Input control={control}
						   name="email"
						   label="E-Mail"
						   placeholder="notfall@example.com"
					/>

				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateEmergencyContactForm;
