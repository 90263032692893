import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import type { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import UpdateFreelanceUserForm
	from "~/modules/user/components/components/UpdateUserSidebar/components/UpdateFreelanceUserForm";
import UpdateInternalUserForm
	from "~/modules/user/components/components/UpdateUserSidebar/components/UpdateInternalUserForm";

type CreateUserSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
	userData: UserWithConfidentialInformation;
};

const UpdateUserSidebar: React.FunctionComponent<CreateUserSidebarProps> = ({ isOpen, closeSidebar, userData }) => {
	const queryClient = useQueryClient();
	const isFreelanceUser = userData?.employmentTypeId === EmploymentTypeId.Freelancer;

	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		closeSidebar();
	}, [closeSidebar, queryClient]);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeSidebar}>
			{userData && isFreelanceUser && <UpdateFreelanceUserForm userData={userData}
																	 onSuccess={handleSuccess}
																	 onCancel={closeSidebar} />}
			{userData && !isFreelanceUser && <UpdateInternalUserForm userData={userData}
																	 onSuccess={handleSuccess}
																	 onCancel={closeSidebar}
			/>}
		</Sidebar>
	);
};

export default UpdateUserSidebar;
