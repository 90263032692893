import { generatePath } from "react-router-dom";

import { appRoutes } from "~/constants/appRoute.ts";
import type { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import NewFirstItemCta from "~/modules/client/components/ClientDetailsView/components/NewFirstItemCta";
import ContactCard from "~/modules/client/components/contact/components/ContactCard";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientContactsListProps {
	contacts?: ClientContactPersonType[];
	displayName: string;
	onAddContactClick: () => void;
}

const ClientContactsList: React.FC<ClientContactsListProps> = ({ contacts, displayName, onAddContactClick }) => {
	if (undefined === contacts || (contacts && 0 === contacts.length))
		return <NewFirstItemCta buttonText="Neuen Kontakt anlegen"
								headline="Noch keine Kontakte vorhanden"
								onCreateClick={onAddContactClick}
								subLineText={`Füge neue Kontakte zu ${displayName} hinzu.`} />;

	return (
		<>
			<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				{contacts.sort(byObjectProperty("firstName")).map(({
					id,
					title,
					firstName,
					lastName,
					jobTitle,
					emailBusiness,
					phone,
				}) => (
					<ContactCard
						key={id}
						title={title}
						clientDisplayName={displayName}
						firstName={firstName}
						lastName={lastName}
						description={jobTitle}
						emailBusiness={emailBusiness}
						phone={phone}
						href={generatePath(appRoutes.contact().path, { contactId: id })}
					/>
				))}
			</div>
		</>
	);
};

export default ClientContactsList;
