import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexStaffMembersEmployments } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsApiDispatchers.ts";

export const EMPLOYMENT_BASE_QUERY_KEY = "staffMembersEmployments";

export const STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY = (staffMemberId: string) => [EMPLOYMENT_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersEmployments = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY(staffMemberId),
	() => indexStaffMembersEmployments(staffMemberId).then(getDataFromResponse),
	options,
);