import { t } from "i18next";

import { CareerLevelId, CareerLevelName } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getCareerLevelDisplayNameById(careerLevelId: CareerLevelId): string {
	let careerLevelName = "";

	switch (careerLevelId) {
		case CareerLevelId.Analyst:
			careerLevelName = CareerLevelName.Analyst;
			break;
		case CareerLevelId.Consultant:
			careerLevelName = CareerLevelName.Consultant;
			break;
		case CareerLevelId.SeniorConsultant:
			careerLevelName = CareerLevelName.SeniorConsultant;
			break;
		case CareerLevelId.Manager:
			careerLevelName = CareerLevelName.Manager;
			break;
		case CareerLevelId.SeniorManager:
			careerLevelName = CareerLevelName.SeniorManager;
			break;
		case CareerLevelId.Partner:
			careerLevelName = CareerLevelName.Partner;
			break;
		case CareerLevelId.Assistant:
			careerLevelName = CareerLevelName.Assistant;
			break;
		case CareerLevelId.Student:
			careerLevelName = CareerLevelName.Student;
			break;
		case CareerLevelId.IT:
			careerLevelName = CareerLevelName.IT;
			break;
		case CareerLevelId.ManagingDirector:
			careerLevelName = CareerLevelName.ManagingDirector;
			break;
		default:
			throw new Error("Unknown career level id");
	}
	if (!careerLevelName) {
		return "unbekannt";
	}

	return t(normalizeTKey(`entities:careerLevel.${careerLevelName}`));
}