import { generatePath, useNavigate, useParams } from "react-router-dom";

import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import { STAFF_MEMBER_BASE_PATH } from "~/constants/appRoute.ts";
import { AbsenceTabName } from "~/modules/absence/types/absenceOverviewTypes.ts";


type AbsencePageTabsProps = {
	selectedTabName: AbsenceTabName;
};

const AbsencePageTabs: React.FunctionComponent<AbsencePageTabsProps> = ({ selectedTabName }) => {
	const navigate = useNavigate();
	const { staffMemberId } = useParams();
	return <SectionHeadingTabs
		tabs={[
			{
				name: "Abwesenheiten",
				value: AbsenceTabName.absences,
				active: selectedTabName === AbsenceTabName.absences,
			},
			{
				name: "Urlaubskonto",
				value: AbsenceTabName.vacationLedger,
				active: selectedTabName === AbsenceTabName.vacationLedger,
			},
			{
				name: "Anstellungsdetails",
				value: AbsenceTabName.workingSchedules,
				active: selectedTabName === AbsenceTabName.workingSchedules,
			},


		]}
		onTabClick={(tabValue) => {
			navigate(generatePath(STAFF_MEMBER_BASE_PATH, { staffMemberId: staffMemberId || "" }) + "/" + tabValue, { replace: true });
		}}
	/>;
};

export default AbsencePageTabs;