import { appRoutes } from "~/constants/appRoute.ts";

export const INTENDED_LOCATION_KEY = "intendedLocation";

export const deleteIntendedLocation = () => {
	localStorage.removeItem(INTENDED_LOCATION_KEY);
};

export const getIntendedLocation = () => {
	const location = localStorage.getItem(INTENDED_LOCATION_KEY);
	if (location) {
		return JSON.parse(location);
	}
	return null;
};

export const setIntendedLocation = (intendedLocation: string | null = null) => {
	intendedLocation = intendedLocation || window.location.pathname;

	if (!intendedLocation.includes(appRoutes.base().path)) {
		localStorage.setItem(INTENDED_LOCATION_KEY, JSON.stringify(intendedLocation));
	}
};
