import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberTotalCompensationData,
	StaffMemberTotalCompensation, UpdateStaffMemberTotalCompensationData,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffTotalCompensationTypes.ts";


const totalCompensationBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/total-compensations`;

export function indexStaffMembersTotalCompensations(staffMemberId: string): AxiosApiResponsePromise<StaffMemberTotalCompensation[]> {
	return axiosApi.get(totalCompensationBasePath(staffMemberId));
}

export function createStaffMemberTotalCompensation(staffMemberId: string,
	employmentData: CreateStaffMemberTotalCompensationData): AxiosApiResponsePromise<void> {
	return axiosApi.post(totalCompensationBasePath(staffMemberId), employmentData);
}

export function updateStaffMemberTotalCompensation({ staffMemberId, totalCompensationId, updateData }: {
	staffMemberId: string;
	totalCompensationId: string;
	updateData: UpdateStaffMemberTotalCompensationData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${totalCompensationBasePath(staffMemberId)}/${totalCompensationId}`, updateData);
}

export function deleteStaffMemberTotalCompensation(staffMemberId: string, totalCompensationId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${totalCompensationBasePath(staffMemberId)}/${totalCompensationId}`);
}