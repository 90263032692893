import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import type { ProductTag } from "~/modules/project/api/productTags/productTagsTypes.ts";

export enum AdminViewTabName {
	DeliverableTags = "deliverable-tags",
	ProductTags = "product-tags",
	Users = "users",
}

export type ProductTagWithProjectId = ProductTag & { projectIds: string[] };

export type DeliverableTagWithProjectId = DeliverableTag & { projectIds: string[] };