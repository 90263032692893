import type {Table as TableType} from "@tanstack/table-core/build/lib/types";

import EmptyFilterResultMessage from "~/components/Table/components/EmptyFilterResultMessage";
import EmptyTableDataMessage from "~/components/Table/components/EmptyTableDataMessage";


type EmptyTableMessageProps<T> = {
    table: TableType<T>
};

const EmptyTableMessage = <T, >({table}: EmptyTableMessageProps<T>) => {
    const tableHasRows = table.options.data.length > 0;
    const tableHasFilteredRows = table.getFilteredRowModel().rows.length > 0;

    return !tableHasRows ?
        <EmptyTableDataMessage message={table.options.meta?.emptyTableDataMessage}/> :
        !tableHasFilteredRows &&
        <EmptyFilterResultMessage message={table.options.meta?.emptyFilteredTableDataMessage}/>
};

export default EmptyTableMessage;