import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import { FormSectionGroup } from "~/components/formElements/FormSection";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import TimingFormSection from "~/components/formSections/TimingFormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientsInvoiceRecipientSelectOptions
	from "~/hooks/form/formOptionsData/useClientsInvoiceRecipientsSelectOptions.ts";
import useCurrencySelectOptions from "~/hooks/form/formOptionsData/useCurrencySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useOrderTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useOrderTitleValidationComparisonData.ts";
import { updateOrder } from "~/modules/project/api/order/orderApiDispatchers.ts";
import type { Order, UpdateOrderData } from "~/modules/project/api/order/orderTypes.ts";
import OrderAccountingFormSection from "~/modules/project/components/forms/formSections/OrderAccountingFormSection";
import OrderBudgetFormSection from "~/modules/project/components/forms/formSections/OrderBudgetFormSection";
import OrderForeignCountryFormSection
	from "~/modules/project/components/forms/formSections/OrderForeignCountryFormSection";
import OrderInvoiceRecipientSection from "~/modules/project/components/forms/formSections/OrderInvoiceRecipientSection";
import OrderStatusFormSection from "~/modules/project/components/forms/formSections/OrderStatusFormSection";
import OrderTitleAndNumberFormSection
	from "~/modules/project/components/forms/formSections/OrderTitleAndNumberFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { convertLocalToUTCDate, convertUTCToLocalDate, formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { getPaymentTargetDefaultValues, preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

export type OrderDefaultValuesFromClientType = {
	invoiceByMail: boolean;
	paymentTargetDays: number | null;
	isVatExempt: boolean;
	travelExpensesIncluded: boolean;
};

type CreateOrderFormProps = {
	clientId: string;
	onSuccess: () => void;
	onCancel: () => void;
	orderData: Order;
};

interface UpdateOrderFormData
	extends Omit<
		UpdateOrderData,
		"projectId" | "projectPhaseId" | "confirmedAt" | "paymentTargetDays" | "clientContactPersonId" | "manDays" | "startDate" | "endDate"
	> {
	clientContactPersonId: string | null;
	confirmed: boolean;
	endDate: Date;
	manDays: number;
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
	startDate: Date;
}

const UpdateOrderForm: React.FC<CreateOrderFormProps> = ({ onSuccess, onCancel, clientId, orderData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const titleComparisonData = useOrderTitleValidationComparisonData({
		projectId: orderData.projectId,
		projectPhaseId: orderData.projectPhaseId,
	});
	const schema = useMemo(() => {
		return yup
			.object({
				budgetCents: yup.number().required(),
				comment: yup.string().default(null),
				confirmed: yup.boolean().required(),
				clientContactPersonId: yup.string().nullable().default(null),
				currencyId: yup.string().required(),
				endDate: yup
					.date()
					.required()
					.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
				invoiceByMail: yup.boolean().required(),
				invoiceOrderNumberRequired: yup.boolean().required(),
				invoiceRecipientId: yup.string().nullable().default(null),
				isBilledMonthly: yup.boolean().required(),
				isFixedPrice: yup.boolean().required(),
				isForeignCountry: yup.boolean().required(),
				isSkillBased: yup.boolean().required(),
				manDays: yup.number().required(),
				orderNumber: yup.string().default(null),
				paymentTargetDays: yup.number().required(),
				paymentTargetDaysCustom: yup.number()
					.nullable()
					.when("paymentTargetDays", (field1Value: number[], schema) => {
						return field1Value[0] === 0
							? schema.required()
							: schema.nullable();
					}),
				startDate: yup.date().required(),
				title: yup.string().required().unique(titleComparisonData, orderData.id),
				travelExpensesIncluded: yup.boolean().required(),
			})
			.test("atLeastOneField", "Bitte wähle einen Kontakt oder zentralen Rechnungsempfänger aus.", (value) =>
				Boolean(value.clientContactPersonId || value.invoiceRecipientId),
			);
	}, [titleComparisonData, orderData]);

	const defaultValues = useMemo(() => {
		const { paymentTargetDays, paymentTargetDaysCustom } = getPaymentTargetDefaultValues(
			orderData.paymentTargetDays,
		);

		return {
			budgetCents: orderData.budgetCents,
			clientContactPersonId: orderData.clientContactPersonId as string,
			comment: orderData.comment || "",
			confirmed: !!orderData.confirmedAt || false,
			currencyId: orderData.currencyId,
			endDate: orderData.endDate ? convertUTCToLocalDate(orderData.endDate) : new Date(),
			invoiceByMail: orderData.invoiceByMail,
			invoiceOrderNumberRequired: orderData.invoiceOrderNumberRequired,
			invoiceRecipientId: orderData.invoiceRecipientId,
			isBilledMonthly: orderData.isBilledMonthly,
			isFixedPrice: orderData.isFixedPrice,
			isForeignCountry: orderData.isForeignCountry,
			isSkillBased: orderData.isSkillBased,
			manDays: parseFloat(orderData.manDays),
			orderNumber: orderData.orderNumber || "",
			paymentTargetDays: paymentTargetDays,
			paymentTargetDaysCustom: paymentTargetDaysCustom,
			startDate: orderData.startDate ? convertUTCToLocalDate(orderData.startDate) : new Date(),
			title: orderData.title,
			travelExpensesIncluded: orderData.travelExpensesIncluded,
		};
	}, [orderData]);
	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		watch,
		setValue,
		trigger,
	} = useForm<UpdateOrderFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateOrderFormData>(schema),
	});

	const showCustomPaymentTargetInput = watch("paymentTargetDays") === 0;
	const showCurrencyInput = !!watch("isForeignCountry");

	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(clientId, orderData.clientContactPersonId || undefined);
	const clientsInvoiceRecipientSelectOptions = useClientsInvoiceRecipientSelectOptions(clientId);
	const currencySelectOptions = useCurrencySelectOptions();

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isValid,
		isSubmitted,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		if (showCustomPaymentTargetInput) {
			trigger("paymentTargetDaysCustom");
		}
	}, [showCustomPaymentTargetInput, trigger]);

	const onSubmit: SubmitHandler<UpdateOrderFormData> = async (data: UpdateOrderFormData) => {
		setBusy(true);
		const orderUpdateData = {
			...data,
			projectId: orderData.projectId,
			projectPhaseId: orderData.projectPhaseId,
			startDate: formatDateToYYYYMMDD(data.startDate!),
			endDate: formatDateToYYYYMMDD(data.endDate!),
			confirmedAt: data.confirmed ? convertLocalToUTCDate(new Date()) : null,
		};

		try {
			await updateOrder({ projectId: orderData.projectId, orderId: orderData.id, orderData: orderUpdateData });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	const isForeignCountry = watch("isForeignCountry");

	useEffect(() => {
		if (isSubmitted) {
			trigger("currencyId");
		}
	}, [isForeignCountry, trigger, isSubmitted]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Bestellung aktualisieren</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<OrderTitleAndNumberFormSection control={control} />
					<OrderStatusFormSection control={control} />
					<OrderBudgetFormSection control={control}
											watch={watch} />
					<TimingFormSection control={control}
									   errors={errors}
									   title={"Laufzeit"} />

					<OrderAccountingFormSection
						control={control}
						errors={errors}
						showCustomPaymentTargetInput={showCustomPaymentTargetInput}
					/>

					<OrderInvoiceRecipientSection
						control={control}
						clientContactPersonSelectOptions={clientContactPersonSelectOptions}
						// @ts-expect-error - this is a hack to pass the error message to the correct form section
						errorMessage={errors[""]?.message}
						invoiceRecipientSelectOptions={clientsInvoiceRecipientSelectOptions}
						setValue={setValue}
						trigger={trigger}
						watch={watch}
					/>
					<OrderForeignCountryFormSection
						control={control}
						currencySelectOptions={currencySelectOptions}
						showCurrencySelect={showCurrencyInput}
						errors={errors}
					/>

					<CommentFormSection
						control={control}
						label="Kommentar"
						title="Anmerkungen" />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateOrderForm;
