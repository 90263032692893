import CardValueWithLabel from "components/CardValueWithLabel";
import UpdateSocialSecurityAndPensionInsuranceSidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateSocialSecurityAndPensionInsuranceDetailsSidebar";
import type { FunctionComponent } from "react";
import { Fragment, useState } from "react";

import Card from "~/components/Card";
import CountryDisplay from "~/components/displays/CountryDisplay";
import Label from "~/components/formElements/Label";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import Section from "~/components/sections/Section";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { HealthInsuranceTypeName } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { useStaffMembersChildren } from "~/modules/humanResources/api/staffMemberChild/staffMemberChildQueries.ts";
import type { TaxClassId } from "~/modules/humanResources/api/taxClass/taxClassTypes.ts";
import UpdateBankAccountSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateBankAcountSidebar";
import UpdateContactDetailsSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateContactDetailsSidebar";
import UpdateEmergencyContactSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateEmergencyContactSidebar";
import UpdateHealthInsuranceSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateHealthInsuranceSidebar";
import UpdatePersonalDetailsSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdatePersonalDetailsSidebar";
import UpdateStaffMembersChildrenSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateStaffMembersChildrenSidebar";
import UpdateTaxDetailsSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateTaxDetailsSidebar";
import { getTaxClassDisplayNameById } from "~/modules/humanResources/utils/taxClassUtils.ts";
import { getGenderDisplayNameById } from "~/modules/user/utils/genderUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	staffMemberCoreData: StaffMember,
};

const StaffMemberCoreDataSection: FunctionComponent<Props> = ({ staffMemberCoreData }) => {
	const [showUpdateBankAccountSidebar, setShowUpdateBankAccountSidebar] = useState(false);
	const [showUpdateContactDetailsSidebar, setShowUpdateContactDetailsSidebar] = useState(false);
	const [showUpdateChildrenSidebar, setShowUpdateChildrenSidebar] = useState<boolean>(false);
	const [showUpdateHealthInsuranceSidebar, setShowUpdateHealthInsuranceSidebar] = useState(false);
	const [showUpdateEmergencyContactSidebar, setShowUpdateEmergencyContactSidebar] = useState(false);
	const [showUpdatePersonalDetailsSidebar, setShowUpdatePersonalDetailsSidebar] = useState(false);
	const [showUpdateSocialAndPensionInsuranceSidebar, setShowUpdateSocialAndPensionInsuranceSidebar] = useState(false);
	const [showUpdateTaxDetailsSidebar, setShowUpdateTaxDetailsSidebar] = useState<boolean>(false);
	const dateOfBirthFormatted = staffMemberCoreData.dateOfBirth ? formatDateWithGermanMonth(new Date(staffMemberCoreData.dateOfBirth)) : null;
	const hasAddressDetail = staffMemberCoreData.addressLine1 || staffMemberCoreData.addressLine2 || staffMemberCoreData.zipCode || staffMemberCoreData.city || staffMemberCoreData.countryId;
	let healthInsuranceType = EMPTY_VALUE_PLACEHOLDER;
	if (staffMemberCoreData.healthInsuranceTypeName === HealthInsuranceTypeName.Public) {
		healthInsuranceType = "Gesetzlich";
	}
	if (staffMemberCoreData.healthInsuranceTypeName === HealthInsuranceTypeName.Private) {
		healthInsuranceType = "Privat";
	}

	const {
		data: childrenData,
		isLoading: childrenIsLoading,
	} = useStaffMembersChildren({ staffMemberId: staffMemberCoreData.id });

	return <Section scrollable={true}
					className="pb-10">
		<div className="grid w-full grid-cols-2 gap-4">
			<Card
				padding="none"
				title="Persönliche Daten"
				onHeaderButtonClick={() => setShowUpdatePersonalDetailsSidebar(true)}
				headerButtonText="Bearbeiten"
			>
				<div className="grid grid-cols-2">
					<CardValueWithLabel label="Vorname">
						{staffMemberCoreData.firstName}
					</CardValueWithLabel>
					<CardValueWithLabel label="Nachname">
						{staffMemberCoreData.lastName}
					</CardValueWithLabel>
					<CardValueWithLabel label="Spitzname">
						{staffMemberCoreData.nickName || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Geschlecht">
						{getGenderDisplayNameById(staffMemberCoreData.genderId)}
					</CardValueWithLabel>
					<CardValueWithLabel label="Geburtsdatum"
										hasBottomBorder={false}>
						{dateOfBirthFormatted || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
				</div>
			</Card>
			<Card
				title="Kontakt / Addresse"
				padding="none"
				onHeaderButtonClick={() => {
					setShowUpdateContactDetailsSidebar(true);
				}}
				headerButtonText="Bearbeiten"
			>
				<div className="grid grid-cols-2">
					<CardValueWithLabel label="E-Mail geschäftlich">
						{staffMemberCoreData.emailCompany ?
							<a className="hover:text-danger-500"
							   href={`mailto:${staffMemberCoreData.emailCompany}`}>{staffMemberCoreData.emailCompany}</a> : EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="E-Mail privat"
										hasBottomBorder={false}>
						{staffMemberCoreData.emailPrivate ?
							<a className="hover:text-danger-500"
							   href={`mailto:${staffMemberCoreData.emailPrivate}`}>{staffMemberCoreData.emailPrivate}</a> : EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Telefonnummer">
						{staffMemberCoreData.phone || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Addresse"
										hasBottomBorder={false}>
						{!hasAddressDetail ? EMPTY_VALUE_PLACEHOLDER :
							<div>
								{staffMemberCoreData.addressLine1}
								<br />
								{staffMemberCoreData.addressLine2 && <>{staffMemberCoreData.addressLine2}<br /></>}
								{staffMemberCoreData.zipCode} {staffMemberCoreData.city}
								<br />
								<span className="text-gray-600">{staffMemberCoreData.countryId &&
									<CountryDisplay id={staffMemberCoreData.countryId.toString()} />}</span>
							</div>
						}
					</CardValueWithLabel>

				</div>
			</Card>

			<Card
				title="Steuerdaten"
				padding="none"
				onHeaderButtonClick={() => setShowUpdateTaxDetailsSidebar(true)}
				headerButtonText="Bearbeiten"
			>

				<CardValueWithLabel label="Steuer-ID">
					{staffMemberCoreData.taxIdNumber || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
				<CardValueWithLabel label="Steuerklasse">
					{staffMemberCoreData.taxClassId ? getTaxClassDisplayNameById(staffMemberCoreData.taxClassId as TaxClassId) : EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
			</Card>

			<Card
				title="Sozial- und Rentenversicherungsnummer"
				padding="none"
				onHeaderButtonClick={() => setShowUpdateSocialAndPensionInsuranceSidebar(true)}
				headerButtonText="Bearbeiten"
			>

				<CardValueWithLabel label="Sozialversicherungsnummer">
					{staffMemberCoreData.socialSecurityNumber || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
				<CardValueWithLabel label="Rentenversicherungsnummer">
					{staffMemberCoreData.pensionInsuranceNumber || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
			</Card>

			<Card
				title="Kinder"
				onHeaderButtonClick={() => setShowUpdateChildrenSidebar(true)}
				headerButtonText="Bearbeiten"
			>
				{childrenIsLoading ? <LoadingSpinnerFull theme="neutral" /> : childrenData?.length === 0 ? "Keine" :
					<div className="grid grid-cols-5">
						<Label size="sm"
							   theme="light"
							   lineHeight="none"
							   className="col-span-2">Vorname</Label>
						<Label size="sm"
							   theme="light"
							   lineHeight="none"
							   className="col-span-2">Nachname</Label>
						<Label size="sm"
							   theme="light"
							   lineHeight="none"
							   >Geburtsdatum</Label>

						{childrenData?.sort(byObjectProperty("dateOfBirth")).map((child) => {
							return (
								<Fragment key={child.id}>
									<div className="col-span-2">
										{child.firstName}
									</div>
									<div className="col-span-2">
										{child.lastName}
									</div>
									{formatDateWithGermanMonth(new Date(child.dateOfBirth))}
								</Fragment>);
						})}
					</div>}
			</Card>

			<Card
				title="Krankenkasse"
				padding="none"
				onHeaderButtonClick={() => setShowUpdateHealthInsuranceSidebar(true)}
				headerButtonText="Bearbeiten"
			>
				<div className="grid grid-cols-6">
					<div className="col-span-4">
						<CardValueWithLabel label="Krankenkasse">
							{staffMemberCoreData.healthInsurance || EMPTY_VALUE_PLACEHOLDER}
						</CardValueWithLabel>
					</div>
					<div className="col-span-2">
						<CardValueWithLabel label="Gesetzlich / Privat">
							{healthInsuranceType}
						</CardValueWithLabel>
					</div>
				</div>
				<CardValueWithLabel label="Versichertennummer">
					{staffMemberCoreData.healthInsuranceNumber || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
			</Card>
			<Card
				title="Notfallkontakt"
				padding="none"
				onHeaderButtonClick={() => setShowUpdateEmergencyContactSidebar(true)}
				headerButtonText="Bearbeiten"
			>
				<div className="grid grid-cols-2">
					<CardValueWithLabel label="Vorname">
						{staffMemberCoreData.emergencyContact?.firstName || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Nachname">
						{staffMemberCoreData.emergencyContact?.lastName || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Verhätnis zum Mitarbeiter">
						{staffMemberCoreData.emergencyContact?.personalRelation || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="Telefon">
						{staffMemberCoreData.emergencyContact?.phone || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
					<CardValueWithLabel label="E-Mail"
										hasBottomBorder={false}>
						{staffMemberCoreData.emergencyContact?.email || EMPTY_VALUE_PLACEHOLDER}
					</CardValueWithLabel>
				</div>
			</Card>
			<Card
				title="Bankverbindung"
				padding="none"
				onHeaderButtonClick={() => setShowUpdateBankAccountSidebar(true)}
				headerButtonText="Bearbeiten"
			>

				<CardValueWithLabel label="Name der Bank">
					{staffMemberCoreData.bankName || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
				<CardValueWithLabel label="Kontoinhaber/in">
					{staffMemberCoreData.bankAccountOwner || EMPTY_VALUE_PLACEHOLDER}
				</CardValueWithLabel>
				<div className="grid grid-cols-6">
					<div className="col-span-4">
						<CardValueWithLabel label="IBAN">
							{staffMemberCoreData.iban || EMPTY_VALUE_PLACEHOLDER}
						</CardValueWithLabel>
					</div>
					<div className="col-span-2">
						<CardValueWithLabel label="BIC">
							{staffMemberCoreData.bic || EMPTY_VALUE_PLACEHOLDER}
						</CardValueWithLabel>
					</div>
				</div>
			</Card>
		</div>

		<UpdateBankAccountSidebar isOpen={showUpdateBankAccountSidebar}
								  onClose={() => setShowUpdateBankAccountSidebar(false)}
								  staffMemberCoreData={staffMemberCoreData} />

		<UpdateStaffMembersChildrenSidebar childrenData={childrenData || null}
										   isOpen={showUpdateChildrenSidebar}
										   onClose={() => setShowUpdateChildrenSidebar(false)}
										   staffMemberId={staffMemberCoreData.id} />

		<UpdateContactDetailsSidebar isOpen={showUpdateContactDetailsSidebar}
									 onClose={() => setShowUpdateContactDetailsSidebar(false)}
									 staffMemberCoreData={staffMemberCoreData} />

		<UpdateEmergencyContactSidebar isOpen={showUpdateEmergencyContactSidebar}
									   onClose={() => setShowUpdateEmergencyContactSidebar(false)}
									   staffMemberCoreData={staffMemberCoreData} />

		<UpdateHealthInsuranceSidebar isOpen={showUpdateHealthInsuranceSidebar}
									  onClose={() => setShowUpdateHealthInsuranceSidebar(false)}
									  staffMemberCoreData={staffMemberCoreData} />

		<UpdatePersonalDetailsSidebar isOpen={showUpdatePersonalDetailsSidebar}
									  onClose={() => setShowUpdatePersonalDetailsSidebar(false)}
									  staffMemberCoreData={staffMemberCoreData} />

		<UpdateSocialSecurityAndPensionInsuranceSidebar isOpen={showUpdateSocialAndPensionInsuranceSidebar}
														onClose={() => setShowUpdateSocialAndPensionInsuranceSidebar(false)}
														staffMemberCoreData={staffMemberCoreData} />

		<UpdateTaxDetailsSidebar isOpen={showUpdateTaxDetailsSidebar}
								 onClose={() => setShowUpdateTaxDetailsSidebar(false)}
								 staffMemberCoreData={staffMemberCoreData} />

	</Section>;
};

export default StaffMemberCoreDataSection;