import {ReactComponent as Icon} from "./type-bold.svg"

type StopwatchIconProps = {
	className?: string;
};

const TextBoldIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default TextBoldIcon;