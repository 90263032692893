import type { FunctionComponent} from "react";
import { useState } from "react";

import type { InvoiceRecipientType } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import DeleteInvoiceRecipientModal
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/DeleteInvoiceRecipientModal";
import InvoiceRecipientContactCard
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/InvoiceRecipientContactCard/InvoiceRecipientContactCard.tsx";
import UpdateInvoiceRecipientSidebar
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/UpdateInvoiceRecipientSidebar";
import NewFirstItemCta from "~/modules/client/components/ClientDetailsView/components/NewFirstItemCta";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientsInvoiceRecipientsListProps {
	clientId: string;
	clientDisplayName: string;
	invoiceRecipients?: InvoiceRecipientType[];
	onAddInvoiceRecipientClick: () => void;
}

const ClientsInvoiceRecipientsList: FunctionComponent<ClientsInvoiceRecipientsListProps> = ({
	clientId,
	clientDisplayName,
	invoiceRecipients,
	onAddInvoiceRecipientClick
}) => {

	const [invoiceRecipientIdToEdit, setInvoiceRecipientIdToEdit] = useState<string | null>(null);
	const [invoiceRecipientIdToDelete, setInvoiceRecipientIdToDelete] = useState<string | null>(null);
	const selectedInvoiceRecipient = invoiceRecipients?.find(({ id }) => id === invoiceRecipientIdToEdit) || null;
	const invoiceRecipientToDelete = invoiceRecipients?.find(({ id }) => id === invoiceRecipientIdToDelete) || null;

	return (
		<>
			{(invoiceRecipients && 0 === invoiceRecipients.length) &&
				<NewFirstItemCta buttonText="Neuen Rechnungsempfänger
							anlegen"
								 headline="Noch keine zentralen Rechnungsempfänger vorhanden"
								 onCreateClick={onAddInvoiceRecipientClick}
								 subLineText={`Füge neue Kontakte zu ${clientDisplayName} hinzu.`} />

			}
			{invoiceRecipients && invoiceRecipients?.length > 0 &&
				<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
					{invoiceRecipients.sort(byObjectProperty("displayName")).map(({
						id,
						displayName,
						email,
						supportContact,
						supportContactPhone,
					}) => (
						<InvoiceRecipientContactCard
							key={id}
							displayName={displayName}
							email={email}
							supportContact={supportContact}
							supportContactPhone={supportContactPhone}
							onDeleteClick={() => setInvoiceRecipientIdToDelete(id)}
							onEditClick={() => setInvoiceRecipientIdToEdit(id)}
						/>
					))}
				</div>}

			<UpdateInvoiceRecipientSidebar
				invoiceRecipientData={selectedInvoiceRecipient}
				isOpen={!!selectedInvoiceRecipient}
				onClose={() => setInvoiceRecipientIdToEdit(null)} />

			<DeleteInvoiceRecipientModal clientId={clientId}
										 invoiceRecipientDisplayName={invoiceRecipientToDelete?.displayName || ""}
										 invoiceRecipientId={invoiceRecipientIdToDelete}
										 isOpen={invoiceRecipientIdToDelete !== null}
										 onClose={() => setInvoiceRecipientIdToDelete(null)} />

		</>
	);
};

export default ClientsInvoiceRecipientsList;
