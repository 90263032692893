import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";


export const cardVariants = tv({
	slots: {
		container: "w-full rounded-lg",
		title: "font-bold",
		header: "flex flex-row items-center justify-between rounded-t-lg",
		content: "",
	},
	variants: {
		height: {
			max: {
				container: "max-h-full",
			},
			full: {
				container: "h-full",
			},
			auto: {
				container: "h-auto",
			},
		},
		theme: {
			light: {
				content: "bg-white text-gray-800",
				header: "border-b border-gray-200 bg-white text-gray-600",
			},
			dark: {
				content: "bg-gray-200 text-gray-800",
				header: "bg-white text-gray-800",
			},
			highlight: {
				content: "bg-primary-500 text-white",
				header: "bg-primary-500 text-white",
			},
		},
		padding: {
			none: {
				content: "p-0",
			},
			sm: {
				content: "px-3 py-2",
			},
			md: {
				content: "px-4 py-3",

			},
			lg: {
				content: "px-8 py-4",
			},
		},
		headerPadding: {
			none: {
				header: "p-0",
			},
			sm: {
				header: "px-3 py-2",
			},
			md: {
				header: "px-4 py-2",

			},
			lg: {
				header: "px-8 py-4",
			},
		},
		shadow: {
			none: {
				container: "shadow-none",
			},
			default: {
				container: "shadow",
			},
			sm: {
				container: "shadow-sm",
			},
			md: {
				container: "shadow-md",
			},
			lg: {
				container: "shadow-lg",
			},
		},
		border: {
			default: { container: "border border-gray-300" },
			none: { container: "border-none" },
		},
		hasTitle: {
			false: {
				container: "",
				content: "h-full rounded-lg",
			},
			true: {
				container: "grid grid-rows-[auto_1fr]",
				content: "rounded-b-lg",
			},
		},
	},
	defaultVariants: {
		border: "none",
		height: "auto",
		theme: "light",
		padding: "md",
		headerPadding: "md",
		shadow: "default",
		hasTitle: false,
	},
});

export type CardVariantProps = VariantProps<typeof cardVariants> & {className?: string};
