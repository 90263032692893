import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	StaffMemberChild,
	StaffMemberChildUpdateData,
} from "~/modules/humanResources/api/staffMemberChild/staffMemberChildTypes.ts";

export function getAllStaffMemberChildren(staffMemberId: string): AxiosApiResponsePromise<StaffMemberChild[]> {
	return axiosApi.get(`/staff-members/${staffMemberId}/children`);
}

export function updateStaffMembersChildren({ staffMemberId, updateData }: {
	staffMemberId: string;
	updateData: StaffMemberChildUpdateData[]
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`/staff-members/${staffMemberId}/children`, { children: updateData });
}