import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

type FormHasErrorsHintProps = {
	className?: string;
	show: boolean;
};

const FormHasErrorsHint: React.FunctionComponent<FormHasErrorsHintProps> = ({ className, show }) => {

	if (!show) return null;
	return <div className={clsx("flex items-center gap-2", className)}>
		<span className="text-sm text-red-600">Bitte Formularfehler beheben</span>
		<ExclamationCircleIcon className="size-5 text-red-500"
							   aria-hidden="true" />
	</div>;
};

export default FormHasErrorsHint;