import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import TableBodyCell from "~/components/Table/components/TableBodyCell";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	amountCents: number,
	description: string,
	endDate: Date | null,
	onDeleteClick: () => void
	onEditClick: () => void,
	startDate: Date,
};

const BenefitsTableRow: FunctionComponent<Props> = ({
	amountCents,
	description,
	endDate,
	onDeleteClick,
	onEditClick,
	startDate,
}) => {
	const popoverItems = [];

	if (onEditClick) {
		popoverItems.push({
			label: "Bearbeiten",
			onClick: onEditClick,
			icon: PencilIcon,
		});
	}

	if (onDeleteClick) {
		popoverItems.push({
			label: "Löschen",
			onClick: onDeleteClick,
			icon: TrashIcon,
		});
	}

	return <tr>
		<TableBodyCell>
			{formatDateWithGermanMonth(startDate)}
		</TableBodyCell>
		<TableBodyCell>
			{endDate ? formatDateWithGermanMonth(endDate) : EMPTY_VALUE_PLACEHOLDER}
		</TableBodyCell>
		<TableBodyCell>
			{formatCentsToCurrency(amountCents)}
		</TableBodyCell>
		<TableBodyCell>
			{description}
		</TableBodyCell>
		<td className="px-4 py-3">
			<div className="relative flex items-center justify-end whitespace-nowrap">
			{popoverItems.length > 0 && <ButtonWithPopover theme="dark" items={popoverItems} />}
			</div>
		</td>
	</tr>;
};

export default BenefitsTableRow;