import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkApiDispatchers.ts";
import type {
	StaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	title: string;
	url: string;
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	documentLinkData: StaffMemberDocumentLink;
};

const UpdateDocumentLinkEntryForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	documentLinkData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = useMemo(() => {
		return yup.object({
			title: yup.string().required(),
			url: yup.string().url("Bitte geben sie eine valide URL ein (beginnend mit https://)").required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			title: documentLinkData.title,
			url: documentLinkData.url,
		};
	}, [documentLinkData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {

			try {
				setIsBusy(true);
				await updateStaffMemberDocumentLink({
					staffMemberId: documentLinkData.staffMemberId, documentLinkId: documentLinkData.id,
					updateData: data,
				});
				onSuccess();
			} catch (error) {
				const apiError = handleApiError(error);
				console.log(apiError);
				setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
			}
		},
		[documentLinkData.id, documentLinkData.staffMemberId, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Benefit aktualisieren
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="flex flex-col gap-4">
					<Input name="title"
						   autoFocus={true}
						   control={control}
						   label="Name des Dokuments"
						   placeholder="Arbeitsvertrag, Zeugnis, etc."
					/>

					<Input name="url"
						   control={control}
						   label="URL"
						   placeholder="https://example.com/document.pdf"
					/>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateDocumentLinkEntryForm;
