export enum GenderNames {
	Male = "male",
	Female = "female",
	Diverse = "divers",
}

export enum PermissionNames {
	CanManageAbsences = "manage-absences",
	CanViewAbsences = "view-absences",
	CanManageInvoices = "manage-invoices",
	CanManageProjects = "manage-projects",
	CanManageProjectTags = "manage-project-tags",
	CanManageStaff = "manage-staff",
	CanManagesStaffSalaries = "manage-staff-salaries",
	CanManageTimeTrackings = "manage-time-trackings",
	CanManageUsers = "manage-users",
	CanViewReports = "view-reports",
}

export enum RoleNames {
	Admin = "admin",
	ProjectManager = "project-manager",
	InvoiceManager = "invoice-manager",
	TeamMember = "team-member",
	HrManager = "hr-manager",
}

export enum WorkingStatusNames {
	Active = "active",
	Quotation = "quotation",
	Hold = "hold",
	Cancelled = "cancelled",
	Closed = "closed",
}

export enum TimeTrackingTypeNames {
	Project = "project",
	Internal = "internal",
	Sales = "sales",
	PartTime = "partTime",
}
