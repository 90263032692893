import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import Section from "~/components/sections/Section";
import { COMPENSATION_VISIBILITY_CHANNEL_NAME } from "~/constants/broadcastChannelNames.ts";
import useBroadcastChannel from "~/hooks/useBroadcastChannel";

type Props = {
	setVisibility: (isVisible: boolean) => void;
};

const CompensationSectionLockedMessage: FunctionComponent<Props> = () => {
	const broadcastVisibility = useBroadcastChannel(COMPENSATION_VISIBILITY_CHANNEL_NAME);

	return <Section scrollable={true}
					className="pb-10">
		<div className="flex w-full justify-center">
			<button onClick={()=>broadcastVisibility("show")}
					className="group flex flex-col items-center justify-center">
				<EyeSlashIcon className="size-40 text-gray-300 group-hover:hidden" />
				<EyeIcon className="hidden size-40 text-gray-400 group-hover:block" />
				<p className="text-sm text-gray-500 group-hover:text-gray-800">Hier klicken um Gehaltsdaten anzuzeigen</p>
			</button>
		</div>

	</Section>;
};

export default CompensationSectionLockedMessage;