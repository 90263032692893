import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";
import { useMemo } from "react";

type WorkingSchedulesTableProps = PropsWithChildren & {
	compactView?: boolean;
};

const WorkingScheduleTable: FunctionComponent<WorkingSchedulesTableProps> = ({ children, compactView }) => {
	const workdayNames = useMemo(() => {
		if (compactView) {
			return ["Mo", "Di", "Mi", "Do", "Fr"];
		}
		return ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"];
	}, [compactView]);

	const dayHeaderCells = workdayNames.map((day) => {
		return (
			<th
				scope="col"
				className="w-[16%] text-center text-sm font-semibold text-gray-900"
				key={day}
			>
				{day}
			</th>
		);
	});

	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col"
					className={clsx("text-left text-sm font-semibold text-gray-900", !compactView ? "py-3.5 pl-4 pr-3" : "p-1")}>
					Gültig ab
				</th>
				<>
					{dayHeaderCells}
				</>

				{!compactView && <th scope="col"
									 className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>}
			</tr>
			</thead>
			<tbody className="divide-y divide-gray-200">{children}</tbody>
		</table>
	);
};


export default WorkingScheduleTable;
