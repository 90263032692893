import type React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { deleteWorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";
import { STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import WorkingSchedulesTable from "~/modules/absence/components/WorkingSchedulesView/components/WorkingSchedulesTable";
import type { RenderWorkingScheduleTableRowFunction } from "~/modules/absence/types/workingScheduleViewTypes.ts";

type DeleteWorkingScheduleModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	renderTableRow: RenderWorkingScheduleTableRowFunction
	workingScheduleData: WorkingSchedule | null;
};

const DeleteWorkingScheduleModal: React.FunctionComponent<DeleteWorkingScheduleModalProps> = ({
	isOpen,
	onCloseClick,
	renderTableRow,
	workingScheduleData,

}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		if (!workingScheduleData) return;
		try {
			setBusy(true);
			const staffMemberId = workingScheduleData.staffMemberId;
			await deleteWorkingSchedule({ staffMemberId: staffMemberId, workingScheduleId: workingScheduleData.id });

			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId));
			onCloseClick();
		} catch (error) {
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Arbeitszeitmodell löschen`,
		bodyText: `Möchtest Du diese Arbeitszeitmodell wirklich löschen?`,
		deleteButtonText: `Arbeitszeitmodell löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!workingScheduleData && <div className="mb-2">
				<WorkingSchedulesTable compactView={true}>
					{renderTableRow({
						workingScheduleData,
						userCanAdminVacations: false,
						compactView: true,
					})}
				</WorkingSchedulesTable>
			</div>}
		</DeleteEntityView>);
};

export default DeleteWorkingScheduleModal;
