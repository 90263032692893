import { t } from "i18next";

import { GenderNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getGenderDisplayNameById(genderId: string): string {
	let genderName: string = "";
	switch (genderId) {
		case "1":
			genderName = GenderNames.Male;
			break;
		case "2":
			genderName = GenderNames.Female;
			break;
		case "3":
			GenderNames.Diverse;
			break;
		default:
			throw new Error('Invalid gender id: "' + genderId)
	}

	return t(normalizeTKey(`entities:gender.${genderName}`))
}