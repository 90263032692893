import { Outlet } from "react-router-dom";

const RootPage: React.FC = () => {
	return (
		<div className="flex h-screen items-center justify-center font-normal">
			<Outlet />
		</div>
	);
};

export default RootPage;
