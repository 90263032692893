import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllProjects,
	getClientsProjects,
	getProject,
	indexProjectDescriptions,
} from "~/modules/project/api/project/projectApiDispatchers.ts";
import type { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

export const PROJECT_BASE_QUERY_KEY = "project";
export const PROJECT_QUERY_KEY = (projectId: string) => [PROJECT_BASE_QUERY_KEY, projectId];

export const CLIENTS_PROJECTS_QUERY_KEY = (clientId: string) => [PROJECT_BASE_QUERY_KEY, { clientId }];
export const ALL_PROJECTS_QUERY_KEY = [PROJECT_BASE_QUERY_KEY, "all"];

export const ALL_PROJECT_DESCRIPTIONS_QUERY_KEY = () => [PROJECT_BASE_QUERY_KEY, "allDescriptions"];

export const useProject = ({ projectId, options }: { projectId: string } & UseQueryBaseParams) => useQuery(
	PROJECT_QUERY_KEY(projectId),
	() => getProject(projectId).then(getDataFromResponse),
	options,
);

export const useClientsProjects = ({ clientId, options }: { clientId: string } & UseQueryBaseParams) => useQuery(
	CLIENTS_PROJECTS_QUERY_KEY(clientId),
	() => getClientsProjects({ clientId }).then(getDataFromResponse),
	options,
);

export const useAllProjects = (options?: QueriesOptions<any>) => useQuery(
	ALL_PROJECTS_QUERY_KEY,
	() => getAllProjects().then(getDataFromResponse),
	options,
);

export const useAllProjectDescriptions = (options?: QueriesOptions<any>) => useQuery(
	ALL_PROJECT_DESCRIPTIONS_QUERY_KEY(),
	() => indexProjectDescriptions().then(getDataFromResponse),
	options,
);