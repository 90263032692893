import { useMemo } from "react";

import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import { sanitiseTitleForComparison } from "~/utils/form/formUtils.ts";
import type { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";


export const useProjectTitleValidationComparisonData = (clientId: string|null): UniqueValidatorArrayObject[] => {
	const {
		data: allProjectsData,
	} = useAllProjects();

	return useMemo(() => {
		if (allProjectsData && clientId) {
			return allProjectsData.filter(project => project.clientId === clientId).map((project) => {
				return {
					id: project.id,
					value: sanitiseTitleForComparison(project.title),
				};
			});
		}

		return [];
	}, [allProjectsData, clientId]);
};