import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getUsersVacationLedgerReport,
	indexUsersVacationLedgerEntries,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";

export const VACATION_LEDGER_ENTRY_BASE_QUERY_KEY = "vacationLedgerEntries";

export const STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY = (staffMemberId: string) => [VACATION_LEDGER_ENTRY_BASE_QUERY_KEY, { staffMemberId }];
export const STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY = (staffMemberId: string) => ["vacationLedgerReport", { staffMemberId }];
export const useStaffMembersVacationLedgerEntries = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId),
	() => indexUsersVacationLedgerEntries({ staffMemberId }).then(getDataFromResponse),
	options,
);


export const useStaffMembersVacationLedgerReport = ({ staffMemberId, options }: { staffMemberId: string, options?: QueriesOptions<any> }) => useQuery(
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId),
	() => getUsersVacationLedgerReport({ staffMemberId }).then(getDataFromResponse),
	options,
);