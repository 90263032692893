import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = PropsWithChildren & {
	isStartItem?: boolean;
	date: Date;
	dotTheme?: "success" | "danger" | "neutral";
	showHoverEffect?: boolean;
};

const TimelineItem: FunctionComponent<Props> = ({ children, date, dotTheme = "neutral", isStartItem = false, showHoverEffect = true }) => {
	return <li className={clsx("group relative flex items-center gap-x-4 ", showHoverEffect && "hover:bg-gray-100")}>
		<div className={clsx(
			isStartItem ? "h-6" : "-bottom-6",
			"absolute left-0 top-0 flex w-6 justify-center",
		)}>
			<div className="w-px bg-gray-200" />
		</div>
		<div className={clsx("relative flex size-6 flex-none items-center justify-center bg-white", showHoverEffect && "group-hover:bg-gray-100")}>
			<div className={clsx("size-1.5 rounded-full ring-1",
				dotTheme === "success" && "bg-success-300 ring-success-500",
				dotTheme === "danger" && "bg-danger-300 ring-danger-500",
				dotTheme === "neutral" && "bg-gray-100 ring-gray-300",
			)} />
		</div>
		<time dateTime={date.toDateString()}
			  className="min-w-20 flex-none py-0.5 text-right text-xs leading-5 text-gray-500">
			{formatDateWithGermanMonth(date)}
		</time>
		{children}
	</li>;
};

export default TimelineItem;