import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useEffect, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import { ADMIN_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import AdminDeliverableTagsSection from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection";
import AdminProductTagsSection from "~/modules/admin/components/AdminView/components/AdminProductTagsSection";
import AdminUsersSection from "~/modules/admin/components/AdminView/components/AdminUsersSection";
import { AdminViewTabName } from "~/modules/admin/types/adminViewTypes.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import type { SectionHeadingTab } from "~/types/tabsTypes.ts";

type Props = {
	activeTabId: AdminViewTabName;
	deliverableTagsLoadingPercent: number;
	productTagsLoadingPercent: number;
}

const AdminView: FunctionComponent<Props> = ({
	activeTabId,
	deliverableTagsLoadingPercent,
	productTagsLoadingPercent,
}) => {
	const { hasAnyPermission } = useAuth();

	const navigate = useNavigate();
	let pageTitle = "";
	const breadcrumbs = ["Admin"];

	switch (activeTabId) {
		case AdminViewTabName.Users:
			pageTitle = "Admin Users";
			breadcrumbs.push("Users");
			break;
		case AdminViewTabName.ProductTags:
			pageTitle = "Admin Produkt Tags";
			breadcrumbs.push("Produkt Tags");
			break;
		case AdminViewTabName.DeliverableTags:
			pageTitle = "Admin Deliverable Tags";
			breadcrumbs.push("Deliverable Tags");
			break;
	}

	const userCanAdminUsers = hasAnyPermission(PermissionNames.CanManageUsers);
	const useCanAdminProjectTags = hasAnyPermission(PermissionNames.CanManageProjectTags);


	const tabs = useMemo(() => {
		const tabs: SectionHeadingTab[] = [];

		if (userCanAdminUsers) {
			tabs.push({
				name: "Users",
				value: AdminViewTabName.Users,
				active: activeTabId === AdminViewTabName.Users,
			});
		}

		if (useCanAdminProjectTags) {
			tabs.push({
				name: "Product Tags",
				value: AdminViewTabName.ProductTags,
				active: activeTabId === AdminViewTabName.ProductTags,
			});
			tabs.push({
				name: "Deliverable Tags",
				value: AdminViewTabName.DeliverableTags,
				active: activeTabId === AdminViewTabName.DeliverableTags,
			});
		}
		return tabs;
	}, [activeTabId, useCanAdminProjectTags, userCanAdminUsers]);

	useEffect(() => {
		if (tabs.find((tab) => tab.active) === undefined) {
			navigate(generatePath(ADMIN_PATH_WITH_TAB_ID, { tabId: tabs[0].value }));
		}
	}, [activeTabId, navigate, tabs]);


	return <div className="grid h-screen grid-rows-[auto_auto_1fr]">
		<BreadcrumbsSection pages={breadcrumbs}
							className="bg-white" />

		<div className="flex w-full flex-col bg-white ">
			<ContentWrapper className="w-full">
				<div className={clsx("min-w-0 flex-1", tabs.length === 1 && "pb-4")}>
					<Headline type="h2"
							  className="pt-2">{pageTitle}</Headline>
				</div>
				{tabs.length > 1 && <SectionHeadingTabs tabs={tabs}
														onTabClick={(value) => navigate(generatePath(ADMIN_PATH_WITH_TAB_ID, { tabId: value }))} />}
			</ContentWrapper>
		</div>

		{activeTabId === AdminViewTabName.ProductTags && useCanAdminProjectTags &&
			<AdminProductTagsSection loadingPercentage={productTagsLoadingPercent} />}
		{activeTabId === AdminViewTabName.DeliverableTags && useCanAdminProjectTags &&
			<AdminDeliverableTagsSection loadingPercentage={deliverableTagsLoadingPercent} />}
		{activeTabId === AdminViewTabName.Users && userCanAdminUsers && <AdminUsersSection />}
	</div>;
};

export default AdminView;