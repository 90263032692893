import { useMemo } from "react";

import { useAllDeliverableTags } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";

export function useDeliverableTagValidationComparisonData() {
	const { data: allDeliverableTags } = useAllDeliverableTags();

	return useMemo(() => {
		if (allDeliverableTags) {
			return allDeliverableTags.map((productTag) => {
				return {
					id: productTag.id,
					value: sanitizeStringForComparison(productTag.displayName),
				};
			});

		}
		return [];
	}, [allDeliverableTags]);


}