import { yupResolver } from "@hookform/resolvers/yup";
import ProjectTag
	from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useProductTagInputOptions } from "~/modules/project/api/productTags/hooks/useProductTagInputOptions.ts";
import { useAllProductTags } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { updateProjectsProductTags } from "~/modules/project/api/project/projectApiDispatchers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateProductTagsFormData {
	productTags: { originalId: string, displayName: string }[];
}

type UpdateProductTagsFormProps = {
	onSuccess: () => void;
	onClose: () => void;
	productTagIds: string[];
	projectId: string;
};

const UpdateProductTagsForm: FunctionComponent<UpdateProductTagsFormProps> = ({
	onSuccess,
	onClose,
	productTagIds,
	projectId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const { data: allProductTags } = useAllProductTags();

	const productTagOptions = useProductTagInputOptions();

	const schema = useMemo(() => {
		return yup.object({
			productTags: yup.array().of(yup.object({
				originalId: yup.string().required(),
				displayName: yup.string().default(""),
			})).required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const productTagDisplayNames: UpdateProductTagsFormData["productTags"] = [];
		if (allProductTags) {
			productTagIds.forEach(productTagId => {
				const productTag = allProductTags.find(productTag => productTag.id === productTagId);
				if (productTag) {
					productTagDisplayNames.push({ originalId: productTag.id, displayName: productTag.displayName });
				}
			});
		}

		return { productTags: productTagDisplayNames.sort() };
	}, [productTagIds, allProductTags]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateProductTagsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProductTagsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "productTags",
	});

	const handleAddTag = useCallback((value: string | null) => {
		const existingTag = productTagOptions.find(productTagOption => productTagOption.value === value);

		if (existingTag && !fields.find(field => field.originalId === existingTag.value)) {
			append({ originalId: existingTag.value, displayName: existingTag.label });
		}
	}, [productTagOptions, fields, append]);

	const onSubmit = useCallback(async (data: UpdateProductTagsFormData) => {
		try {
			setIsBusy(true);
			const updateData = data.productTags.map(tag => tag.originalId);
			await updateProjectsProductTags({ projectId, tagIds: updateData });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten");
		}
	}, [onSuccess, projectId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">Produkt Tags bearbeiten</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<ComboBoxPlain value=""
							   autoFocus={true}
							   allowNew={false}
							   immediate={true}
							   placeholder="Produkt Tag hinzufügen"
							   optionsData={productTagOptions}
							   onChange={(value) => handleAddTag(value as string | null)}
				/>
				<div className="mt-4 flex flex-wrap gap-2 rounded-lg border p-2">
					{fields.length === 0 && <ProjectTag displayName="Keine Tags ausgewählt"
														theme="neutral" />}
					{fields.map((field, index) => {
						return <ProjectTag key={index}
										   theme="product"
										   displayName={field.displayName}
										   onDeleteClick={() => remove(index)} />;
					})}
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onClose}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateProductTagsForm;
