import type { PropsWithChildren } from "react";

type HeaderWithIconProps = PropsWithChildren & {
	icon: React.ReactNode;
};

const HeaderWithIcon: React.FunctionComponent<HeaderWithIconProps> = ({ icon, children }) => {
	return <div className="mb-3 flex flex-row items-center gap-x-3">
		{icon}
		<div className="text-xl font-bold text-primary-500">{children}</div>
	</div>;
};

export default HeaderWithIcon;