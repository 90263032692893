import type { SortingState } from "@tanstack/react-table";
import type Decimal from "decimal.js-light";

import type { ColumnFilterType } from "~/types/tanstackTableTypes.ts";

export type ProjectsTimeTrackingsTableData = {
	dailyRateCents: number;
	date: Date,
	deliverablesText: string;
	location: string;
	minutesTracked: number;
	orderId: string;
	orderTitle: string;
	phaseId: string;
	phaseTitle: string;
	projectRoleDisplayName: string;
	totalAmountCents: number;
	userFullName: string;
	userId: string;
};

export enum ProjectDetailsTabName{
	Overview = "overview",
	TimeTrackings = "time-trackings",
	Phase = "phase-",
}

export type ProjectDetailsTimeTrackingsPageState = {
	columnFilters: ColumnFilterType[],
	sorting: SortingState,
};

export type TimeTrackingExcelData = {
	userFullName: string;
	projectRoleDisplayName: string;
	phaseTitle: string;
	orderTitle: string;
	date: Date;
	dailyRateCents: number;
	minutesTracked: number;
	totalAmountCents: number;
};


export type MonthlyReportData = {
	userDisplayName: string;
	dailyRateCents: number;
	totalDays: Decimal;
	totalHours: Decimal;
	totalCents: Decimal;
	timeTrackings: TimeTrackingListEntry[];
}

export type TimeTrackingListEntry = {
	date:Date,
	hours: Decimal,
	locationDisplayName: string,
	text: string
}