import clsx from "clsx";
import { format } from "date-fns";
import type { PropsWithChildren } from "react";

import GridSegmentText
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/GridSegmentText";
import type { GridSegmentType } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getWorkDaysInterval } from "~/utils/dateAndTimeUtils.ts";

type DayGridLinesProps = PropsWithChildren & {
	dayWidthPx: number;
	dividerWidthPx: number;
	startDate: Date;
	endDate: Date;
	isWeekView: boolean;
	showDates: boolean;
}

const DayGridLines: React.FunctionComponent<DayGridLinesProps> = ({
	dayWidthPx,
	dividerWidthPx,
	endDate,
	startDate,
	isWeekView,
	showDates,
}) => {
	const segments: GridSegmentType[] = [];
	const today = new Date().toDateString();
	getWorkDaysInterval(startDate, endDate).forEach((date, index) => {
		segments.push({
			offsetLeft: (index) * dayWidthPx,
			isCurrentDate: date.toDateString() === today,
			text: format(date, "dd.MM."),
			width: dayWidthPx,
			startDate: date,
		});
	});

	return segments.map((segment, index) => {
		return <div key={index}
					className={clsx("absolute top-0 h-full", isWeekView ? "bg-gray-300" : "bg-gray-100/30")}
					style={{ left: segment.offsetLeft, width: dividerWidthPx }}>
			{showDates && <GridSegmentText startDate={segment.startDate}
										   highlighted={segment.isCurrentDate}
										   widthPx={segment.width}>{segment.text}</GridSegmentText>}
		</div>;
	});

};

export default DayGridLines;