import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllSalesFunnels } from "~/modules/client/api/salesFunnel/salesFunnelApiDispatchers.ts";

const ENTITY_QUERY_KEY = "salesFunnel";

export const ALL_SALES_FUNNELS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllSalesFunnels = (options?: QueriesOptions<any>) =>
	useQuery(ALL_SALES_FUNNELS_QUERY_KEY, () => getAllSalesFunnels().then(getDataFromResponse), options);
