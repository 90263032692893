import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import UpdatePasswordForm from "~/modules/user/components/components/UpdatePasswordForm";

type UpdatePasswordSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	userId: string | null;
};

const UpdatePasswordSidebar: React.FunctionComponent<UpdatePasswordSidebarProps> = ({
	isOpen,
	onClose,
	userId,
}) => {

	const { data: allUsersData } = useAllUsers();

	const userName = userId && allUsersData ? allUsersData.find((user) => user.id === userId)?.fullName : null;

	const handleSuccess = useCallback(async () => {
		onClose();
	}, [onClose]);

	return (
		<Sidebar closeOnOutsideClick={false}
			open={isOpen}
			setOpen={() => onClose()}>
			{userName && userId &&
				<UpdatePasswordForm onSuccess={handleSuccess}
					onCancel={onClose}
					userId={userId}
					userName={userName} />}
		</Sidebar>
	);
};

export default UpdatePasswordSidebar;
