import { UserIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

interface Props {
	firstName?: string;
	lastName?: string;
	image?: AvatarImage|null;
	active?: boolean;
	width?: string;
	height?: string;
}

const Avatar: React.FC<Props> = ({ active, firstName, lastName, image, width = "w-10", height = "h-10" }) => {
	const avatarClassName = `${width} ${height} relative flex-shrink-0 rounded-full bg-gray-300 flex justify-center items-center select-none object-cover`;
	if (!image) {
		if (undefined === firstName && undefined === lastName) {
			return (
				<div className={avatarClassName}>
					<UserIcon className={clsx("w-4/6 text-primary-500")} />
				</div>
			);
		}
		return (
			<div className={clsx(avatarClassName, "text-base font-bold uppercase text-white")}>
				{firstName && firstName.substring(0, 1)}
				{lastName && lastName.substring(0, 1)}
				{active !== undefined &&
					<div className={clsx("absolute right-0 top-0 size-2.5 rounded-full bg-green-500 ring-1 ring-white", active ? "bg-success-500" : "bg-red-400")} />}
			</div>
		);
	}

	return <img className={avatarClassName}
				src={image.sizes.find((size) => size.size === "small")?.url || image.sizes[0].url}
				alt="" />;
};

export default Avatar;
