import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateFreelanceUserData, CreateInactiveUserData,
	CreateInternalUserData, UpdateFreelanceUserData, UpdateInternalUserData,
	User,
	UserWithConfidentialInformation,
} from "~/modules/user/api/user/userTypes.ts";

export function getAllUsers(): AxiosApiResponsePromise<User[] | UserWithConfidentialInformation[]> {
	return axiosApi.get("users");
}

export function resetPassword(password: string,
	passwordConfirmation: string,
	token: string): AxiosApiResponsePromise<User[]> {
	return axiosApi.patch("users/password", { password, passwordConfirmation, token });
}

export function createFreelanceUser(user: CreateFreelanceUserData): AxiosApiResponsePromise<UserWithConfidentialInformation> {
	return axiosApi.post("users/freelance", user);
}

export function createInactiveUser(user: CreateInactiveUserData): AxiosApiResponsePromise<UserWithConfidentialInformation> {
	return axiosApi.post("users/inactive", user);
}

export function createInternalUser(user: CreateInternalUserData): AxiosApiResponsePromise<UserWithConfidentialInformation> {
	return axiosApi.post("users/internal", user);
}


export function updateFreelanceUser(userId: string, user: UpdateFreelanceUserData): AxiosApiResponsePromise<User> {
	return axiosApi.put(`users/freelance/${userId}`, user);
}

export function updateInternalUser(userId: string, user: UpdateInternalUserData): AxiosApiResponsePromise<User> {
	return axiosApi.put(`users/internal/${userId}`, user);
}

export function updateUserPassword({ userId, data }: {
	userId: string;
	data: {
		password: string,
		passwordConfirmation: string
	}
}): AxiosApiResponsePromise<User> {
	return axiosApi.patch(`users/${userId}/password`, data);
}

export function updateUserStatus(userId: string, isActive: boolean): AxiosApiResponsePromise<User> {
	return axiosApi.patch(`users/${userId}/status`, { isActive });
}

export function sendUserInvitation(userId: string): AxiosApiResponsePromise<User> {
	return axiosApi.post(`users/${userId}/invitation`);
}
