export type CareerLevel = {
	id: string
	dailyRateTargetCents: number;
	displayName: string;
	utilisationRateTargetPercentage: number;
}


export enum CareerLevelName {
	Analyst = "analyst",
	Consultant = "consultant",
	SeniorConsultant = "seniorConsultant",
	SeniorManager = "seniorManager",
	Partner = "partner",
	Assistant = "assistant",
	Student = "student",
	IT = "it",
	Manager = "manager",
	ManagingDirector = "managingDirector",
}

export enum CareerLevelId {
	Analyst = "1",
	Consultant = "2",
	SeniorConsultant = "3",
	SeniorManager = "4",
	Partner = "5",
	Assistant = "6",
	Student = "7",
	IT = "8",
	Manager = "9",
	ManagingDirector = "10",
}
