import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import type { DeliverableTagUsageReport } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";

const basePath = "deliverable-tags";

export function indexDeliverableTags(): AxiosApiResponsePromise<DeliverableTag[]> {
	return axiosApi.get(basePath);
}

export function getDeliverableTagsUsageReport(): AxiosApiResponsePromise<DeliverableTagUsageReport[]> {
	return axiosApi.get(`${basePath}/usage-report`);
}

export function createDeliverableTag({ displayName }: {
	displayName: string
}): AxiosApiResponsePromise<DeliverableTag> {
	return axiosApi.post(basePath, { displayName });
}

export function updateDeliverableTag({ id, displayName }: {
	id: string,
	displayName: string
}): AxiosApiResponsePromise<DeliverableTag> {
	return axiosApi.put(`${basePath}/${id}`, { displayName });
}

export function mergeDeliverableTags({ tagId, tagIdsToMerge }: {
	tagId: string,
	tagIdsToMerge: string[];
}): AxiosApiResponsePromise<DeliverableTag> {
	return axiosApi.post(`${basePath}/${tagId}/merge`, { tagIdsToMerge });
}

export function deleteDeliverableTag({ id }: { id: string }): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${basePath}/${id}`);
}