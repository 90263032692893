import { startOfMonth, startOfWeek } from "date-fns";
import type Decimal from "decimal.js-light";
import { useCallback } from "react";

import DayGridLines
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/DayGridLines";
import MonthGridLines
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/MonthGridLines";
import WeekGridLines
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/WeekGridLines";
import { AbsenceCalendarTimespanOption } from "~/modules/absence/types/absenceOverviewTypes.ts";

type VerticalGridLinesProps = {
	containerHeight: Decimal;
	containerWidth: Decimal;
	dayWidthPx: number;
	dividerWidthPx: number;
	startDate: Date;
	endDate: Date;
	selectedDisplayTimespanWeeks: AbsenceCalendarTimespanOption;
	setSelectedTimespanWeeks?: (value: AbsenceCalendarTimespanOption) => void;
	setStartDate?: (value: Date) => void;
	showSegmentTexts?: boolean;
};

const VerticalGridLines: React.FunctionComponent<VerticalGridLinesProps> = ({
	containerHeight,
	containerWidth,
	dayWidthPx,
	dividerWidthPx,
	startDate,
	endDate,
	selectedDisplayTimespanWeeks,
	setSelectedTimespanWeeks,
	setStartDate,
	showSegmentTexts,
}) => {
	const showDayGridLines = [AbsenceCalendarTimespanOption.WEEK, AbsenceCalendarTimespanOption.MONTH].includes(selectedDisplayTimespanWeeks);
	const showWeekGridLines = [AbsenceCalendarTimespanOption.MONTH, AbsenceCalendarTimespanOption.QUARTER].includes(selectedDisplayTimespanWeeks);
	const showMonthGridLines = selectedDisplayTimespanWeeks === AbsenceCalendarTimespanOption.YEAR;
	const showCalendarWeeks = !!showSegmentTexts && [AbsenceCalendarTimespanOption.MONTH, AbsenceCalendarTimespanOption.QUARTER].includes(selectedDisplayTimespanWeeks);
	const showMonthNames = !!showSegmentTexts && selectedDisplayTimespanWeeks === AbsenceCalendarTimespanOption.YEAR;

	const handleHeaderSegmentClick = useCallback((date:Date) => {
			if(setStartDate && setSelectedTimespanWeeks && selectedDisplayTimespanWeeks !== AbsenceCalendarTimespanOption.WEEK){
				switch (selectedDisplayTimespanWeeks) {
					case AbsenceCalendarTimespanOption.MONTH:
					case AbsenceCalendarTimespanOption.QUARTER:
						setSelectedTimespanWeeks(AbsenceCalendarTimespanOption.WEEK);
						setStartDate(startOfWeek(date));
						break;
					case AbsenceCalendarTimespanOption.YEAR:
						setSelectedTimespanWeeks(AbsenceCalendarTimespanOption.MONTH);
						setStartDate(startOfMonth(date));
						break;
				}
			}
	}, [setStartDate, selectedDisplayTimespanWeeks, setSelectedTimespanWeeks]);

	return <div className="absolute left-0 top-0 z-20"
				style={{ height: containerHeight.toNumber() }}>
		<div className="absolute top-0 h-full bg-gray-100"
			 style={{ left: 0, width: dividerWidthPx }} />
		{showDayGridLines && <DayGridLines dayWidthPx={dayWidthPx}
										   dividerWidthPx={dividerWidthPx}
										   endDate={endDate}
										   isWeekView={selectedDisplayTimespanWeeks === AbsenceCalendarTimespanOption.WEEK}
										   startDate={startDate}
										   showDates={!!showSegmentTexts && selectedDisplayTimespanWeeks === AbsenceCalendarTimespanOption.WEEK}
		/>}
		{showWeekGridLines && <WeekGridLines containerWidthPx={containerWidth.toNumber()}
											 dayWidthPx={dayWidthPx}
											 dividerWidthPx={dividerWidthPx}
											 endDate={endDate}
											 onSegmentClick={handleHeaderSegmentClick}
											 startDate={startDate}
											 showCalendarWeeks={showCalendarWeeks}

		/>}
		{showMonthGridLines && <MonthGridLines dayWidthPx={dayWidthPx}
											   dividerWidthPx={dividerWidthPx}
											   endDate={endDate}
											   onSegmentClick={handleHeaderSegmentClick}
											   showMonthNames={showMonthNames}
											   startDate={startDate}
		/>}

	</div>;
};

export default VerticalGridLines;