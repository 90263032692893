import clsx from "clsx";
import type { PropsWithChildren } from "react";

import CompanyLogo from "~/components/CompanyLogo";
import Headline from "~/components/Headline/Headline.tsx";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

interface Props {
	avatarImage?: AvatarImage | null;
	className?: string;
	companyName: string;
	projectCount?: number;
}

const CompaniesListHead: React.FC<PropsWithChildren<Props>> = ({
	avatarImage,
	companyName,
	className,
	projectCount,
}) => {
	return (
		<div className="flex items-center gap-x-2">
			{avatarImage && <CompanyLogo image={avatarImage}
										 width="w-12"
										 height="h-12" />}
			<div className="flex flex-col items-start justify-between">
				<Headline className={clsx(avatarImage && "leading-[0.9]",className)}
						  type="h2">{companyName}</Headline>
				{projectCount !== undefined &&
					<p className="max-w-4xl text-sm text-gray-500 ">{projectCount} {projectCount === 1 ? "Projekt": "Projekte"}</p>}
			</div>
		</div>
	);
};

export default CompaniesListHead;
