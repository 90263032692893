import type Decimal from "decimal.js-light";

import type { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";

export type AbsenceCalendarEvent = {
	id: string;
	absenceTypeId: string;
	absenceTypeName: string;
	comment: string | null;
	startDate: Date;
	endDate: Date;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean
	staffMemberId: string;
	staffMemberFullName: string;
	workDays: Decimal;
};

export enum AbsenceCalendarTimespanOption {
	WEEK = "1",
	MONTH = "4",
	QUARTER = "12",
	YEAR = "52",
}

export type GridSegmentType = { offsetLeft: number, text: string, width: number, startDate: Date, isCurrentDate: boolean };

export type AbsenceCalendarPageState = {
	hiddenStaffMemberIds: string[];
	typeFilter: AbsenceTypeId[];
	selectedDisplayTimespanWeeks: AbsenceCalendarTimespanOption;
};

export enum AbsenceTabName {
	absences = "absences",
	workingSchedules = "working-schedules",
	vacationLedger = "vacation-ledger",
}
