import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import AccountingDetailsFormSection from "~/components/formSections/AccountingDetailsFormSection";
import AddressFormSection from "~/components/formSections/AddressFormSection/AddressFormSection.tsx";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { DEFAULT_COUNTRY_ID } from "~/constants/form.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useClientValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useClientValidationComparisonData.ts";
import { updateClient } from "~/modules/client/api/client/clientApiDispatchers.ts";
import type { Client, CreateClientData } from "~/modules/client/api/client/clientTypes.ts";
import { getPaymentTargetDefaultValues, preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	clientData: Client;
};

interface UpdateClientFormData extends Omit<CreateClientData, "paymentTargetDays" | "companyId"> {
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
}

const UpdateClientForm: React.FC<Props> = ({ onSuccess, onCancel, clientData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const displayNameComparisonData = useClientValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			city: yup.string().default(""),
			comment: yup.string().default(""),
			countryId: yup.string().default(""),
			displayName: yup.string().required().unique(displayNameComparisonData, clientData.id),
			houseNumber: yup.string().default(""),
			invoiceByMail: yup.boolean().required(),
			isVatExempt: yup.boolean().required(),
			paymentTargetDays: yup.number().required(),
			paymentTargetDaysCustom: yup.number()
				.nullable()
				.when('paymentTargetDays', (field1Value:number[], schema) => {
					return field1Value[0] === 0
						? schema.required()
						: schema.nullable();
				}),
			street: yup.string().default(""),
			travelExpensesIncluded: yup.boolean().required(),
			zipCode: yup.string().default(""),
		});
	}, [displayNameComparisonData, clientData]);

	const defaultValues = useMemo(() => {
		const { paymentTargetDays, paymentTargetDaysCustom } = getPaymentTargetDefaultValues(
			clientData.paymentTargetDays,
		);
		console.log(paymentTargetDays, paymentTargetDaysCustom, clientData);
		return {
			city: clientData.city || "",
			comment: clientData.comment || "",
			countryId: clientData.countryId || DEFAULT_COUNTRY_ID,
			displayName: clientData.displayName,
			houseNumber: clientData.houseNumber || "",
			invoiceByMail: clientData.invoiceByMail,
			isVatExempt: clientData.isVatExempt,
			paymentTargetDays: paymentTargetDays,
			paymentTargetDaysCustom: paymentTargetDaysCustom,
			street: clientData.street || "",
			travelExpensesIncluded: clientData.travelExpensesIncluded,
			zipCode: clientData.zipCode || "",
		};
	}, [clientData]);
	const {
		handleSubmit,
		control,
		watch,
		formState: {isValid, isDirty, isSubmitted },
		trigger
	} = useForm<UpdateClientFormData>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver<UpdateClientFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isValid,
		isSubmitted,
		isLoading: busy,
		enableInitially: false,
	});

	const showCustomPaymentTargetInput = watch("paymentTargetDays") === 0;

	const countrySelectOptions = useCountrySelectOptions();

	useEffect(() => {
		if(showCustomPaymentTargetInput){
			trigger();
		}
	}, [showCustomPaymentTargetInput, trigger]);

	const onSubmit: SubmitHandler<UpdateClientFormData> = async (data) => {
		setBusy(true);
		const { paymentTargetDays, paymentTargetDaysCustom, ...rest } = data;
		const paymentTargetDaysValue =
			paymentTargetDays === 0 && paymentTargetDaysCustom ? paymentTargetDaysCustom : paymentTargetDays;

		const updateData = { paymentTargetDays: paymentTargetDaysValue, ...rest };
		try {
			await updateClient(clientData.id, updateData);
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Firmendaten aktualisieren</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Firma">
						<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
							<div className="col-span-full sm:col-start-1">
								<Input
									name="displayName"
									control={control}
									label="Name" />
							</div>
						</div>
					</FormSection>

					<AddressFormSection<UpdateClientFormData>
						control={control}
						countrySelectOptions={countrySelectOptions}
					/>

					<AccountingDetailsFormSection<UpdateClientFormData>
						control={control}
						showCustomPaymentTargetInput={showCustomPaymentTargetInput}
					/>
					<CommentFormSection
						control={control}
						label="Rechnungsbesonderheiten"
						placeholder="Hier können allgemeine Anmerkungen für die Rechnungserstellung hinterlegt werden."
						title="Anmerkungen" />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={!isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateClientForm;
