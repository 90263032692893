import type { PropsWithChildren } from "react";

interface TimelineProps {
	theme?: "primary" | "secondary" | "accent";
}

const StaffMemberTimeline: React.FC<PropsWithChildren<TimelineProps>> = ({ children }) => (
	<ul role="list"
		className="space-y-6">
		{children}
	</ul>
);

export default StaffMemberTimeline;
