import { endOfMonth, startOfMonth } from "date-fns";

import type { SelectedStaffingDataType, UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import type { TimeTrackingTotalsByType } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { formatDateToYYYYMMDD, isDateBetween } from "~/utils/dateAndTimeUtils.ts";

export function calculateTimeTrackingTotals({ endDate, startDate, timeTrackingData }: {
	endDate: Date,
	startDate: Date,
	timeTrackingData: TimeTrackingExtendedType[]
}): TimeTrackingTotalsByType {
	const totals: TimeTrackingTotalsByType = {
		[TimeTrackingTypeId.Internal]: 0,
		[TimeTrackingTypeId.Project]: 0,
		[TimeTrackingTypeId.Sales]: 0,
		[TimeTrackingTypeId.PartTime]: 0,
	};

	for (const timeTracking of timeTrackingData) {
		const timeTrackingDate = new Date(timeTracking.date);
		if (isDateBetween(timeTrackingDate, startDate, endDate)) {
			totals[timeTracking.timeTrackingTypeId as TimeTrackingTypeId] += timeTracking.minutes;
		}
	}

	return totals;
}

export function minutesToWorkdays(minutes: number, precision: number = 0): number {
	const result = minutes / 60 / 8;
	return Math.round(result * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function formatManDays(manDays: number): string {
	return manDays.toLocaleString("de-DE", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

export function getStartAndEndOfMonthFormatted(date: Date): { startDate: string, endDate: string } {
	const dates = getStartAndEndOfMonthDates(date);

	return {
		startDate: formatDateToYYYYMMDD(dates.startDate),
		endDate: formatDateToYYYYMMDD(dates.endDate),
	};
}

export function getStartAndEndOfMonthDates(date: Date): { startDate: Date, endDate: Date } {
	return {
		startDate: startOfMonth(date),
		endDate: endOfMonth(date),
	};
}

export function getStaffingDetailsFromActiveStaffings(staffingId: string,
	projects: UsersActiveStaffingType[]): SelectedStaffingDataType & {
	projectTitle: string,
	phaseTitle: string,
	orderTitle: string
} | null {
	for (const project of projects) {
		for (const phase of project.projectPhases) {
			for (const order of phase.orders) {
				for (const staffing of order.staffings) {
					if (staffing.id === staffingId) {
						return {
							...staffing,
							projectTitle: project.title,
							phaseTitle: phase.title,
							orderTitle: order.title,
						};
					}
				}
			}
		}
	}
	return null;
}
