import { UserGroupIcon, UserIcon } from "@heroicons/react/20/solid";
import type React from "react";

import Button from "~/components/buttons/Button";
import Headline from "~/components/Headline";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import type { DispatchStateAction } from "~/types/hookTypes.ts";

type ExpectedPaymentTypeSelectProps = {
	onCancelClick: () => void;
	onClick: DispatchStateAction<"freelancer" | "internal" | null>
};

const UserTypeSelector: React.FunctionComponent<ExpectedPaymentTypeSelectProps> = ({
	onCancelClick,
	onClick,
}) => {

	return (
		<div className="flex min-h-full w-full flex-col justify-start">
			<SidebarHeader>
				<SidebarHeaderHeadline>Art des Users Auswählen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<div className="grid grid-cols-2 gap-x-8">
					<button onClick={() => onClick("internal")}
							className="flex flex-col items-center justify-start rounded-lg bg-primary-500 p-5 text-white transition-colors hover:bg-primary-700"
					>
						<UserGroupIcon />
						<Headline type="h3"
								  colorClassName="text-white py-2">Intern</Headline>
						<ul className="list-disc px-10 text-left text-sm leading-5">
							<li>Festangestellte</li>
							<li>Praktikant:innen</li>
							<li>Werkstudent:innen</li>
						</ul>
					</button>
					<button onClick={() => onClick("freelancer")}
							className="flex flex-col items-center justify-start rounded-lg bg-primary-500 p-5 text-white transition-colors hover:bg-primary-700"
					>
						<UserIcon />
						<Headline type="h3"
								  colorClassName="text-white py-2">Freelancer</Headline>
						<ul className="list-disc px-10 text-left text-sm leading-5">
							<li>Kann ausschliesslich die Zeiterfassung nutzen</li>
						</ul>

					</button>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<Button theme="white"
						onClick={onCancelClick}>
					Cancel
				</Button>
			</SidebarFooter>
		</div>);
};

export default UserTypeSelector;