import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import {
	deleteStaffMemberFtePercentage,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageApiDispatchers.ts";
import {
	STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageQueries.ts";
import type {
	StaffMemberFtePercentageWithDate,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageTypes.ts";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	ftePercentage: StaffMemberFtePercentageWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteFtePercentageEntryModal: React.FunctionComponent<Props> = ({
	ftePercentage,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!ftePercentage) return;
		try {
			setBusy(true);
			await deleteStaffMemberFtePercentage(ftePercentage.staffMemberId, ftePercentage.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY(ftePercentage.staffMemberId));

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `FTE-Eintrag löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			key={isOpen ? "open" : "closed"}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!ftePercentage && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>FTE Prozentsatz</Label>
					<span className="text-base">{formatNumberToCurrency(ftePercentage.percentage)} %</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base">{formatDateWithGermanMonth(ftePercentage.validFrom)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteFtePercentageEntryModal;
