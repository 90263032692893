import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_DELIVERABLE_TAGS_QUERY_KEY } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import UpdateDeliverableTagForm
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/UpdateDelliverableTagSidebar/components/UpdateDeliverableTagForm";

type UpdateDeliverableTagSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	deliverableTagData: DeliverableTag | null
};

const UpdateDeliverableTagSidebar: FunctionComponent<UpdateDeliverableTagSidebarProps> = ({
	isOpen,
	onClose,
	deliverableTagData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(ALL_DELIVERABLE_TAGS_QUERY_KEY);
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && deliverableTagData && <UpdateDeliverableTagForm onSuccess={handleSuccess}
																	   onCancel={onClose}
																	   deliverableTagData={deliverableTagData}
			/>}
		</Sidebar>
	);
};

export default UpdateDeliverableTagSidebar;
