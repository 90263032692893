import type { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ProjectCompactInfoItem
	from "~/modules/client/components/ClientDetailsView/components/ClientsProjectsList/components/ProjectCompactInfoItem";
import NewFirstItemCta from "~/modules/client/components/ClientDetailsView/components/NewFirstItemCta";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import type { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientsProjectsListProps {
	clientDisplayName: string;
	projects?: Project[];
	workingStatuses: WorkingStatusType[];
	onAddProjectClick: () => void;
	contacts?: ClientContactPersonType[];
}

const ClientsProjectsList: React.FC<ClientsProjectsListProps> = ({
	clientDisplayName,
	projects,
	workingStatuses,
	onAddProjectClick,
	contacts,
}) => {
	if (undefined === projects || (projects && 0 === projects.length))
		return <NewFirstItemCta buttonText="Neues Projekt anlegen"
								headline="Noch keine Projekte vorhanden"
								onCreateClick={onAddProjectClick}
								subLineText={`Füge neue Projekte zu ${clientDisplayName} hinzu.`} />;

	return (
		<ul role="list" className="flex flex-col gap-y-4">
			{projects.sort(byObjectProperty("endDate", "desc")).map((data) => (
				<ProjectCompactInfoItem
					key={`project-${data.projectNumber}-${data.id}`}
					data={data}
					contacts={contacts}
					workingStatuses={workingStatuses}
				/>
			))}
		</ul>
	);
};

export default ClientsProjectsList;
