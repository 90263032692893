import type { FunctionComponent } from "react";

 const EmptyFilterResultMessage: FunctionComponent<{ message?: string }> = ({ message }) => {
	return <tr>
		<td colSpan={100}
			className="whitespace-nowrap bg-inherit px-3 py-4 text-center text-gray-500">{message || "Es existieren keine Daten für diese Filtereinstellungen."}
		</td>
	</tr>;
};


export default EmptyFilterResultMessage;