import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import { Fragment, useMemo } from "react";

import type { EmploymentType } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type StatusFilterProps = {
	employmentTypes?: EmploymentType[]
	employmentTypeFilter: string[];
	onEmploymentStatusFilterChange: (employmentTypeId: string) => void;
};

const EmploymentStatusFilter: React.FunctionComponent<StatusFilterProps> = ({
		employmentTypes,
		employmentTypeFilter,
		onEmploymentStatusFilterChange,
	}) => {
		const statusOptions = useMemo(() => {
			if (!employmentTypes) return [];
			return employmentTypes.map((status) => {
				const statusDisplayName = t(normalizeTKey(`entities:employmentType.${status.name}`));
				return {
					value: status.id,
					element: statusDisplayName,
					checked: employmentTypeFilter.includes(status.id),
				};
			});
		}, [employmentTypes, employmentTypeFilter]);

		return <div className="flow-root">
			<Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
				<Popover className="relative inline-block px-4 text-left">
					<Popover.Button
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
						<span>Anstellungsstatus</span>
						{0 < statusOptions.filter((o) => o.checked).length ? (
							<span
								className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
														{statusOptions.filter((o) => o.checked).length}
													</span>
						) : null}
						<ChevronDownIcon
							className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
							aria-hidden="true"
						/>
					</Popover.Button>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Popover.Panel
							className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black/5 focus:outline-none">
							<form className="space-y-4">
								{statusOptions.map((option, optionIdx) => (
									<div key={option.value}
										className="flex items-center">
										<input
											id={`filter-status-${optionIdx}`}
											name={`status[]`}
											defaultValue={option.value}
											type="checkbox"
											defaultChecked={option.checked}
											className="size-4 cursor-pointer rounded border-gray-300 text-accent-600 accent-accent-500 focus:ring-accent-500"
											onChange={() => onEmploymentStatusFilterChange(option.value)}
										/>
										<label
											htmlFor={`filter-status-${optionIdx}`}
											className="ml-3 cursor-pointer whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
										>
											{option.element}
										</label>
									</div>
								))}
							</form>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>;
	}
;

export default EmploymentStatusFilter;