import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import CardValueWithLabel from "~/components/CardValueWithLabel";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import { useAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import UpdateCompanyLocationSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/UpdateCompanyLocationSidebar";

type Props = { staffMemberCoreData: StaffMember }

const CompanyLocationSection: FunctionComponent<Props> = ({
	staffMemberCoreData,
}) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState(false);
	const {
		data: allCompanyLocations,
		isLoading: companyLocationsIsLoading,
	} = useAllCompanyLocations();

	const companyLocationDisplayName = useMemo(() => {
		if (staffMemberCoreData.companyLocationId && allCompanyLocations) {
			const companyLocation = allCompanyLocations.find((companyLocation) => companyLocation.id === staffMemberCoreData.companyLocationId);
			if (companyLocation) {
				return companyLocation.location.displayName;
			}
		}
		return EMPTY_VALUE_PLACEHOLDER;
	}, [staffMemberCoreData.companyLocationId, allCompanyLocations]);

	return <div className="col-span-2">
		<Card title="Standort"
			  height="full"
			  padding="none"
			  onHeaderButtonClick={() => setShowUpdateSidebar(true)}
			  headerButtonText="Bearbeiten">
			<CardValueWithLabel label="Standort"
			>
				{companyLocationsIsLoading ? <LoadingSpinnerFull theme="neutral" /> : companyLocationDisplayName}
			</CardValueWithLabel>
		</Card>
		<UpdateCompanyLocationSidebar isOpen={showUpdateSidebar}
									  onClose={() => setShowUpdateSidebar(false)}
									  staffMemberCoreData={staffMemberCoreData} />
	</div>;
};

export default CompanyLocationSection;