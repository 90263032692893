import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useHealthInsuranceSelectOptions from "~/hooks/form/formOptionsData/useHealthInsuranceSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMembersHealthInsurance,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateHealthInsuranceFormData = {
	healthInsurance: string | null;
	healthInsuranceNumber: string | null;
	healthInsuranceTypeName: string | null;
}

type UpdateBankAccountFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateHealthInsuranceForm: React.FunctionComponent<UpdateBankAccountFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const healthInsuranceSelectOptions = useHealthInsuranceSelectOptions();
	const schema = useMemo(() => {
		return yup.object({
			healthInsurance: yup.string().nullable().default(null),
			healthInsuranceNumber: yup.string().nullable().default(null),
			healthInsuranceTypeName: yup.string().nullable().default(null),
		});

	}, []);
	const defaultValues = useMemo(() => {
		if (staffMemberCoreData) {
			return {
				healthInsurance: staffMemberCoreData.healthInsurance,
				healthInsuranceNumber: staffMemberCoreData.healthInsuranceNumber,
				healthInsuranceTypeName: staffMemberCoreData.healthInsuranceTypeName,
			};
		}
		return {
			healthInsurance: null,
			healthInsuranceNumber: null,
			healthInsuranceTypeName: null,
		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateHealthInsuranceFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateHealthInsuranceFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdateHealthInsuranceFormData) => {

		try {
			setIsBusy(true);
			await updateStaffMembersHealthInsurance(staffMemberCoreData.id, data);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Krankenkasse
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}

				<div className="grid grid-cols-2 gap-4">

					<Input control={control}
						   name="healthInsurance"
						   label="Krankenkasse"
						   placeholder="Techniker Krankenkasse"
					/>


					<Select optionsData={healthInsuranceSelectOptions}
							name="healthInsuranceTypeName"
							control={control}
							label="Gesetzlich / Privat" />
					<Input control={control}
						   name="healthInsuranceNumber"
						   label="Versichertennummer"
						   placeholder="1234567890"
					/>

				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateHealthInsuranceForm;
