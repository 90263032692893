import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Card from "~/components/Card";
import CardValueWithLabel from "~/components/CardValueWithLabel";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import UpdateMentorSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/UpdateMentorSidebar";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";

type Props = { staffMemberCoreData: StaffMember }

const MentorSection: FunctionComponent<Props> = ({ staffMemberCoreData }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState(false);
	const {
		data: allStaffMembers,
		isLoading: staffMembersIsLoading,
	} = useAllStaffMembers();

	const mentorLink = useMemo(() => {
		if (staffMemberCoreData.mentorStaffMemberId && allStaffMembers) {
			const mentor =  allStaffMembers.find((staffMember) => staffMember.id === staffMemberCoreData.mentorStaffMemberId);
			if (mentor) {
				const linkPath = generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
					staffMemberId: mentor.id,
					tabId: StaffMemberDetailsTabId.EmploymentDetails,
				});
				return <NavLink to={linkPath} className="hover:text-danger-500">{mentor.fullName || ""}</NavLink>;
			}
		}
		return EMPTY_VALUE_PLACEHOLDER;
	}, [staffMemberCoreData.mentorStaffMemberId, allStaffMembers]);

	return <div className="col-span-2">
		<Card title="Mentor"
			  height="full"
			  padding="none"
			  onHeaderButtonClick={() => setShowUpdateSidebar(true)}
			  headerButtonText="Bearbeiten">
			<CardValueWithLabel label="Mitarbeiter"
			>
				{staffMembersIsLoading ? <LoadingSpinner theme="neutral" /> : mentorLink}
			</CardValueWithLabel>
		</Card>
		<UpdateMentorSidebar isOpen={showUpdateSidebar}
							 onClose={() => setShowUpdateSidebar(false)}
							 staffMemberCoreData={staffMemberCoreData} />
	</div>;
};

export default MentorSection;