import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type { BonusTypeId } from "~/modules/humanResources/api/bonusType/bonusTypes.ts";
import {
	deleteStaffMemberBonus,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusApiDispatchers.ts";
import {
	STAFF_MEMBERS_BONUSES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusQueries.ts";
import type { StaffMemberBonusWithDate } from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusTypes.ts";
import { getBonusTypeDisplayNameById } from "~/modules/humanResources/utils/bonusTypeUtils.ts";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	bonusData: StaffMemberBonusWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteBonusEntryModal: React.FunctionComponent<Props> = ({
	bonusData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!bonusData) return;
		try {
			setBusy(true);
			await deleteStaffMemberBonus(bonusData.staffMemberId, bonusData.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_BONUSES_QUERY_KEY(bonusData.staffMemberId));

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Bonus löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};
	const bonusTypeDisplayName = bonusData?.bonusTypeId ? getBonusTypeDisplayNameById(bonusData.bonusTypeId as BonusTypeId) : EMPTY_VALUE_PLACEHOLDER;
	return (
		<DeleteEntityView
			key={isOpen ? "open" : "closed"}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!bonusData && <div className="mb-4 grid grid-cols-2 gap-y-2">
				<div className="col-span-2">
					<Label>Art</Label>
					<span className="text-base">{bonusTypeDisplayName}</span>
				</div>

				<div className="col-span-2">
					<Label>Beschreibung</Label>
					<span className="text-base">{bonusData.description}</span>
				</div>
				<div className="col-span-2">
					<Label>Wert</Label>
					<span className="text-base">{formatNumberToCurrency(bonusData.amountCents)}</span>
				</div>
				<div>
					<Label>Ausgezahlt am,</Label>
					<span className="text-base">{formatDateWithGermanMonth(bonusData.paidAt)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteBonusEntryModal;
