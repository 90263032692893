import { useEffect, useState } from "react";
import type {
	Control,
	FieldValues,
	Path,
	PathValue,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
} from "react-hook-form";

import ComboBox from "~/components/formElements/ComboBox";
import FormInputError from "~/components/formElements/FormInputError";
import FormSection from "~/components/formElements/FormSection";
import type { FormInputOption } from "~/types/form.ts";

type OrderInvoiceRecipientSectionProps<T extends FieldValues> = {
	control: Control<T>;
	errorMessage?: string;
	invoiceRecipientSelectOptions: FormInputOption[];
	clientContactPersonSelectOptions: FormInputOption[];
	setValue: UseFormSetValue<T>;
	trigger: UseFormTrigger<T>;
	watch: UseFormWatch<T>;
};

interface OrderInvoiceRecipientSectionFormData {
	invoiceRecipientId: string | null;
	clientContactPersonId: string | null;
}

const OrderInvoiceRecipientSection = <T extends OrderInvoiceRecipientSectionFormData>({
	control,
	errorMessage,
	invoiceRecipientSelectOptions,
	clientContactPersonSelectOptions,
	setValue,
	trigger,
	watch,
}: OrderInvoiceRecipientSectionProps<T>) => {
	const invoiceRecipientId = watch("invoiceRecipientId" as Path<T>);
	const clientContactPersonId = watch("clientContactPersonId" as Path<T>);
	const [isInitialRender, setIsInitialRender] = useState(true);

	useEffect(() => {
		if (!isInitialRender && invoiceRecipientId) {
			setValue("clientContactPersonId" as Path<T>, null as PathValue<T, any>);
			trigger();
		}
	}, [invoiceRecipientId, isInitialRender, setValue, trigger]);

	useEffect(() => {
		if (!isInitialRender && clientContactPersonId) {
			setValue("invoiceRecipientId" as Path<T>, null as PathValue<T, any>);
			trigger();
		}
	}, [clientContactPersonId, isInitialRender, setValue, trigger]);

	useEffect(() => {
		setIsInitialRender(false);
	}, []);

	return (
		<FormSection title="Rechnungsempfänger">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-6">
					<ComboBox
						allowNew={false}
						control={control}
						label="Zentraler Rechnungsempfänger"
						name="invoiceRecipientId"
						optionsData={invoiceRecipientSelectOptions}
					/>
				</div>
				<div className="col-span-6">
					<ComboBox
						allowNew={false}
						control={control}
						label="Kontakt"
						name="clientContactPersonId"
						optionsData={clientContactPersonSelectOptions}
					/>
				</div>
				<div className={"col-span-6"}>{errorMessage && <FormInputError>{errorMessage}</FormInputError>}</div>
			</div>
		</FormSection>
	);
};

export default OrderInvoiceRecipientSection;
