import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { PRODUCT_TAG_USAGE_REPORT_QUERY_KEY } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { ALL_PROJECTS_QUERY_KEY, PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import UpdateProductTagsForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectProductTagsSection/components/UpdateProductTagsSidebar/components/UpdateProductTagsForm";

type UpdateProductTagsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	productTagIds: string[];
	projectId: string;
};

const UpdateProductTagsSidebar: FunctionComponent<UpdateProductTagsSidebarProps> = ({
	isOpen,
	onClose,
	productTagIds,
	projectId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
		queryClient.invalidateQueries(PRODUCT_TAG_USAGE_REPORT_QUERY_KEY);
		queryClient.invalidateQueries(ALL_PROJECTS_QUERY_KEY);
		onClose();
	}, [onClose, projectId, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <UpdateProductTagsForm onSuccess={handleSuccess}
											  onClose={onClose}
											  projectId={projectId}
											  productTagIds={productTagIds} />}
		</Sidebar>
	);
};

export default UpdateProductTagsSidebar;
