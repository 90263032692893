import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import { STAFF_MEMBER_BASE_PATH } from "~/constants/appRoute.ts";
import useStaffMemberSelectOptions from "~/hooks/form/formOptionsData/useStaffMemberSelectOptions.ts";
import type { FormInputOption } from "~/types/form.ts";

type AbsenceViewUserSelectProps = {
	userIsVacationManager: boolean;
	staffMemberId: string;
	currentSubPath: string;
};


const AbsenceViewStaffMemberSelect: React.FunctionComponent<AbsenceViewUserSelectProps> = ({
	userIsVacationManager,
	staffMemberId,
	currentSubPath = "",
}) => {
	const navigate = useNavigate();
	const userSelectOptions = useStaffMemberSelectOptions();

	const handleChange = useCallback((staffMemberId: string | null) => {
		if (staffMemberId) {
			navigate(generatePath(STAFF_MEMBER_BASE_PATH, { staffMemberId }) + "/" + currentSubPath);
		}
	}, [currentSubPath, navigate]);

	if (!userIsVacationManager) return null;
	return (
		<div className="ml-auto flex items-center gap-x-5">
			<div>Mitarbeiter auswählen:</div>
			<div className="w-56">
				<ComboBoxPlain
					allowNew={false}
					optionsData={userSelectOptions}
					onChange={handleChange as (value: string | FormInputOption | null) => void}
					value={staffMemberId} />
			</div>
		</div>
	);


};

export default AbsenceViewStaffMemberSelect;