import Card from "~/components/Card";
import MapDisplay from "~/components/displays/MapDisplay";
import FormInput from "~/components/formElements/FormInput";
import Label from "~/components/formElements/Label";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import { useAllCurrencies } from "~/modules/country/api/currency/currencyQueries.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

type ClientsBillingDetailsProps = {
	clientData: Client;
};

const ClientsBillingDetails: React.FunctionComponent<ClientsBillingDetailsProps> = ({
	clientData,
}) => {
	const {
		countryId,
		comment,
		currencyId,
		street,
		houseNumber,
		city,
		zipCode,
		invoiceByMail,
		paymentTargetDays,
		travelExpensesIncluded,
	} = clientData;

	const { data: allCurrenciesData } = useAllCurrencies();

	const currencyDisplayName = currencyId ? getOneOfCollection(allCurrenciesData, currencyId, "displayName"): "n/a";

	const showMap = !!street && !!houseNumber && !!zipCode && !!city && countryId;

	return (
		<div className="flex flex-col gap-y-8">
			<div className="grid w-full grid-cols-2 gap-x-2">
				<Card>
					<div className="grid grid-cols-2 gap-4">
						{[
							{
								label: "Rechnung per",
								value: invoiceByMail ? "Mail" : "Post",
							},

							{
								label: "Zahlungsziel",
								value: paymentTargetDays ? `${paymentTargetDays} Tage` : "n/a",
							},
							{
								label: "Reisekosten inkludiert",
								value: travelExpensesIncluded ? "Ja" : "Nein",
							},
							{
								label: "Währung",
								value: currencyDisplayName || "n/a",
							},

						].map(({ label, value }, index) => (
							<FormInput key={`contacts-${index}`}
									   className="sm:col-span-1">
								<Label>{label}</Label>
								<div className="text-sm font-bold text-gray-900">{value}</div>
							</FormInput>
						))}
						<div className="col-span-2">
							<FormInput className="sm:col-span-1">
								<Label>Rechnungsbesonderheiten</Label>
								<div className="text-sm font-bold text-gray-900">{comment || "n/a"}</div>
							</FormInput>
						</div>
					</div>
				</Card>
				<Card>
					<div className="text-sm font-medium text-gray-500">Addresse</div>
					{showMap ? <MapDisplay address={{ street, houseNumber, zipCode, city, countryId }} /> : "n/a"}
				</Card>
			</div>
		</div>
	);
};

export default ClientsBillingDetails;