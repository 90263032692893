import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Hint from "~/components/Hint";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createWorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";
import WorkingScheduleFormSection from "~/modules/absence/components/formSections/WorkingScheduleFormSection";
import {
	useUsersWorkingSchedulesValidationComparisonData
} from "~/modules/absence/hooks/useUsersWorkingSchedulesValidationComparisonData.ts";
import type { WorkingScheduleFormData } from "~/modules/absence/types/workingScheduleViewTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type CreateWorkingScheduleFormProps = {
	onCancel: () => void;
	onSuccess: () => void;
	staffMemberId: string;
	workingSchedulesMinDate: Date;
};

const CreateWorkingScheduleForm: React.FunctionComponent<CreateWorkingScheduleFormProps> = ({
	onCancel,
	onSuccess,
	staffMemberId,
	workingSchedulesMinDate,
}) => {
	const [busy, setBusy] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);
	const [noWorkdaySelectedErrorMessage, setNoWorkdaySelectedErrorMessage] = useState<string|null>(null);

	const comparisonData = useUsersWorkingSchedulesValidationComparisonData(staffMemberId);

	const schema = useMemo(() => {
		return yup.object({
			monday: yup.boolean().default(true),
			tuesday: yup.boolean().default(true),
			wednesday: yup.boolean().default(true),
			thursday: yup.boolean().default(true),
			friday: yup.boolean().default(true),
			validFrom: yup.date().transform(transformEmptyDateValueToNull).nullable().required().uniqueDay(comparisonData),
		}).test("atLeastOneChecked", "", (obj) => {
			if (!obj.monday && !obj.tuesday && !obj.wednesday && !obj.thursday && !obj.friday) {
				setNoWorkdaySelectedErrorMessage("Es muss mindestens ein Wochentag ausgewählt werden.");
				return false;
			}
			setNoWorkdaySelectedErrorMessage(null);
			return true;
			/*return obj.monday || obj.tuesday || obj.wednesday || obj.thursday || obj.friday;*/
		});
	}, [comparisonData]);

	const defaultValues = useMemo(() => {
		return {
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			validFrom: null as unknown as Date,
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<WorkingScheduleFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<WorkingScheduleFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: busy,
	});

	const onSubmit = useCallback(async (data: WorkingScheduleFormData) => {
		const validFromFormatted = formatDateToYYYYMMDD(data.validFrom);

		try {
			setBusy(true);
			await createWorkingSchedule({ staffMemberId, data: { ...data, validFrom: validFromFormatted } });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			if (apiError.type === ApiResponseErrorType.VALIDATION) {
				if (apiError.messages.validFrom?.find((message) => message.rule === "unique")) {
					setServerError("Es existiert bereits ein Arbeitszeitmodell mit diesem Gültigkeitsdatum.");
				}
			} else {
				setServerError("Ein unerwarteter Fehler ist aufgetreten.");
			}
			setBusy(false);
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neues Arbeitszeitmodell erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<WorkingScheduleFormSection
					dateIsLocked={false}
					noWorkdaySelectedErrorMessage={noWorkdaySelectedErrorMessage}
					minDate={workingSchedulesMinDate}
					control={control}
				/>
				{serverError && <Hint theme="error">{serverError}</Hint>}
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || !!serverError}>
					{t(normalizeTKey('form:buttonTexts.save'))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey('form:buttonTexts.cancel'))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateWorkingScheduleForm;