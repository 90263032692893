import type { FunctionComponent, PropsWithChildren } from "react";

const VacationLedgerTable: FunctionComponent<PropsWithChildren> = ({ children }) => {
	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col"
					className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
					Typ
				</th>
				<th scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Kommentar
				</th>
				<th scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Gültig ab
				</th>
				<th scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Urlaubstage
				</th>
				<th scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Erstellt von
				</th>
				<th scope="col"
					className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>
			</tr>
			</thead>
			<tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
		</table>
	);
};


export default VacationLedgerTable;
