import type React from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteDeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsApiDispatchers.ts";
import { ALL_DELIVERABLE_TAGS_QUERY_KEY } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";

type DeleteProductTagModalProps = {
	isOpen: boolean;
	onClose: () => void;
	deliverableTagData: DeliverableTag | null;
	setSelectedTagId:DispatchStateAction<string|null>
};

const DeleteDeliveryTagModal: React.FunctionComponent<DeleteProductTagModalProps> = ({
	isOpen,
	onClose,
	deliverableTagData,
	setSelectedTagId
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	const handleClose = useCallback(() => {
		setBusy(false);
		setError("");
		onClose();
	}, []);
	const handleConfirmationClick = async () => {
		if (!deliverableTagData) return;
		try {
			setBusy(true);
			await deleteDeliverableTag({ id: deliverableTagData.id });
			await queryClient.refetchQueries(ALL_DELIVERABLE_TAGS_QUERY_KEY);
			setSelectedTagId(null);
			handleClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectDeliverableTagId.find(
						(validationError) => validationError.rule === "projectDeliverableTagIsDeletableRule",
					)
				) {
					setError("Der Tag kann nicht gelöscht werden, da er noch Projekten zugeordnet ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Deliverable Tag "${deliverableTagData?.displayName}" löschen`,
		bodyText: `Möchtest Du den Deliverable Tag wirklich löschen?`,
		deleteButtonText: `Deliverable Tag löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={handleClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteDeliveryTagModal;
