import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelApiDispatchers.ts";

export const GET_ALL_CAREER_LEVELS_QUERY_KEY = ["careerLevels"];

export const useAllCareerLevels = (options?: QueriesOptions<any>) => useQuery(
	GET_ALL_CAREER_LEVELS_QUERY_KEY,
	() => getAllCareerLevels().then(getDataFromResponse),
	options,
);