import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import ContactsIndexView from "~/modules/client/components/contact/ContactsIndexView";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

const ContactsIndexPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if (guard) return guard;
	const { data, options } = useAggregatedDataV2({
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		companies: useAllCompanies(),
		clients: useAllClients(),
		contacts: useAllClientContactPersons(),
	});

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

	return <ContactsIndexView {...data} />;
};

export default ContactsIndexPage;
