import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createStaffMemberCareerLevel,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelApiDispatchers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateEmploymentFormData = {
	careerLevelId: string;
	validFrom: Date
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberId: string;
};

const CreateCareerLevelForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	staffMemberId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const careerLevelSelectOptions = useCareerLevelOptions();
	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required().default(""),
			validFrom: yup.date().required().default(new Date()),

		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			careerLevelId: "",
			validFrom: new Date(),
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<CreateEmploymentFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateEmploymentFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: CreateEmploymentFormData) => {

		const updateData = {
			...data,
			validFrom: formatDateToYYYYMMDD(data.validFrom),
		};

		try {
			setIsBusy(true);
			await createStaffMemberCareerLevel(staffMemberId, updateData);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Neuer Eintrag in Karrierrestufenhistorie
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Select optionsData={careerLevelSelectOptions}
							name="careerLevelId"
							label="Karrierestufe"
							control={control}
					/>
					<DatePickerInput name="validFrom"
									 control={control}
									 label="Gültig ab" />
				</div>

			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateCareerLevelForm;
