import type { Control, FieldErrors, FieldValues, Path, UseFormWatch } from "react-hook-form";

import CurrencyInput from "~/components/formElements/CurrencyInput";
import type { FormSectionProps } from "~/components/formElements/FormSection";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Hint from "~/components/Hint";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type BudgetFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>;
	errors: FieldErrors<T>;
	showsConfirmedValues?: boolean;
	watch: UseFormWatch<T>
};

type BudgetFormSectionType = {
	manDaysPlanned: number;
	budgetCentsPlanned: number;
};

const BudgetFormSection = <T extends BudgetFormSectionType>({
	control,
	errors,
	showsConfirmedValues,
	title,
	watch,
}: BudgetFormSectionProps<T>) => {
	const manDaysPlanned = watch("manDaysPlanned" as Path<T>);
	const budgetCentsPlanned = watch("budgetCentsPlanned" as Path<T>);
	const averageDailyRateCents = manDaysPlanned && budgetCentsPlanned ? budgetCentsPlanned / manDaysPlanned : undefined;

	return (
		<FormSection title={title}>
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<Input
						disabled={showsConfirmedValues}
						name="manDaysPlanned"
						control={control}
						label="Personentage"
						type={"number"}
					/>
				</div>
				<div className="col-span-3">
					<CurrencyInput
						disabled={showsConfirmedValues}
						control={control}
						name={"budgetCentsPlanned"}
						label="Budget (EUR)"
						error={errors.budgetCentsPlanned?.message as string}
					/>
				</div>
				<div className="col-span-6 text-sm text-gray-500">
					Ø Tagessatz: {averageDailyRateCents ? formatCentsToCurrency(averageDailyRateCents) : "--"}
				</div>
				{showsConfirmedValues && <div className="col-span-6">
					<Hint size="sm">
						Da diese Phase bestätigte Bestellungen hat, werden hier die kumulierten Werte der bestätigten
						Bestellungen angezeigt
					</Hint>
				</div>}
			</div>
		</FormSection>
	);
};

export default BudgetFormSection;
