import type React from "react";
import type { Control, FieldValues } from "react-hook-form";

import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import type { FormInputOption } from "~/types/form.ts";

type UserEmploymentInformationFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	children?: React.ReactNode;
	careerLevelSelectOptions: FormInputOption[];
	companyLocationSelectOptions: FormInputOption[];
	disableEmploymentTypeSelect?: boolean;
	employmentTypeSelectOptions: FormInputOption[];
};

type UserEmploymentInformationFormData = {
	employmentTypeId: string;
	careerLevelId: string;
	locationId: string;
	shortName: string;
}

const UserEmploymentInformationFormSection = <T extends UserEmploymentInformationFormData>({
	control,
	children,
	careerLevelSelectOptions,
	companyLocationSelectOptions,
	disableEmploymentTypeSelect,
	employmentTypeSelectOptions,
}: UserEmploymentInformationFormSectionProps<T>) => {
	return (
		<FormSection title="Mitarbeiterdaten">
			<div className="grid grid-cols-6 gap-6">
				<div className="col-span-3">
					<Select
						disabled={disableEmploymentTypeSelect}
						name="employmentTypeId"
						label={"Anstellungsstatus"}
						optionsData={employmentTypeSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="careerLevelId"
						label={"Karrierestufe"}
						optionsData={careerLevelSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="locationId"
						label={"Standort"}
						optionsData={companyLocationSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Input
						name="shortName"
						placeholder="MM"
						label="Namenskürzel"
						control={control} />
				</div>
				{children}
			</div>
		</FormSection>
	);
};

export default UserEmploymentInformationFormSection;
