import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useMemo } from "react";

import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { getFullPhaseTitle } from "~/modules/project/utils/projectPhaseUtils.ts";
import { toggleItemInArray } from "~/utils/arrayUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type PhaseFilterProps = {
	projectPhases: ProjectPhase[];
	filterIds: string[];
	onChange: Dispatch<SetStateAction<string[]>>
};

const PhaseFilter: React.FunctionComponent<PhaseFilterProps> = ({ projectPhases, filterIds, onChange }) => {
		const phaseOptions = useMemo(() => {
			return projectPhases.sort(byObjectProperty("phaseNumber")).map((phase) => {
				return {
					value: phase.id,
					element: getFullPhaseTitle(phase),
					checked: filterIds.includes(phase.id),
				};
			});
		}, [projectPhases, filterIds]);

		const handleChange = useCallback((userId: string) => {
			if (onChange) {
				onChange(toggleItemInArray(filterIds, userId));
			}
		}, [onChange, filterIds]);

		return (

			<Popover className="relative isolate">
				<PopoverButton
					className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
					<span>Phasen</span>
					{0 < phaseOptions.filter((o) => o.checked).length ? (
						<span
							className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
														{phaseOptions.filter((o) => o.checked).length}
													</span>
					) : null}
					<ChevronDownIcon
						className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
						aria-hidden="true"
					/>
				</PopoverButton>

				<PopoverPanel
					anchor="bottom start"
					className="z-[1000] flex flex-col rounded-md bg-white p-4 shadow-lg ring-1 ring-black/5 focus:outline-none">
					<form className="space-y-4">
						{phaseOptions.map((option, optionIdx) => (
							<div key={option.value}
								 className="flex items-center">
								<input
									id={`filter-status-${optionIdx}`}
									name={`status[]`}
									defaultValue={option.value}
									type="checkbox"
									defaultChecked={option.checked}
									className="size-4 cursor-pointer rounded border-gray-300 text-accent-600 accent-accent-500 focus:ring-accent-500"
									onChange={() => handleChange(option.value)}
								/>
								<label
									htmlFor={`filter-status-${optionIdx}`}
									className="ml-3 cursor-pointer whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
								>
									{option.element}
								</label>
							</div>
						))}
					</form>
				</PopoverPanel>
			</Popover>);
	}
;

export default PhaseFilter;