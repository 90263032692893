import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import type { SortDirection } from "@tanstack/react-table";
import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import type { TableHeaderBgTheme } from "~/components/Table/tableTypes.ts";


type TableHeaderCellProps = PropsWithChildren & {
	bgColor?: TableHeaderBgTheme;
	canSort?: boolean;
	cellClassName?: string;
	className?: string;
	onSortClick?: ((event: unknown) => void) | undefined
	isSorted?: false | SortDirection;
	rounded?: boolean;
	textAlign?: "left" | "center" | "right";
	widthPercentage?: number;
};

const TableHeaderCell: FunctionComponent<TableHeaderCellProps> = ({
	bgColor = "light",
	children,
	canSort,
	cellClassName,
	className,
	isSorted = false,
	textAlign = "center",
	widthPercentage,
	onSortClick = () => {
	},
	rounded = true,
}) => {
	let textAlignClass = "justify-center";
	if (textAlign === "left") {
		textAlignClass = "justify-start";
	}
	if (textAlign === "right") {
		textAlignClass = "justify-end";
	}

	const style = widthPercentage ? { width: `${widthPercentage}%` } : {};
	return (
		<th onClick={onSortClick}
			className={clsx("font-bold text-gray-700",
				canSort && "cursor-pointer",
				cellClassName,
				bgColor === "light" ? "bg-white" : "bg-gray-500",
				rounded ? "pb-3" : "py-3",
			)}
			style={style}>
			<div className={clsx("relative flex items-center gap-x-2 px-4 text-xs", textAlignClass, className)}>
				<div className="relative">{children}
					{isSorted &&
						<div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full text-gray-500">{isSorted === "desc" ?
							<ChevronDownIcon className="size-5" /> :
							<ChevronUpIcon className="size-5" />}</div>}
				</div>
			</div>
		</th>);
};

export default TableHeaderCell;