import type { FilterFn } from "@tanstack/react-table";

import type { ColumnFilterType } from "~/types/tanstackTableTypes.ts";


export const customArrayIncludesSome: FilterFn<any> = (row, columnId, value) => {
	if (!Array.isArray(value)) {
		return false;
	}

	if (value.length === 0) {
		return true;
	}
	return value.includes(row.original[columnId]);
};

export const customStringEquals: FilterFn<any> = (row, columnId, value) => {
	if (value === null) {
		return true;
	}
	return value === (row.original[columnId]);
};

export const customDateFilter = (row: any, columnId: string, filterValue: [Date, Date]) => {
	const rowDate = new Date(row.getValue(columnId));
	const [startDate, endDate] = filterValue;

	if (startDate && endDate) {
		return rowDate >= startDate && rowDate <= endDate;
	} else if (startDate) {
		return rowDate >= startDate;
	} else if (endDate) {
		return rowDate <= endDate;
	}

	return true;
};


export const createColumnFilterInputHandler = (
	filters: ColumnFilterType[],
	setFilters: React.Dispatch<React.SetStateAction<ColumnFilterType[]>>,
) => (key: string, value: any) => {

	const newFilters = filters.map(filter =>
		filter.id === key ? { ...filter, value } : filter,
	);
	setFilters(newFilters);
};

export const getColumnFilterValueForKey = (key: string, filters: ColumnFilterType[]): any => {
	const filter = filters.find(f => f.id === key);
	return filter ? filter.value : "";
};

export function sanitiseColumnFilters(defaultColumnFilters: ColumnFilterType[], columnFilters: ColumnFilterType[], dateFields?:string[]) {
	const columnFilterIds = defaultColumnFilters.map(columnFilter => columnFilter.id);
	const sanitisedColumnFilter = columnFilters.filter(columnFilter => columnFilterIds.includes(columnFilter.id));
	if (sanitisedColumnFilter.length !== columnFilterIds.length) {
		defaultColumnFilters.forEach(defaultColumnFilter => {
			if (!sanitisedColumnFilter.find(columnFilter => columnFilter.id === defaultColumnFilter.id)) {
				sanitisedColumnFilter.push(defaultColumnFilter);
			}
		});
	}

	if(dateFields){
		sanitisedColumnFilter.forEach(filter=>{
			if(dateFields.includes(filter.id)){
				filter.value = filter.value.map((date: string)=>date ? new Date(date) : null);
			}
		})
	}
	return sanitisedColumnFilter
}
