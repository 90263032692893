import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { startOfDay } from "date-fns";
import Decimal from "decimal.js-light";
import { t } from "i18next";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Hint from "~/components/Hint";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateVacationLedgerEntry,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";
import type { VacationLedgerEntryType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import VacationLedgerEntryFormSection from "~/modules/absence/components/formSections/VacationLedgerEntryFormSection";
import useVacationLedgerEntryTypeOptions from "~/modules/absence/hooks/useVacationLedgerEntryTypeOptions.tsx";
import { EntryType } from "~/modules/absence/types/vacationLedgerEnums.ts";
import type { VacationLedgerFormData } from "~/modules/absence/types/vacationLedgerFormTypes.ts";
import { convertVacationLedgerEntryFormDataToApiData } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateVacationLedgerEntryFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	vacationLedgerEntryData: VacationLedgerEntryType,
};

const UpdateVacationLedgerEntryForm: React.FunctionComponent<CreateVacationLedgerEntryFormProps> = ({
	onSuccess,
	onCancel,
	vacationLedgerEntryData,
}) => {
	const [busy, setBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(null);
	const schema = useMemo(() => {
		return yup.object({
			entryTypeId: yup.string().required(),
			validFrom: yup.date().required(),
			comment: yup.string().required().default(""),
			workDays: yup.number().required().default(0),
		});
	}, []);
	const defaultValues = useMemo(() => {
		let workDays = new Decimal(parseFloat(vacationLedgerEntryData.workDays));
		const entryTypeId = workDays.isNegative() ? EntryType.Deduction : EntryType.Credit;
		workDays = workDays.abs();
		return {
			entryTypeId: entryTypeId,
			validFrom: startOfDay(new Date(vacationLedgerEntryData.validFrom)),
			comment: vacationLedgerEntryData.comment || "",
			workDays: workDays.toNumber() * 100,
		};
	}, [vacationLedgerEntryData]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
	} = useForm<VacationLedgerFormData>({
		mode: "onSubmit",
		defaultValues: defaultValues,
		resolver: yupResolver<VacationLedgerFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	const onSubmit = useCallback(async (data: VacationLedgerFormData) => {
		try {
			setBusy(true);
			await updateVacationLedgerEntry({
				staffMemberId: vacationLedgerEntryData.staffMemberId,
				vacationLedgerEntryId: vacationLedgerEntryData.id,
				data: convertVacationLedgerEntryFormDataToApiData(data),
			});
			onSuccess();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.vacationLedgerEntryId?.find(
						(validationError) => validationError.rule === "vacationLedgerEntryIsDeletableOrUpdatableRule",
					)
				) {
					setServerErrorMessage("Der Eintrag kann nicht bearbeitet werden.");
				}
			} else {
				setServerErrorMessage("Ein unbekannter Fehler ist aufgetreten.");
			}
			setBusy(false);
		}
	}, [onSuccess, vacationLedgerEntryData.id, vacationLedgerEntryData.staffMemberId]);

	const entryTypeSelectOptions = useVacationLedgerEntryTypeOptions();

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Eintrag bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<VacationLedgerEntryFormSection
					control={control}
					entryTypeSelectOptions={entryTypeSelectOptions}
					errors={errors} />
				{serverErrorMessage && <Hint theme="error">{serverErrorMessage}</Hint>}
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={!isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || !!serverErrorMessage}>
					{t(normalizeTKey('form:buttonTexts.save'))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey('form:buttonTexts.cancel'))}
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateVacationLedgerEntryForm;
