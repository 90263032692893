import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent, ReactNode } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import {
	useStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type {
	StaffMemberEmploymentWithDate,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import {
	useStaffMembersCareerLevels,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelQueries.ts";
import type {
	StaffMemberCareerLevelWithDate,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelTypes.ts";
import CreateCareerLevelSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection/components/CreateCareerLevelSidebar";
import DeleteCareerLevelModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection/components/DeleteCareerLevelModal";
import UpdateCareerLevelSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection/components/UpdateCareerLevelSidebar";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import TimelineEmptyMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TimelineEmptyMessage";
import TimelineItem from "modules/humanResources/components/StaffMemberDetailsView/components/TimelineItem";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { staffMemberId: string };

type CareerLevelListElement = StaffMemberCareerLevelWithDate | StaffMemberEmploymentWithDate

const CareerLevelsSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateEntrySidebar, setShowCreateEntrySidebar] = useState(false);
	const [entryDataToDelete, setEntryDataToDelete] = useState<StaffMemberCareerLevelWithDate | null>(null);
	const [entryDataToUpdate, setEntryDataToUpdate] = useState<StaffMemberCareerLevelWithDate | null>(null);
	const {
		data: employmentsData,
		isLoading: employmentsIsLoading,
	} = useStaffMembersEmployments({ staffMemberId });

	const {
		data: careerLevelsData,
		isLoading: careerLevelsIsLoading,
	} = useStaffMembersCareerLevels({ staffMemberId });

	const timeLineElements = useMemo(() => {
		if (employmentsData !== undefined && careerLevelsData !== undefined) {
			if (careerLevelsData.length === 0) {
				return [];
			}
			const leaveEvents = employmentsData
				.filter(employment => employment.employmentTypeId === EmploymentTypeId.Left)
				.map(employment => ({ ...employment, validFrom: new Date(employment.validFrom) }));

			const careerLevelsWithDate = careerLevelsData.map(careerLevel => {
				return { ...careerLevel, validFrom: new Date(careerLevel.validFrom) };
			});

			const timelineElements: ReactNode[] = [];

			const careerLevelsSorted: CareerLevelListElement[] = [...careerLevelsWithDate, ...leaveEvents].sort(byObjectProperty("validFrom", "desc"));

			careerLevelsSorted.forEach((careerLevel, index) => {
				const nextElement = careerLevelsSorted[index + 1] as StaffMemberEmploymentWithDate;
				const nextElementIsLeftEntry = nextElement?.employmentTypeId !== undefined;

				const isStarItem = !nextElement || nextElementIsLeftEntry;
				const isLeftEntry = (careerLevel as StaffMemberEmploymentWithDate).employmentTypeId !== undefined;
				const title = isLeftEntry ? getEmploymentTypeDisplayNameById(EmploymentTypeId.Left) : getCareerLevelDisplayNameById((careerLevel as StaffMemberCareerLevelWithDate).careerLevelId as CareerLevelId);

				let dotTheme: "success" | "danger" | "neutral" = "neutral";
				if (isLeftEntry) {
					dotTheme = "danger";
				} else if (isStarItem) {
					dotTheme = "success";
				}

				const popOverItems =
					[
						{
							label: "Bearbeiten",
							onClick: () => setEntryDataToUpdate(careerLevel as StaffMemberCareerLevelWithDate),
							icon: PencilIcon,
						},
						{
							label: "Löschen",
							onClick: () => setEntryDataToDelete(careerLevel as StaffMemberCareerLevelWithDate),
							icon: TrashIcon,
						},
					];

				timelineElements.push(
					<TimelineItem date={new Date(careerLevel.validFrom)}
								  dotTheme={dotTheme}
								  key={index}
								  showHoverEffect={!isLeftEntry}
								  isStartItem={isStarItem}
					>
						<div className="flex w-full justify-between">
							<div>{title}</div>
							{!isLeftEntry && <ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>}

						</div>
					</TimelineItem>,
				);
			});
			return timelineElements;
		}
		return [];
	}, [careerLevelsData, employmentsData]);

	return <div className="col-span-3">
		<Card title="Karrierestufen"
			  onHeaderButtonClick={() => setShowCreateEntrySidebar(true)}
			  headerButtonText="Neuer Eintrag">
			{employmentsIsLoading || careerLevelsIsLoading && <LoadingSpinnerFull theme="neutral" />}
			{!employmentsIsLoading && !careerLevelsIsLoading && <StaffMemberTimeline>
				{timeLineElements.length > 0 ? timeLineElements : <TimelineEmptyMessage />}
			</StaffMemberTimeline>}
		</Card>
		<CreateCareerLevelSidebar isOpen={showCreateEntrySidebar}
								  onClose={() => setShowCreateEntrySidebar(false)}
								  staffMemberId={staffMemberId} />

		<UpdateCareerLevelSidebar isOpen={!!entryDataToUpdate}
								  onClose={() => setEntryDataToUpdate(null)}
								  careerLevelData={entryDataToUpdate} />

		<DeleteCareerLevelModal isOpen={!!entryDataToDelete}
								onCloseClick={() => setEntryDataToDelete(null)}
								careerLevelData={entryDataToDelete}
		/>
	</div>;
};

export default CareerLevelsSection;