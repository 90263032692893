import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import Headline from "~/components/Headline";
import ImageUploadAndCropSidebar from "~/components/ImageUploadAndCropSidebar";
import Sidebar from "~/components/Sidebar";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { uploadAvatarImage } from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { OriginalImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type Props = {
	staffMemberId: string;
	isOpen: boolean;
	onClose: () => void;
	originalImage?:OriginalImage;
};

const StaffMemberAvatarUploadSidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	originalImage,
	staffMemberId,
}) => {
	const queryClient = useQueryClient();
	const [busy, setBusy] = useState<boolean>(false);

	const handleClose = useCallback(() => {
		setBusy(false);
		onClose();
	}, [onClose]);
	const handleSubmit = async (formData: FormData) => {
		setBusy(true);

		try {
			await uploadAvatarImage(staffMemberId, formData);
			queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);
			queryClient.invalidateQueries(ALL_STAFF_MEMBERS_QUERY_KEY);
			await queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(staffMemberId));
			handleClose();
		} catch (error) {
			console.error("Upload failed:", error);
			// Handle error (e.g., show error message)
		}
	};

	return (
		<Sidebar open={isOpen}
				 setOpen={handleClose}>
			<SidebarHeader>
				<Headline type="h3">Profilbild ändern</Headline>
			</SidebarHeader>
			{busy && <SidebarBusyOverlay showSpinner={true} />}

			{isOpen && <ImageUploadAndCropSidebar onSuccess={handleSubmit}
												  initialImage={originalImage}
												  onClose={handleClose} />}
		</Sidebar>
	);
};


export default StaffMemberAvatarUploadSidebar;