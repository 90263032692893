import type { PropsWithChildren } from "react";
import type React from "react";

import Label from "~/components/formElements/Label";

type PhaseInfoBoxElementProps = PropsWithChildren & {
	label: string;
	icon: React.ReactNode;
};

const PhaseInfoBoxElement: React.FunctionComponent<PhaseInfoBoxElementProps> = ({ label, icon, children }) => {
	return <div className="flex flex-row gap-x-4">
		<div className="flex flex-col items-end justify-end pb-0.5">
			{icon}
		</div>
		<div>
			<Label size="sm">{label}</Label>
			<dd className="text-sm font-semibold tracking-tight text-gray-900">
				{children}
			</dd>
		</div>
	</div>;
};

export default PhaseInfoBoxElement;