import { format } from "date-fns";
import ExcelJS from "exceljs";
import type { FunctionComponent } from "react";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import {
	timeTrackingsTableColumnDefs,
} from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/components/DownloadProjectsTimeTrackingsTableDataButton/projectsTimeTrackinsExcelColumnDefs.ts";
import type {
	ProjectsTimeTrackingsTableData,
	TimeTrackingExcelData,
} from "~/modules/project/types/projectViewTypes.ts";
import { getFullPhaseTitle } from "~/modules/project/utils/projectPhaseUtils.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { convertLocalToUTCDate } from "~/utils/dateAndTimeUtils.ts";
import { roundToTwoDecimals } from "~/utils/numberUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	includedUserIds: string[];
	endDate: Date | null;
	filteredTableData: ProjectsTimeTrackingsTableData[];
	projectPhaseIds: string[];
	projectPhases: ProjectPhase[];
	projectTitle: string;
	startDate: Date | null;
};

const DownloadProjectsTimeTrackingsTableDataButton: FunctionComponent<Props> = ({
	includedUserIds,
	endDate,
	filteredTableData,
	projectPhaseIds,
	projectPhases,
	projectTitle,
	startDate,
}) => {
	const { data: allUsersData } = useAllUsers();

	const handleDownload = async () => {
		if (!allUsersData) return;

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(`${projectTitle} - Erfassungen`);

		const userNames = includedUserIds.map(userId => {
			return allUsersData.find(user => user.id === userId)!.fullName;
		}).join(", ");

		const phaseNames = projectPhases
			.filter(phase => {
				return projectPhaseIds.length > 0 ? projectPhaseIds.includes(phase.id) : true;
			})
			.map(phase => getFullPhaseTitle(phase)).join(", ");

		let startDateDocument = startDate;
		let endDateDocument = endDate;

		if (!startDateDocument || !endDateDocument) {
			const tableDataSortedByDate = filteredTableData.sort(byObjectProperty("date"));
			if (!startDateDocument) {
				startDateDocument = tableDataSortedByDate.length > 0 ? tableDataSortedByDate[0].date : null;
			}
			if (!endDateDocument) {
				endDateDocument = tableDataSortedByDate.length > 0 ? tableDataSortedByDate[tableDataSortedByDate.length - 1].date : null;
			}
		}

		// Add header information
		worksheet.addRow(["Projekt", projectTitle]);
		worksheet.addRow(["Mitarbeiter", userNames]);
		worksheet.addRow(["Phasen", phaseNames]);
		const startDateRow = worksheet.addRow(["Startdatum", startDateDocument ? convertLocalToUTCDate(startDateDocument) : "k.A."]);
		const endDateRow = worksheet.addRow(["Enddatum", endDateDocument ? convertLocalToUTCDate(endDateDocument) : "k.A."]);
		worksheet.addRow(["Erstellungszeitpunkt", format(new Date(), "dd.MM.yyyy HH:mm") + " h"]);

		// Align start and end dates to the left
		startDateRow.getCell(2).alignment = { horizontal: "left" };
		endDateRow.getCell(2).alignment = { horizontal: "left" };

		// Add an empty row for spacing
		worksheet.addRow([]);

		// Add table headers
		const headerRowNumber = worksheet.rowCount + 1;
		const headers = timeTrackingsTableColumnDefs.map(col => col.header);
		worksheet.addRow(headers);

		// Prepare usersActiveStaffings rows
		const rows = filteredTableData.map((row: TimeTrackingExcelData) => {
			const hours = roundToTwoDecimals(row.minutesTracked / 60);
			return [
				row.userFullName,
				row.projectRoleDisplayName,
				row.phaseTitle,
				row.orderTitle,
				row.date,
				row.dailyRateCents / 100,
				hours,
				{ formula: `F${worksheet.rowCount + 1} * G${worksheet.rowCount + 1} / 8` }, // Calculate cost using formula
			];
		});

		// Create an Excel table
		const tableEndRowNumber = headerRowNumber + rows.length;
		const tableEndColumnLetter = String.fromCharCode(64 + headers.length);
		const tableName = "TimeTrackingsTable";
		const tableRef = `A${headerRowNumber}:${tableEndColumnLetter}${tableEndRowNumber}`;

		const table = worksheet.addTable({
			name: tableName,
			ref: tableRef,
			columns: timeTrackingsTableColumnDefs.map(col => ({ name: col.header })),
			rows: rows,
			style: {
				theme: "TableStyleMedium2",
				showRowStripes: true,
			},
			totalsRow: false,
		});
		console.log(table);
		// Make table sortable and filterable
		// @ts-expect-error type definitions are missing these properties
		table.table.columns.forEach(column => {
			column.filterButton = true;
		});

		// Apply cell formatting
		timeTrackingsTableColumnDefs.forEach((colDef, index) => {
			const column = worksheet.getColumn(index + 1);
			let numFmt: string | undefined;
			switch (colDef.type) {
				case "currency":
					numFmt = "#,##0.00 \"€\"";
					break;
				case "date":
					numFmt = "dd.mm.yyyy";
					break;
				case "number":
					numFmt = "#,##0.00";
					break;
			}
			if (numFmt) {
				column.eachCell({ includeEmpty: true }, cell => {
					cell.numFmt = numFmt!;
				});
			}
		});

		// Add sum row with formulas
		const sumRow = worksheet.addRow([
			"", "", "", "", "", "Summe Stunden",
			{ formula: `SUBTOTAL(9,G${headerRowNumber + 1}:G${tableEndRowNumber})` },
			{ formula: `SUBTOTAL(9,H${headerRowNumber + 1}:H${tableEndRowNumber})` },
		]);
		sumRow.font = { bold: true };

		// Add total days row with formula
		const totalDaysRow = worksheet.addRow([
			"", "", "", "", "", "Summe Tage",
			{ formula: `G${sumRow.number} / 8` },
			"",
		]);
		totalDaysRow.font = { bold: true };

		// Format sum and total days rows
		[sumRow, totalDaysRow].forEach(row => {
			row.eachCell((cell, colNumber) => {
				const column = timeTrackingsTableColumnDefs[colNumber - 1];
				if (column) {
					switch (column.type) {
						case "currency":
							cell.numFmt = "#,##0.00 \"€\"";
							break;
						case "number":
							cell.numFmt = "#,##0.00";
							break;
					}
				}
				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;
			// @ts-expect-error - TS doesn't know about the includeEmpty option
			column.eachCell({ includeEmpty: true }, (cell) => {
				const cellLength = cell.value ? cell.value.toString().length : 10;
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});
			if (column) {
				column.width = Math.min(maxLength + 2, 30);
			}
		});

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = `${projectTitle} - Erfassungen.xlsx`;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton onClick={handleDownload} />;
};

export default DownloadProjectsTimeTrackingsTableDataButton;