import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getStaffMembersUtilisationReport,
	getYearlyUtilisationReport,
} from "~/modules/reports/api/utilisationReport/utilisationReportApiDispatchers.ts";

export const UTILISATION_REPORT_BASE_QUERY_KEY = "utilisation-report";

export const YEARLY_UTILISATION_REPORT_QUERY_KEY = (year: string) => [UTILISATION_REPORT_BASE_QUERY_KEY, year];
export const STAFF_MEMBERS_YEARLY_UTILISATION_REPORT_QUERY_KEY = (staffMemberId: string,
	year: number) => [UTILISATION_REPORT_BASE_QUERY_KEY, staffMemberId, year];
export const useYearlyUtilisationReport = ({ year, options }: {
	year: string,
	options?: QueriesOptions<any>
}) => useQuery(
	YEARLY_UTILISATION_REPORT_QUERY_KEY(year),
	() => getYearlyUtilisationReport(year).then(getDataFromResponse),
	options,
);

export const useStaffMembersYearlyUtilisationReport = ({ staffMemberId, year, options }: {
	staffMemberId: string,
	year: number,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_YEARLY_UTILISATION_REPORT_QUERY_KEY(staffMemberId, year),
	() => getStaffMembersUtilisationReport(year, staffMemberId).then(getDataFromResponse),
	options,
);