import clsx from "clsx";
import type { MouseEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import type { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";

import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ComboBox from "~/components/formElements/ComboBox";
import FormInputError from "~/components/formElements/FormInputError";
import RestrictedNumberInput from "~/components/formElements/RestrictedNumberInput";
import Textarea from "~/components/formElements/Textarea";
import GlobeIcon from "~/components/icons/GlobeIcon";
import SpeechBubbleIcon from "~/components/icons/SpeechBubbleIcon";
import StopwatchIcon from "~/components/icons/StopwatchIcon";
import { useAuth } from "~/contexts/AuthContext";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import type { Location, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import type { SelectedStaffingDataType } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import type { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import Deliverables from "~/modules/timeTracking/components/components/Deliverables";
import Locations from "~/modules/timeTracking/components/components/Locations";
import HeaderWithIcon from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/components/HeaderWithIcon";
import {
	DurationSliderPlain,
} from "~/modules/timeTracking/components/forms/formSections/TimeTrackingFormSection/components/DurationSlider/DurationSlider.tsx";
import MinuteSelect
	from "~/modules/timeTracking/components/forms/formSections/TimeTrackingFormSection/components/MinuteSelect";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type TimeTrackingFormSectionProps = {
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	control: Control<TimeTrackingFormData>;
	errors: FieldErrors<TimeTrackingFormData>;
	isEditForm: boolean;
	isSubmitted: boolean;
	locations: Location[];
	selectedStaffingData: SelectedStaffingDataType | null;
	selectedTimeTrackingTypeId: string | null;
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum) => void;
	setValue: UseFormSetValue<TimeTrackingFormData>;
	selectedUserId: string;
	trigger: UseFormTrigger<TimeTrackingFormData>;
	users: User[];
	watch: UseFormWatch<TimeTrackingFormData>;
};

const TimeTrackingFormSection: React.FunctionComponent<TimeTrackingFormSectionProps> = ({
	availableDeliverables,
	availableLocations,
	control,
	errors,
	isEditForm,
	isSubmitted,
	locations,
	selectedStaffingData,
	selectedTimeTrackingTypeId,
	setCurrentFormName,
	setValue,
	selectedUserId,
	trigger,
	users,
	watch,
}) => {
	const locationOptions = useLocationOptions();
	const timeTrackingTextValue = watch("text");
	const { user: authenticatedUser } = useAuth();
	const sortAlphabetically = (a: any, b: any) => a.displayName.localeCompare(b.displayName);

	const selectedStaffingProjectId = selectedStaffingData?.projectId;

	// this is necessary to correctly display the error message when both minutes and hours are 0
	// @toDo Find a better solution
	const minutes = watch("minutes");
	const hours = watch("hours");

	useEffect(() => {
		if (isEditForm || isSubmitted) {
			trigger("hours");
		}
	}, [minutes, hours, trigger, isSubmitted, isEditForm]);

	const remainingManDays = useMemo(() => {
		if (selectedStaffingData) {
			const manDaysSelected = minutesToWorkdays(minutes + hours * 60, 1);
			return (
				selectedStaffingData.manDaysPlanned -
				minutesToWorkdays(selectedStaffingData?.minutesTracked, 1) -
				manDaysSelected
			);
		}
		return 0;
	}, [selectedStaffingData, minutes, hours]);


	const locationsData = useMemo(() => {
		const selectedLocationId = users.find((u) => u.id === selectedUserId)?.locationId;

		const preFilteredLocations = availableLocations.filter(
			(l) => selectedTimeTrackingTypeId === l.timeTrackingTypeId && l.id !== selectedLocationId,
		);

		return [
			[locations.find((l) => l.id === selectedLocationId)?.displayName],
			preFilteredLocations
				.filter((l) => l.userId === selectedUserId && l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId === selectedUserId && !l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId !== selectedUserId && l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId !== selectedUserId && !l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
		];
	}, [users, availableLocations, locations, selectedUserId, selectedTimeTrackingTypeId]);

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - deliverables
	const deliverablesData = useMemo(() => {
		const preFilteredAvailableDeliverables = availableDeliverables.filter((deliverable) => {
			return (
				(!selectedStaffingProjectId || selectedStaffingProjectId === deliverable.projectId) &&
				selectedTimeTrackingTypeId === deliverable.timeTrackingTypeId
			);
		});

		return [
			preFilteredAvailableDeliverables
				.filter((d) => d.userId === selectedUserId && d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId === selectedUserId && !d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId !== selectedUserId && d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId !== selectedUserId && !d.isFavorite)
				.sort(sortAlphabetically),
		];
	}, [availableDeliverables, selectedStaffingProjectId, selectedTimeTrackingTypeId, selectedUserId]);

	const handeLocationClick = useCallback(
		(value: string, event: MouseEvent) => {
			event.preventDefault();
			setValue("locationName", value, { shouldDirty: true });
			trigger("locationName");
		},
		[setValue, trigger],
	);

	const handleDeliverableClick = useCallback(
		(value: string, event: MouseEvent) => {
			event.preventDefault();
			const index = timeTrackingTextValue?.indexOf(value) ?? -1;
			const isTrimmedValueEqual = timeTrackingTextValue?.trim() === value;

			if (index >= 0 || isTrimmedValueEqual) {
				let newValue = timeTrackingTextValue?.replace(new RegExp(`\\s*${value}\\s*`, "g"), "") ?? "";
				newValue = newValue.replace(",,", ",").replace(/^,|,$| $/g, "");

				setValue("text", newValue.trim(), { shouldDirty: true });
			} else {
				const newValue = timeTrackingTextValue?.trim().length === 0 ? value : `${timeTrackingTextValue}, ${value}`;
				setValue("text", newValue, { shouldDirty: true });
			}

			trigger("text");
		},
		[timeTrackingTextValue, trigger, setValue],
	);

	const text = watch("text");

	const durationSliderMarks = useMemo(() => {
		const startHour = 0;
		const endHour = 8;

		return Array.from({ length: endHour - startHour + 1 },
			(_, i) => startHour + i).map((hour) => {
			return {
				value: hour * 60,
				label: hour.toString(),
			};
		});

	}, []);

	const handleDurationSliderChange = useCallback((totalMinutes: number) => {
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		setValue("hours", hours, { shouldDirty: true });
		setValue("minutes", minutes, { shouldDirty: true });
		trigger("hours");
	}, [setValue, trigger]);

	return (
		<><HeaderWithIcon icon={<StopwatchIcon className="w-7 fill-primary-700" />}>Zeiterfassung</HeaderWithIcon>
			<div className="mb-0 mt-2 flex flex-row items-center gap-2 gap-x-10">
				<div className="flex flex-row items-center justify-center gap-2">
					<div className="w-[3.6rem]">
						<RestrictedNumberInput
							autoFocus={true}
							minValue={0}
							maxValue={23}
							name={"hours"}
							control={control}
							placeholder="08"
							className="text-center text-xl text-primary-900"
						/>
					</div>
					<span className="font-bold text-primary-500">h</span>
					<MinuteSelect name="minutes"
								  control={control} />
					<span className="font-bold text-primary-500">m</span>
				</div>
				<DurationSliderPlain
					value={minutes + hours * 60}
					hourMarks={durationSliderMarks}
					onChange={handleDurationSliderChange}
				/>
			</div>
			<div className="block">
				{(isEditForm || isSubmitted) && errors.hours && <FormInputError>{errors.hours.message}</FormInputError>}
			</div>

			{selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ?
				<div className="mb-2 mt-4 flex items-center justify-start gap-x-1 text-xs text-gray-500">
					<span>Verbleibende Tage: </span><span className={clsx(remainingManDays >= 0 ? "text-gray-500" : "text-danger-500")}>{formatNumberWithComma(remainingManDays || 0, 2)} PT</span>
				</div> : <div className="h-6" />}

			{selectedTimeTrackingTypeId !== TimeTrackingTypeId.PartTime &&
				<>
					<div className="h-2" />
					<HeaderWithIcon icon={<GlobeIcon className="w-7  fill-primary-700" />}>Ort</HeaderWithIcon>
					<ComboBox
						control={control}
						name="locationName"
						optionsData={locationOptions}
						error={errors.locationName?.message}
					/>
					<div className="mt-2 flex flex-row flex-wrap items-center justify-start gap-2">
						<Locations data={locationsData}
								   onClick={handeLocationClick} />
						{selectedUserId === authenticatedUser?.id && <div>
							<ButtonNewItem onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.LOCATION_FORM)}
										   size="sm" />
						</div>}
					</div>
					<div className="h-10" />
					<HeaderWithIcon icon={
						<SpeechBubbleIcon className="w-7 fill-primary-700 pt-[0.3rem]" />}>Buchungstext</HeaderWithIcon>
					<Textarea
						control={control}
						name="text"
					/>
					<div className="mt-2 flex flex-row flex-wrap items-center justify-start gap-2">
						{deliverablesData.some((deliverable) => 0 < deliverable.length) && (
							<>
								<Deliverables
									currentDeliverablesString={text}
									data={deliverablesData}
									onClick={handleDeliverableClick} />
							</>
						)}
						{selectedUserId === authenticatedUser?.id && <div>
							<ButtonNewItem onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.DELIVERABLE_FORM)}
										   size="sm" />
						</div>}
					</div>
				</>}
		</>
	);
};

export default TimeTrackingFormSection;
