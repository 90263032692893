import { useMemo } from "react";

import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useStaffMemberSelectOptions = (): FormInputOption[] => {
	const { data: allStaffMembersData } = useAllStaffMembers();
	return useMemo(() => {
		if (allStaffMembersData) {
			return allStaffMembersData.sort(byObjectProperty("fullName")).map((staffMember) => {
				return createInputOptionData(staffMember.fullName, staffMember.id);
			});

		}
		return [];
	}, [allStaffMembersData]);
};

export default useStaffMemberSelectOptions;

