import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllQuotas, getQuota, indexQuotasByClientId } from "~/modules/project/api/quota/quotaApiDispatchers.ts";

const ENTITY_QUERY_KEY = "quota";

export const QUOTA_QUERY_KEY = (quotaId: string) => [ENTITY_QUERY_KEY, quotaId];
export const ALL_QUOTAS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const QUOTAS_BY_CLIEBT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];
export const useQuota = (clientId: string, quotaId: string, options?: QueriesOptions<any>) => useQuery(
	QUOTA_QUERY_KEY(quotaId),
	() => getQuota(clientId, quotaId).then(getDataFromResponse),
	options,
);

export const useAllQuotas = (options?: QueriesOptions<any>) => useQuery(
	ALL_QUOTAS_QUERY_KEY,
	() => getAllQuotas().then(getDataFromResponse),
	options,
);

export const useClientsQuotas = (clientId: string, options?: QueriesOptions<any>) => useQuery(
	QUOTAS_BY_CLIEBT_QUERY_KEY(clientId),
	() => indexQuotasByClientId(clientId).then(getDataFromResponse),
	options,
);