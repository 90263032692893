import { useMemo } from "react";

import { DEFAULT_MIN_YEAR_FOR_YEAR_SELECT } from "~/constants/config.ts";
import type { FormInputOption } from "~/types/form.ts";
import {
	createInputOptionData,
} from "~/utils/form/formUtils.ts";

const useYearSelectOptions = (minYear = DEFAULT_MIN_YEAR_FOR_YEAR_SELECT, maxYear: number): FormInputOption[] => {
	if (!maxYear) {
		throw new Error("maxYear is required");
	}
	return useMemo(() => {
		const options: FormInputOption[] = [];
		while (minYear <= maxYear) {
			options.push(createInputOptionData(minYear.toString(), minYear.toString()));
			minYear++;
		}
		return options;

	}, [maxYear, minYear]);
};

export default useYearSelectOptions;

