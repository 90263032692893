import { t } from "i18next";

import { TaxClassId, TaxClassName } from "~/modules/humanResources/api/taxClass/taxClassTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getTaxClassDisplayNameById(careerLevelId: TaxClassId): string {
	let taxClassName = "";

	switch (careerLevelId) {
		case TaxClassId.I:
			taxClassName = TaxClassName.I;
			break;
		case TaxClassId.II:
			taxClassName = TaxClassName.II;
			break;
		case TaxClassId.III:
			taxClassName = TaxClassName.III;
			break;
		case TaxClassId.IV:
			taxClassName = TaxClassName.IV;
			break;
		case TaxClassId.V:
			taxClassName = TaxClassName.V;
			break;
		default:
			throw new Error("Unknown career level id");
	}
	if (!taxClassName) {
		return "unbekannt";
	}

	return t(normalizeTKey(`entities:taxClass.${taxClassName}`));
}