import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useClipboard } from "@mantine/hooks";
import clsx from "clsx";
import ProjectTag
	from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { useRef } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Card from "~/components/Card";
import CompanyLogo from "~/components/CompanyLogo";
import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import type { ProjectDescriptionCardData } from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { formatManDays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";

type Props = {
	projectData: ProjectDescriptionCardData;
};

const ProjectDescriptionResultCard: FunctionComponent<Props> = ({ projectData }) => {
	const clipboard = useClipboard({ timeout: 1000 });
	const textElementRef = useRef<HTMLDivElement>(null);

	const handleCopyToClipboardClick = () => {
		if (textElementRef.current) {
			clipboard.copy(textElementRef.current.innerText);
		}
	};

	return (
		<div>
			<div className={clsx("group/infobox group/summary")}>
				<div className="mb-1 flex flex-wrap items-center gap-x-4">
					{projectData.companyAvatarImage && <CompanyLogo image={projectData.companyAvatarImage}/> }
					<div>
						<div className={clsx("flex items-center gap-2",
							"text-[1.375rem] font-bold text-primary-500",
							"transition-colors duration-200 group-hover/summary:text-secondary-500",
						)}
						>
							<NavLink to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId: projectData.id })}>{projectData.title}</NavLink>
						</div>
						<div className="text-sm text-primary-500">
							{projectData.clientDisplayName} | {projectData.projectNumber} | {formatManDays(parseInt(projectData.manDaysPlanned))} PT
						</div>
					</div>
				</div>
				<div className={clsx(
					"grid grid-cols-5 gap-x-4",
					"transition-colors duration-200",
				)}>
					<div className="relative col-span-3 group-hover/infobox:shadow-md">
						{clipboard.copied &&
							<div className="absolute right-0 z-10 mr-4 mt-3 flex items-center gap-x-2 rounded-lg bg-accent-500 p-1 pr-2 text-sm text-white">
								<CheckCircleIcon className="w-5" /> Text in Zwischenablage kopiert
							</div>}
						{projectData.descriptionHtml ? (
								<button type="button"
										className="size-full text-left"
										onClick={handleCopyToClipboardClick}>
									<Card height="full">
										<div className="size-full text-sm"
											 ref={textElementRef}
											 tabIndex={0}
											 dangerouslySetInnerHTML={{ __html: projectData.descriptionHtml }} />
									</Card>
								</button>) :
							<div className="size-full cursor-not-allowed"><Card height="full">
								<span className="text-sm text-gray-500">--</span>
							</Card></div>}
					</div>
					<div className="col-span-2 group-hover/infobox:shadow-md">
						<Card height="full">
							<div className="flex flex-wrap gap-2">
								{projectData.productTags.map((tag) => {
									return <ProjectTag key={"product" + tag.id}
													   displayName={tag.displayName}
													   muted={projectData.matchedProductTagIds.length > 0 ? !projectData.matchedProductTagIds.includes(tag.id) : true}
													   theme="product" />;
								})}
								{projectData.deliverableTags.map((tag) => {

									return <ProjectTag key={"deliverable" + tag.id}
													   displayName={tag.displayName}
													   muted={projectData.matchedDeliverableTagIds.length > 0 ? !projectData.matchedDeliverableTagIds.includes(tag.id) : true}
													   theme="deliverable" />;
								})}
							</div>
						</Card>
					</div>
				</div>
			</div>
		</div>);

};

export default ProjectDescriptionResultCard;