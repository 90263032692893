import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import type { TableHeaderBgTheme } from "~/components/Table/tableTypes.ts";


type TableHeaderCellProps = PropsWithChildren & {
	bgColor?: TableHeaderBgTheme;
	cellClassName?: string;
	className?: string;
	rounded?: boolean;
	textAlign?: "left" | "center" | "right";
};

const TableFooterCell: FunctionComponent<TableHeaderCellProps> = ({
	bgColor = "light",
	children,
	cellClassName,
	className,
	textAlign = "center",
	rounded = true,
}) => {
	let textAlignClass = "justify-center";
	if (textAlign === "left") {
		textAlignClass = "justify-start";
	}
	if (textAlign === "right") {
		textAlignClass = "justify-end";
	}

	return (
		<th className={clsx("font-bold text-gray-700",
			cellClassName,
			bgColor === "light" ? "bg-white" : "bg-gray-500",
			rounded ? "pt-3" : "py-3",
		)}>
			<div className={clsx("relative flex items-center whitespace-nowrap px-4 text-xs", textAlignClass, className)}>
				{children}
			</div>
		</th>);
};

export default TableFooterCell;