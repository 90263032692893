import {useEffect} from 'react';

type Callback = () => void;

const useOnClickOutside = (ref: React.RefObject<HTMLElement>, callback: Callback, enabled=true) => {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {

            if (enabled && ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref, callback, enabled]);
};

export default useOnClickOutside;
