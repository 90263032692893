import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { Company, CreateCompanyData, UpdateCompanyData } from "~/modules/client/api/company/companyTypes.ts";

const companyBaseApiUrl = "companies";

export const getCompany = (companyId: string): AxiosApiResponsePromise<Company> => axiosApi.get(`companies/${companyId}`);
export const getAllCompanies = (): AxiosApiResponsePromise<Company[]> => axiosApi.get("companies");

export const createCompany = (data: CreateCompanyData): AxiosApiResponsePromise<Company> => axiosApi.post("companies", { ...data });

export function updateCompany({ companyId, data }: {
	companyId: string,
	data: UpdateCompanyData
}): AxiosApiResponsePromise<Company> {
	return axiosApi.put(`companies/${companyId}`, { ...data });
}

export function uploadCompanyLogo(companyId: string, formData: FormData): AxiosApiResponsePromise<void> {
	return axiosApi.post(`${companyBaseApiUrl}/${companyId}/logo`, formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
}

export function deleteCompanyLogo(companyId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${companyBaseApiUrl}/${companyId}/logo`);
}