import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const labelVariants = tv({
	base: "block font-medium text-gray-500",
	variants: {
		theme:{
			dark: "text-gray-200",
			light: "text-gray-500",
		},
		lineHeight: {
			none: "",
			sm: "leading-3",
			md: "leading-6",
		},
		size: {
			sm: "text-xs",
			md: "text-sm",
		},
	},
	defaultVariants: {
		theme: "light",
		lineHeight: "md",
		size: "md",
	},
});


export type LabelVariants = VariantProps<typeof labelVariants>;
