import {ReactComponent as Icon} from "./brainIcon.svg"

type StopwatchIconProps = {
	className?: string;
};

const BrainIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default BrainIcon;