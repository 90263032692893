import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberCareerLevelData,
	StaffMemberCareerLevel,
	UpdateStaffMemberCareerLevelData,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelTypes.ts";

const staffMembersCareerLevelsBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/career-levels`;

export function indexStaffMembersCareerLevels(staffMemberId: string): AxiosApiResponsePromise<StaffMemberCareerLevel[]> {
	return axiosApi.get(staffMembersCareerLevelsBasePath(staffMemberId));
}

export function createStaffMemberCareerLevel(staffMemberId: string,
	careerLevelData: CreateStaffMemberCareerLevelData): AxiosApiResponsePromise<void> {
	return axiosApi.post(staffMembersCareerLevelsBasePath(staffMemberId), careerLevelData);
}

export function updateStaffMemberCareerLevel({ staffMemberId, careerLevelId, updateData }: {
	staffMemberId: string;
	careerLevelId: string;
	updateData: UpdateStaffMemberCareerLevelData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${staffMembersCareerLevelsBasePath(staffMemberId)}/${careerLevelId}`, updateData);
}

export function deleteStaffMemberCareerLevel(staffMemberId: string, careerLevelId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${staffMembersCareerLevelsBasePath(staffMemberId)}/${careerLevelId}`);
}