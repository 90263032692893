import clsx from "clsx";
import type { CSSProperties, FunctionComponent, PropsWithChildren } from "react";

import Headline from "~/components/Headline/Headline";

interface Props {
	title?: string;
	theme?: "default" | "dark" | "none";
	sticky?: boolean;
	style?: CSSProperties;
}

const SectionHeading: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	title,
	theme = "default",
	sticky,
	style,
}) => (
	<div
		className={clsx(
			"border-b pb-5 sm:pb-0",
			{
				"bg-gray-100 border-gray-200": "default" === theme,
				"border-gray-300": "dark" === theme,
				"border-transparent": "none" === theme,
			},
			undefined === sticky ? "relative" : "sticky z-10 shadow-xl shadow-gray-100",
		)}
		style={style}
	>
		{title ? (
			<div className="md:flex md:items-center md:justify-between">
				<Headline type="h5">{title}</Headline>
			</div>
		) : (
			<div className="pt-1">{children}</div>
		)}
	</div>
);

export default SectionHeading;
