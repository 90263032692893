import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberVariableCompensationPercentageData,
	StaffMemberVariableCompensationPercentage,
	UpdateStaffMemberVariableCompensationPercentageData,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageTypes.ts";

const variableCompensationPercentageBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/variable-compensation-percentages`;

export function indexStaffMembersVariableCompensationPercentages(staffMemberId: string): AxiosApiResponsePromise<StaffMemberVariableCompensationPercentage[]> {
	return axiosApi.get(variableCompensationPercentageBasePath(staffMemberId));
}

export function createStaffMemberVariableCompensationPercentage(staffMemberId: string,
	variableCompensationData: CreateStaffMemberVariableCompensationPercentageData): AxiosApiResponsePromise<void> {
	return axiosApi.post(variableCompensationPercentageBasePath(staffMemberId), variableCompensationData);
}

export function updateStaffMemberVariableCompensationPercentage({ staffMemberId, variableCompensationId, updateData }: {
	staffMemberId: string;
	variableCompensationId: string;
	updateData: UpdateStaffMemberVariableCompensationPercentageData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${variableCompensationPercentageBasePath(staffMemberId)}/${variableCompensationId}`, updateData);
}

export function deleteStaffMemberVariableCompensationPercentage(staffMemberId: string,
	variableCompensationId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${variableCompensationPercentageBasePath(staffMemberId)}/${variableCompensationId}`);
}