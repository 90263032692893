import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";



export const TableFooter: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({
	className,
	children,
}) => {
	return <tfoot className={clsx("sticky bottom-0 z-20", className)}>{children}</tfoot>;
};

export default TableFooter;