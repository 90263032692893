import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import type { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

import type { AppRoute} from "~/constants/appRoute.ts";
import { appRoutes } from "~/constants/appRoute.ts";

interface Props {
	pages: (AppRoute | string)[];
	className?: string;
}

const TextOnlyBreadcrumb: React.FC<PropsWithChildren> = ({ children }) => (
	<span className="ml-3 text-xs text-gray-500 text-opacity-50">{children}</span>
);

const Breadcrumbs: React.FC<Props> = ({ pages }) => {
	return (
		<ol role="list"
			className="flex items-center space-x-3">
			<li>
				<div>
					<NavLink to={appRoutes.base().path}
							 className="text-gray-500 hover:text-gray-300">
						<HomeIcon className="size-4 shrink-0"
								  aria-hidden="true" />
						<span className="sr-only">Home</span>
					</NavLink>
				</div>
			</li>
			{pages.map((page, pageIndex) => {
				let content = null;
				if (typeof page === "string") {
					content = <TextOnlyBreadcrumb>{page}</TextOnlyBreadcrumb>;
				} else {
					if (pages.length - 1 === pageIndex) {
						content = <TextOnlyBreadcrumb>{page.displayName}</TextOnlyBreadcrumb>;
					} else {
						content = (
							<NavLink
								to={page.path}
								className="ml-3 text-xs font-medium tracking-wide text-gray-500 hover:text-gray-700"
							>
								{page.displayName}
							</NavLink>
						);
					}
				}

				return (
					<li key={`page-${pageIndex}`}>
						<div className="flex items-center">
							<ChevronRightIcon
								className="size-5 shrink-0 text-gray-500"
								aria-hidden="true"
							/>
							{content}
						</div>
					</li>
				);
			})}
		</ol>

	);
};
export default Breadcrumbs;
