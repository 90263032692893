import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils";
import { getAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeApiDispatchers.ts";

const ENTITY_QUERY_KEY = "timeTrackingType";

export const ALL_TIME_TRACKING_TYPES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllTimeTrackingTypes = (options?: QueriesOptions<any>) => useQuery(
	ALL_TIME_TRACKING_TYPES_QUERY_KEY,
	() => getAllTimeTrackingTypes().then(getDataFromResponse),
	options,
);