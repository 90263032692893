import { isSameDay } from "date-fns";
import type { Message} from "yup";
import { addMethod, date, string } from "yup";

import {
	sanitiseTitleForComparison,
	sanitizeEmailForComparison,
	sanitizeStringForComparison,
} from "~/utils/form/formUtils.ts";

export type UniqueValidatorArrayObject = {
	id: string;
	value: string;
}

export type UniqueDateValidatorArrayObject = {
	id: string;
	value: Date;
};

// PLEASE NOTE: If you add a new validator, you need to add its type to "~types/yup.custom.d.ts"

function validateUnique(comparisonArray: UniqueValidatorArrayObject[],
	sanitizeFunction: (value: string) => string,
	value?: string,
	id?: string) {
	if (!value) return true;
	// If comparisonArray is not provided or not an comparisonArray, skip uniqueness check
	if (!Array.isArray(comparisonArray)) {
		return true;
	}
	const sanitizedValue = sanitizeFunction(value);

	const existingItem = comparisonArray.find(item => item.value === sanitizedValue);

	return !existingItem || existingItem.id === id;
}

function validateUniqueDay(comparisonArray: UniqueDateValidatorArrayObject[],
	value?: Date,
	id?: string) {
	if (!value) return true;
	// If comparisonArray is not provided or not an comparisonArray, skip uniqueness check
	if (!Array.isArray(comparisonArray)) {
		return true;
	}

	const existingItem = comparisonArray.find(item => isSameDay(item.value, value));

	return !existingItem || existingItem.id === id;
}

export function addCustomValidators() {
	addMethod(string, "unique", function(comparisonArray: UniqueValidatorArrayObject[],
		id?: string,
		message?: Message) {
		return this.test("unique", message || "Dieser Wert wird bereits verwendet.", function(value) {
			const { createError } = this;

			const isUnique = validateUnique(comparisonArray, sanitizeStringForComparison, value, id);

			return isUnique || createError({ message });
		});
	});

	addMethod(string, "uniqueEmail", function(comparisonArray: UniqueValidatorArrayObject[],
		id?: string,
		message?: Message) {
		return this.test("uniqueEmail", message || "Dieser Wert wird bereits verwendet.", function(value) {
			const { createError } = this;
			const isUnique = validateUnique(comparisonArray, sanitizeEmailForComparison, value, id);
			return isUnique || createError({ message });
		});
	});

	addMethod(date, "uniqueDay", function(comparisonArray: UniqueDateValidatorArrayObject[],
		id?: string,
		message?: Message) {
		return this.test("uniqueDay", message || "Dieser Wert wird bereits verwendet.", function(value) {
			const { createError } = this;
			const isUnique = validateUniqueDay(comparisonArray, value, id);
			return isUnique || createError({ message });
		});
	});

	addMethod(string, "uniqueProjectTitle", function(comparisonArray: UniqueValidatorArrayObject[],
		id?: string,
		message?: Message) {
		return this.test("uniqueProjectTitle", message || "Dieser Wert wird bereits verwendet.", function(value) {
			const { createError } = this;
			const isUnique = validateUnique(comparisonArray, sanitiseTitleForComparison, value, id);
			return isUnique || createError({ message });
		});
	});

	addMethod(string, "dateString", function() {
		return this.test("test-date", "Ungültiges Datum", function(value) {
			const { path, createError } = this;
			if (!value) return true; // allow empty values (e.g. for optional fields
			const dateValue = new Date(value);
			if (isNaN(dateValue.getTime())) {
				return createError({ path, message: "Invalid date" });
			}
			return true;  // validation succeeded
		});
	});
}

