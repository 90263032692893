import clsx from "clsx";
import type React from "react";
import { useEffect, useRef,useState } from "react";

import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";


interface ResponsiveChartBarProps {
	percentage: number;
	value: number;
	className: string;
	showBudget?: boolean;
}

const ResponsiveChartBar: React.FC<ResponsiveChartBarProps> = ({ percentage, value, className, showBudget }) => {
	const [textInside, setTextInside] = useState<boolean>(true);
	const [firstRender, setFirstRender] = useState<boolean>(true);
	const barRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLSpanElement | null>(null);
	const formatter = showBudget ? formatCentsToCurrency : (value: number) => formatNumberWithComma(value, 2) + " PT";
	const formattedValue = value === 0 ? "0" :formatter(value);

	useEffect(() => {
		if (firstRender) {
			// Skip the logic during the very first render, and force a re-render.
			setFirstRender(false);
			return;
		}

		const barWidth = barRef.current?.offsetWidth || 0;
		const textWidth = textRef.current?.offsetWidth || 0;
		setTextInside(barWidth > textWidth + 10);

	}, [percentage, firstRender]);

	return (
		<div className={clsx("relative h-4 rounded-full bg-blue-400", className)}
			 style={{ width: `${percentage}%` }}
			 ref={barRef}>
			{value !== 0 ?
				<span className={clsx("absolute flex h-full items-center text-xs", textInside ? "right-2 text-white" : "right-0 -mr-2 min-w-14 translate-x-full text-gray-800")}
					  ref={textRef}>
				{formattedValue}
			  </span> : <span className="absolute flex h-full min-w-fit items-center text-xs text-gray-800">{formattedValue}</span>}
		</div>
	);
};

export default ResponsiveChartBar;