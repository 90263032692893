import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusApiDispatchers.ts";

const ENTITY_QUERY_KEY = "workingStatus";

export const ALL_WORKING_STATUSES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllWorkingStatuses = (options?: QueriesOptions<any>) => useQuery(
	ALL_WORKING_STATUSES_QUERY_KEY,
	() => getAllWorkingStatuses().then(getDataFromResponse),
	options,
);