import clsx from "clsx";
import type { KeyboardEvent } from "react";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

interface Mark {
	label: string;
	value: number;
}

type SharedPropsType = {
	marks?: Mark[];
	label?: string;
	error?: string;
	min: number;
	max: number;
	step: number;
	showValue?: boolean;
};

type SliderPlainProps = SharedPropsType & {
	name?: string;
	value?: number;
	onChange?: (value: number) => void;
	onBlur?: () => void;
};

export const SliderPlain = forwardRef<HTMLDivElement, SliderPlainProps>(
	({ marks, label, value = 0, onChange, onBlur, min, max, showValue, step }, forwardedRef) => {
		const [isDragging, setIsDragging] = useState(false);
		const [internalValue, setInternalValue] = useState(value);
		const internalRef = useRef<HTMLDivElement | null>(null);

		const sliderRef = useCallback(
			(node: HTMLDivElement | null) => {
				internalRef.current = node;
				if (typeof forwardedRef === "function") {
					forwardedRef(node);
				} else if (forwardedRef) {
					forwardedRef.current = node;
				}
			},
			[forwardedRef],
		);

		useEffect(() => {
			const sanitisedValue = Math.min(Math.max(value, min), max);
			setInternalValue(Math.round(sanitisedValue / step) * step);
		}, [value, min, max, step]);

		const getNearestValue = useCallback((percentage: number): number => {
			const exactValue = percentage * (max - min) + min;
			return Math.round(exactValue / step) * step;
		}, [min, max, step]);

		const updateInternalValue = useCallback((clientX: number) => {
			const sliderElement = internalRef.current;
			if (sliderElement) {
				const rect = sliderElement.getBoundingClientRect();
				const position = Math.max(0, Math.min(clientX - rect.left, rect.width));
				const percentage = position / rect.width;
				const newValue = getNearestValue(percentage);
				setInternalValue(newValue);
				if (onChange) {
					onChange(newValue);
				}
			}
		}, [getNearestValue, onChange]);

		const handleMouseDown = useCallback((e: React.MouseEvent) => {
			e.preventDefault(); // Prevent text selection
			setIsDragging(true);
			updateInternalValue(e.clientX);
		}, [updateInternalValue]);

		const handleMouseMove = useCallback((e: MouseEvent) => {
			if (isDragging) {
				updateInternalValue(e.clientX);
			}
		}, [isDragging, updateInternalValue]);

		const handleMouseUp = useCallback(() => {
			setIsDragging(false);
		}, []);

		useEffect(() => {
			if (isDragging) {
				document.addEventListener("mousemove", handleMouseMove);
				document.addEventListener("mouseup", handleMouseUp);
			}

			return () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
			};
		}, [isDragging, handleMouseMove, handleMouseUp]);

		const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
			let newValue = internalValue;

			switch (e.key) {
				case "ArrowLeft":
					newValue = Math.max(min, internalValue - step);
					break;
				case "ArrowRight":
					newValue = Math.min(internalValue + step, max);
					break;
				default:
					return;
			}

			if (newValue !== internalValue) {
				setInternalValue(newValue);
				onChange && onChange(newValue);
			}
		};

		const getLeftPosition = (value: number) => {
			return `${((value - min) / (max - min)) * 100}%`;
		};

		const getRightPosition = (value: number) => {
			return `${100 - ((value - min) / (max - min)) * 100}%`;
		};

		const formatValue = (value: number) => {
			return value.toFixed(2);
		};

		return (
			<div className="relative w-full select-none">
				{label && <label className="mb-1 block text-sm font-medium text-gray-700">{label}</label>}
				<div
					className={clsx("group relative h-20 w-full focus:outline-none focus:ring-0",
						isDragging ? "cursor-grabbing" : "cursor-pointer")}
					ref={sliderRef}
					onMouseDown={handleMouseDown}
					tabIndex={0}
					onKeyDown={handleKeyDown}
					onBlur={onBlur}
				>
					<div className="absolute top-1/2 h-2 w-full -translate-y-1/2 rounded-lg bg-gray-300" />
					<div className="absolute left-0 top-1/2 h-2 -translate-y-1/2 rounded-lg bg-primary-500"
						 style={{ right: getRightPosition(internalValue) }} />
					{marks && marks.map((mark, index) => {
						const leftPosition = getLeftPosition(mark.value);

						return <React.Fragment key={index}>
							<div className={clsx(
								"absolute bottom-0 -translate-x-1/2 whitespace-nowrap text-xs tracking-tighter",
								mark.value <= internalValue ? "font-bold text-gray-600" : "text-gray-400",
							)}
								 style={{ left: leftPosition }}
							>
								{mark.label}
							</div>
						</React.Fragment>;
					})}
					<div
						className="absolute top-1/2 size-5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary-500 shadow ring-4 ring-white group-focus:border-2 group-focus:border-primary-300"
						style={{ left: getLeftPosition(internalValue) }}
					>
						{showValue && <div className={clsx("absolute -top-full left-1/2 -mt-1 -translate-x-1/2 text-xs font-bold text-gray-500")}>
							{formatValue(internalValue)}
						</div>}
					</div>
				</div>
			</div>
		);
	},
);

SliderPlain.displayName = "SliderPlain";

type SliderProps = SharedPropsType & {
	name: string;
	control: Control<any>;
};

const Slider: React.FC<SliderProps> = ({ name, control, marks, label, min, max, step, ...rest }) => {
	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	});

	return (
		<SliderPlain
			ref={ref}
			value={value}
			onChange={onChange}
			{...inputProps}
			{...rest}
			marks={marks}
			label={label}
			error={error?.message}
			min={min}
			max={max}
			step={step}
		/>
	);
};

export default Slider;