import { Menu, MenuButton, MenuItems, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { ReactElement } from "react";

interface Props {
	items: {
		disabled?: boolean;
		icon?: ReactElement | any;
		label: string;
		onClick: () => void;
	}[];
	theme?: "default" | "dark";
}

const ButtonWithPopover: React.FC<Props> = ({ items, theme = "default" }) => {
	return (
		<Menu as="div"
			  className="relative flex text-left">
			<MenuButton
				className={clsx("flex items-center hover:text-gray-700", {
					"text-white": "default" === theme,
					"text-gray-500": "dark" === theme,
				})}
			>
				<span className="sr-only">Open options</span>
				<EllipsisVerticalIcon className="size-5"
									  aria-hidden="true" />
			</MenuButton>
			<Transition appear={true}>
				<MenuItems anchor="bottom end"
						   className="absolute right-0 z-40 w-56 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition
						   duration-100 ease-in-out focus:outline-none data-[closed]:scale-75 data-[closed]:opacity-0">
					<div>
						{items.map(({ label, icon, onClick, disabled }, itemIndex) => {
							const Icon = icon;
							return (
								<Menu.Item key={itemIndex}>
									{({ active }) => (
										<div
											onClick={onClick}
											className={clsx(
												active ? "bg-gray-100 text-gray-900" : "text-gray-700",
												"flex cursor-pointer px-4 py-2 text-sm",
												disabled && "pointer-events-none opacity-50",
											)}
										>
											{icon && <Icon className="mr-3 size-5 text-gray-400"
														   aria-hidden="true" />}
											<span>{label}</span>
										</div>
									)}
								</Menu.Item>
							);
						})}
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	);
};

export default ButtonWithPopover;
