import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import Button from "components/buttons/Button";
import AddContactPersonToClientSidebar from "modules/client/components/AddContactPersonToClientSidebar";
import AddProjectToClientSidebar from "modules/client/components/AddProjectToClientSidebar";
import ClientContactsList from "modules/client/components/ClientDetailsView/components/ClientContactsList";
import ClientsProjectsList from "modules/client/components/ClientDetailsView/components/ClientsProjectsList";
import UpdateClientSidebar from "modules/client/components/UpdateClientSidebar";
import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import CompanyLogo from "~/components/CompanyLogo";
import ContentWrapper from "~/components/ContentWrapper";
import SectionHeading from "~/components/headings/SectionHeading";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import Hint from "~/components/Hint";
import MainContent from "~/components/mainContent/MainContent";
import { heroSectionBackgroundColor } from "~/components/sections/HeroSection";
import Section from "~/components/sections/Section";
import { appRoutes, COMPANY_DETAILS_PATH } from "~/constants/appRoute.ts";
import type { InvoiceRecipientType } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import ClientsBillingDetails from "~/modules/client/components/ClientDetailsView/components/ClientsBillingDetails";
import ClientsInvoiceRecipientsList
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList";
import CreateInvoiceRecipientSidebar
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/CreateInvoiceRecipientSidebar";
import DeleteClientModal from "~/modules/client/components/DeleteClientModal";
import type { AvatarImageWithClientIds } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import type { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";

interface ClientViewProps {
	allCompanyAvatarImages?: AvatarImageWithClientIds[];
	clientData: Client;
	company: Company;
	contacts: ClientContactPersonType[];
	invoiceRecipients: InvoiceRecipientType[];
	projects: Project[];
	workingStatuses: WorkingStatusType[];
}

enum ClientDetailsTabId {
	BILLING_DETAILS = "billingDetails",
	CONTACTS = "contacts",
	INVOICE_RECIPIENTS = "invoiceRecipients",
	PROJECTS = "projects",
	QUOTAS = "quotas",
}

const ClientDetailsView: React.FC<ClientViewProps> = ({
	allCompanyAvatarImages,
	clientData,
	company,
	contacts,
	invoiceRecipients,
	projects,
	workingStatuses,
}) => {
	const [activeTabSection, setActiveTabSection] = useState<ClientDetailsTabId>(ClientDetailsTabId.CONTACTS);
	const [showNewContactForm, setShowNewContactForm] = useState(false);
	const [showNewInvoiceRecipientForm, setShowNewInvoiceRecipientForm] = useState(false);
	const [showEditForm, setShowEditForm] = useState(false);
	const [showDeletionModal, setShowDeletionModal] = useState(false);
	const [showAddProjectForm, setShowAddProjectForm] = useState(false);

	const popoverItems = useMemo(() => {
		return [
			{
				label: "bearbeiten",
				onClick: () => setShowEditForm(true),
				icon: PencilIcon,
			},
			{
				label: "löschen",
				onClick: () => setShowDeletionModal(true),
				icon: TrashIcon,
				disabled: contacts?.length > 0 || projects?.length > 0 || invoiceRecipients?.length > 0,
			},
		];
	}, [contacts, projects, invoiceRecipients]);
	const avatarImage = allCompanyAvatarImages?.find((image) => image.clientIds.includes(clientData.id));
	const addNewItemButtonTabSection = useMemo(() => {
		if (![ClientDetailsTabId.CONTACTS,
			ClientDetailsTabId.PROJECTS,
			ClientDetailsTabId.INVOICE_RECIPIENTS].includes(activeTabSection))
			return null;

		if (activeTabSection === ClientDetailsTabId.PROJECTS && projects.length === 0) return null;
		if (activeTabSection === ClientDetailsTabId.CONTACTS && contacts.length === 0) return null;
		if (activeTabSection === ClientDetailsTabId.INVOICE_RECIPIENTS && invoiceRecipients.length === 0) return null;

		let buttonText = "";
		let onClick = () => {
		};

		switch (activeTabSection) {
			case ClientDetailsTabId.CONTACTS:
				buttonText = "Neuer Kontakt";
				onClick = () => setShowNewContactForm(true);
				break;
			case ClientDetailsTabId.PROJECTS:
				buttonText = "Neues Projekt";
				onClick = () => setShowAddProjectForm(true);
				break;
			case ClientDetailsTabId.INVOICE_RECIPIENTS:
				buttonText = "Neuer Rechnungsempfänger";
				onClick = () => setShowNewInvoiceRecipientForm(true);
				break;
		}

		return <div>
			<Button size="sm"
					onClick={onClick}>
				<PlusIcon className="size-5"
						  aria-hidden="true" />
				{buttonText}
			</Button>
		</div>;

	}, [activeTabSection, projects, contacts, invoiceRecipients]);

	return (
		<MainContent>
			<BreadcrumbsSection pages={[appRoutes.clients(), {
				path: generatePath(COMPANY_DETAILS_PATH, { companyId: company.id }),
				displayName: company.displayName,
			}, "Firmen", clientData.displayName]}
								className={heroSectionBackgroundColor} />
			<Section className="bg-white pb-2">
				<div className="flex w-full justify-between py-2">
					<div className="flex items-center gap-x-4">
						{avatarImage && <CompanyLogo image={avatarImage}/>}
						<Headline type="h2">{clientData.displayName}</Headline>
					</div>
					<ButtonWithPopover
						theme="dark"
						items={popoverItems}
					/>
				</div>
			</Section>
			<div className="flex flex-col gap-y-8">
				<ContentWrapper className="w-full">
					<SectionHeading sticky
									style={{ top: 0 }}>
						<div className="flex w-full items-center justify-between">
							<SectionHeadingTabs
								tabs={[
									{
										name: "Abrechnungsdetails",
										value: ClientDetailsTabId.BILLING_DETAILS,
										active: activeTabSection === ClientDetailsTabId.BILLING_DETAILS,
									},
									{
										name: "Projekte",
										value: ClientDetailsTabId.PROJECTS,
										active: activeTabSection === ClientDetailsTabId.PROJECTS,
									},
									{
										name: "Kontakte",
										value: ClientDetailsTabId.CONTACTS,
										active: activeTabSection === ClientDetailsTabId.CONTACTS,
									},
									{
										name: "Zentrale Rechnungsempfänger",
										value: ClientDetailsTabId.INVOICE_RECIPIENTS,
										active: activeTabSection === ClientDetailsTabId.INVOICE_RECIPIENTS,
									},
									{
										name: "Quotas",
										value: ClientDetailsTabId.QUOTAS,
										active: activeTabSection === ClientDetailsTabId.QUOTAS,
									},
								]}
								onTabClick={setActiveTabSection}
							/>
							{addNewItemButtonTabSection}
						</div>
					</SectionHeading>

					<div className="mt-4">
						{ClientDetailsTabId.BILLING_DETAILS === activeTabSection &&
							<ClientsBillingDetails
								clientData={clientData}
							/>}
						{ClientDetailsTabId.INVOICE_RECIPIENTS === activeTabSection &&
							<ClientsInvoiceRecipientsList
								clientId={clientData.id}
								clientDisplayName={clientData.displayName}
								invoiceRecipients={invoiceRecipients}
								onAddInvoiceRecipientClick={() => setShowNewInvoiceRecipientForm(true)}
							/>}
						{ClientDetailsTabId.CONTACTS === activeTabSection && contacts && (
							<ClientContactsList
								contacts={contacts}
								displayName={clientData.displayName}
								onAddContactClick={() => setShowNewContactForm(true)}
							/>
						)}

						{ClientDetailsTabId.PROJECTS === activeTabSection && projects && (
							<ClientsProjectsList
								clientDisplayName={clientData.displayName}
								contacts={contacts}
								projects={projects}
								workingStatuses={workingStatuses}
								onAddProjectClick={() => setShowAddProjectForm(true)}
							/>
						)}

						{ClientDetailsTabId.QUOTAS === activeTabSection && projects && <Hint>Coming soon...</Hint>}
					</div>
				</ContentWrapper>

				<AddContactPersonToClientSidebar
					isOpen={showNewContactForm}
					setOpen={setShowNewContactForm}
					clientId={clientData.id}
					companyId={clientData.companyId}
				/>

				<CreateInvoiceRecipientSidebar clientId={clientData.id}
											   isOpen={showNewInvoiceRecipientForm}
											   onClose={() => setShowNewInvoiceRecipientForm(false)} />

				<UpdateClientSidebar isOpen={showEditForm}
									 setOpen={setShowEditForm}
									 clientData={clientData} />


				<AddProjectToClientSidebar isOpen={showAddProjectForm}
										   setOpen={setShowAddProjectForm}
										   clientId={clientData.id} />

				<DeleteClientModal clientId={clientData.id}
								   isOpen={showDeletionModal}
								   onClose={() => setShowDeletionModal(false)} />
			</div>
		</MainContent>
	);
};

export default ClientDetailsView;
