import LoadingSpinner from "~/components/LoadingSpinners/LoadingSpinner";

interface Props {
	theme?: "default" | "neutral";
}

const LoadingSpinnerFull: React.FC<Props> = ({ theme = "default" }) => (
	<div className="flex size-full items-center justify-center">
		<LoadingSpinner theme={theme} />
	</div>
);

export default LoadingSpinnerFull;
