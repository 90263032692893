import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersVariableCompensationPercentages,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageApiDispatchers.ts";

export const STAFF_MEMBER_VARIABLE_SALARY_COMPENSATION_BASE_QUERY_KEY = "staffMembersVariableCompensationPercentage";

export const STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_VARIABLE_SALARY_COMPENSATION_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersVariableCompensationPercentages = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY(staffMemberId),
	() => indexStaffMembersVariableCompensationPercentages(staffMemberId).then(getDataFromResponse),
	options,
);