import type { FunctionComponent } from "react";

import {
	phaseTabStatusIndicatorVariants,
} from "~/modules/project/components/ProjectDetailsView/components/ProjectDetailsTabBar/components/phaseTabStatusIndicator/phaseTabStatusIndicatorVariants.ts";
import { getWorkingStatusThemeById } from "~/modules/project/utils/projectUtils.ts";

type Props = { statusId: string };

const phaseTabStatusIndicator: FunctionComponent<Props> = ({ statusId }) => {
	const theme = getWorkingStatusThemeById(statusId);

	return <div className={phaseTabStatusIndicatorVariants({ theme })} />;
};

export default phaseTabStatusIndicator;