import type { Control, FieldValues } from "react-hook-form";

import FormSection from "~/components/formElements/FormSection";
import PaymentTargetDaysInput from "~/components/formElements/PaymentTargetInput";
import type { PaymentTargetDaysInputFormData } from "~/components/formElements/PaymentTargetInput/paymentTargetDaysInputTypes.ts";
import RadioGroup from "~/components/formElements/RadioGroup";
import { INVOICE_BY_MAIL_OPTIONS, TRAVEL_EXPENSES_INCLUDED_OPTIONS } from "~/constants/form.ts";

type AccountingDetailsFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	showCustomPaymentTargetInput: boolean;
};

interface AccountingDetailsFormSectionFormData extends PaymentTargetDaysInputFormData {
	travelExpensesIncluded: boolean;
	invoiceByMail: boolean;
}

const AccountingDetailsFormSection = <T extends AccountingDetailsFormSectionFormData>({
	control,
	showCustomPaymentTargetInput,
}: AccountingDetailsFormSectionProps<T>) => {
	return (
		<FormSection title="Abrechnung">
			<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
				<div className="sm:col-span-4 sm:col-start-1">
					<RadioGroup
						control={control}
						label="Reisekosten"
						name="travelExpensesIncluded"
						options={TRAVEL_EXPENSES_INCLUDED_OPTIONS}
						numCols={2}
					/>
				</div>
				<div className="sm:col-span-4 sm:col-start-1">
					<RadioGroup
						control={control}
						label="Versand"
						name="invoiceByMail"
						options={INVOICE_BY_MAIL_OPTIONS}
						numCols={2}
					/>
				</div>
				<PaymentTargetDaysInput
					className="sm:col-span-6 sm:col-start-1"
					control={control}
					showCustomPaymentTargetInput={showCustomPaymentTargetInput}
				/>
			</div>
		</FormSection>
	);
};

export default AccountingDetailsFormSection;
