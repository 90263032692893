import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent, ReactNode } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { useStaffMembersEmployments } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type { StaffMemberEmploymentWithDate } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import CreateEmploymentSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/EmploymentsSection/components/CreateEmploymentSidebar";
import DeleteEmploymentModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/EmploymentsSection/components/DeleteEmploymentModal";
import UpdateEmploymentSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/EmploymentsSection/components/UpdateEmploymentSidebar";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import TimelineEmptyMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TimelineEmptyMessage";
import TimelineItem from "modules/humanResources/components/StaffMemberDetailsView/components/TimelineItem";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { staffMemberId: string };

const EmploymentsSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateEmploymentSidebar, setShowCreateEmploymentSidebar] = useState(false);
	const [employmentDataToDelete, setEmploymentDataToDelete] = useState<StaffMemberEmploymentWithDate | null>(null);
	const [employmentDataToUpdate, setEmploymentDataToUpdate] = useState<StaffMemberEmploymentWithDate | null>(null);
	const {
		data: employmentsData,
		isLoading: employmentsIsLoading,
	} = useStaffMembersEmployments({ staffMemberId });
	
	const timeLineElements = useMemo(() => {
		if (employmentsData) {
			const employmentsSorted = employmentsData.map(employment => {
				return { ...employment, validFrom: new Date(employment.validFrom) };
			}).sort(byObjectProperty("validFrom", "desc"));
			const timelineElements: ReactNode[] = [];

			employmentsSorted.forEach((employment, index) => {
				const nextElement = employmentsSorted[index + 1];
				const isStarItem = !nextElement || nextElement.employmentTypeId as EmploymentTypeId === EmploymentTypeId.Left;
				const isLeft = employment.employmentTypeId as EmploymentTypeId === EmploymentTypeId.Left;
				let dotTheme: "success" | "danger" | "neutral" = "neutral";
				if (isLeft) {
					dotTheme = "danger";
				} else if (isStarItem) {
					dotTheme = "success";
				}

				const popOverItems =
					[
						{
							label: "Bearbeiten",
							onClick: () => setEmploymentDataToUpdate(employment),
							icon: PencilIcon,
						},
						{
							label: "Löschen",
							onClick: () => setEmploymentDataToDelete(employment),
							icon: TrashIcon,
						},
					];

				timelineElements.push(
					<TimelineItem date={new Date(employment.validFrom)}
								  dotTheme={dotTheme}
								  key={index}
								  isStartItem={isStarItem}
					>
						<div className="flex w-full justify-between">
							<div>{getEmploymentTypeDisplayNameById(employment.employmentTypeId as EmploymentTypeId)}</div>
							<ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>

						</div>
					</TimelineItem>,
				);
			});
			return timelineElements;
		}
		return [];
	}, [employmentsData]);

	return <div className="col-span-3">
		<Card title="Beschäftigungsart"
			  onHeaderButtonClick={() => setShowCreateEmploymentSidebar(true)}
			  headerButtonText="Neuer Eintrag">
			{employmentsIsLoading && <LoadingSpinnerFull theme="neutral" />}
			{!employmentsIsLoading && <StaffMemberTimeline>
				{timeLineElements.length > 0 ?timeLineElements : <TimelineEmptyMessage/>}
			</StaffMemberTimeline>}
		</Card>
		<CreateEmploymentSidebar isOpen={showCreateEmploymentSidebar}
								 onClose={() => setShowCreateEmploymentSidebar(false)}
								 staffMemberId={staffMemberId} />

		<UpdateEmploymentSidebar isOpen={!!employmentDataToUpdate}
								 onClose={() => setEmploymentDataToUpdate(null)}
								 employmentData={employmentDataToUpdate} />

		<DeleteEmploymentModal isOpen={!!employmentDataToDelete}
							   onCloseClick={() => setEmploymentDataToDelete(null)}
							   employmentData={employmentDataToDelete}
		/>
	</div>;
};

export default EmploymentsSection;