import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Textarea from "~/components/formElements/Textarea";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createInvoiceRecipient,
	updateInvoiceRecipient,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientApiDispatchers.ts";
import type {
	CreateOrUpdateInvoiceRecipientData,
	InvoiceRecipientType,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateOrUpdateInvoiceRecipientFormProps = {
	clientId: string,
	initialValues?: InvoiceRecipientType
	onCancelClick: () => void;
	onSuccess: () => void;
};

type CreateOrUpdateInvoiceRecipientFormData = Omit<CreateOrUpdateInvoiceRecipientData, "clientId">
const CreateOrUpdateInvoiceRecipientForm: React.FunctionComponent<CreateOrUpdateInvoiceRecipientFormProps> = ({
	clientId,
	initialValues,
	onCancelClick,
	onSuccess,
}) => {
	const [busy, setBusy] = useState(false);
	const isUpdateProcess = !!initialValues;
	const schema = useMemo(() => {
		return yup.object({
			comment: yup.string().default(""),
			displayName: yup.string().required().default(""),
			email: yup.string().email().default(""),
			invoiceByMail: yup.boolean().required(),
			invoiceHead: yup.string().required().default(""),
			supportContact: yup.string().default(""),
			supportContactPhone: yup.string().default(""),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			comment: initialValues?.comment || "",
			displayName: initialValues?.displayName || "",
			email: initialValues?.email || "",
			invoiceByMail: initialValues?.invoiceByMail || true,
			invoiceHead: initialValues?.invoiceHead || "",
			supportContact: initialValues?.supportContact || "",
			supportContactPhone: initialValues?.supportContactPhone || "",
		};
	}, [initialValues]);

	const {
		handleSubmit,
		formState: { isValid, isDirty, isSubmitted },
		control,
	} = useForm<CreateOrUpdateInvoiceRecipientFormData>({
		defaultValues: defaultValues,
		mode: isUpdateProcess ? "onChange" : "onSubmit",
		resolver: yupResolver<CreateOrUpdateInvoiceRecipientFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: !isUpdateProcess,
	});

	const onSubmit = async (data: CreateOrUpdateInvoiceRecipientFormData) => {
		setBusy(true);

		try {
			if (initialValues) {

				await updateInvoiceRecipient({ clientId, invoiceRecipientId: initialValues.id, data });
			} else {
				await createInvoiceRecipient({ clientId, data });
			}
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>{initialValues ? "Rechnungsempfänger aktualisieren" : "Rechnungsempfänger erstellen"}</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Kontaktdaten">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6">
								<Input
									name="displayName"
									control={control}
									label="Name"
								/>
							</div>
							<div className="col-span-6">
								<Input
									name="supportContact"
									control={control}
									label="Support Kontakt"
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="supportContactPhone"
									control={control}
									label="Support Kontakt Telefon"
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="email"
									control={control}
									label="Support Kontakt E-Mail"
								/>
							</div>
						</div>
					</FormSection>
					<FormSection title="Rechnungsanschrift">
						<Textarea
							control={control}
							name="invoiceHead"
						/>
					</FormSection>
					<FormSection title="Anmerkungen">
						<Textarea
							control={control}
							name="comment"
						/>
					</FormSection>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isUpdateProcess && !isValid || (!isUpdateProcess && !isValid && isSubmitted)}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey('form:buttonTexts.save'))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancelClick}>
					{t(normalizeTKey('form:buttonTexts.cancel'))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateOrUpdateInvoiceRecipientForm;