import type { InitOptions } from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { customFormatters } from "./format";
import { defaultFlavours, Namespace, supportedLanguages } from "./i18n.config";
// * to display placeholders, use querystring `?lang=cimode`
import de_entities from "./lang/de_entities.ts";
import de_form from "./lang/de_form.ts";
import de_main from "./lang/de_form.ts";
import en_main from "./lang/en_main.ts";

const settings: InitOptions = {
	lng: "de", // undefined -> auto-detect
	fallbackLng: "de",
	resources: {
		en: {
			main: en_main,
		},
		de: {
			entities: de_entities,
			form: de_form,
			main: de_main,
		},
	},
	cleanCode: true,
	ns: [Namespace.Default],
	defaultNS: Namespace.Default,
	interpolation: {
		escapeValue: false, // react already saves from xss
	},
};

const initI18n = (initOptions: { debug: boolean } = { debug: false }) => {
	const instance = i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({ ...settings, ...initOptions });
	if (initOptions.debug) {
		instance.then(() => {
			console.debug("languages loaded:", i18n.languages);
		});
	}
	customFormatters.forEach((formatFunction, name) => i18n.services.formatter!.add(name, formatFunction));
};

// validate
if (import.meta.env.DEV) {
	supportedLanguages.forEach((language) => {
		if (language.length === 2 && !defaultFlavours.has(language)) {
			console.warn(`missing default flavor for ${language}`);
		}
	});
}

export default initI18n;
