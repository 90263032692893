import { Popover } from "@mantine/core";
import clsx from "clsx";
import { differenceInBusinessDays, isAfter, isBefore } from "date-fns";
import type Decimal from "decimal.js-light";
import { useState } from "react";

import type { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import type {
	AbsenceTypeBorderRadiusVariants,
} from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeBadge/absenceTypeBadgeVariantsVariants.ts";
import {
	absenceTypeBadgeVariantsVariants,
} from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeBadge/absenceTypeBadgeVariantsVariants.ts";
import type { AbsenceCalendarEvent } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getAbsenceTypeThemeById } from "~/modules/absence/utils/absenceTypesUtils.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";


type AbsenceBarProps = {
	absence: AbsenceCalendarEvent;
	containerWidthPx: Decimal;
	dayWidthPx: Decimal;
	endDate: Date;
	isUsersEvent: boolean;
	muted: boolean;
	offsetPerDayPx: number;
	showDetails: boolean;
	startDate: Date;
};

const AbsenceBar: React.FunctionComponent<AbsenceBarProps> = ({
	absence,
	containerWidthPx,
	dayWidthPx,
	isUsersEvent,
	endDate,
	muted,
	offsetPerDayPx,
	startDate,
}) => {
	const [showPopover, setShowPopover] = useState(false);

	const barStartDate = isBefore(absence.startDate, startDate) ? startDate : absence.startDate;
	const barEndDate = isAfter(absence.endDate, endDate) ? endDate : absence.endDate;

	let offsetLeftPx = offsetPerDayPx + differenceInBusinessDays(barStartDate, startDate) * dayWidthPx.toNumber();
	let daysToDisplay = differenceInBusinessDays(barEndDate, barStartDate) + 1;

	if (absence.firstDayIsHalf && !isBefore(absence.startDate, startDate)) {
		daysToDisplay -= 0.5;
		offsetLeftPx += dayWidthPx.div(2).toNumber();
	}

	if (absence.lastDayIsHalf && !isAfter(absence.endDate, endDate)) {
		daysToDisplay -= 0.5;
	}

	let totalWorkDaysWidth = dayWidthPx.mul(daysToDisplay).toDecimalPlaces(2).toNumber();

	if (totalWorkDaysWidth > containerWidthPx.toNumber()) {
		totalWorkDaysWidth = containerWidthPx.toNumber();
	}

	totalWorkDaysWidth -= offsetPerDayPx;

	let borderRadius: AbsenceTypeBorderRadiusVariants = "default";

	if (isBefore(absence.startDate, startDate)) {
		borderRadius = "right";
	} else if (isAfter(absence.endDate, endDate)) {
		borderRadius = "left";
	}

	const theme = getAbsenceTypeThemeById(absence.absenceTypeId as AbsenceTypeId);

	const barVariants = absenceTypeBadgeVariantsVariants({ borderRadius, size: "timelineBar", theme, muted });


	return <div className="absolute isolate flex h-full"
				style={{ width: totalWorkDaysWidth, left: offsetLeftPx }}>
		<Popover width={200}
				 position="bottom"
				 opened={showPopover}
				 classNames={{
					 dropdown: "bg-white shadow-md rounded-md absolute",
				 }}
				 zIndex={99999}
		>
			<Popover.Target>
				<button className={clsx("h-6 w-full", barVariants, isUsersEvent ? "cursor-pointer" : "cursor-default")}
						onMouseEnter={() => setShowPopover(true)}
						onMouseLeave={() => setShowPopover(false)}
				>
				</button>
			</Popover.Target>
			<Popover.Dropdown style={{ pointerEvents: "none", zIndex: 99999 }}>
				<div className="flex flex-col p-2 text-sm">
					<div>{absence.staffMemberFullName}</div>
					<div>Typ: {absence.absenceTypeName}</div>
					<div>{formatDateRange(absence.startDate, absence.endDate)}</div>
					{/*<div>ID:{absence.id}</div>*/}
				</div>
			</Popover.Dropdown>
		</Popover>

	</div>;
};

export default AbsenceBar;