import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { FocusEvent, ForwardedRef, InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { InputPlain } from "~/components/formElements/Input/Input.tsx";
import Label from "~/components/formElements/Label";

type SharedProps =
	Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">
	& FormInputBaseVariants & {
	minValue: number;
	maxValue: number;
	className?: string;
	errorIconOnly?: boolean;
	name: string;
	label?: string;
	error?: string;
}

type RestrictedNumberInputPlainProps = SharedProps & {
	value: number;
	className?: string;
	onChange: (value: number) => void;
}

export const RestrictedNumberInputPlain = forwardRef<HTMLInputElement, RestrictedNumberInputPlainProps>(
	(
		{
			className,
			value,
			onBlur,
			onChange,
			label,
			error,
			errorIconOnly,
			name,
			minValue,
			maxValue,
			...props
		}: RestrictedNumberInputPlainProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
			e.target.select(); // Select input value
		}

		function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
			const inputValue = e.target.value;

			// Reject non-digit characters
			const newValue = inputValue.replace(/[^0-9]/g, "");

			if (!newValue) {
				onChange(0);
				return;
			}

			const numericValue = parseInt(newValue);

			// Check against max and min values
			if (isNaN(numericValue) || numericValue < minValue || numericValue > maxValue) {
				onChange(0);
			} else {
				onChange(numericValue);
			}
		}

		function handleBlur(event: FocusEvent<HTMLInputElement>) {
			const inputValue = value ? value.toString() : "";
			const numericValue = parseInt(inputValue);

			if (numericValue < minValue || numericValue > maxValue) {
				onChange(0);
			}
			onBlur && onBlur(event);
		}


		return (
			<FormInput className={clsx(props.disabled && "opacity-50")}>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<InputPlain
						name={name}
						className={className}
						max={maxValue}
						type="number"
						value={value}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						ref={ref}
						{...props}
					/>
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="size-5 text-red-500"
												   aria-hidden="true" />
						</div>
					)}
				</div>
				{error && !errorIconOnly && (
					<p className="mt-2 text-sm text-red-600"
					   id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);
	},
);

RestrictedNumberInputPlain.displayName = "RestrictedNumberInputPlain";

type RestrictedNumberInputProps = SharedProps & {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const RestrictedNumberInput: React.FC<RestrictedNumberInputProps> = ({
	name,
	control,
	useControllerError,
	error,
	rules,
	...rest
}) => {
	const {
		field,
		fieldState: { error: errorFromRHF },
	} = useController({
		name,
		control,
		rules,
	});

	const errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <RestrictedNumberInputPlain {...field} {...rest} error={errorMessage} />;
};

export default RestrictedNumberInput;
