import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import type { TableHeaderBgTheme } from "~/components/Table/tableTypes.ts";


export const TableHeader: FunctionComponent<PropsWithChildren<{
	bgColor?: TableHeaderBgTheme,
	hasFilterBar?: boolean,
}>> = ({
	bgColor = "light",
	children,
	hasFilterBar = false,
}) => {
	const topClass = hasFilterBar ? "top-[3.25rem]" : "top-0";

	return <thead className={clsx("sticky z-20", bgColor === "light" ? "bg-white" : "bg-gray-500", topClass)}>{children}</thead>;
};

export default TableHeader;