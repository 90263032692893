import clsx from "clsx";
import type { PropsWithChildren } from "react";

import ContentWrapper from "~/components/ContentWrapper";

export const heroSectionBackgroundColor = "bg-white";

const HeroSection: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
	<div className={clsx(heroSectionBackgroundColor, className)}>
		<header className="py-8">
			<ContentWrapper>{children}</ContentWrapper>
		</header>
	</div>
);

export default HeroSection;

export const HeroSectionHighlights: React.FC<PropsWithChildren> = ({ children }) => (
	<>
		<div className={`${heroSectionBackgroundColor} h-12 border-b`} />
		<div className="-translate-y-12 transform-gpu">
			<div className="px-4 sm:px-6 lg:px-8">
				<ContentWrapper className="rounded-lg bg-primary-400 p-4 pb-6 text-white shadow">
					{children}
				</ContentWrapper>
			</div>
		</div>
	</>
);
