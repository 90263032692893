import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useEmploymentTypeSelectOptions, {
	internalEmploymentTypesIncludingLeftFn,
} from "~/hooks/form/formOptionsData/useEmploymentTypeSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateStaffMemberEmployment } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsApiDispatchers.ts";
import type { StaffMemberEmploymentWithDate } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateEmploymentFormData = {
	employmentTypeId: string;
	validFrom: Date
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	employmentData: StaffMemberEmploymentWithDate;
};

const UpdateEmploymentForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	employmentData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const employmentTypeSelectOptions = useEmploymentTypeSelectOptions(internalEmploymentTypesIncludingLeftFn);
	const schema = useMemo(() => {
		return yup.object({
			employmentTypeId: yup.string().required().default(""),
			validFrom: yup.date().required().default(new Date()),

		});
	}, []);

	const defaultValues = useMemo(() => {
		if (employmentData) {
			return {
				employmentTypeId: employmentData.employmentTypeId,
				validFrom: new Date(employmentData.validFrom),
			};
		}

		return {
			employmentTypeId: "",
			validFrom: new Date(),
		};
	}, [employmentData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateEmploymentFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateEmploymentFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdateEmploymentFormData) => {

		const updateData = {
			...data,
			validFrom: formatDateToYYYYMMDD(data.validFrom),
		};
		try {
			setIsBusy(true);
			await updateStaffMemberEmployment({
				staffMemberId: employmentData.staffMemberId,
				employmentId: employmentData.id,
				updateData,
			});
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, employmentData]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Eintrag in Beschäftigungshistorie aktualisieren
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Select optionsData={employmentTypeSelectOptions}
							name="employmentTypeId"
							label="Anstellungsart"
							control={control}
					/>
					<DatePickerInput name="validFrom"
									 control={control}
									 label="Gültig ab" />
				</div>

			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateEmploymentForm;
