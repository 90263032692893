import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	addProjectRoleToProjectEndpoint,
	getAllProjectRolesEndpoint,
	getProjectsProjectRolesEndpoint, removeProjectRoleFromProjectEndpoint,
} from "~/modules/project/api/projectRole/projectRoleApiEndpoints.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";

export function getAllProjectRoles(): AxiosApiResponsePromise<ProjectRole> {
	return axiosApi.get(getAllProjectRolesEndpoint);
}

export function getProjectsProjectRoles(projectId: string): AxiosApiResponsePromise<ProjectRole[]> {
	return axiosApi.get(getProjectsProjectRolesEndpoint(projectId));
}


// returns all the project roles for the given project
export function addProjectRoleToProject(projectId: string, projectRoleName: string): AxiosApiResponsePromise<ProjectRole[]> {
	return axiosApi.post(addProjectRoleToProjectEndpoint(projectId), { projectRoleName });
}

// returns all the project roles for the given project
export function removeProjectRoleFromProject(projectId: string, projectRoleId: string): AxiosApiResponsePromise<ProjectRole[]> {
	return axiosApi.delete(removeProjectRoleFromProjectEndpoint(projectId, projectRoleId));
}
