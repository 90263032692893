import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { isDate } from "date-fns";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import UserStatusFormSection from "~/components/formSections/UserStatusFormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBackButton from "~/components/Sidebar/components/SidebarBackButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { COMPANY_LOCATION_DEFAULT_ID } from "~/constants/form.ts";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import useEmploymentTypeSelectOptions, {
	freelanceEmploymentTypeOnlyFn,
} from "~/hooks/form/formOptionsData/useEmploymentTypeSelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { createFreelanceUser, sendUserInvitation } from "~/modules/user/api/user/userApiDispatchers.ts";
import type { CreateFreelanceUserData } from "~/modules/user/api/user/userTypes.ts";
import UserPersonalInformationFormSection
	from "~/modules/user/components/forms/formSections/UserPersonalInformationFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	onBackClick: () => void;
};

type CreateFreelanceUserFormData = Omit<CreateFreelanceUserData, "dateOfBirth"> & {
	dateOfBirth: Date | null;
}

const CreateFreelanceUserForm: React.FC<Props> = ({ onBackClick, onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			locationId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			email: yup.string()
				.email()
				.required()
				.uniqueEmail(emailComparisonData),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			isActive: yup.boolean().required(),
			lastName: yup.string().required(),
			nickname: yup.string().default(""),
			phone: yup.string().default(""),
			shortName: yup.string().required().default(""),
		});
	}, [emailComparisonData]);


	const defaultValues = useMemo(() => {
		return {
			careerLevelId: "",
			locationId: COMPANY_LOCATION_DEFAULT_ID,
			dateOfBirth: null,
			email: "",
			firstName: "",
			genderId: "",
			isActive: true,
			lastName: "",
			nickname: "",
			phone: "",
		};
	}, []);


	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty, isValid, isSubmitted },
	} = useForm<CreateFreelanceUserFormData>({
		defaultValues,
		mode: "onSubmit",
		resolver: yupResolver<CreateFreelanceUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const careerLevelSelectOptions = useCareerLevelOptions();
	const companyLocationSelectOptions = useCompanyLocationSelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const employmentTypeSelectOptions = useEmploymentTypeSelectOptions(freelanceEmploymentTypeOnlyFn);

	const onSubmit: SubmitHandler<CreateFreelanceUserFormData> = async (data) => {
		setBusy(true);
		const dateOfBirth = isDate(data.dateOfBirth) ? formatDateToYYYYMMDD(data.dateOfBirth as Date) : null;

		const newUserData = { ...data, permissions: [], dateOfBirth };

		try {
			const newUserResponse = await createFreelanceUser(newUserData);
			const userData = getDataFromResponse(newUserResponse);

			if (userData.isActive) {
				try {
					await sendUserInvitation(userData.id);
				} catch (error) {
					console.log(error);
				}
			}
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};
	const isActive = watch("isActive");

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neuen Freelance-User anlegen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<UserPersonalInformationFormSection<CreateFreelanceUserFormData>
						control={control}
						genderSelectOptions={genderSelectOptions}
					/>
					<FormSection title="Stammdaten">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-3">
								<SelectPlain
									disabled={true}
									name="employmentTypeId"
									label="Anstellungsstatus"
									optionsData={employmentTypeSelectOptions}
									value={EmploymentTypeId.Freelancer}
								/>
							</div>
							<div className="col-span-3">
								<Select
									name="careerLevelId"
									label="Skill Level"
									optionsData={careerLevelSelectOptions}
									control={control}
								/>
							</div>
							<div className="col-span-3">
								<Select
									name="locationId"
									label="Standort"
									optionsData={companyLocationSelectOptions}
									control={control}
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="shortName"
									placeholder="MM"
									label="Namenskürzel"
									control={control} />
							</div>
						</div>
					</FormSection>

					<UserStatusFormSection isActive={isActive}
										   control={control} />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SidebarBackButton onClick={onBackClick} />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateFreelanceUserForm;
