import type { FunctionComponent } from "react";

import { useAggregatedData } from "~/hooks/useAggregatedData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationQueries.ts";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeQueries.ts";
import { useAllGenders } from "~/modules/humanResources/api/gender/genderQueries.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import StaffMembersIndexView from "~/modules/humanResources/components/StaffMembersIndexView";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

const StaffMembersIndexPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanManageStaff);
	if (guard) return guard;

	const { data, options } = useAggregatedData([
		{ hook: () => useAllStaffMembers(), name: "staffMembers" },
		{ hook: () => useAllCareerLevels(), name: "careerLevels" },
		{ hook: () => useAllCompanyLocations(), name: "companyLocations" },
		{ hook: () => useAllEmploymentTypes(), name: "employmentTypes" },
		{ hook: () => useAllGenders(), name: "genders" },
	]);

	const { staffMembers, careerLevels, companyLocations, employmentTypes } = data;

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return (
		<StaffMembersIndexView
			allStaffMembers={staffMembers as StaffMember[]}
			allCareerLevelsData={careerLevels}
			allCompanyLocationsData={companyLocations}
			allEmploymentStatusesData={employmentTypes}
		/>
	);
};

export default StaffMembersIndexPage;