export type BonusType = {
	id: string;
	name: string
	listPosition: number;
}

export enum BonusTypeName {
	Fixed = "fixed",
	Spot = "spot",
	Special = "special",
	Yearly = "yearly",

}

export enum BonusTypeId {
	Yearly = "1",
	Spot = "2",
	Fixed = "3",
	Special = "4",
}