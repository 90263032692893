import { addDays, format } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { v4 as uuid } from "uuid";

import type { AbsenceIcsData } from "~/modules/absence/api/absence/absenceTypes.ts";

export function createIcsContent(absenceEvent: AbsenceIcsData): string {
	const timeZone = "Europe/Berlin";

	const startDate = utcToZonedTime(new Date(absenceEvent.startDate), timeZone);
	let endDate = utcToZonedTime(new Date(absenceEvent.endDate), timeZone);

	const isAllDay = !absenceEvent.firstDayIsHalf && !absenceEvent.lastDayIsHalf;

	if (isAllDay) {
		startDate.setHours(0, 0, 0, 0);
		// For all-day events, the end date should be the next day at 00:00
		endDate = addDays(endDate, 1);
		endDate.setHours(0, 0, 0, 0);
	} else {
		if (absenceEvent.firstDayIsHalf) {
			startDate.setHours(13, 0, 0, 0);
		}
		if (absenceEvent.lastDayIsHalf) {
			endDate.setHours(13, 0, 0, 0);
		} else {
			// If the last day is not half, set it to end of day
			endDate.setHours(23, 59, 59, 999);
		}
	}

	const summary = `${absenceEvent.absenceTypeName}${absenceEvent.comment && absenceEvent.comment !== absenceEvent.absenceTypeName ? ` : ${absenceEvent.comment}` : ""}`;
	const description = "";
	const organizerEmail = absenceEvent.userEmail;
	const organizerDisplayName = absenceEvent.userFullName;

	const formatDateTime = (date: Date): string => {
		return formatInTimeZone(date, timeZone, "yyyyMMdd'T'HHmmss");
	};

	const formatDate = (date: Date): string => {
		return format(date, "yyyyMMdd");
	};

	const dtstart = isAllDay
		? `DTSTART;VALUE=DATE:${formatDate(startDate)}`
		: `DTSTART;TZID=${timeZone}:${formatDateTime(startDate)}`;

	const dtend = isAllDay
		? `DTEND;VALUE=DATE:${formatDate(endDate)}`
		: `DTEND;TZID=${timeZone}:${formatDateTime(endDate)}`;

	return `BEGIN:VCALENDAR
METHOD:PUBLISH
PRODID:Nord.Work App
VERSION:2.0
BEGIN:VEVENT
ORGANIZER;CN=${organizerDisplayName} | NORD.NORD:mailto:${organizerEmail}
DESCRIPTION:${description.replace(/\n/g, "\\n")}
UID:${uuid()}@Nord.Work
SUMMARY:${summary}
${dtstart}
${dtend}
CLASS:PUBLIC
PRIORITY:5
DTSTAMP:${formatInTimeZone(new Date(), "UTC", "yyyyMMdd'T'HHmmss'Z'")}
TRANSP:OPAQUE
STATUS:CONFIRMED
X-MICROSOFT-CDO-BUSYSTATUS:OOF
X-MICROSOFT-CDO-INTENDEDSTATUS:BUSY
X-MICROSOFT-CDO-ALLDAYEVENT:${isAllDay ? "TRUE" : "FALSE"}
X-MICROSOFT-CDO-IMPORTANCE:1
X-MICROSOFT-CDO-INSTTYPE:0
BEGIN:VALARM
DESCRIPTION:REMINDER
TRIGGER;RELATED=START:-PT18H
ACTION:DISPLAY
END:VALARM
END:VEVENT
END:VCALENDAR`;
}