import { useMemo } from "react";

import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData, getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	projectId: string;
	includeCtaOption?: boolean;
	valueProperty?: ProjectRole["id"] | ProjectRole["displayName"];
}

const useProjectsProjectRolesOptions = ({
	projectId,
	includeCtaOption,
	valueProperty = "id",
}: Props): FormInputOption[] => {
	const { data: projectRolesData } = useProjectProjectRoles({ projectId });
	return useMemo(() => {
		if (projectRolesData) {
			const options = projectRolesData.sort(byObjectProperty("displayName")).map((projectRole) => {
				// @ts-expect-error I don't know why this is throwing an error
				return createInputOptionData(projectRole.displayName, projectRole[valueProperty]);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [projectRolesData, includeCtaOption, valueProperty]);
};

export default useProjectsProjectRolesOptions;

