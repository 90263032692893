import type { FunctionComponent } from "react";

const TableEmptyMessage: FunctionComponent = () => {
	return <tr>
		<td colSpan={100} className="h-12">
			<div className="flex items-center justify-center text-sm text-gray-500 ">Keine Einträge vorhanden</div>
		</td>
	</tr>;
};

export default TableEmptyMessage;