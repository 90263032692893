import { t } from "i18next";

import { BonusTypeId, BonusTypeName } from "~/modules/humanResources/api/bonusType/bonusTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getBonusTypeDisplayNameById(bonusTypeId: BonusTypeId): string {
	let bonusTypeName = "";

	switch (bonusTypeId) {
		case BonusTypeId.Yearly:
			bonusTypeName = BonusTypeName.Yearly;
			break;
		case BonusTypeId.Spot:
			bonusTypeName = BonusTypeName.Spot;
			break;
		case BonusTypeId.Fixed:
			bonusTypeName = BonusTypeName.Fixed;
			break;
		case BonusTypeId.Special:
			bonusTypeName = BonusTypeName.Special;
			break;

		default:
			throw new Error("Unknown bonus type id:" + bonusTypeId);
	}
	if (!bonusTypeName) {
		return "unbekannt";
	}

	return t(normalizeTKey(`entities:bonusType.${bonusTypeName}`));
}