import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import Headline from "~/components/Headline/Headline";
import Hint from "~/components/Hint";
import Modal from "~/components/Modal/Modal";
import Paragraph from "~/components/Paragraph";
import { deleteMonthlyClosing } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingApiDispatchers.ts";
import { USERS_MONTHLY_CLOSINGS_QUERY_KEY } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingQueries.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type DeleteMonthlyClosingModalProps = {
	isOpen: boolean;
	onClose: () => void;
	userCanManageTimeTrackings: boolean;
	monthlyClosingId: string | null;
	userId: string;
};

const DeleteMonthlyClosingModal: React.FunctionComponent<DeleteMonthlyClosingModalProps> = ({
	isOpen,
	onClose,
	monthlyClosingId,
	userId,
	userCanManageTimeTrackings,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");

	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		if (!monthlyClosingId) return;
		try {
			setBusy(true);
			await deleteMonthlyClosing({ userId, monthlyClosingId });

			await queryClient.invalidateQueries(USERS_MONTHLY_CLOSINGS_QUERY_KEY(userId));
			onClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (errorData.messages.monthlyClosingId.find((validationError) => validationError.rule === "exists")) {
					onClose();
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	return (
		<Modal open={isOpen}
			setOpen={onClose}>
			<div className="flex min-h-full w-full flex-col justify-start">
				<Headline type="h4">Monatsabschluss rückgängig machen</Headline>
				<div className="">
					<Hint theme="info">
						<Paragraph>
							{userCanManageTimeTrackings
								? `Der Monatsabschluss sollte nur in Ausnahmefällen
					(z.B. bei versehentlicher Schliessung) und in Absprache mit dem jeweiligen Mitarbeiter vorgenommen
					werden.`
								: "Dir fehlen leider die nötigen Berechtigungen, um den Monatsabschluss rückgängig zu machen."}
						</Paragraph>
					</Hint>

					{error && <Hint theme="error">{error}</Hint>}
				</div>
				<div className="mt-4">
					<Button disabled={busy || !!error || !userCanManageTimeTrackings}
						onClick={handleConfirmationClick}>
						Monatsabschluss rückgängig machen
					</Button>
					<Button theme="none"
						onClick={onClose}>
						{t(normalizeTKey("form:buttonTexts.cancel"))}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteMonthlyClosingModal;
