import {ReactComponent as Icon} from "./type-underline.svg"

type StopwatchIconProps = {
	className?: string;
};

const TextUnderlineIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default TextUnderlineIcon;