import type { PropsWithChildren } from "react";

import type {
	HourCountThemes} from "~/modules/timeTracking/components/components/Day/components/HourCount/hourCountVariants.ts";
import {
	hourCountVariants,
} from "~/modules/timeTracking/components/components/Day/components/HourCount/hourCountVariants.ts";

type HourCountProps = PropsWithChildren & {
	theme?: HourCountThemes

};

const HourCount: React.FunctionComponent<HourCountProps> = ({ theme, children }) => {

	return <div className={hourCountVariants({ theme })}>{children}</div>;
};

export default HourCount;