import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { generatePath, useNavigate } from "react-router-dom";

import Sidebar from "~/components/Sidebar";
import { PROJECT_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { All_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import CreateProjectPhaseForm from "~/modules/project/components/forms/CreateProjectPhaseForm";
import { generatePhaseTabId } from "~/modules/project/utils/projectDetailsViewUtils.ts";

type CreateProjectPhaseSidebarPhase = {
	isOpen: boolean;
	clientId: string;
	projectId: string;
	setOpen: (open: boolean) => void;
	setSelectedPhaseId: (value: string) => void;
};

const CreateProjectPhaseSidebar: FunctionComponent<CreateProjectPhaseSidebarPhase> = ({
	isOpen,
	clientId,
	projectId,
	setOpen,
}) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const handleSuccess = useCallback(
		async (newPhaseNumber: number) => {
			await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
			await queryClient.invalidateQueries(PROJECTS_ORDERS_QUERY_KEY(projectId));
			await queryClient.invalidateQueries(PROJECT_QUERY_KEY(projectId));
			await queryClient.invalidateQueries(All_LOCATIONS_QUERY_KEY);
			navigate(generatePath(PROJECT_DETAILS_PATH_WITH_TAB_ID, {
				projectId,
				tabId: generatePhaseTabId(newPhaseNumber),
			}));
			setOpen(false);
		},
		[navigate, projectId, queryClient, setOpen],
	);

	return (
		<Sidebar
			closeOnOutsideClick={false}
			open={isOpen}
			setOpen={() => setOpen(!isOpen)}>
			{isOpen && <CreateProjectPhaseForm
				clientId={clientId}
				projectId={projectId}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)} />}
		</Sidebar>
	);
};

export default CreateProjectPhaseSidebar;
