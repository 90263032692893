import clsx from "clsx";
import type { FunctionComponent } from "react";

import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import MainContent from "~/components/mainContent/MainContent";
import Section from "~/components/sections/Section";
import type { CareerLevel } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import TotalUtilisationChartSection
	from "~/modules/reports/components/UtilisationReportsView/components/TotalUtilisationChartSection";
import TotalUtilisationTableSection
	from "~/modules/reports/components/UtilisationReportsView/components/TotalUtilisationTableSection";
import { UtilisationReportViewTabId } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import type { TimeTrackingType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

type Props = {
	careerLevels: CareerLevel[];
	isLoading: boolean;
	loadingPercent: number;
	staffMembers: StaffMember[];
	timeTrackingTypes: TimeTrackingType[];
	utilisationReport: YearlyUtilisationReport;
	year: string;
};

const UtilisationReportsView: FunctionComponent<Props> = ({
	isLoading,
	loadingPercent,
	utilisationReport,
	year,
}) => {
	return <MainContent className="grid h-screen w-full grid-rows-[auto_auto_auto_1fr] overflow-hidden"
						withBottomPadding={false}>
		<nav className={clsx("flex bg-white py-4")}
			 aria-label="Breadcrumbs">
			<Section>
				<Breadcrumbs pages={["Reports", "Auslastung"]} />
			</Section>
		</nav>
		<Section className="bg-white">
			<div className="flex items-center justify-between pb-8">
				<Headline type="h2">Reports: Auslastung</Headline>
			</div>
		</Section>
		<Section>
			<SectionHeadingTabs
				tabs={[
					{
						name: "Auslastung",
						value: UtilisationReportViewTabId.Utilisation,
						active: true,
					},

				]}
				onTabClick={() => {
				}}
			/>
		</Section>
		<div className="mx-auto size-full max-w-7xl overflow-hidden px-2 sm:px-6 lg:px-8">
			{isLoading && <LoadingPage pcent={loadingPercent} />}

			{!isLoading && <div className="grid size-full grid-rows-[auto_1fr] gap-y-8 overflow-hidden pb-8">
				<TotalUtilisationChartSection utilisationReportData={utilisationReport}
											  year={year} />
				<TotalUtilisationTableSection utilisationReportData={utilisationReport}
											  year={year} />
			</div>}
		</div>
	</MainContent>;
};

export default UtilisationReportsView;