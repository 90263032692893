import type { ChangeEvent } from "react";
import { forwardRef } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import FormInputError from "~/components/formElements/FormInputError";
import Label from "~/components/formElements/Label";

type SharedPropsType = & FormInputBaseVariants & {
	label?: string;
	placeholder?: string;
	error?: string;
	rows?: number;
};

type TextareaPlainProps = SharedPropsType & {
	name?: string;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: () => void;
};

export const TextareaPlain = forwardRef<HTMLTextAreaElement, TextareaPlainProps>(
	({
		disabled,
		error,
		inputSize,
		label,
		name,
		onBlur,
		onChange,
		placeholder,
		rows = 3,
		theme,
		value = "",
	}, ref) => {
		return (
			<FormInput className="col-span-full">
				{label && <Label>{label}</Label>}
				<div>
					<textarea
						rows={rows}
						className={formInputBaseVariants({ disabled, inputSize, theme })}
						name={name}
						id={name}
						onKeyDown={(e) => {
							e.stopPropagation();
						}} // Form submission on enter is prevented in most parent form components
						value={value}
						ref={ref}
						onChange={onChange}
						onBlur={onBlur}
					/>
				</div>
				{error && <FormInputError>{error}</FormInputError>}
				{placeholder && <p className="mt-3 text-sm leading-6 text-gray-600">{placeholder}</p>}
			</FormInput>
		);
	},
);

TextareaPlain.displayName = "TextareaPlain";

type TextareaProps = SharedPropsType & {
	name: string;
	control: Control<any>;
};

const Textarea: React.FC<TextareaProps> = ({ name, control, label, placeholder, ...rest }) => {
	const {
		field: { ref, value, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	});

	return (
		<TextareaPlain
			ref={ref}
			value={value}
			{...inputProps}
			{...rest}
			label={label}
			placeholder={placeholder}
			error={error?.message}
		/>
	);
};

export default Textarea;