import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import Table from "~/components/Table";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import {
	monthlyUtilisationColumnDefs,
} from "~/modules/reports/components/UtilisationReportsView/components/TotalUtilisationTableSection/MonthlyUtilisationTable/monthlyUtilisationTableColumnDefs.tsx";
import type { MonthlyUtilisationTableData } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	reportData: YearlyUtilisationReport;
	search: string;
	selectedMonth: number;
};

const MonthlyUtilisationTable: FunctionComponent<Props> = ({ reportData, search, selectedMonth }) => {
	const {
		data: staffMembersData,
	} = useAllStaffMembers();
	const tableDataByMonth: Record<number, MonthlyUtilisationTableData[]> = useMemo(() => {
		const tableDataByMonth: Record<number, MonthlyUtilisationTableData[]> = {};
		if (staffMembersData) {
			reportData.monthlyBreakdown.forEach((monthData) => {
				const monthTableData: MonthlyUtilisationTableData[] = [];
				monthData.staffMemberBreakdown.forEach((staffMemberMonthData) => {
					const staffMember = staffMembersData.find((staffMember) => staffMember.id === staffMemberMonthData.staffMemberId);
					if (staffMember) {
						monthTableData.push({
							ftePercentages: staffMemberMonthData.ftePercentages,
							longTermAbsenceDays: staffMemberMonthData.longTermAbsenceDays,
							staffMemberDisplayName: staffMember.fullName,
							workDaysTargetFte: staffMemberMonthData.workDaysTargetFte,
							vacationDays: staffMemberMonthData.vacationDays,
							vacationDaysFte: staffMemberMonthData.vacationDaysFte,
							projectMinutesTracked: staffMemberMonthData.projectMinutesTracked,
							projectUtilizationPercentage: staffMemberMonthData.projectUtilizationPercentage,
							regularWorkDays: staffMemberMonthData.regularWorkDays,
						});
					}
				});
				tableDataByMonth[monthData.month] = monthTableData.sort(byObjectProperty("staffMemberDisplayName"));
			});

		}
		return tableDataByMonth;
	}, [reportData, staffMembersData]);

	const tableData = useMemo(() => {
		if (tableDataByMonth) {
			return tableDataByMonth[selectedMonth] || [];
		}
		return [];
	}, [tableDataByMonth, selectedMonth]);

	const table = useReactTable({
		data: tableData,
		state:{
			globalFilter:search
		},
		columns: monthlyUtilisationColumnDefs,
		globalFilterFn: "includesString",
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	});

	return <Table table={table} fixedWidth={false} />;
};

export default MonthlyUtilisationTable;