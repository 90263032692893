import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
	onClick: () => void,
	checked: boolean
};

const ToggleUserButton: FunctionComponent<Props> = ({ checked, children, onClick }) => {
	return <button className={clsx("group absolute z-50 flex h-8 w-full items-center justify-start pl-2 hover:bg-gray-400/50 hover:text-white", checked ? "text-gray-800 " : "text-gray-400")}
				   onClick={onClick}>
		{checked ? <EyeIcon className="mr-2 size-4 fill-gray-600 group-hover:fill-white" /> :
			<EyeSlashIcon className="mr-2 size-4 fill-gray-400 group-hover:fill-white" />}
		{children}

	</button>;
};

export default ToggleUserButton