import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
	highlighted: boolean;
	fontBold?: boolean;
};

const CompensationReportBaseCell: FunctionComponent<Props> = ({fontBold, highlighted, children}) => {
	return <div className={clsx("w-full text-center", highlighted ? "bg-success-300":"bg-inherit", fontBold && "font-medium")}>{children}</div>;
};

export default CompensationReportBaseCell;