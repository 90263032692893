import type { Control, FieldErrors, FieldValues } from "react-hook-form";

import ComboBox from "~/components/formElements/ComboBox";
import FormSection from "~/components/formElements/FormSection";
import type { FormInputOption } from "~/types/form.ts";

type PlaceOfPerformanceFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	countrySelectOptions: FormInputOption[];
	errors: FieldErrors<T>;
	locationOptions: FormInputOption[];
};

type PlaceOfPerformanceFormSectionFormDataType = {
	locationName: string | null;
	countryId: string | null;
};

const PlaceOfPerformanceFormSection = <T extends PlaceOfPerformanceFormSectionFormDataType>({
	control,
	countrySelectOptions,
	locationOptions,
	errors,
}: PlaceOfPerformanceFormSectionProps<T>) => {
	return (
		<FormSection title="Leistungsort">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-6">
					<ComboBox
						control={control}
						placeholder="Ort"
						name="locationName"
						label="Ort"
						optionsData={locationOptions}
						error={errors.locationName?.message as string}
					/>
				</div>
				<div className="col-span-6">
					<ComboBox
						allowNew={false}
						name="countryId"
						label="Land"
						optionsData={countrySelectOptions}
						control={control}
						error={errors.countryId?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default PlaceOfPerformanceFormSection;
