import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import {
	HR_STAFF_MEMBERS_INDEX_PATH,
	HR_STAFF_MONTHLY_REPORT_PATH,
	HR_STATISTICS_PATH,
} from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import { useStaffMemberCompensationVisibilityStore } from "~/stores/useStaffMemberCompensationVisibilityStore.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import type { SectionHeadingTab } from "~/types/tabsTypes.ts";

type AbsencePageTabsProps = {
	selectedTabName: "staff-members" | "monthly-report" | "statistics"
};

const HrMainPageTabs: React.FunctionComponent<AbsencePageTabsProps> = ({ selectedTabName }) => {
	const navigate = useNavigate();

	const {
		isVisible: totalCompensationSectionIsVisible,
	} = useStaffMemberCompensationVisibilityStore((state) => state);

	const { hasAnyPermission } = useAuth();
	const userCanUseCompensationSection = hasAnyPermission([PermissionNames.CanManagesStaffSalaries]);

	const tabs: SectionHeadingTab[] = useMemo(() => {
		const tabs: SectionHeadingTab[] = [
			{
				name: "Mitarbeiter",
				value: HR_STAFF_MEMBERS_INDEX_PATH,
				active: selectedTabName === "staff-members",
			},
		];

		if (userCanUseCompensationSection) {
			tabs.push({
				name: "Gehaltsreport",
				value: HR_STAFF_MONTHLY_REPORT_PATH,
				active: selectedTabName === "monthly-report",
				icon: !totalCompensationSectionIsVisible ? <EyeSlashIcon className="size-5 opacity-60" /> :
					<EyeIcon className="size-5" />,
			});
		}

		tabs.push({
			name: "Weitere Reports",
			value: HR_STATISTICS_PATH,
			active: selectedTabName === "statistics",
		});
		return tabs;

	}, [selectedTabName, totalCompensationSectionIsVisible, userCanUseCompensationSection]);


	return <SectionHeadingTabs
		tabs={tabs}
		onTabClick={(tabValue) => {
			navigate(tabValue, { replace: true });
		}}
	/>;
};

export default HrMainPageTabs;