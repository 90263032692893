import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import TableHeaderCell from "~/components/Table/components/TableHeaderCell";
import TableWrapper from "~/components/Table/components/TableWrapper";
import { useStaffMembersBonuses } from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusQueries.ts";
import type { StaffMemberBonusWithDate } from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusTypes.ts";
import BonusTableRow
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection/components/BonusTableRow";
import CreateBonusEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection/components/CreateBonusEntrySidebar";
import DeleteBonusEntryModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection/components/DeleteBonusEntryModal";
import UpdateBonusEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection/components/UpdateBonusEntrySidebar";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import TableEmptyMessage from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableEmptyMessage";
import TableRowLoadingSpinner
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableRowLoadingSpinner";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { staffMemberId: string };

const BonusesSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateBonusSidebar, setShowCreateBonusSidebar] = useState<boolean>(false);
	const [bonusDataToEdit, setBonusDataToEdit] = useState<StaffMemberBonusWithDate | null>(null);
	const [bonusDataToDelete, setBonusDataToDelete] = useState<StaffMemberBonusWithDate | null>(null);
	const {
		data: bonusesData,
		isLoading: bonusesIsLoading,
	} = useStaffMembersBonuses({ staffMemberId });

	const tableRows = useMemo(() => {
		if (bonusesData !== undefined) {
			if (bonusesData.length === 0) {
				return [];
			}
			const bonusesWithDate = bonusesData.map(benefit => {
				return {
					...benefit,
					paidAt: new Date(benefit.paidAt),
				};
			});
			return bonusesWithDate.sort(byObjectProperty("paidAt", "desc"))
				.map(benefit => {
					return <BonusTableRow
						key={benefit.id}
						amountCents={benefit.amountCents}
						bonusTypeId={benefit.bonusTypeId}
						description={benefit.description}
						onDeleteClick={() => {
							setBonusDataToDelete(benefit);
						}}
						onEditClick={() => {
							setBonusDataToEdit(benefit);
						}}
						paidAt={benefit.paidAt}
					/>;
				});
		}
		return [];
	}, [bonusesData]);

	return <><Card title="Bonuszahlungen"
				   padding="none"
				   onHeaderButtonClick={() => setShowCreateBonusSidebar(true)}
				   headerButtonText="Neuer Eintrag">
		<StaffMemberTimeline>
			<TableWrapper rounded={false}>
				<thead>
				<tr>
					<TableHeaderCell widthPercentage={20}
					rounded={false}>
						Ausgezahlt am
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={20}
					rounded={false}>
						Wert
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={20}
					rounded={false}>
						Art
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={40}>
						Beschreibung
					</TableHeaderCell>
					<TableHeaderCell  />
				</tr>
				</thead>
				<tbody>
				{bonusesIsLoading && <TableRowLoadingSpinner />}
				{!bonusesIsLoading && tableRows.length === 0 ? <TableEmptyMessage /> : tableRows}
				</tbody>
			</TableWrapper>
		</StaffMemberTimeline>
	</Card>
		<CreateBonusEntrySidebar
			isOpen={showCreateBonusSidebar}
			onClose={() => {
				setShowCreateBonusSidebar(false);
			}}
			staffMemberId={staffMemberId}
		/>
		<DeleteBonusEntryModal bonusData={bonusDataToDelete}
							   onCloseClick={() => setBonusDataToDelete(null)}
							   isOpen={!!bonusDataToDelete} />
		<UpdateBonusEntrySidebar isOpen={!!bonusDataToEdit}
								 onClose={() => setBonusDataToEdit(null)}
								 bonusData={bonusDataToEdit} />
	</>;
};

export default BonusesSection;