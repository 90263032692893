import type {
	ProjectDescriptionCardData,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { byMultipleObjectProperties } from "~/utils/sortFunctions.ts";

export function getTagFilterResults(productTags: ProjectTagComboboxOption[],
	deliverableTags: ProjectTagComboboxOption[],
	projects: ProjectDescriptionCardData[]): ProjectDescriptionCardData[] {
	const result: ProjectDescriptionCardData[] = [];

	projects.forEach(project => {
		const matchedDeliverableTagIds = project.deliverableTagIds.filter(tagId => deliverableTags.some(tag => tag.value === tagId));
		const matchedProductTagIds = project.productTagIds.filter(tagId => productTags.some(tag => tag.value === tagId));

		if (matchedDeliverableTagIds.length === 0 && matchedProductTagIds.length === 0) return;

		result.push({
			...project,
			matchedDeliverableTagIds,
			matchedProductTagIds,
			totalMatches: matchedDeliverableTagIds.length + matchedProductTagIds.length,
		});
	});
	return result.sort(byMultipleObjectProperties([{ property: "totalMatches", direction: "desc" }, {
		property: "title",
		direction: "asc",
	}]));

}

export function getSelectedTagsFromIds(tags: ProjectTagComboboxOption[], type: ProjectTagComboboxOption["type"],
	ids: string[]): ProjectTagComboboxOption[] {
	return tags.filter(tag => type === tag.type && ids.includes(tag.value));
}