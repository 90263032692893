import clsx from "clsx";

import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import Section from "~/components/sections/Section";
import type { AppRoute } from "~/constants/appRoute.ts";

interface Props {
	pages: (AppRoute | string)[];
	className?: string;
}

const BreadcrumbsSection: React.FC<Props> = ({ pages, className }) => {

	return <nav className={clsx("flex py-2", className)}
				aria-label="Breadcrumbs">
		<Section>
			<Breadcrumbs pages={pages} />
		</Section>
	</nav>;

};
export default BreadcrumbsSection;
