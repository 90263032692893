export const MONTHLY_INVOICING_PAGE_STATE_KEY = 'monthlyInvoicing';
export const PROJECTS_INDEX_PAGE_STATE_KEY = 'projectsIndex';
export const TIME_TRACKING_INDEX_PAGE_KEY = 'timeTrackingIndex';

export const ADMIN_USERS_INDEX_PAGE_STATE_KEY = 'adminUsersIndex';

export const STAFF_MEMBERS_INDEX_PAGE_STATE_KEY = 'staffMembersIndex';

export const HR_STATISTICS_PAGE_STATE_KEY = 'hrStatistics';

export const PROJECT_DETAILS_PAGE_STATE_KEY = (projectId:string) =>'projectDetails-' + projectId;

export const PROJECT_DESCRIPTION_VIEW_PAGE_STATE_KEY = 'projectDescriptionsIndex';