import {ReactComponent as Icon} from "./bookIcon.svg"

type StopwatchIconProps = {
	className?: string;
};

const BookIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default BookIcon;