import type React from "react";

import Avatar from "~/components/Avatar";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

type UserListCardProps = {
	avatarImage: AvatarImage | null;
	careerLevel: string;
	companyLocation?: string;
	email: string;
	employmentType: string;
	firstName: string;
	lastName: string;
	onRowClick: () => void;
};

const StaffMemberTableRow: React.FunctionComponent<UserListCardProps> = ({
	avatarImage,
	careerLevel,
	companyLocation,
	email,
	employmentType,
	firstName,
	lastName,
	onRowClick,
}) => {
	return (
		<tr className="cursor-pointer bg-white hover:shadow"
			onClick={onRowClick}>
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				<div className="flex items-center">
					<Avatar firstName={firstName}
							lastName={lastName}
							image={avatarImage}
					/>
					<div className="ml-4">
						<div className="font-medium text-gray-900">{`${firstName} ${lastName}`}</div>
						<div className="mt-1 text-gray-500">{email}</div>
					</div>
				</div>
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				<div className="text-gray-900">{careerLevel}</div>
				<div className="mt-1 text-gray-500">{employmentType}</div>
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{companyLocation || "--"}</td>
		</tr>
	);
};
export default StaffMemberTableRow;
