import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateInvoiceData,
	CreateInvoiceResponseType,
	Invoice,
	UpdateInvoiceData,
} from "~/modules/billing/api/invoice/invoiceTypes.ts";

export function generateInvoiceFile({ orderId, month }: {
	orderId: string,
	month: string
}): AxiosApiResponsePromise<CreateInvoiceResponseType> {
	return axiosApi.post(`/invoice-documents`, { orderId, month });
}

export function createInvoice(invoiceData: CreateInvoiceData): AxiosApiResponsePromise<CreateInvoiceResponseType> {
	return axiosApi.post(`/invoices`, invoiceData);
}

export function updateInvoice({ invoiceId, invoiceData }: {
	invoiceId: string,
	invoiceData: UpdateInvoiceData
}): AxiosApiResponsePromise<Invoice> {
	return axiosApi.put(`/invoices/${invoiceId}`, invoiceData);
}