import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import ExampleTheme from "~/components/TextEditor/EditorTheme.ts";

export const editorBaseConfig: InitialConfigType = {
    // The editor theme
    theme: ExampleTheme,
    editorState: null,
    namespace: "editor",
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        AutoLinkNode,
        LinkNode,
        /*            ExtendedTextNode,
                    { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key) },*/
    ]
};