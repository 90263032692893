import { t } from "i18next";

import {
	EmploymentTypeId,
	EmploymentTypeName,
} from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getEmploymentTypeDisplayNameById(employmentTypeId: EmploymentTypeId): string {
	let employmentTypeName = "";

	switch (employmentTypeId) {
		case EmploymentTypeId.Employed:
			employmentTypeName = EmploymentTypeName.Employed;
			break;
		case EmploymentTypeId.Freelancer:
			employmentTypeName = EmploymentTypeName.Freelancer;
			break;
		case EmploymentTypeId.Intern:
			employmentTypeName = EmploymentTypeName.Intern;
			break;
		case EmploymentTypeId.Student:
			employmentTypeName = EmploymentTypeName.Student;
			break;
		case EmploymentTypeId.Left:
			employmentTypeName = EmploymentTypeName.Left;
			break;
		default:
			throw new Error("Unknown employment type id");
	}
	if (!employmentTypeName) {
		return "unbekannt";
	}

	return t(normalizeTKey(`entities:employmentType.${employmentTypeName}`));
}