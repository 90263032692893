import { AbsenceTypeId, AbsenceTypeName } from "~/modules/absence/api/absence/absenceTypes.ts";
import type {
	AbsenceTypeThemeVariants,
} from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeBadge/absenceTypeBadgeVariantsVariants.ts";


export function getAbsenceTypeThemeById(absenceTypeId: AbsenceTypeId): AbsenceTypeThemeVariants {
	switch (absenceTypeId) {
		case AbsenceTypeId.Vacation:
			return "vacation";
		case AbsenceTypeId.Sick:
			return "sick";
		case AbsenceTypeId.Sabbatical:
		case AbsenceTypeId.MaternityLeave:
		case AbsenceTypeId.ParentalLeave:
			return "longTerm";
		default:
			return "default";
	}
}

export const absenceTypeFilterOptions = [
	{
		id: AbsenceTypeId.Vacation,
		name: AbsenceTypeName.Vacation,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.Vacation),
	},
	{
		id: AbsenceTypeId.Sick,
		name: AbsenceTypeName.Sick,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.Sick),
	},
	{
		id: AbsenceTypeId.Training,
		name: AbsenceTypeName.Training,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.Training),
	},
	{
		id: AbsenceTypeId.InternalEvent,
		name: AbsenceTypeName.InternalEvent,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.InternalEvent),
	},
	{
		id: AbsenceTypeId.Special,
		name: AbsenceTypeName.Special,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.Special),
	},
	{
		id: AbsenceTypeId.Sabbatical,
		name: AbsenceTypeName.Sabbatical,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.Sabbatical),
	},
	{
		id: AbsenceTypeId.MaternityLeave,
		name: AbsenceTypeName.MaternityLeave,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.MaternityLeave),
	},
	{
		id: AbsenceTypeId.ParentalLeave,
		name: AbsenceTypeName.ParentalLeave,
		theme: getAbsenceTypeThemeById(AbsenceTypeId.ParentalLeave),
	},

];