import clsx from "clsx";
import { NavLink } from "react-router-dom";

interface Props {
	href: string;
	current: boolean;
	name: string;
	icon?: any;
	isChild?: boolean;
}

const NavItem: React.FC<Props> = ({ href, current, name, icon, isChild }) => {
	const Icon = icon;
	return (
		<NavLink
			to={href}
			className={clsx(
				current
					? "bg-primary-700/50 text-secondary-500"
					: "text-gray-300 group-hover:bg-primary-700/50 group-hover:text-white",
				"transition-colors duration-150",
				"flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium tracking-wide",
				isChild ? "pl-12" : "pl-3",
			)}
		>
			{icon && <Icon className={"size-6 shrink-0 text-current"}
						   aria-hidden="true" />}
			{name}
		</NavLink>
	);
};

export default NavItem;
