import { PencilIcon, ShareIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProjectTag from "components/projectTags/ProjectTag";
import ProjectTagWithUsage from "components/projectTags/ProjectTagWithUsage";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardWrapper from "~/components/Card/components/CardWrapper";
import ContentWrapper from "~/components/ContentWrapper";
import SearchInput from "~/components/formElements/SearchInput";
import CreateDeliverableTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/CreateDeliverableTagSidebar";
import DeleteDeliveryTagModal
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/DeleteDeliveryTagModal";
import MergeDeliverableTagsSidebar
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/MergeDeliverableTagsSidebar";
import UpdateDeliverableTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/UpdateDelliverableTagSidebar";
import TaggedProjectListItem from "~/modules/admin/components/AdminView/components/TaggedProjectListItem";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import {
	useAllDeliverableTags,
	useDeliverableTagUsageReport,
} from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import type { DeliverableTagWithProjectId } from "~/modules/project/types/adminProjectTagsViewTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	loadingPercentage: number;
}

const AdminDeliverableTagsSection: FunctionComponent<Props> = ({
	loadingPercentage,
}) => {
	const [search, setSearch] = useState<string>("");
	const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
	const [selectedTagData, setSelectedTagData] = useState<DeliverableTagWithProjectId | null>(null);
	const [showCreateSidebar, setShowCreateSidebar] = useState<boolean>(false);
	const [deliverableTagDataToUpdate, setDeliverableTagDataToUpdate] = useState<DeliverableTag | null>(null);
	const [deliverableTagDataToDelete, setDeliverableTagDataToDelete] = useState<DeliverableTag | null>(null);
	const [deliverableTagDataToMerge, setDeliverableTagDataToMerge] = useState<DeliverableTag | null>(null);

	const { data: allClients } = useAllClients();
	const { data: allProjects } = useAllProjects();
	const { data: allDeliverableTags } = useAllDeliverableTags();
	const { data: usageReport } = useDeliverableTagUsageReport();

	const enhancedSortedTags: DeliverableTagWithProjectId[] = useMemo(() => {
		if (allDeliverableTags && usageReport) {
			return allDeliverableTags.map((tag) => {
				const projectIds = usageReport.find((report) => report.deliverableTagId === tag.id)?.projectIds || [];
				return {
					...tag,
					projectIds,
				};
			});
		}
		return [];
	}, [allDeliverableTags, usageReport]);

	const filteredTags = useMemo(() => {
		if (enhancedSortedTags) {
			const deliverableTagsSorted = enhancedSortedTags.sort(byObjectProperty("displayName"));
			if (search) {
				return deliverableTagsSorted.filter((tag) => tag.displayName.toLowerCase().includes(search.toLowerCase()));
			}
			return deliverableTagsSorted;
		}
		return undefined;
	}, [enhancedSortedTags, search]);

	// we're doing this in use effect to automatically update the selectedTagData when enhancedSortedTags updates
	useEffect(() => {
		if (enhancedSortedTags && selectedTagId) {
			const deliverableTagData = enhancedSortedTags.find((tag) => tag.id === selectedTagId);

			if (deliverableTagData) {
				setSelectedTagData(deliverableTagData);
			}
		}

		if (!selectedTagId) {
			setSelectedTagData(null);
		}
	}, [selectedTagId, enhancedSortedTags]);

	if (loadingPercentage !== 100) {
		return <LoadingPage pcent={loadingPercentage}/>;
	}
	return <ContentWrapper className="size-full overflow-hidden pb-10 pt-4">
		<div className="grid h-full grid-cols-5 gap-x-8">
			<div className="col-span-2 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max" className="flex flex-col">
					<CardHeader>
						<div className="mr-2 max-w-full grow">
							<SearchInput value={search}
										 onChange={setSearch} />
						</div>
						<Button onClick={() => setShowCreateSidebar(true)}>Neuer Tag</Button>
					</CardHeader>
					<CardContent hasTitle={true} className="grow overflow-y-auto">
						<div className="flex flex-col gap-y-2 ">
							{filteredTags?.map((tag) => {
								return <div key={tag.id}><ProjectTagWithUsage
									onClick={() => setSelectedTagId(tag.id)}
									size="sm"
									theme="deliverable"
									selected={selectedTagData?.id === tag.id}
									displayName={tag.displayName}
									numberUsages={tag.projectIds.length} /></div>;

							})}
						</div>
					</CardContent>
				</CardWrapper>
			</div>
			<div className="col-span-3 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max" className="flex flex-col">
					<CardHeader>
						<div className="max-w-40 grow-0">
							{selectedTagData ? <ProjectTag theme="deliverable"
														   size="md"
														   displayName={selectedTagData.displayName} /> :
								<ProjectTag size="md"
											displayName="Kein Tag ausgewählt"
											theme="neutral" />}
						</div>
						<div className="ml-2 flex shrink-0 justify-end gap-x-2 self-end">
							<Button theme="secondary"
									disabled={!selectedTagData}
									onClick={() => setDeliverableTagDataToMerge(selectedTagData)}
							>
								<ShareIcon className="size-4 rotate-180" />
								Tags zusammenführen</Button>
							<Button theme="danger"
									disabled={!selectedTagData}
									onClick={() => setDeliverableTagDataToDelete(selectedTagData)}
							>
								<TrashIcon className="size-4" />
								Löschen</Button>
							<Button theme="primary"
									disabled={!selectedTagData}
									onClick={() => setDeliverableTagDataToUpdate(selectedTagData)}>
								<PencilIcon className="size-4" />Bearbeiten</Button>
						</div>
					</CardHeader>
					<CardContent hasTitle={true} className="grow overflow-y-auto">
					<div className="flex flex-col gap-y-2">
						{selectedTagData && selectedTagData.projectIds.map((projectId) => {
							const projectData = allProjects?.find((project) => project.id === projectId);
							let clientDisplayName = "";
							if (projectData && allClients) {
								clientDisplayName = allClients.find((client) => client.id === projectData.clientId)?.displayName || "";
							}

							return projectData ? <TaggedProjectListItem key={projectId}
																		projectData={projectData}
																		clientDisplayName={clientDisplayName} /> : null;
						})}
						{selectedTagData && selectedTagData.projectIds.length === 0 &&
							<div className="text-gray-500">Dieser Tag ist keinem Projekt zugeordnet.</div>}
						{!selectedTagData &&
							<div className="text-gray-500">Tag auswählen, um dessen Details anzuzeigen</div>}
					</div>
					</CardContent>

				</CardWrapper>

			</div>
		</div>
		<CreateDeliverableTagSidebar isOpen={showCreateSidebar}
									 onClose={() => setShowCreateSidebar(false)} />
		<UpdateDeliverableTagSidebar isOpen={!!deliverableTagDataToUpdate}
									 onClose={() => setDeliverableTagDataToUpdate(null)}
									 deliverableTagData={deliverableTagDataToUpdate} />
		<MergeDeliverableTagsSidebar isOpen={!!deliverableTagDataToMerge}
									 onClose={() => setDeliverableTagDataToMerge(null)}
									 deliverableTagData={deliverableTagDataToMerge} />
		<DeleteDeliveryTagModal isOpen={!!deliverableTagDataToDelete}
								onClose={() => setDeliverableTagDataToDelete(null)}
								deliverableTagData={deliverableTagDataToDelete}
								setSelectedTagId={setSelectedTagId}
		/>
	</ContentWrapper>;
};

export default AdminDeliverableTagsSection;