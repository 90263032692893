import Button from "components/buttons/Button";
import ClientItem from "modules/client/components/ClientItem";
import UpdateCompanySidebar from "modules/client/components/CompanyDetailsView/components/UpdateCompanySidebar";
import CreateClientSidebar from "modules/client/components/CreateClientSidebar";
import CreateCompanySidebar from "modules/client/components/CreateCompanySidebar";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ContentWrapper from "~/components/ContentWrapper";
import SearchInput from "~/components/formElements/SearchInput";
import PageHeading from "~/components/headings/PageHeading";
import SectionHeading from "~/components/headings/SectionHeading";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import MainContent from "~/components/mainContent/MainContent";
import NoEntriesFound from "~/components/NoEntriesFound";
import { appRoutes, COMPANY_DETAILS_PATH } from "~/constants/appRoute.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import CompaniesListHead from "~/modules/client/components/CompaniesListHead";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import { isMobileOrTablet } from "~/utils/mobileAndTabletDetection.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientsViewProps {
	allCompanyAvatarImages?: AvatarImage[];
	companies?: Company[];
	projects?: Project[];
	clients?: Client[];
}

const ClientsIndexView: React.FC<ClientsViewProps> = ({ allCompanyAvatarImages, companies, projects, clients }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [sidebarNewCustomerVisible, setSidebarNewCustomerVisible] = useState(false);
	const [companyIdToUpdate, setCompanyIdToUpdate] = useState<string | null>(null);
	const [selectedCompanyIdForNewClient, setSelectedCompanyIdForNewClient] = useState<string | null>(null);
	const [search, setSearch] = useState("");

	const searchInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		// autofocus only on wider screens
		if (searchInputRef.current && !isMobileOrTablet()) {
			searchInputRef.current.focus();
		}
	}, []);

	const companyIdsOfClientSearchMatches = clients ? clients.filter((client) => client.displayName.toLowerCase().includes(search.trim().toLowerCase())).map(client => client.companyId) : [];

	const directCompanyIdMatches = companies ? companies.filter((company) => company.displayName.toLowerCase().includes(search.trim().toLowerCase())).map(company => company.id) : [];

	return (
		<MainContent>
			<BreadcrumbsSection pages={[appRoutes.clients()]}
								className="bg-white" />

			<PageHeading title={t("clients.title", "Alle Kunden")} />
			<PageHeading.BottomBar>
				<div className="flex w-full justify-between">
					<div className="w-48">
						<SearchInput placeholder="Kunde..."
									 value={search}
									 onChange={setSearch}
									 ref={searchInputRef} />
					</div>
					<Button onClick={() => setSidebarNewCustomerVisible(true)}>
						{t("clients.btnAddNewClient", "Neuer Kunde")}
					</Button>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper>
				<SectionHeading sticky
								style={{ top: 73 }}>
					<SectionHeadingTabs
						tabs={[
							{ name: "Kunden", value: "clients", active: true },
							{ name: "Kontakte", value: "contacts" },
						]}
						onTabClick={(tab) => {
							"clients" === tab && navigate(appRoutes.clients().path, { replace: true });
							"contacts" === tab && navigate(appRoutes.contacts().path, { replace: true });
						}}
					/>
				</SectionHeading>

				<div className="mt-8 flex flex-col gap-10">
					{companyIdsOfClientSearchMatches.length === 0 && directCompanyIdMatches.length === 0 ? (
						<NoEntriesFound />
					) : (
						allCompanyAvatarImages &&
						companies &&
						clients &&
						projects &&
						companies.sort(byObjectProperty("displayName")).map(({
							id,
							avatarImageId,
							displayName,
						}) => {
							if (!companyIdsOfClientSearchMatches.includes(id) && !directCompanyIdMatches.includes(id)) return null;
							const companyClients = clients.filter((client) => client.companyId === id);
							const firstClient = companyClients.sort(byObjectProperty("id")).shift() as Client;
							const companyClientsSorted = [firstClient, ...companyClients.sort(byObjectProperty("displayName"))].filter(Boolean);
							const clientIds = companyClientsSorted.map((client) => client.id);
							const companyProjects = projects.filter((project) => clientIds.includes(project.clientId));
							const projectCount = companyProjects.length;
							const avatarImage = allCompanyAvatarImages.find((image) => image.id === avatarImageId);
							return (
								<div key={id}>
									<button className="flex flex-col"
											onClick={() => navigate(generatePath(COMPANY_DETAILS_PATH, { companyId: id }))}>
										<CompaniesListHead className="hover:text-gray-600"
														   avatarImage={avatarImage}
														   companyName={displayName}
														   projectCount={projectCount}
										/>
									</button>

									<ul
										role="list"
										className="my-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
									>
										{companyClientsSorted
											.map(({ id, displayName, city }) => (
												<ClientItem
													displayName={displayName}
													city={city || ""}
													href={generatePath(appRoutes.client().path, { clientId: id })}
													key={`client-${id}`}
												/>
											))}
										<ButtonNewItem
											size="sm"
											onClick={() => setSelectedCompanyIdForNewClient(id)}
										/>
									</ul>
								</div>
							);
						})
					)}
				</div>
			</ContentWrapper>
			<CreateCompanySidebar
				isOpen={sidebarNewCustomerVisible}
				setOpen={() => setSidebarNewCustomerVisible(!sidebarNewCustomerVisible)}
			/>
			<CreateClientSidebar
				selectedCompanyId={selectedCompanyIdForNewClient}
				isOpen={!!selectedCompanyIdForNewClient}
				onClose={() => setSelectedCompanyIdForNewClient(null)}
			/>
			<UpdateCompanySidebar isOpen={companyIdToUpdate !== null}
								  setOpen={() => setCompanyIdToUpdate(null)}
								  companyId={companyIdToUpdate!} />
		</MainContent>
	);
};

export default ClientsIndexView;
