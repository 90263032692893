import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBER_QUERY_KEY } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import UpdateContactDetailsForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateContactDetailsSidebar/components/UpdateContactDetailsForm";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type UpdatePersonalDetailsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateContactDetailsSidebar: React.FunctionComponent<UpdatePersonalDetailsSidebarProps> = ({
	isOpen,
	onClose,
	staffMemberCoreData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(staffMemberCoreData.id));
		queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);
		onClose();
	}, [onClose, queryClient, staffMemberCoreData.id]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<UpdateContactDetailsForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberCoreData={staffMemberCoreData} />
		</Sidebar>
	);
};

export default UpdateContactDetailsSidebar;
