import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersCareerLevels,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelApiDispatchers.ts";

export const STAFF_MEMBERS_CAREER_LEVELS_BASE_QUERY_KEY = "staffMembersCareerLevels";

export const STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBERS_CAREER_LEVELS_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersCareerLevels = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY(staffMemberId),
	() => indexStaffMembersCareerLevels(staffMemberId).then(getDataFromResponse),
	options,
);