import type React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { deleteWorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";
import { STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { WorkingScheduleWithDate } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import WorkingScheduleWeek
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection/components/WorkingScheduleWeek";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type DeleteWorkingScheduleModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	workingScheduleData: WorkingScheduleWithDate | null;
};

const DeleteWorkingScheduleModal: React.FunctionComponent<DeleteWorkingScheduleModalProps> = ({
	isOpen,
	onCloseClick,
	workingScheduleData,

}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		if (!workingScheduleData) return;
		try {
			setBusy(true);
			const staffMemberId = workingScheduleData.staffMemberId;
			await deleteWorkingSchedule({ staffMemberId: staffMemberId, workingScheduleId: workingScheduleData.id });

			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId));
			onCloseClick();
		} catch (error) {
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Arbeitszeitmodell löschen`,
		bodyText: `Möchtest Du diese Arbeitszeitmodell wirklich löschen?`,
		deleteButtonText: `Arbeitszeitmodell löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!workingScheduleData && <div className="mb-6 flex flex-col">
				<Label>Gültig ab</Label>
				<span className="text-base">{formatDateWithGermanMonth(workingScheduleData.validFrom)}</span>
				<div className="h-4"/>
				<Label>Arbeitszeitmodell</Label>
				<div className="h-1"/>
				<WorkingScheduleWeek workingScheduleData={workingScheduleData} />
			</div>}
		</DeleteEntityView>);
};

export default DeleteWorkingScheduleModal;
