import Button from "components/buttons/Button";
import { useCallback, useState } from "react";

import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import { useAuth } from "~/contexts/AuthContext";
import useUserSelectOptions from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";

const ImpersonationWidget: React.FunctionComponent = () => {
	const { user, isImpersonating, impersonateUser, stopImpersonating } = useAuth();
	const userSelectOptions = useUserSelectOptions();
	const [selectedUserId, setSelectedUserId] = useState(user?.id);

	const handleImpersonate = useCallback(async () => {
		if (user && selectedUserId && selectedUserId !== user.id) {
			await impersonateUser(selectedUserId);
		}
	}, [impersonateUser, selectedUserId, user]);

	const handleSelectChange = useCallback((value:string) => {
		setSelectedUserId(value);
	}, []);

	return <div className="">
		{!isImpersonating ? <><Button onClick={handleImpersonate}>Impersonate</Button>
			<SelectPlain value={selectedUserId} optionsData={userSelectOptions} onChange={handleSelectChange}
			></SelectPlain></> : <Button onClick={stopImpersonating}>Stop Impersonating</Button>}
	</div>;
};

export default ImpersonationWidget;