import { useMemo } from "react";

import { useAllCountries } from "~/modules/country/api/country/countryQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData,getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";


const useCountrySelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: countryData } = useAllCountries();
	return useMemo(() => {
		if (countryData) {
			const options = countryData.map((country) => {
				return createInputOptionData(country.displayName, country.id);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [countryData, includeCtaOption]);
};

export default useCountrySelectOptions;

