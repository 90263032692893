import Decimal from "decimal.js-light";
import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	deleteUsersVacationEntitlement,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementApiDispatchers.ts";
import {
	STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import type { VacationEntitlement } from "~/modules/absence/api/vacationEntitlement/vacationEntitlementTypes.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type DeleteVacationLedgerEntryModalProps = {
	vacationEntitlement: VacationEntitlement | null;
	isOpen: boolean;
	onCloseClick: () => void;
};

const DeleteVacationEntitlementModal: React.FunctionComponent<DeleteVacationLedgerEntryModalProps> = ({
	vacationEntitlement,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!vacationEntitlement) return;
		try {
			setBusy(true);
			await deleteUsersVacationEntitlement({
				staffMemberId: vacationEntitlement.staffMemberId,
				vacationEntitlementId: vacationEntitlement.id,
			});
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY(vacationEntitlement.staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(vacationEntitlement.staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(vacationEntitlement.staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(vacationEntitlement.staffMemberId));
			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			debugger;
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.vacationEntitlementId?.find(
						(validationError) => validationError.rule === "vacationEntitlementIsDeletableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Urlaubsanspruch löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!vacationEntitlement && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base font-bold">{formatDateWithGermanMonth(new Date(vacationEntitlement.validFrom))}</span>
				</div>
				<div>
					<Label>Urlaubsanspruch in Arbeitstagen (Vollzeit)</Label>
					<span className="text-base font-bold">{formatNumberWithComma(new Decimal(parseFloat(vacationEntitlement.workDays)).toNumber(), 2)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteVacationEntitlementModal;
