import { useMemo } from "react";

import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


export const staffableUsersFilterFn = (user: User) => user.employmentTypeId !== EmploymentTypeId.Left;

export const allActiveUsersFilterFn = (user: User) => user.isActive;

export const employeesFilterFn = (user: User) => user.employmentTypeId === EmploymentTypeId.Employed;

export const internalStaffFilterFn = (user: User) => user.employmentTypeId !== EmploymentTypeId.Freelancer && user.employmentTypeId !== EmploymentTypeId.Left;

export const employeesWithLeftFilterFn = (user: User) => user.employmentTypeId !== EmploymentTypeId.Freelancer;


type UserSelectOptionArgs = {
	filterFunction?: (user: User) => boolean;
	ignoreIds?: string[];
};

type IgnoreIdsFilterFunction = (user: User) => boolean;
const useUserSelectOptions = (options?: UserSelectOptionArgs): FormInputOption[] => {
	const { data: usersData } = useAllUsers();
	return useMemo(() => {
		if (usersData) {
			let filteredUsers = usersData;
			if (options?.filterFunction) {
				let ignoreIdsFn: IgnoreIdsFilterFunction = () => false;

				if (options.ignoreIds) {
					ignoreIdsFn = (user) => options.ignoreIds!.includes(user.id);
				}
				filteredUsers = usersData.filter(user => options.filterFunction!(user) || ignoreIdsFn(user));
			}
			return filteredUsers.sort(byObjectProperty("firstName")).map((user) => {
				return createInputOptionData(user.fullName, user.id);
			});

		}
		return [];

	}, [usersData, options]);
};

export default useUserSelectOptions;

