import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberDocumentLinkData,
	StaffMemberDocumentLink,
	UpdateStaffMemberDocumentLinkData,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkTypes.ts";


const documentLinksBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/document-links`;

export function indexStaffMembersDocumentLinks(staffMemberId: string): AxiosApiResponsePromise<StaffMemberDocumentLink[]> {
	return axiosApi.get(documentLinksBasePath(staffMemberId));
}

export function createStaffMemberDocumentLink(staffMemberId: string,
	documentLinkData: CreateStaffMemberDocumentLinkData): AxiosApiResponsePromise<void> {
	return axiosApi.post(documentLinksBasePath(staffMemberId), documentLinkData);
}

export function updateStaffMemberDocumentLink({ staffMemberId, documentLinkId, updateData }: {
	staffMemberId: string;
	documentLinkId: string;
	updateData: UpdateStaffMemberDocumentLinkData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${documentLinksBasePath(staffMemberId)}/${documentLinkId}`, updateData);
}

export function deleteStaffMemberDocumentLink(staffMemberId: string,
	documentLinkId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${documentLinksBasePath(staffMemberId)}/${documentLinkId}`);
}