import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexProjectPhasesByProjectId } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import type { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

interface UseProjectsProjectPhasesParamsType extends UseQueryBaseParams {
	projectId: string;
}

const ENTITY_QUERY_KEY = "projectPhase";

export const PROJECTS_PHASES_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];

export const useProjectsProjectPhases = ({ projectId, options }: UseProjectsProjectPhasesParamsType) => useQuery(
	PROJECTS_PHASES_QUERY_KEY(projectId),
	() => indexProjectPhasesByProjectId(projectId).then(getDataFromResponse),
	options,
);