import type { PropsWithChildren } from "react";

import Button from "~/components/buttons/Button";
import type { CardVariantProps } from "~/components/Card/cardVariants.ts";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardTitle from "~/components/Card/components/CardTitle";
import CardWrapper from "~/components/Card/components/CardWrapper";

type CardProps = PropsWithChildren & CardVariantProps & {
	title?: string;
	headerButtonText?: string;
	onHeaderButtonClick?: () => void;
};

const Card: React.FunctionComponent<CardProps> = ({
		title,
		headerButtonText,
		onHeaderButtonClick,
		children,
		...variantProps
	}) => {
		return <CardWrapper hasTitle={!!title} {...variantProps}>
			{title && <CardHeader hasTitle={!!title} {...variantProps}>
				<CardTitle>{title}</CardTitle>

				{headerButtonText && onHeaderButtonClick && <Button size="auto"
																	theme="plain"
																	className="px-2 py-1 text-xs"
																	onClick={onHeaderButtonClick}>{headerButtonText}</Button>}
			</CardHeader>}
			<CardContent hasTitle={!!title} {...variantProps}>{children}</CardContent>
		</CardWrapper>;
	}
;

export default Card;