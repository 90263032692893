import { CheckIcon, EllipsisHorizontalIcon, PencilIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type React from "react";

import Avatar from "~/components/Avatar";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

type UserListCardProps = {
	avatarImage: AvatarImage | null;
	careerLevel: string;
	companyLocation?: string;
	email: string;
	employmentType: string;
	firstName: string;
	isActive: boolean;
	jobDescription?: string;
	lastName: string;
	nickname?: string;
	phone?: string;
	shortName: string;
	permissionNames: string[];
	onEditClick: () => void;
	onUpdateStatusClick: () => void;
	onUpdatePasswordClick: () => void;
};

const UserTableRow: React.FunctionComponent<UserListCardProps> = ({
	avatarImage,
	careerLevel,
	companyLocation,
	email,
	employmentType,
	firstName,
	isActive,
	lastName,
	onEditClick,
	onUpdatePasswordClick,
	onUpdateStatusClick,
}) => {
	return (
		<tr className="bg-white">
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				<div className="flex items-center">
					<Avatar firstName={firstName} lastName={lastName} image={avatarImage}/>
					<div className="ml-4">
						<div className="font-medium text-gray-900">{`${firstName} ${lastName}`}</div>
						<div className="mt-1 text-gray-500">{email}</div>
					</div>
				</div>
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				<div className="text-gray-900">{careerLevel}</div>
				<div className="mt-1 text-gray-500">{employmentType}</div>
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{companyLocation || "--"}</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				<span
					className={clsx(
						"inline-flex items-center rounded-md " + "px-2 py-1 text-xs font-medium  ring-1 ring-inset ",
						isActive
							? "bg-green-50 text-green-700 ring-green-600/20"
							: "bg-red-50 text-red-700 ring-red-600/20",
					)}
				>
					{isActive ? "Aktiv" : "Inaktiv"}
				</span>
			</td>
			<td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm">
				<ButtonWithPopover
					theme="dark"
					items={[
						{
							label: "Bearbeiten",
							onClick: onEditClick,
							icon: PencilIcon,
						},
						{
							label: isActive ? "Deaktivieren" : "Aktivieren",
							onClick: onUpdateStatusClick,
							icon: isActive ? XMarkIcon : CheckIcon,
						},
						{
							label: "Passwort ändern",
							onClick: onUpdatePasswordClick,
							icon: EllipsisHorizontalIcon
						},
					]}
				/>
			</td>
		</tr>
	);
};
export default UserTableRow;
