import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { ProductTag, ProductTagUsageReport } from "~/modules/project/api/productTags/productTagsTypes.ts";

const basePath = "product-tags";

export function indexProductTags(): AxiosApiResponsePromise<ProductTag[]> {
	return axiosApi.get(basePath);
}

export function getProductTagsUsageReport(): AxiosApiResponsePromise<ProductTagUsageReport[]> {
	return axiosApi.get(`${basePath}/usage-report`);
}

export function createProductTag({ displayName }: { displayName: string }): AxiosApiResponsePromise<ProductTag> {
	return axiosApi.post(basePath, { displayName });
}

export function updateProductTag({ id, displayName }: {
	id: string,
	displayName: string
}): AxiosApiResponsePromise<ProductTag> {
	return axiosApi.put(`${basePath}/${id}`, { displayName });
}

export function deleteProductTag({ id }: { id: string }): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${basePath}/${id}`);
}