import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getReportByMonth,
} from "~/modules/humanResources/api/staffMonthlyReport/staffMonthlyReportApiDispatchers.ts";

export const MONTHLY_COMPENSATION_REPORTS_BASE_QUERY_KEY = "monthly-compensation-reports";
export const MONTHLY_REPORT_QUERY_KEY = (startOfMonth: string) => [MONTHLY_COMPENSATION_REPORTS_BASE_QUERY_KEY, startOfMonth];

export const useMonthlyReport = ({ startOfMonth, options }: {
	startOfMonth: string,
	options?: QueriesOptions<any>
}) => useQuery(
	MONTHLY_REPORT_QUERY_KEY(startOfMonth),
	() => getReportByMonth(startOfMonth).then(getDataFromResponse),
	options,
);