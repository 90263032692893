import { useAllCountries } from "~/modules/country/api/country/countryQueries";

interface Props {
	id: string;
}

const CountryDisplay: React.FC<Props> = ({ id }) => {
	const { data } = useAllCountries();
	if (undefined === data || undefined === id) return null;
	const countryData = data.find((c) => id === c.id);
	if (undefined === countryData) return null;
	const { displayName } = countryData;
	return <>{displayName}</>;
};

export default CountryDisplay;
