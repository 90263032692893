import { isAfter } from "date-fns";

import { byObjectProperty } from "~/utils/sortFunctions.ts";

export type ArrayFilterCriteria<T> = {
	[Property in keyof T]?: (value: T[Property]) => boolean;
};

export function filterArray<T>(items: T[], criteria: ArrayFilterCriteria<T>): T[] {
	return items.filter(item => {
		for (const key in criteria) {
			if (criteria[key] && !criteria[key]!(item[key])) {
				return false;
			}
		}
		return true;
	});
}

export function getIndices<T>(array: T[], filterFunction: (value: T) => boolean): number[] {
	const indices: number[] = [];
	array.forEach((value, index) => {
		if (filterFunction(value)) {
			indices.push(index);
		}
	});
	return indices;
}

export function toggleItemInArray<T>(arr: T[], item: T): T[] {
	// Check if the item is already in the array
	const index = arr.indexOf(item);

	// Clone the array
	const newArr = [...arr];

	if (index === -1) {
		// Item not found, add it
		newArr.push(item);
	} else {
		// Item found, remove it
		newArr.splice(index, 1);
	}

	// Return the new array
	return newArr;
}

export function getEntryValidAtDate<T extends { validFrom: Date }>(entries: T[], date: Date, entriesAreSorted=false): T | undefined {
	if (entries.length === 0) {
		return undefined;
	}
	const entriesSorted = entriesAreSorted ? entries : entries.sort(byObjectProperty("validFrom", "asc"));
	let validEntry: T | undefined = undefined;

	entriesSorted.forEach((entry) => {
		if (!isAfter(entry.validFrom, date)) {
			validEntry = entry;
		}
	});

	return validEntry;
}