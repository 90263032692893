import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import ComboBox from "~/components/formElements/ComboBox";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import TitleFormSection from "~/components/formSections/TitleFormSection";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientsProgramsSelectOptions from "~/hooks/form/formOptionsData/useClientsProgramSelectOptions.ts";
import useClientsQuotaSelectOptions from "~/hooks/form/formOptionsData/useClientsQuotaSelectOptions.ts";
import useUserSelectOptions, { allActiveUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectTitleValidationComparisonData.ts";
import { updateProject } from "~/modules/project/api/project/projectApiDispatchers.ts";
import type { Project, UpdateProjectData } from "~/modules/project/api/project/projectTypes.ts";
import ProgramAndQuotaFormSection from "~/modules/project/components/forms/formSections/ProgramAndQuotaFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateProjectFormProps = {
	projectData: Project;
	onSuccess: () => void;
	onCancel: () => void;
};

interface UpdateProjectFormData extends UpdateProjectData {
}

const UpdateProjectForm: React.FC<CreateProjectFormProps> = ({ projectData, onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const titleComparisonData = useProjectTitleValidationComparisonData(projectData.clientId);
	console.log(titleComparisonData);
	const schema = useMemo(() => {
		return yup.object({
			clientContactPersonId: yup.string().required(),
			comment: yup.string().default(null),
			managedBy: yup.string().required(),
			programId: yup.string().default(null),
			quotaId: yup.string().default(null),
			title: yup.string().required().uniqueProjectTitle(titleComparisonData, projectData.id),
		});
	}, [titleComparisonData, projectData]);
	
	const defaultValues = useMemo(() => {
		return {
			clientContactPersonId: projectData.clientContactPersonId,
			comment: projectData.comment || "",
			managedBy: projectData.managedBy,
			programId: projectData.programId || "",
			quotaId: projectData.quotaId || "",
			title: projectData.title,
		};
	}, [projectData]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		trigger,
	} = useForm<UpdateProjectFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProjectFormData>(schema),
	});

	const managerSelectOptions = useUserSelectOptions({
		filterFunction: allActiveUsersFilterFn,
		ignoreIds: [projectData.managedBy],
	});
	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(projectData.clientId, projectData.clientContactPersonId);
	const programSelectOptions = useClientsProgramsSelectOptions(projectData.clientId);
	const quotaSelectOptions = useClientsQuotaSelectOptions(projectData.clientId);
	const isSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		trigger();
	}, [trigger]);

	const onSubmit: SubmitHandler<UpdateProjectFormData> = async (data: UpdateProjectFormData) => {
		setBusy(true);

		try {
			await updateProject({ projectId: projectData.id, projectData: data });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Projekt bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<TitleFormSection control={control}
									  title="Projektname" />

					<FormSection title="Ansprechpartner Kunde">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									allowNew={false}
									name="clientContactPersonId"
									optionsData={clientContactPersonSelectOptions}
									control={control}
									error={errors.clientContactPersonId?.message as string}
								/>
							</div>
						</div>
					</FormSection>
					<ProgramAndQuotaFormSection
						control={control}
						errors={errors}
						programInputDisabled={programSelectOptions.length === 0}
						programSelectOptions={programSelectOptions}
						quotaInputDisabled={quotaSelectOptions.length === 0}
						quotaSelectOptions={quotaSelectOptions}
					/>
					<FormSection title="Verantwortlichkeit">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									allowNew={false}
									name="managedBy"
									label="Ansprechpartner"
									error={errors.managedBy?.message}
									optionsData={managerSelectOptions}
									control={control}
								/>
							</div>
						</div>
					</FormSection>
					<CommentFormSection
						control={control}
						placeholder={"Hier können allgemeine Anmerkungen zum Projekt hinterlegt werden."}
						label="Kommentar"
						title="Anmerkungen"
					/>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<SubmitButton busy={busy}
							  disabled={!isSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateProjectForm;
