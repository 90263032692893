import type { FunctionComponent} from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { All_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { ALL_PROJECTS_QUERY_KEY, CLIENTS_PROJECTS_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import CreateProjectForm from "~/modules/project/components/forms/CreateProjectForm";

type AddProjectToClientSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
};

const AddProjectToClientSidebar: FunctionComponent<AddProjectToClientSidebarProps> = ({
																								isOpen,
																								setOpen,
																								clientId,
																							}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		queryClient.invalidateQueries(ALL_PROJECTS_QUERY_KEY);
		queryClient.invalidateQueries(All_LOCATIONS_QUERY_KEY);
		await queryClient.invalidateQueries(CLIENTS_PROJECTS_QUERY_KEY(clientId));
		setOpen(false);
	}, [queryClient, clientId, setOpen]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			<CreateProjectForm clientId={clientId} onSuccess={handleSuccess} onCancel={() => setOpen(false)} />
		</Sidebar>
	);
};

export default AddProjectToClientSidebar;
