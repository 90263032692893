export function formatNumberWithComma(input: number, maxNumberOfDigits: number, removeTrailingZeros?:boolean): string {
	// Convert number to a string with max decimal places using toFixed.
	let formattedNumber = input.toFixed(maxNumberOfDigits);

	// Remove trailing zeros after the decimal point.
	if(removeTrailingZeros){
		formattedNumber = formattedNumber.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
	}

	// Replace dot with comma.
	return formattedNumber.replace('.', ',');
}

export function roundToTwoDecimals(input: number): number {
	return Math.round(input * 100) / 100;
}