import type { FunctionComponent } from "react";

import Hint from "~/components/Hint";
import Section from "~/components/sections/Section";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import VacationEntitlementsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/VacationEntitlementsSection";
import WorkingSchedulesSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection";
import { useAllSettings } from "~/modules/settings/api/settings/settingsQueries.ts";
import type { Setting } from "~/modules/settings/api/settings/settingsTypes.ts";
import { SettingKey } from "~/modules/settings/api/settings/settingsTypes.ts";

type Props = {
	staffMemberCoreData: StaffMember;
};

const StaffMemberWorkingSchedulesSection: FunctionComponent<Props> = ({ staffMemberCoreData }) => {

	const {
		data: allSettingsData,
	} = useAllSettings();

	const workingSchedulesMinDateSetting = allSettingsData?.find((setting: Setting) => setting.key === SettingKey.EmploymentEntitiesMinDate);
	const minDateNewEntry = workingSchedulesMinDateSetting ? new Date(workingSchedulesMinDateSetting.value) : new Date();

	return <Section scrollable={true}
					className="pb-10">
		<div className="grid w-full grid-cols-5 gap-4">
			{!staffMemberCoreData.userId ?
				<div className="col-span-5"><Hint>Mit diesem Mitarbeiter ist noch kein User verknüpft, daher können das Arbeitszeitmodell und der Urlaubsanspruch nicht bearbeitet werden.</Hint></div>
				: <>
					<div className="col-span-3">
						<WorkingSchedulesSection
							minDateNewEntry={minDateNewEntry}
							staffMemberCoreData={staffMemberCoreData} />
					</div>
					<div className="col-span-2">
						<VacationEntitlementsSection
							minDateNewEntry={minDateNewEntry}
							staffMemberCoreData={staffMemberCoreData} />

					</div>
				</>}
		</div>
	</Section>;

};

export default StaffMemberWorkingSchedulesSection;