import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import Headline from "~/components/Headline";

type NewFirstItemCtaProps = {
	buttonText: string;
	headline: string;
	onCreateClick: () => void;
	subLineText: string;
};

const NewFirstItemCta: React.FunctionComponent<NewFirstItemCtaProps> = ({
	buttonText,
	headline,
	onCreateClick,
	subLineText,
}) => {
	return <div className="flex flex-col">
		<Headline type="h5">{headline} </Headline>
		<p className="mt-1 text-sm text-gray-500">{subLineText}</p>
		<div className="mt-6 w-1/3">
			<ButtonNewItem onClick={onCreateClick}>{buttonText}</ButtonNewItem>
		</div>
	</div>;
};
export default NewFirstItemCta;