import type React from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteProductTag } from "~/modules/project/api/productTags/productTagsApiDispatchers.ts";
import { ALL_PRODUCT_TAGS_QUERY_KEY } from "~/modules/project/api/productTags/productTagsQueries.ts";
import type { ProductTag } from "~/modules/project/api/productTags/productTagsTypes.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";

type DeleteProductTagModalProps = {
	isOpen: boolean;
	onClose: () => void;
	productTagData: ProductTag | null;
	setSelectedTagId: DispatchStateAction<string | null>
};

const DeleteProductTagModal: React.FunctionComponent<DeleteProductTagModalProps> = ({
	isOpen,
	onClose,
	productTagData,
	setSelectedTagId,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	const handleClose = useCallback(() => {
		setBusy(false);
		setError("");
		onClose();
	}, [onClose]);
	const handleConfirmationClick = async () => {
		if (!productTagData) return;
		try {
			setBusy(true);
			await deleteProductTag({ id: productTagData.id });
			await queryClient.refetchQueries(ALL_PRODUCT_TAGS_QUERY_KEY);
			setSelectedTagId(null);
			handleClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectProductTagId.find(
						(validationError) => validationError.rule === "projectProductTagIsDeletableRule",
					)
				) {
					setError("Der Tag kann nicht gelöscht werden, da er noch Projekten zugeordnet ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Produkt Tag "${productTagData?.displayName}" löschen`,
		bodyText: `Möchtest Du den Produkt Tag wirklich löschen?`,
		deleteButtonText: `Produkt Tag löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={handleClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteProductTagModal;
