import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	createQuotaEndpoint,
	getAllQuotasEndpoint,
	getQuotaEndpoint,
	indexQuotasByClientIdEndpoint, updateQuotaEndpoint,
} from "~/modules/project/api/quota/quotaApiEndpoints.ts";
import type { CreateQuotaData, Quota, UpdateQuotaData } from "~/modules/project/api/quota/quotaTypes.ts";

export function getQuota(clientId: string, quotaId: string): AxiosApiResponsePromise<Quota> {
	return axiosApi.get(getQuotaEndpoint(clientId, quotaId));
}

export function getAllQuotas(): AxiosApiResponsePromise<Quota[]> {
	return axiosApi.get(getAllQuotasEndpoint);
}

export function indexQuotasByClientId(clientId: string): AxiosApiResponsePromise<Quota[]> {
	return axiosApi.get(indexQuotasByClientIdEndpoint(clientId));
}

export function createQuota(clientId: string, data: CreateQuotaData): AxiosApiResponsePromise<Quota> {
	return axiosApi.post(createQuotaEndpoint(clientId), { ...data });
}

export function updateQuota(clientId: string, quotaId: string, data: UpdateQuotaData): AxiosApiResponsePromise<Quota> {
	return axiosApi.put(updateQuotaEndpoint(clientId, quotaId), { ...data });
}
