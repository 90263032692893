import { useMemo } from "react";

import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import type { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";


export const useClientValidationComparisonData = (): UniqueValidatorArrayObject[] => {
	const {
		data: allClientsData,
	} = useAllClients();

	return useMemo(() => {
		if (allClientsData) {
			return allClientsData.map((client) => {
				return {
					id: client.id,
					value: sanitizeStringForComparison(client.displayName),
				};
			});
		}

		return [];
	}, [allClientsData]);
};