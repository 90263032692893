import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

type TableBodyProps = PropsWithChildren & {
	horizontalBorders?: boolean;
}

const TableBody: FunctionComponent<TableBodyProps> = ({ children,horizontalBorders=true }) => {
	return <tbody className={clsx("bg-inherit", horizontalBorders && "divide-y")}>{children}</tbody>;
};

export default TableBody;