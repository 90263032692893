import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { Area } from "react-easy-crop";
import Cropper from "react-easy-crop";

import Button from "~/components/buttons/Button";
import { SliderPlain } from "~/components/formElements/Slider/Slider.tsx";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import type { OriginalImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

type Props = {
	onSuccess: (formData: FormData) => void;
	onClose: () => void;
	initialImage?: OriginalImage;
	maxSizeMB?: number;
};

type Crop = {
	x: number;
	y: number;
};

const defaultZoom = 1;

const ImageUploadAndCropSidebar: FunctionComponent<Props> = ({
	onSuccess,
	onClose,
	initialImage = null,
	maxSizeMB = 5,
}) => {
	const [step, setStep] = useState<"upload" | "crop">("upload");
	const [image, setImage] = useState<string | null>(null);
	const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(defaultZoom);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
	const [dropZoneErrorMessages, setDropZoneErrorMessages] = useState<string[]>([]);
	const acceptedFileRef = useRef<File | null>(null);

	useEffect(() => {
		if (initialImage) {
			setImage(initialImage.url);
			setStep("crop");
		}
	}, [initialImage]);

	const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
		const file = acceptedFiles[0];
		const errorMessages: string[] = [];
		if (rejectedFiles && rejectedFiles.length > 0) {
			const code = rejectedFiles[0].errors[0].code;
			if (code === "file-invalid-type") {
				errorMessages.push("Es sind nur Dateien mit dem Format JPEG oder PNG erlaubt.");
			} else if (code === "file-too-large") {
				errorMessages.push(`Die maximale Dateigröße beträgt ${maxSizeMB}MB.`);
			}
		}

		setDropZoneErrorMessages(errorMessages);

		if (errorMessages.length > 0) {
			return;
		}

		const url = URL.createObjectURL(file);
		setImage(url);
		setStep("crop");
		acceptedFileRef.current = file;
	}, [maxSizeMB]);

	const { getRootProps, getInputProps, isDragReject, isDragAccept } = useDropzone({
		onDrop,
		accept: {
			"image/jpeg": [".jpeg", ".jpg"],
			"image/png": [".png"],
		},
		multiple: false,
		maxSize: maxSizeMB * 1024 * 1024,
	});

	const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const handleSubmit = async () => {
		if (image && croppedAreaPixels) {
			let originalFile: File;

			if (acceptedFileRef.current !== null) {
				originalFile = acceptedFileRef.current;
			} else if (initialImage) {
				const response = await fetch(initialImage.url);
				const blob = await response.blob();
				originalFile = new File([blob], "original.jpg", { type: blob.type });
			} else {
				console.error("No original file or initial image available");
				return;
			}

			const formData = new FormData();
			formData.append("image", originalFile, originalFile.name);
			formData.append("cropWidth", croppedAreaPixels.width.toString());
			formData.append("cropHeight", croppedAreaPixels.height.toString());
			formData.append("cropX", croppedAreaPixels.x.toString());
			formData.append("cropY", croppedAreaPixels.y.toString());

			onSuccess(formData);
		}
	};

	useEffect(() => {
		return () => {
			if (image && !initialImage) {
				URL.revokeObjectURL(image);
			}
		};
	}, [image, initialImage]);

	const dropzoneStyles: React.CSSProperties = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		rowGap: "1rem",
		alignItems: "center",
		width: "100%",
		height: "400px",
		border: "2px dashed #cccccc",
		borderRadius: "4px",
		padding: "20px",
		textAlign: "center",
		cursor: "pointer",
	};

	if (isDragReject) {
		dropzoneStyles.border = "2px dashed red";
		dropzoneStyles.cursor = "not-allowed";
	}
	if (isDragAccept) {
		dropzoneStyles.border = "2px dashed green";
		dropzoneStyles.cursor = "pointer";
	}

	const initialCroppedAreaPixels = useMemo(() => {
		if (initialImage) {
			return {
				x: initialImage.cropX,
				y: initialImage.cropY,
				width: initialImage.cropWidth,
				height: initialImage.cropHeight,
			};
		}
	}, [initialImage]);

	return (
		<>
			<SidebarContent>
				<div className="flex flex-col gap-y-4">
					<div className="w-full">
						{step === "upload" ? (
							<div {...getRootProps()} style={dropzoneStyles}>
								<input {...getInputProps()} />
								<p className="text-gray-500">
									Bilddatei (JPEG oder PNG, max. {maxSizeMB} MB) hier ablegen
									oder klicken um eine Datei auszuwählen
								</p>
								{dropZoneErrorMessages.length > 0 &&
									dropZoneErrorMessages.map((message, index) => (
										<p key={index}
										   style={{ color: "red" }}>
											{message}
										</p>
									))}
							</div>
						) : (
							<div className="flex flex-col">
								<div className="relative h-[400px] w-full">
									{image && (
										<Cropper
											image={image}
											crop={crop}
											zoom={zoom}
											cropShape="round"
											showGrid={false}
											aspect={1}
											onCropChange={setCrop}
											onZoomChange={setZoom}
											onCropComplete={onCropComplete}
											initialCroppedAreaPixels={initialCroppedAreaPixels}
										/>
									)}
								</div>
								<div className="flex items-center gap-x-6">
									<span>Zoom</span>
									<SliderPlain
										min={1}
										max={3}
										step={0.1}
										marks={[]}
										showValue={false}
										value={zoom}
										onChange={setZoom}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</SidebarContent>
			<SidebarFooter>
				{step !== "upload" && (
					<>
						<Button
							theme="white"
							className="mr-auto"
							onClick={() => setStep("upload")}
						>
							<ChevronLeftIcon className="size-5" /> Anderes Bild wählen
						</Button>
						<Button onClick={handleSubmit}>Speichern</Button>
					</>
				)}
				<Button theme="white"
						onClick={onClose}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</>
	);
};

export default ImageUploadAndCropSidebar;