import clsx from "clsx";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";

interface LoadingPageProps {
	pcent?: number;
	fullscreen?: boolean;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ fullscreen = true, pcent }) => (
	<section className={clsx("mx-auto flex max-w-7xl items-center justify-center", fullscreen ? "h-96" : "")}>
		<div className="flex flex-col items-center gap-4">
			<LoadingSpinner />
			<p className="select-none text-xs font-bold text-gray-400">
				LADE DATEN
				{undefined !== pcent && (
					<span className="ml-1 text-xs font-medium text-gray-400">{Math.round(pcent)}%</span>
				)}
			</p>
			{undefined !== pcent && (
				<>
					<div className="w-20 overflow-hidden rounded-full bg-gray-200">
						<div
							className="h-2 rounded-full bg-primary-500 transition-all duration-200 ease-in-out"
							style={{ width: `${pcent}%` }}
						/>
					</div>
				</>
			)}
		</div>
	</section>
);

export default LoadingPage;
