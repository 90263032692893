import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { FC, PropsWithChildren } from "react";
import { useCallback, useEffect, useRef } from "react";

import type { SideBarVariantProps } from "~/components/Sidebar/sidebarVariants.ts";
import { sideBarVariants } from "~/components/Sidebar/sidebarVariants.ts";
import useOnClickOutside from "~/hooks/useOnClickOutside.ts";

type SideBarProps = PropsWithChildren & SideBarVariantProps & {
	open: boolean;
	setOpen: (bool: boolean) => void;
	closeOnOutsideClick?: boolean;
	closeOnEscape?: boolean;
}

const Sidebar: FC<SideBarProps> = ({
	children,
	open,
	setOpen,
	theme,
	width,
	closeOnEscape = false,
	closeOnOutsideClick = true
}) => {
	const sidebarRef = useRef<HTMLDivElement>(null);
	const outsideClickHandler = useCallback(() => {
		if (closeOnOutsideClick) {
			setOpen(false);
		}
	}, [closeOnOutsideClick, setOpen]);

	useOnClickOutside(sidebarRef, () => setOpen(false), closeOnOutsideClick);

	const {innerWrapper, contentContainer} = sideBarVariants({width, theme})

	useEffect(() => {
		const keyHandler = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				setOpen(false);
			}
		}
		if (closeOnEscape && open) {
			document.addEventListener("keydown", keyHandler);
		} else {
			document.removeEventListener("keydown", keyHandler);
		}
		return () => document.removeEventListener("keydown", keyHandler);
	}, [open, closeOnEscape, setOpen]);

	return (
		<Dialog open={open}
				onClose={outsideClickHandler}
				className="relative z-50">

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<DialogBackdrop
						transition
						className="fixed inset-0 cursor-pointer bg-gray-500/75 duration-300 ease-out data-[closed]:opacity-0"
					/>
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">

						<DialogPanel
							transition
							className={clsx(innerWrapper(), "duration-300 ease-in-out data-[closed]:translate-x-full")}
						>
							<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
								<button
									type="button"
									className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
									onClick={() => setOpen(false)}
								>
									<span className="sr-only">Close panel</span>
									<XMarkIcon className="size-6"
											   aria-hidden="true"/>
								</button>
							</div>
							<div className={contentContainer()}>
								{children}
							</div>
						</DialogPanel>

					</div>
				</div>
			</div>
		</Dialog>
	)
}

export default Sidebar;
