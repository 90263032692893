import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexAbsences, indexUsersAbsences } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";

export const ABSENCE_BASE_QUERY_KEY = "absences";
export const ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY = ABSENCE_BASE_QUERY_KEY + "-timespan";

export const STAFF_MEMBERS_ABSENCES_QUERY_KEY = (staffMemberId: string) => [ABSENCE_BASE_QUERY_KEY, { staffMemberId }];

export const ABSENCES_IN_TIMESPAN_QUERY_KEY = (startDate: string,
	endDate: string) => [ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY, startDate, endDate];

export const useAbsencesInTimespan = ({ startDate, endDate, options }: {
	startDate: string,
	endDate: string,
	options?: QueriesOptions<any>
}) => useQuery(
	ABSENCES_IN_TIMESPAN_QUERY_KEY(startDate, endDate),
	() => indexAbsences({
		startDate,
		endDate,
	}).then(getDataFromResponse),
	options,
);

export const useStaffMembersAbsences = ({ staffMemberId, options }: { staffMemberId: string, options?: QueriesOptions<any> }) => useQuery(
	STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId),
	() => indexUsersAbsences({ staffMemberId }).then(getDataFromResponse),
	options,
);


