import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import {
	deleteStaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkApiDispatchers.ts";
import {
	STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkQueries.ts";
import type {
	StaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkTypes.ts";

type Props = {
	documentLinkData: StaffMemberDocumentLink | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteDocumentLinkEntryModal: React.FunctionComponent<Props> = ({
	documentLinkData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!documentLinkData) return;
		try {
			setBusy(true);
			await deleteStaffMemberDocumentLink(documentLinkData.staffMemberId, documentLinkData.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY(documentLinkData.staffMemberId));

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Dokument löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			key={isOpen ? "open" : "closed"}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!documentLinkData && <div className="mb-4 grid grid-cols-2 gap-y-2">
				<div className="col-span-2">
					<Label>Names des Dokuments</Label>
					<span className="text-base">{documentLinkData.title}</span>
				</div>
				<div className="col-span-2">
					<Label>URL</Label>
					<a href={documentLinkData.url}
					   target="_blank"
					   rel="noreferrer">{documentLinkData.url}</a>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteDocumentLinkEntryModal;
