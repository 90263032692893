import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const projectTagVariants = tv({
	slots: {
		container: "inline-flex max-w-full items-center whitespace-nowrap rounded-full",
	},
	variants: {
		size: {
			xs: {
				container: "px-3 py-1.5 text-xs",
			},
			sm: {
				container: "px-3 py-1.5 text-sm",
			},
			md: {
				container: "h-10 p-3 text-sm font-medium",
			},
		},
		theme: {
			product: {
				container: "bg-primary-300 text-white",
			},
			deliverable: {
				container: "bg-gray-500 text-white",
			},
			neutral: {
				container: "bg-gray-200 text-gray-600",
			},
		},
		muted: {
			true:{
				container: "opacity-30",
			}
		},
	},
	defaultVariants: {
		theme: "neutral",
		size: "xs",
	},
});

export type ProjectTagVariantsProps = VariantProps<typeof projectTagVariants>;