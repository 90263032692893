import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateOrUpdateWorkingScheduleData,
	WorkingSchedule,
} from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";

const staffMembersWorkingSchedulesBasePath = (staffMemberId:string)=>`/staff-members/${staffMemberId}/working-schedules`;

export function indexUsersWorkingSchedules({ staffMemberId }: {
	staffMemberId: string
}): AxiosApiResponsePromise<WorkingSchedule[]> {
	return axiosApi.get(staffMembersWorkingSchedulesBasePath(staffMemberId));
}

export function createWorkingSchedule({ staffMemberId, data }: {
	staffMemberId: string,
	data: CreateOrUpdateWorkingScheduleData
}): AxiosApiResponsePromise<WorkingSchedule> {
	return axiosApi.post(staffMembersWorkingSchedulesBasePath(staffMemberId), data);
}

export function updateWorkingSchedule({ staffMemberId, workingScheduleId, data }: {
	staffMemberId: string,
	workingScheduleId: string,
	data: CreateOrUpdateWorkingScheduleData
}): AxiosApiResponsePromise<WorkingSchedule> {
	return axiosApi.put(`${staffMembersWorkingSchedulesBasePath(staffMemberId)}/${workingScheduleId}`, data);
}

export function deleteWorkingSchedule({ staffMemberId, workingScheduleId }: {
	staffMemberId: string,
	workingScheduleId: string
}): AxiosApiResponsePromise<WorkingSchedule> {
	return axiosApi.delete(`${staffMembersWorkingSchedulesBasePath(staffMemberId)}/${workingScheduleId}`);
}


