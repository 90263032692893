import { useMemo } from "react";

import { useAllSalesTypes } from "~/modules/client/api/salesType/salesTypeQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData,getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useSalesTypeSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: salesTypeData } = useAllSalesTypes();
	return useMemo(() => {
		if (salesTypeData) {
			const options = salesTypeData.sort(byObjectProperty("listPosition")).map((salesType) => {
				return createInputOptionData(salesType.displayName, salesType.id);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [salesTypeData, includeCtaOption]);
};

export default useSalesTypeSelectOptions;

