import { useMemo } from "react";
import type { UseQueryResult } from "react-query";

type ErrorInfo = {
    name: string;
    error: unknown;
};

export type AggregatedDataOptions<T> = {
    isLoading: boolean;
    loadingPercent: number;
    status: Array<"idle" | "error" | "loading" | "success">;
    errors: ErrorInfo[];
    disabledQueries: string[];
    loadingStates: { [K in keyof T]: boolean }; // Renamed and typed more strictly
};

type InferHookResult<T> = T extends UseQueryResult<infer R, any> ? R : never;

export function useAggregatedDataV2<T extends Record<string, UseQueryResult<any, unknown>>>(
    hookResults: T
): {
    data: { [K in keyof T]: InferHookResult<T[K]> };
    options: AggregatedDataOptions<T>;
} {
    return useMemo(() => {
        const data = {} as { [K in keyof T]: InferHookResult<T[K]> };
        const statusArray: AggregatedDataOptions<T>["status"] = [];
        const errors: ErrorInfo[] = [];
        const disabledQueries: string[] = [];
        const loadingStates = {} as { [K in keyof T]: boolean }; // Renamed and typed more strictly

        Object.entries(hookResults).forEach(([name, { data: loadedData, status, error, isIdle, isLoading }]) => {
            (data as any)[name] = loadedData;
            statusArray.push(status);
            if (error) errors.push({ name, error });
            if (isIdle) disabledQueries.push(name);
            loadingStates[name as keyof T] = isLoading; // Renamed and added type assertion
        });

        const totalEnabledQueries = Object.keys(hookResults).length - disabledQueries.length;
        const successCount = statusArray.filter((s) => s === "success").length;
        const loadingPercent = totalEnabledQueries > 0
            ? (successCount / totalEnabledQueries) * 100
            : 100;

        return {
            data,
            options: {
                isLoading: statusArray.some((s) => s === "loading"),
                loadingPercent,
                status: statusArray,
                errors,
                disabledQueries,
                loadingStates, // Renamed
            },
        };
    }, [hookResults]);
}