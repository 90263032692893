import { useMemo } from "react";

import { useAllProductTags } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

export function useProductTagInputOptions() {
	const { data: allProductTags } = useAllProductTags();

	return useMemo(() => {
		if (allProductTags) {
			return mapDataToInputOptionsData(allProductTags.sort(byObjectProperty("displayName")), "id", "displayName");

		}
		return [];
	}, [allProductTags]);


}