import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberBenefitData,
	StaffMemberBenefit,
	UpdateStaffMemberBenefitData,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitTypes.ts";


const benefitsBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/benefits`;

export function indexStaffMembersBenefits(staffMemberId: string): AxiosApiResponsePromise<StaffMemberBenefit[]> {
	return axiosApi.get(benefitsBasePath(staffMemberId));
}

export function createStaffMemberBenefit(staffMemberId: string,
	benefitData: CreateStaffMemberBenefitData): AxiosApiResponsePromise<void> {
	return axiosApi.post(benefitsBasePath(staffMemberId), benefitData);
}

export function updateStaffMemberBenefit({ staffMemberId, benefitId, updateData }: {
	staffMemberId: string;
	benefitId: string;
	updateData: UpdateStaffMemberBenefitData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${benefitsBasePath(staffMemberId)}/${benefitId}`, updateData);
}

export function deleteStaffMemberBenefit(staffMemberId: string, employmentId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${benefitsBasePath(staffMemberId)}/${employmentId}`);
}