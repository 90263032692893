import { useMemo } from "react";

import { useAllProductTags } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";

export function useProductTagValidationComparisonData() {
	const { data: allProductTags } = useAllProductTags();

	return useMemo(() => {
		if (allProductTags) {
			return allProductTags.map((productTag) => {
				return {
					id: productTag.id,
					value: sanitizeStringForComparison(productTag.displayName),
				};
			});

		}
		return [];
	}, [allProductTags]);


}