import type { FunctionComponent, PropsWithChildren } from "react";

import Hint from "~/components/Hint";


const SidebarErrorOverlay: FunctionComponent<PropsWithChildren<{ title: string }>> = ({ title, children }) =>
	(<div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-100/50">
		<div className="mx-10 my-8 flex items-center justify-center rounded-lg bg-white p-10">
			<Hint theme="error"
				  title={title}>
				{children}
			</Hint>
		</div>

	</div>);
export default SidebarErrorOverlay;