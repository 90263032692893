import type { TimeTrackingTypeId } from "~/types/entityIds.ts";

export enum TimeTrackingFormNamesEnum {
	TIME_TRACKING_FORM = "timeTrackingForm",
	DELIVERABLE_FORM = "deliverableForm",
	LOCATION_FORM = "locationForm",
}

export type TimeTrackingTotalsByType = {
	[TimeTrackingTypeId.Internal]: number,
	[TimeTrackingTypeId.Project]: number,
	[TimeTrackingTypeId.Sales]: number,
	[TimeTrackingTypeId.PartTime]: number,
}