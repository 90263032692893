import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import Decimal from "decimal.js-light";
import TimelineItem from "modules/humanResources/components/StaffMemberDetailsView/components/TimelineItem";
import type { FunctionComponent, ReactNode } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { useAuth } from "~/contexts/AuthContext";
import { useStaffMembersVacationEntitlements } from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import type {
	VacationEntitlementWithDate,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import {
	useStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type {
	StaffMemberEmploymentWithDate,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import CreateVacationEntitlementSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/VacationEntitlementsSection/components/CreateVacationEntitlementSidebar";
import DeleteVacationEntitlementModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/VacationEntitlementsSection/components/DeleteVacationEntitlementModal";
import UpdateVacationEntitlementSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/VacationEntitlementsSection/components/UpdateVacationEntitlementSidebar";
import TimelineEmptyMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TimelineEmptyMessage";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type WorkingSchedulesViewProps = {
	staffMemberCoreData: StaffMember;
	minDateNewEntry: Date;
};

type TimelineElement = VacationEntitlementWithDate | StaffMemberEmploymentWithDate

const VacationEntitlementsSection: FunctionComponent<WorkingSchedulesViewProps> = ({
	staffMemberCoreData,
	minDateNewEntry,
}) => {
	const { hasAnyPermission } = useAuth();

	const [showCreateEntrySidebar, setShowCreateEntrySidebar] = useState(false);
	const [entryDataToUpdate, setEntryDataToUpdate] = useState<VacationEntitlementWithDate | null>(null);
	const [entryDataToDelete, setEntryDataToDelete] = useState<VacationEntitlementWithDate | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);

	const {
		data: vacationEntitlementsData,
		isLoading: vacationEntitlementsIsLoading,
	} = useStaffMembersVacationEntitlements({ staffMemberId: staffMemberCoreData.id || "" });

	const {
		data: employmentsData,
		isLoading: employmentsIsLoading,
	} = useStaffMembersEmployments({ staffMemberId: staffMemberCoreData.id });

	const timeLineElements = useMemo(() => {
		if (employmentsData !== undefined && vacationEntitlementsData !== undefined) {
			if (vacationEntitlementsData.length === 0) {
				return [];
			}
			const leaveEvents = employmentsData
				.filter(employment => employment.employmentTypeId === EmploymentTypeId.Left)
				.map(employment => ({ ...employment, validFrom: new Date(employment.validFrom) }));

			const workingSchedulesWithDate = vacationEntitlementsData.map(workingSchedule => {
				return { ...workingSchedule, validFrom: new Date(workingSchedule.validFrom) };
			});

			const timelineElements: ReactNode[] = [];

			const workingSchedulesSorted: TimelineElement[] = [...workingSchedulesWithDate, ...leaveEvents].sort(byObjectProperty("validFrom", "desc"));

			workingSchedulesSorted.forEach((vacationEntitlement, index) => {
				const nextElement = workingSchedulesSorted[index + 1] as StaffMemberEmploymentWithDate;
				const nextElementIsLeftEntry = nextElement?.employmentTypeId !== undefined;

				const isStarItem = !nextElement || nextElementIsLeftEntry;
				const isLeftEntry = (vacationEntitlement as StaffMemberEmploymentWithDate).employmentTypeId !== undefined;
				const title = isLeftEntry ? getEmploymentTypeDisplayNameById(EmploymentTypeId.Left) : new Decimal((vacationEntitlement as VacationEntitlementWithDate).workDays).toString() + " Tage";

				let dotTheme: "success" | "danger" | "neutral" = "neutral";
				if (isLeftEntry) {
					dotTheme = "danger";
				} else if (isStarItem) {
					dotTheme = "success";
				}

				const popOverItems = [];

				if (userCanAdminVacations) {
					popOverItems.push({
						label: "Bearbeiten",
						onClick: () => setEntryDataToUpdate(vacationEntitlement as VacationEntitlementWithDate),
						icon: PencilIcon,
					});
				}

				if (userCanAdminVacations && !(vacationEntitlement as VacationEntitlementWithDate).isDateLocked) {
					popOverItems.push({
						label: "Löschen",
						onClick: () => setEntryDataToDelete(vacationEntitlement as VacationEntitlementWithDate),
						icon: TrashIcon,
					});
				}


				timelineElements.push(
					<TimelineItem date={new Date(vacationEntitlement.validFrom)}
								  dotTheme={dotTheme}
								  key={index}
								  showHoverEffect={!isLeftEntry}
								  isStartItem={isStarItem}
					>
						<div className="flex w-full justify-between">
							<div>{title}</div>
							{!isLeftEntry && <ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>}

						</div>
					</TimelineItem>,
				);
			});
			return timelineElements;
		}
		return [];
	}, [employmentsData, vacationEntitlementsData, userCanAdminVacations]);

	const isLoading = vacationEntitlementsIsLoading || employmentsIsLoading;
	return staffMemberCoreData.userId && <>
		<Card title="Urlaubsanspruch (Vollzeit)"
			  height="full"
				onHeaderButtonClick={() => setShowCreateEntrySidebar(true)}
			  headerButtonText="Neuer Eintrag"

		>
			{isLoading && <LoadingSpinnerFull theme="neutral" />}
			{!isLoading && <StaffMemberTimeline>
				{timeLineElements.length > 0 ? timeLineElements : <TimelineEmptyMessage />}
			</StaffMemberTimeline>}
		</Card>
		<CreateVacationEntitlementSidebar
			staffMemberId={staffMemberCoreData.userId}
			isOpen={showCreateEntrySidebar}
			minDate={minDateNewEntry}
			onClose={() => setShowCreateEntrySidebar(false)}
		/>

		<DeleteVacationEntitlementModal
			isOpen={!!entryDataToDelete}
			onCloseClick={() => setEntryDataToDelete(null)}
			vacationEntitlement={entryDataToDelete}
		/>

		<UpdateVacationEntitlementSidebar onClose={() => setEntryDataToUpdate(null)}
										  isOpen={!!entryDataToUpdate}
										  minDate={minDateNewEntry}
										  vacationEntitlementData={entryDataToUpdate} />
	</>;
};

export default VacationEntitlementsSection;