import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteInvoiceRecipient } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientApiDispatchers.ts";
import {
	CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";


type DeleteInvoiceRecipientModalProps = {
	clientId: string;
	invoiceRecipientDisplayName: string;
	invoiceRecipientId: string | null;
	isOpen: boolean;
	onClose: () => void;
};

const DeleteInvoiceRecipientModal: React.FunctionComponent<DeleteInvoiceRecipientModalProps> = ({
	clientId,
	invoiceRecipientDisplayName,
	invoiceRecipientId,
	onClose,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!invoiceRecipientId) return;
		try {
			setBusy(true);
			await deleteInvoiceRecipient({ clientId, invoiceRecipientId });
			await queryClient.invalidateQueries(CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY(clientId));
			onClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.invoiceRecipientId?.find(
						(validationError) => validationError.rule === "invoiceRecipientIsDeletableRule",
					)
				) {
					setError("Dieser Rechnungsempfänger kann nicht gelöscht werden, da er noch in Verwendung ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Rechnungsempfänger löschen`,
		bodyText: `Möchtest Du den Rechnungsempfänger "${invoiceRecipientDisplayName}" wirklich löschen?`,
		deleteButtonText: `Rechnungsempfänger löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteInvoiceRecipientModal;
