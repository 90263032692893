import { useMemo } from "react";

import { HealthInsuranceTypeName } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData, getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";


const useHealthInsuranceSelectOptions = (): FormInputOption[] => {
	return useMemo(() => {
		return [
			getInputDefaultOptionsData(),
			createInputOptionData("Gesetzlich", HealthInsuranceTypeName.Public),
			createInputOptionData("Privat", HealthInsuranceTypeName.Private),
		]
	},[]);
};

export default useHealthInsuranceSelectOptions;

