import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import {
	STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelQueries.ts";
import CreateCareerLevelForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection/components/CreateCareerLevelSidebar/components/CreateCareerLevelForm";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string;
};

const CreateCareerLevelSidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	staffMemberId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY(staffMemberId));
		queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(staffMemberId));
		queryClient.invalidateQueries(ALL_STAFF_MEMBERS_QUERY_KEY);
		queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);
		onClose();
	}, [onClose, queryClient, staffMemberId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<CreateCareerLevelForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberId={staffMemberId} />
		</Sidebar>
	);
};

export default CreateCareerLevelSidebar;
