import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import type { SickDaysPerStaffMemberTableData } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import type { SickDaysPerStaffMemberTableMeta } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

const columnHelper = createColumnHelper<SickDaysPerStaffMemberTableData>();

const employeeNameColumn = columnHelper.accessor("staffMemberFullName", {
	header: "Mitarbeiter/in",
	meta: {
		headerTextAlign: "left",
		cellTextAlign: "left",
	},
	footer: "Summe Krankheitstage",
});

const monthColumns = Array.from({ length: 12 }, (_, i) => (i + 1).toString()).map(month => {
	return columnHelper.accessor(`months.${month}`, {
		header: format(new Date(2024, parseInt(month) - 1, 1), "MMM", { locale: de }),
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => props.row.original.months[month] ? formatNumberWithComma(props.row.original.months[month], 1, true) :
			<div className="text-gray-200">0</div>,
		footer: (props) => {
			const { table } = props;
			const total = table.getRowModel().rows.reduce((acc, row) => {
				const value = row.original.months[month];
				return acc + (value ? value : 0);
			}, 0);
			return formatNumberWithComma(total, 1, true);
		},
	});
});

const totalColumn = columnHelper.accessor("total", {
	header: ({ table }) => {
		const { selectedYear } = table.options.meta as SickDaysPerStaffMemberTableMeta;
		return `Gesamt ${selectedYear}`;
	},
	cell: (props) => formatNumberWithComma(props.getValue(), 1, true),
	footer: (props) => {
		const { table } = props;
		const total = table.getRowModel().rows.reduce((acc, row) => {
			return acc + row.original.total;
		}, 0);
		return <div className="font-bold">∑ {formatNumberWithComma(total, 1, true)}</div>
	},
});

export default [employeeNameColumn, ...monthColumns, totalColumn];

/*
export const monthlyReportTableColumnDefs = [
	columnHelper.accessor("careerLevelDisplayName", {
		header: "Karrierestufe",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
	}),
];*/
