import clsx from "clsx";
import type { PropsWithChildren } from "react";

import Headline from "~/components/Headline";

interface Props {
	title?: string;
	description?: string;
}

const PageHeading: React.FC<PropsWithChildren<Props>> = ({ children, title, description }) => (
	<div className="bg-white py-2 md:flex md:items-start md:justify-between">
		<div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
			{(title || description) && (
				<div className="min-w-0 flex-1">
					{title && <Headline type="h2">{title}</Headline>}
					{description && <p className="mt-4 max-w-xl text-sm text-gray-700">{description}</p>}
				</div>
			)}
			{children}
		</div>
	</div>
);

const ButtonArea: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="mt-4 flex shrink-0 gap-2 md:ml-4 md:mt-0">{children}</div>
);

const BottomBar: React.FC<PropsWithChildren & { showBottomBorder?: boolean }> = ({
																					children,
																					showBottomBorder = true,
																				}) => (
	<div className={clsx("sticky  top-0 z-20 bg-white py-4", showBottomBorder && "border-b")}>
		<div className="mx-auto flex h-10 w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
			{children}
		</div>
	</div>
);

export default Object.assign(PageHeading, { Buttons: ButtonArea, BottomBar });
