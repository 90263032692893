import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
	className?: string;
	withBottomPadding?: boolean;
};

const MainContent: FunctionComponent<Props> = ({ className, children, withBottomPadding = true }) => {
	return <div className={clsx(className, withBottomPadding && "pb-10")}>
		{children}
	</div>;
};

export default MainContent;