import clsx from "clsx";
import type { FunctionComponent } from "react";

import type { SectionHeadingTab } from "~/types/tabsTypes.ts";

interface TabsProps {
	tabs: SectionHeadingTab[];
	onTabClick: (tab: any) => void;
}

const SectionHeadingTabs: FunctionComponent<TabsProps> = ({ tabs, onTabClick }) => {
	const activeTab = tabs.findIndex((tab) => tab.active);

	return (
		<div className="mt-4">
			<div className="sm:hidden">
				<label htmlFor="current-tab"
					   className="sr-only">
					Select a tab
				</label>
				<select
					id="current-tab"
					name="current-tab"
					className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
					defaultValue={activeTab}
					onChange={(value)=>onTabClick(value)}
				>
					{tabs.map(({ name }) => (
						<option key={`sectionTabs-m-${name}`}
								value={name}>
							{name}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<nav className="-mb-px flex space-x-8">
					{tabs.map(({ name, icon, active, value }, tabIndex) => (
						<div
							key={`sectionTabs-${tabIndex}`}
							onClick={()=>onTabClick(value)}
							className={clsx(
								active
									? "border-primary-500 text-primary-500"
									: "border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700",
								"cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-bold",
								"flex items-center gap-2",
							)}
							aria-current={active ? "page" : undefined}
						>
							{icon && icon}
							{name}
						</div>
					))}
				</nav>
			</div>
		</div>
	);
};

export default SectionHeadingTabs;