import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getSickDaysReport } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportApiDispatchers.ts";

export const SICK_DAYS_REPORT_QUERY_KEY = ['sick-days-report'];

export const useSickDaysReport = (options?: QueriesOptions<any>) => useQuery(
	SICK_DAYS_REPORT_QUERY_KEY,
	() => getSickDaysReport().then(getDataFromResponse),
	options,
);