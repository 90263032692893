import type { ChartType, Plugin } from "chart.js";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import type { FunctionComponent } from "react";
import { Bar } from "react-chartjs-2";

import Card from "~/components/Card";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

type Props = {
	manDaysPlanned: number;
	manDaysTracked: number;
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	showBudget?: boolean;
	totalBudgetCents: number;
	totalManDays: number;
};

interface CustomPluginOptions {
	enabled: boolean;
	showBudget?: boolean;
	plannedValue?: number;
}

const barLabelsPlugin: Plugin<ChartType, CustomPluginOptions> = {
	id: "barLabels",
	afterDraw(chart, _, options) {
		if (!options.enabled) return;
		const { ctx, data } = chart;
		ctx.save();

		const showBudget = options.showBudget ?? false;
		const plannedValue = options.plannedValue || 0;

		data.datasets[0].data.forEach((value, i) => {
			if (value === null) return;
			const valueNum = value as number;
			const labelHasPercentage = i !== 0 && valueNum > 0;
			const meta = chart.getDatasetMeta(0);
			const bar = meta.data[i] as any;
			const { x, y, width: barWidth, height: barHeight } = bar.getProps(["x", "y", "width", "height"]);

			let formattedValue = valueNum.toString() + " PT";
			if (showBudget) {
				formattedValue = formatCentsToCurrency(valueNum, 0);
			}

			ctx.fillStyle = "white";
			ctx.font = "bold 14px Arial";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";

			// Calculate position for the text
			const textX = x;
			let textY = Math.min(y + barHeight / 2, chart.chartArea.bottom - 2);

			// Measure text width
			const textWidth = ctx.measureText(formattedValue).width;
			let textHeight = 14; // Approximate height of the text

			if (labelHasPercentage) {
				textHeight = 28;
			}

			// Check if text fits inside the bar
			const textFits = textWidth < barWidth - 10 && textHeight < barHeight - 10 && barHeight > 5;

			if (!textFits) {
				const offset = labelHasPercentage ? 25 : 14;
				// If text doesn't fit, position it above the bar or x-axis
				textY = Math.min(y, chart.chartArea.bottom) - offset;
				ctx.fillStyle = "black"; // Change text color for better visibility
			}

			if (valueNum === 0) {
				textY = chart.chartArea.bottom - 10;
			}

			// Draw the value
			ctx.fillText(formattedValue, textX, textY);

			// Draw the percentage for "Verfügt" and "Rest" bars only
			if (labelHasPercentage) { // Skip the "Plan" bar
				const percentage = ((valueNum / plannedValue) * 100).toFixed(1);
				ctx.font = "12px Arial";
				const percentageY = textY + 14;

				ctx.fillText(`(${percentage}%)`, textX, percentageY);
			}
		});
		ctx.restore();
	},
};

ChartJS.register(barLabelsPlugin);

const ProjectBudgetSection: FunctionComponent<Props> = ({
	manDaysPlanned,
	manDaysTracked,
	budgetCentsPlanned,
	budgetCentsTracked,
	showBudget = false,

}) => {

	const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
	const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
	const leftValue = trackedValue > plannedValue ? 0 : plannedValue - trackedValue;

	const bgColorTracked = trackedValue > plannedValue ? "#ef1807" : "#ea580c";
	const bgColorLeft = "#15803d";

	const data = {
		labels: ["Plan", "Verfügt", "Rest"],
		datasets: [
			{
				data: [plannedValue, trackedValue, leftValue],
				backgroundColor: ["#1e3560", bgColorTracked, bgColorLeft],
				hoverBackgroundColor: ["#1e3560", bgColorTracked, bgColorLeft],
			},
		],
	};

	const options = {
		animation: false,
		plugins: {
			legend: {
				position: "bottom" as const,
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			barLabels: {
				enabled: true,
				showBudget: showBudget,
				plannedValue,
			},
		},
		layout: {
			padding: {
				top: 20, // Add some padding at the top of the chart
			},
		},
		elements: {
			bar: {
				borderRadius: 2, // Adjust this value to change the border radius
			},
		},
		barPercentage: 0.9,
		categoryPercentage: 0.8,
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
				beginAtZero: true,
				ticks: {
					callback: function(value: number) {
						if (showBudget) {
							return formatCentsToCurrency(value, 0);
						}
						return value;
					},
				},
			},
		},
	};

	return <Card title="Gesamtbudget">
		{/*// @ts-expect-error type defintion in library is wrong*/}
		<Bar options={options}
			 data={data} />
	</Card>;
};

export default ProjectBudgetSection;