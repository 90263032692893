import { t } from "i18next";
import type { LocaleObject } from "yup";


// This being a function instead of a plain object  is a workaround for the issue
// that i18next is not initialized when this file is loaded.
// @ToDo Find a way to make sure i18next is initialized before this file is loaded.
export const getCustomValidationErrorMessages = (): LocaleObject => {
	return {
		mixed: {
			required: t("form:validationErrors.mixed.required"),
		},
		string: {
			email: t("form:validationErrors.string.email"),
		},
	};
};