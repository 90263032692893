import clsx from "clsx";

interface Props {
	scale?: number;
	theme?: "default" | "neutral";
}

const LoadingSpinner: React.FC<Props> = ({ scale, theme="default" }) => (
	<div style={{ transform: `scale(${scale})` }}>
		<div className={clsx("size-6 animate-spin rounded-[50%] border-4 border-white", theme === "neutral" ? "border-t-gray-500":"border-t-secondary-500")} />
	</div>
);

export default LoadingSpinner;
