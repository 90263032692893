import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type {HeadingTagType } from "@lexical/rich-text";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import { $patchStyleText, $setBlocksType } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isRangeSelection,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";

import BulletListIcon from "~/components/TextEditor/assets/icons/BulletListIcon";
import NumberedListIcon from "~/components/TextEditor/assets/icons/NumberedListIcon";
import TextBoldIcon from "~/components/TextEditor/assets/icons/TextBoldIcon";
import TextColorIcon from "~/components/TextEditor/assets/icons/TextColorIcon";
import TextItalicIcon from "~/components/TextEditor/assets/icons/TextItalicIcon";
import TextUnderlineIcon from "~/components/TextEditor/assets/icons/TextUnderlineIcon";
import EditorToolbarButton from "~/components/TextEditor/components/ToolbarBasicPlugin/components/EditorToolbarButton";
import type { EditorToolbarOptions } from "~/components/TextEditor/editorTypes.ts";

const LowPriority = 1;

function Divider() {
	return <div className="divider" />;
}

const defaultToolbarOptions: EditorToolbarOptions = {
	bold: true,
	italic: true,
	underline: true,
	textColor: true,
	bulletList: true,
	orderedList: true,
	h1: true,
	h2: true,
};

export default function ToolbarPlugin({ options }: { options?: EditorToolbarOptions }) {
	const [editor] = useLexicalComposerContext();
	const toolbarRef = useRef(null);
	const [blockType, setBlockType] = useState("paragraph");
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);

	const currentOptions = options || defaultToolbarOptions;

	const showBold = currentOptions?.bold;
	const showItalic = currentOptions?.italic;
	const showUnderline = currentOptions?.underline;
	const showTextColor = currentOptions?.textColor;
	const showBulletList = currentOptions?.bulletList;
	const showOrderedList = currentOptions?.orderedList;
	const showH1 = currentOptions?.h1;
	const showH2 = currentOptions?.h2;

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === "root"
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
					setBlockType(type);
				}
			}
			// Update text format
			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));
		}
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateToolbar();
					return false;
				},
				LowPriority,
			),
		);
	}, [editor, updateToolbar]);

	const formatBulletList = () => {
		if (blockType !== "ul") {
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		}
	};

	const formatNumberedList = () => {
		if (blockType !== "ol") {
			editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		}
	};

	const formatHeading = (headingSize: HeadingTagType) => {
		if (blockType !== headingSize) {
			editor.update(() => {
				const selection = $getSelection();
				$setBlocksType(selection, () => $createHeadingNode(headingSize));
			});
		}
	};

	const applyStyleText = useCallback(
		(styles: Record<string, string>) => {
			editor.update(() => {
				const selection = $getSelection();
				if (
					$isRangeSelection(selection)
				) {
					$patchStyleText(selection, styles);
				}
			});
		},
		[editor],
	);

	return (
		<div className="mb-0.5 flex flex-row items-center rounded-t bg-white"
			 ref={toolbarRef}>

			<EditorToolbarButton
				disabled={!showBold}
				isActive={isBold}
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
				}}
				aria-label="Format Bold"
			>
				<TextBoldIcon className="w-[1.1rem] text-gray-900" />
			</EditorToolbarButton>

			<EditorToolbarButton
				disabled={!showItalic}
				isActive={isItalic}
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
				}}
				aria-label="Format Italic"
			>
				<TextItalicIcon className="w-[1.1rem] text-gray-900" />
			</EditorToolbarButton>

			<EditorToolbarButton
				disabled={!showUnderline}
				isActive={isUnderline}
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
				}}
				aria-label="Format Underline"
			>
				<TextUnderlineIcon className="w-[1.1rem] text-gray-900" />
			</EditorToolbarButton>

			<EditorToolbarButton
				disabled={!showTextColor}
				onClick={() => applyStyleText({ color: "red" })}
				aria-label="Format Font Color"
			>
				<TextColorIcon className="w-[1.4rem] text-danger-500" />
			</EditorToolbarButton>
			<EditorToolbarButton
				disabled={!showTextColor}
				onClick={() => applyStyleText({ color: "inherit" })}
				aria-label="Format Font Color"
			>
				<TextColorIcon className="w-[1.4rem] text-gray-900" />
			</EditorToolbarButton>

			<Divider />

			<EditorToolbarButton
				disabled={!showBulletList}
				isActive={blockType === "ul"}
				onClick={formatBulletList}
				aria-label="Format Bullet list"
			>
				<BulletListIcon className="w-5 text-gray-900" />
			</EditorToolbarButton>

			<EditorToolbarButton
				disabled={!showOrderedList}
				isActive={blockType === "ol"}
				onClick={formatNumberedList}
				aria-label="Format Numered List"
			>
				<NumberedListIcon className="w-5 text-gray-900" />
			</EditorToolbarButton>

			<Divider />

			<EditorToolbarButton
				disabled={!showH1}
				isActive={blockType === "h1"}
				onClick={() => formatHeading("h1")}
				aria-label="Format H1 Heading"
			>
				<div className="flex size-5 items-center justify-center font-light text-gray-900">H1</div>
			</EditorToolbarButton>

			<EditorToolbarButton
				disabled={!showH2}
				isActive={blockType === "h2"}
				onClick={() => formatHeading("h2")}
				aria-label="Format H2 Heading"
			>
				<div className="flex size-5 items-center justify-center font-light text-gray-900">H2</div>
			</EditorToolbarButton>
		</div>
	);
}