import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexUsersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";

export const WORKING_SCHEDULE_BASE_QUERY_KEY = "workingSchedules";

export const STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY = (staffMemberId: string) => [WORKING_SCHEDULE_BASE_QUERY_KEY, { staffMemberId }];

export const useStaffMembersWorkingSchedules = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId),
	() => indexUsersWorkingSchedules({ staffMemberId }).then(getDataFromResponse),
	options,
);
