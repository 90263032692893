import ProjectTag
	from "components/projectTags/ProjectTag";
import UpdateDeliverableTagsSidebar
	from "modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectDeliverableTagsSection/components/UpdateDeliverableTagsSidebar";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import { useAllDeliverableTags } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";

type Props = { deliverableTagIds: string[], projectId: string };

const ProjectDeliverableTagsSection: FunctionComponent<Props> = ({ deliverableTagIds, projectId }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState<boolean>(false);
	const { data: allDeliverableTags } = useAllDeliverableTags();

	const deliverableTagPills = useMemo(() => {
		if (deliverableTagIds && allDeliverableTags) {
			return deliverableTagIds.map((productTagId) => {
				const productTag = allDeliverableTags.find((tag) => tag.id === productTagId);
				if (productTag) {
					return <ProjectTag key={productTag.id}
									   displayName={productTag.displayName}
									   theme="deliverable" />;
				}

			});
		}
		return null;
	}, [allDeliverableTags, deliverableTagIds]);
	return <>
		<Card title="Projekt-Deliverable Tags"
			  onHeaderButtonClick={() => setShowUpdateSidebar(!showUpdateSidebar)}
			  headerButtonText="Bearbeiten">
			<div className="flex flex-wrap gap-2">
				{deliverableTagPills?.length === 0 &&
					<span className="py-1.5 text-sm text-gray-500">Keine Deliverable Tags zugewiesen</span>}
				{deliverableTagPills}
			</div>
		</Card>
		<UpdateDeliverableTagsSidebar
			isOpen={showUpdateSidebar}
			onClose={() => setShowUpdateSidebar(false)}
			deliverableTagIds={deliverableTagIds}
			projectId={projectId} />
	</>;
};


export default ProjectDeliverableTagsSection;