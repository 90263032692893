import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const buttonVariants = tv({
	base: ["inline-flex flex-shrink-0 items-center justify-center gap-2 tracking-wide transition-colors duration-200",
		"truncate font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"],
	variants: {
		theme: {
			plain: "",
			primary: "bg-primary-500 text-white hover:bg-primary-700",
			secondary: "bg-secondary-500 text-white hover:bg-secondary-600",
			accent: "bg-accent-500 text-white hover:bg-accent-700",
			danger: "bg-danger-500 text-white hover:bg-danger-700",
			white: "bg-white text-gray-700 hover:bg-opacity-50",
			none: "bg-transparent text-gray-600 hover:text-gray-900 hover:underline",
		},
		size: {
			sm: "h-7 px-2 text-xs",
			md: "h-10 px-3 text-sm",
			auto: "px-0",
		},
		disabled: {
			true: "pointer-events-none opacity-30",
		},
		rounded: {
			none: "",
			md: "rounded-md",
			full: "rounded-full",
		},
		isGrouped: {
			true: "border-y border-r border-gray-300 first:rounded-l-md first:border-l last:rounded-r-md last:border-r",
		},
	},
	defaultVariants: {
		size: "md",
		rounded: "full",
		disabled: false,
		theme: "secondary",
	},
});


export type ButtonVariantsProps = VariantProps<typeof buttonVariants>;
export type ButtonThemeVariants = "primary" | "secondary" | "accent" | "danger" | "white" | "none" | "plain";
export type ButtonSizeVariants = "sm" | "md" | "auto";
export type ButtonBorderRadiusVariants = "md" | "full" | "none";

