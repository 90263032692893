import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { Absence } from "~/modules/absence/api/absence/absenceTypes.ts";
import type {
	CreateOrUpdateVacationLedgerEntryDataType,
	VacationLedgerEntryType, VacationLedgerReportType,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";

const staffMembersVacationLedgerEntriesBasePath = (staffMemberId:string)=>`/staff-members/${staffMemberId}/vacation-ledger-entries`;

export function indexUsersVacationLedgerEntries({ staffMemberId }: {
	staffMemberId: string
}): AxiosApiResponsePromise<VacationLedgerEntryType[]> {
	return axiosApi.get(staffMembersVacationLedgerEntriesBasePath(staffMemberId));
}

export function createVacationLedgerEntry({ staffMemberId, data }: {
	staffMemberId: string,
	data: CreateOrUpdateVacationLedgerEntryDataType
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.post(staffMembersVacationLedgerEntriesBasePath(staffMemberId), data);
}

export function updateVacationLedgerEntry({ staffMemberId, vacationLedgerEntryId, data }: {
	staffMemberId: string,
	vacationLedgerEntryId: string,
	data: any
}): AxiosApiResponsePromise<Absence> {
	return axiosApi.put(`${staffMembersVacationLedgerEntriesBasePath(staffMemberId)}/${vacationLedgerEntryId}`, data);
}

export function deleteVacationLedgerEntry({ staffMemberId, vacationLedgerEntryId }: {
	staffMemberId: string,
	vacationLedgerEntryId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${staffMembersVacationLedgerEntriesBasePath(staffMemberId)}/${vacationLedgerEntryId}`);
}

export function getUsersVacationLedgerReport({ staffMemberId }: {
	staffMemberId: string,
}): AxiosApiResponsePromise<VacationLedgerReportType> {
	return axiosApi.get(`${staffMembersVacationLedgerEntriesBasePath(staffMemberId)}/report`);
}