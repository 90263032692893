import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import {
	deleteStaffMemberVariableCompensationPercentage,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageApiDispatchers.ts";
import {
	STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageQueries.ts";
import type {
	StaffMemberVariableCompensationPercentageWithDate,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageTypes.ts";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	variableCompensationPercentageData: StaffMemberVariableCompensationPercentageWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteVariableCompensationPercentageEntryModal: React.FunctionComponent<Props> = ({
	variableCompensationPercentageData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!variableCompensationPercentageData) return;
		try {
			setBusy(true);
			await deleteStaffMemberVariableCompensationPercentage(variableCompensationPercentageData.staffMemberId, variableCompensationPercentageData.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY(variableCompensationPercentageData.staffMemberId));

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Variablen Gehaltseintrag löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			key={isOpen ? "open" : "closed"}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!variableCompensationPercentageData && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>Variabler Gehaltsanteil</Label>
					<span className="text-base">{formatNumberToCurrency(variableCompensationPercentageData.percentage)} %</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base">{formatDateWithGermanMonth(variableCompensationPercentageData.validFrom)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteVariableCompensationPercentageEntryModal;
