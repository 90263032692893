import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembers,
	indexStaffMembersMentees,
	showStaffMember,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";

export const STAFF_MEMBER_BASE_QUERY_KEY = "staffMembers";

export const ALL_STAFF_MEMBERS_QUERY_KEY = [STAFF_MEMBER_BASE_QUERY_KEY, "all"];

export const useAllStaffMembers = (options?: QueriesOptions<any>) => useQuery(
	ALL_STAFF_MEMBERS_QUERY_KEY,
	() => indexStaffMembers().then(getDataFromResponse),
	options,
);

export const STAFF_MEMBER_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BASE_QUERY_KEY, staffMemberId];

export const useStaffMember = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBER_QUERY_KEY(staffMemberId),
	() => showStaffMember(staffMemberId).then(getDataFromResponse),
	options,
);

export const STAFF_MEMBERS_MENTEES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BASE_QUERY_KEY, "mentees", staffMemberId];

export const useStaffMembersMentees = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_MENTEES_QUERY_KEY(staffMemberId),
	() => indexStaffMembersMentees(staffMemberId).then(getDataFromResponse),
	options,
);