import type { FunctionComponent } from "react";

import Hint from "~/components/Hint";

const EligibleStaffMembersMessage: FunctionComponent = () => {
	return <Hint size="sm">
		<p>Neue User für interne Mitarbeiter:innen können nur auf Basis bestehender HR-Daten
			erzeugt
			werden.</p>
		<p className="mt-4 font-bold">Bitte wählen den gewünschten Mitarbeiter / die gewünschte Mitarbeiterin aus der folgenden Liste aus.</p>
	</Hint>;
};

export default EligibleStaffMembersMessage;