import { t } from "i18next";
import type { Control, FieldValues } from "react-hook-form";

import CheckButton from "~/components/formElements/CheckButton";
import FormSection from "~/components/formElements/FormSection";
import type { FormDataPropertyObject } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type UserPermissionsFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	permissions: string[] | undefined;
};

type UserPermissionsFormData = {
	permissions: FormDataPropertyObject | undefined;
};

const UserPermissionsFormSection = <T extends UserPermissionsFormData>({
	control,
	permissions,
}: UserPermissionsFormSectionProps<T>) => {
	return (
		<FormSection title="NORD.WORK User Berechtigungen">
			<div className="mt-2 flex flex-col gap-2">
				{permissions &&
					permissions.map((permission, index) => (
						<CheckButton
							key={index}
							control={control}
							name={`permissions.${permission}`}
						>
							{t(normalizeTKey(`entities:permission.${permission}`))}
						</CheckButton>
					))}
			</div>
		</FormSection>
	);
};

export default UserPermissionsFormSection;
