import ProjectTag
	from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import { useAllProductTags } from "~/modules/project/api/productTags/productTagsQueries.ts";
import UpdateProductTagsSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectProductTagsSection/components/UpdateProductTagsSidebar";

type Props = { productTagIds: string[], projectId: string };

const ProjectProductTagsSection: FunctionComponent<Props> = ({ productTagIds, projectId }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState<boolean>(false);
	const { data: allProductTags } = useAllProductTags();

	const productTagPills = useMemo(() => {
		if (productTagIds && allProductTags) {
			return productTagIds.map((productTagId) => {
				const productTag = allProductTags.find((tag) => tag.id === productTagId);
				if (productTag) {
					return <ProjectTag key={productTag.id}
									   displayName={productTag.displayName}
									   theme="product" />;
				}

			});
		}
		return null;
	}, [allProductTags, productTagIds]);

	return <>
		<Card title="Produkt Tags"
			  onHeaderButtonClick={() => setShowUpdateSidebar(!showUpdateSidebar)}
			  headerButtonText="Bearbeiten">
			<div className="flex flex-wrap gap-2">
				{productTagPills?.length === 0 && <span className="py-1.5 text-sm text-gray-500">Keine Produkt Tags zugewiesen</span>}
				{productTagPills}
			</div>
		</Card>
		<UpdateProductTagsSidebar
			isOpen={showUpdateSidebar}
			onClose={() => setShowUpdateSidebar(false)}
			productTagIds={productTagIds}
			projectId={projectId} />
	</>;
};


export default ProjectProductTagsSection;