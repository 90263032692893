import type { FunctionComponent } from "react";

import { ReactComponent as LogoSvg } from "./logo-nordnord.svg";

const Logo: FunctionComponent = () => (
	<div className="flex items-center">
		<LogoSvg className="w-full fill-current text-current transition-colors duration-200" />
	</div>
);

export default Logo;
