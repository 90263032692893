import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import Button from "~/components/buttons/Button";

type Props = {
	onClick: () => void;
};

const SidebarBackButton: FunctionComponent<Props> = ({ onClick }) => {
	return <Button className="mr-auto"
				   theme="none"
				   onClick={onClick}>
		<ChevronLeftIcon className="size-6" /><span>Zurück</span>
	</Button>;
};

export default SidebarBackButton;