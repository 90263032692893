import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	StaffMonthlyReportEntry,
} from "~/modules/humanResources/api/staffMonthlyReport/staffMonthlyReportTypes.ts";

const basePath = "monthly-compensation-reports";

export function getReportByMonth(startOfMonth: string): AxiosApiResponsePromise<StaffMonthlyReportEntry[]> {
	return axiosApi.get(basePath + "/" + startOfMonth);
}
