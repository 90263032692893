import { ComboboxOption } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import ProjectTag
	from "components/projectTags/ProjectTag";
import ProjectTagWithUsage from "components/projectTags/ProjectTagWithUsage";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useState } from "react";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import Card from "~/components/Card";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import PageHeading from "~/components/headings/PageHeading";
import Section from "~/components/sections/Section";
import { PROJECT_DESCRIPTION_VIEW_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import ProjectDescriptionResultCard
	from "~/modules/project/components/ProjectDescriptionsIndexView/components/ProjectDescriptionResultCard";
import type {
	ProjectDescriptionCardData,
	ProjectDescriptionViewPageState,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { getSelectedTagsFromIds, getTagFilterResults } from "~/modules/project/utils/projectDescriptionViewUtils.ts";

type Props = {
	projectData: ProjectDescriptionCardData[];
	projectTags: ProjectTagComboboxOption[];
};

const projectTagOptionRenderFn = (tag: ProjectTagComboboxOption) => {
	return <ComboboxOption
		key={`${tag.value} ${tag.type}`}
		value={tag}
		className={({ focus }) =>
			clsx(
				"relative cursor-default select-none px-3 py-1.5 ",
				focus ? "bg-accent-500 text-white" : "text-gray-900",
			)
		}
	>
		<ProjectTagWithUsage displayName={tag.label}
							 numberUsages={tag.usages}
							 theme={tag.type} />
	</ComboboxOption>;
};

const defaultPageState: ProjectDescriptionViewPageState = {
	selectedDeliverableTagIds: [],
	selectedProductTagIds: [],
};

const ProjectDescriptionsIndexView: FunctionComponent<Props> = ({
	projectData,
	projectTags,
}) => {
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: PROJECT_DESCRIPTION_VIEW_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});

	const [selectedDeliverableTags, setSelectedDeliverableTags] = useState<ProjectTagComboboxOption[]>(getSelectedTagsFromIds(projectTags, "deliverable", pageState.selectedDeliverableTagIds));
	const [selectedProductTags, setSelectedProductTags] = useState<ProjectTagComboboxOption[]>(getSelectedTagsFromIds(projectTags, "product", pageState.selectedProductTagIds));


	useEffect(() => {
		setPageState({
			selectedDeliverableTagIds: selectedDeliverableTags.map(tag => tag.value),
			selectedProductTagIds: selectedProductTags.map(tag => tag.value),
		});
	}, [selectedDeliverableTags, selectedProductTags, setPageState, setSelectedProductTags]);

	const handleAddTag = useCallback((newTagOption: ProjectTagComboboxOption | null) => {
		if (newTagOption === null) return;
		if (newTagOption.type === "deliverable") {
			setSelectedDeliverableTags(prevState => {
				if (prevState.find(tagOption => newTagOption.value === tagOption.value)) return prevState;
				return [...prevState, newTagOption];
			});
		} else {
			setSelectedProductTags(prevState => {
				if (prevState.find(tagOption => newTagOption.value === tagOption.value)) return prevState;
				return [...prevState, newTagOption];
			});
		}

	}, []);

	const handleRemoveTag = useCallback((tagToRemove: ProjectTagComboboxOption) => {

		if (tagToRemove.type === "deliverable") {
			setSelectedDeliverableTags(prevState => {
				const tagIndex = prevState.findIndex(tagOption => tagToRemove.value === tagOption.value);
				if (tagIndex === -1) return prevState;
				const newState = [...prevState];
				newState.splice(tagIndex, 1);
				return newState;
			});
		} else {
			setSelectedProductTags(prevState => {
				const tagIndex = prevState.findIndex(tagOption => tagToRemove.value === tagOption.value);
				if (tagIndex === -1) return prevState;
				const newState = [...prevState];
				newState.splice(tagIndex, 1);
				return newState;
			});
		}


	}, []);

	const tagsAreSelected = selectedDeliverableTags.length + selectedProductTags.length > 0;

	const filteredProjects = getTagFilterResults(selectedProductTags, selectedDeliverableTags, projectData).map(project => {
		return <ProjectDescriptionResultCard key={project.id}
											 projectData={project} />;
	});

	return <div className="grid h-screen grid-rows-[auto_auto_auto_1fr]">
		<div className="bg-white">
			<BreadcrumbsSection pages={["Suche"]} />
		</div>
		<div className="w-full bg-white pb-2">
			<PageHeading title="Suche" />
		</div>
		<Section className="z-10 mt-4">
			<Card>
				<div className="flex gap-x-4">
					<div className="w-96">
						<ComboBoxPlain optionsData={projectTags}
									   value=""
									   immediate
									   onChange={(tag) => handleAddTag(tag as ProjectTagComboboxOption)}
									   placeholder="Project Tags eingeben..."
									   allowNew={false}
									   optionRenderFn={projectTagOptionRenderFn}
						/>
					</div>
					<div className="flex size-full flex-wrap justify-start gap-2 pt-1.5">
						{selectedProductTags.map(tag => {
							if (!tag) return null;
							return <ProjectTag key={tag.value + tag.type}
											   displayName={tag.label}
											   theme="product"
											   onDeleteClick={() => handleRemoveTag(tag)} />;
						})}
						{selectedDeliverableTags.map(tag => {
							if (!tag) return null;
							return <ProjectTag key={tag.value + tag.type}
											   theme="deliverable"
											   displayName={tag.label}
											   onDeleteClick={() => handleRemoveTag(tag)} />;
						})}
					</div>
				</div>
			</Card>
		</Section>
		<Section scrollable={true}>
			<div className="mt-4 flex h-full max-w-full flex-col gap-y-6 pb-10">
				{tagsAreSelected && filteredProjects}
				{!tagsAreSelected && <div className="flex size-full flex-col items-center justify-center text-gray-400">
					<MagnifyingGlassIcon className="size-48" />Tags eingeben, um Referenztexte zu suchen. </div>}
			</div>
		</Section>
	</div>;
};

export default ProjectDescriptionsIndexView;