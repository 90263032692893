import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberBonusData,
	StaffMemberBonus,
	UpdateStaffMemberBonusData,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusTypes.ts";


const bonusesBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/bonuses`;

export function indexStaffMembersBonuses(staffMemberId: string): AxiosApiResponsePromise<StaffMemberBonus[]> {
	return axiosApi.get(bonusesBasePath(staffMemberId));
}

export function createStaffMemberBonus(staffMemberId: string,
	bonusData: CreateStaffMemberBonusData): AxiosApiResponsePromise<void> {
	return axiosApi.post(bonusesBasePath(staffMemberId), bonusData);
}

export function updateStaffMemberBonus({ staffMemberId, bonusId, updateData }: {
	staffMemberId: string;
	bonusId: string;
	updateData: UpdateStaffMemberBonusData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${bonusesBasePath(staffMemberId)}/${bonusId}`, updateData);
}

export function deleteStaffMemberBonus(staffMemberId: string, bonusId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${bonusesBasePath(staffMemberId)}/${bonusId}`);
}