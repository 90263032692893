import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import Avatar from "~/components/Avatar";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import UserStatusFormSection from "~/components/formSections/UserStatusFormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBackButton from "~/components/Sidebar/components/SidebarBackButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { COMPANY_LOCATION_DEFAULT_ID } from "~/constants/form.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMemberUserData } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { createInternalUser, sendUserInvitation } from "~/modules/user/api/user/userApiDispatchers.ts";
import type { CreateInternalUserData } from "~/modules/user/api/user/userTypes.ts";
import UserPermissionsFormSection from "~/modules/user/components/forms/formSections/UserPermissionsFormSection";
import type { PermissionNames } from "~/types/entityNames.ts";
import type { FormDataPropertyObject } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onBackClick: () => void;
	onCancel: () => void;
	onSuccess: () => void;
	staffMemberUserData: StaffMemberUserData
};

type CreateUserFormData = Omit<CreateInternalUserData, "permissions" | "staffMemberId"> & {
	permissions: FormDataPropertyObject;
}

const CreateInternalUserForm: React.FC<Props> = ({ onBackClick, onSuccess, onCancel, staffMemberUserData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			email: yup.string()
				.email()
				.required()
				.uniqueEmail(emailComparisonData),
			isActive: yup.boolean().required(),
			locationId: yup.string().required(),
			permissions: yup.object(),
			shortName: yup.string().required(),
		});
	}, [emailComparisonData]);

	const { data: allPermissionsData } = useAllPermissions();

	const permissions = useMemo(() => {
		if (allPermissionsData) {
			return allPermissionsData.map((permission) => {
				return permission.name;
			});
		}
	}, [allPermissionsData]);

	const defaultValues = useMemo(() => {
		return {
			locationId: staffMemberUserData.locationId || COMPANY_LOCATION_DEFAULT_ID,
			email: staffMemberUserData.email || "",
			isActive: true,
			permissions: permissions
				? permissions.reduce((values, permission) => ({ ...values, [permission]: false }), {})
				: {},
		};
	}, [staffMemberUserData, permissions]);


	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty, isValid, isSubmitted },
	} = useForm<CreateUserFormData>({
		defaultValues,
		mode: "onSubmit",
		resolver: yupResolver<CreateUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const companyLocationSelectOptions = useCompanyLocationSelectOptions();

	const onSubmit: SubmitHandler<CreateUserFormData> = async (data) => {
		setBusy(true);
		let selectedPermissions: PermissionNames[] = [];

		if (data.permissions) {
			selectedPermissions = Object.keys(data.permissions).filter((key) =>
				data.permissions ? data.permissions[key] : false,
			) as PermissionNames[];
		}

		const newUserData = { ...data, permissions: selectedPermissions };

		try {
			const newUserResponse = await createInternalUser({
				...newUserData,
				staffMemberId: staffMemberUserData.staffMemberId,
			});
			const userData = getDataFromResponse(newUserResponse);

			if (userData.isActive) {
				try {
					await sendUserInvitation(userData.id);
				} catch (error) {
					console.log(error);
				}
			}
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};
	const isActive = watch("isActive");
	const careerLevel = getCareerLevelDisplayNameById(staffMemberUserData?.careerLevelId as CareerLevelId || EMPTY_VALUE_PLACEHOLDER);
	const employmentType = getEmploymentTypeDisplayNameById(staffMemberUserData.employmentTypeId as EmploymentTypeId || EMPTY_VALUE_PLACEHOLDER);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neuen internen User anlegen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<FormSectionGroup>
					{busy && <SidebarBusyOverlay />}

					<FormSection title="NORD.WORK User Daten">
						<div className="rounded-md px-4 py-3 text-sm shadow-sm ring-1 ring-inset ring-gray-300">
							<div className="flex items-center">
								<Avatar firstName={staffMemberUserData.firstName}
										lastName={staffMemberUserData.lastName}
										image={staffMemberUserData.avatarImage}
								/>
								<div className="ml-4 flex flex-col">
									<div className="text-left font-medium text-gray-900 group-hover:text-white">{`${staffMemberUserData.firstName} ${staffMemberUserData.lastName}`}</div>
									<div className="mt-1 text-gray-500 group-hover:text-gray-300">{careerLevel + " / " + employmentType}</div>
								</div>
							</div>
						</div>
						<div className="mt-6 grid grid-cols-2 gap-6">
							<Input name="email"
								   control={control}
								   label="E-Mail" />
							<Input name="shortName"
								   control={control}
								   label="Kürzel"
								   placeholder="MM"
							/>
							<Select name="locationId"
									optionsData={companyLocationSelectOptions}
									control={control}
									label="Standort" />
						</div>
					</FormSection>
					<UserStatusFormSection isActive={isActive}
										   control={control} />

					<UserPermissionsFormSection<CreateUserFormData> control={control}
																	permissions={permissions} />

				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SidebarBackButton onClick={onBackClick} />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateInternalUserForm;
