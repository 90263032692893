import CreateInternalUserForm
	from "modules/user/components/components/CreateUserSidebar/components/CreateInternalUserForm";
import UserTypeSelector
	from "modules/user/components/components/CreateUserSidebar/components/UserTypeSelector";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBER_BASE_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMemberUserData } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import CreateFreelanceUserForm
	from "~/modules/user/components/components/CreateUserSidebar/components/CreateFreelanceUserForm";
import StaffMemberSelector from "~/modules/user/components/components/CreateUserSidebar/components/StaffMemberSelector";

type CreateUserSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
};

const CreateUserSidebar: React.FunctionComponent<CreateUserSidebarProps> = ({ isOpen, closeSidebar }) => {
	const queryClient = useQueryClient();
	const [newUserType, setNewUserType] = useState<"freelancer" | "internal" | null>(null);
	const [selectedStaffMemberData, setSelectedStaffMemberData] = useState<StaffMemberUserData | null>(null);

	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		if (newUserType === "internal") {
			await queryClient.invalidateQueries(STAFF_MEMBER_BASE_QUERY_KEY);
		}
		await queryClient.invalidateQueries(STAFF_MEMBER_BASE_QUERY_KEY);
		closeSidebar();
	}, [closeSidebar, queryClient, newUserType]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeSidebar}>
			{newUserType === null && <UserTypeSelector onCancelClick={closeSidebar}
													   onClick={setNewUserType} />}
			{newUserType === "freelancer" && <CreateFreelanceUserForm onSuccess={handleSuccess}
																	  onBackClick={() => setNewUserType(null)}
																	  onCancel={closeSidebar} />}

			{newUserType === "internal" && selectedStaffMemberData === null &&
				<StaffMemberSelector onCancelClick={closeSidebar}
									 onBackClick={() => setNewUserType(null)}
									 setSelectedStaffMemberData={setSelectedStaffMemberData} />}

			{newUserType === "internal" && selectedStaffMemberData !== null &&
				<CreateInternalUserForm onSuccess={handleSuccess}
										onBackClick={() => setSelectedStaffMemberData(null)}
										onCancel={closeSidebar}
										staffMemberUserData={selectedStaffMemberData}
				/>}


		</Sidebar>
	);
};

export default CreateUserSidebar;
