export const DEFAULT_COUNTRY_ISO_NAME = "DE";

export const DEFAULT_COUNTRY_ID = "48";

export const DEFAULT_CURRENCY_ID = "3";

export const COMPANY_LOCATION_DEFAULT_ID = "1";

export const DEFAULT_LANGUAGE_ID = "1";

export const DEFAULT_SALES_FUNNEL_ID = "3";

export const DEFAULT_SALES_TYPE_ID = "1";

export const DEFAULT_PAYMENT_TARGET_DAYS_OPTIONS = [30, 60];

export const PAYMENT_TARGET_DAYS_OPTIONS = [{ value: 30 }, { value: 60 }, { value: 0, label: "Individuell" }];

export const PAYMENT_TARGET_DAYS_DEFAULT_VALUE = 30;


export const INVOICE_BY_MAIL_OPTIONS = [
	{ value: true, label: "Mail" },
	{ value: false, label: "Post" },
];

export const TRAVEL_EXPENSES_INCLUDED_OPTIONS = [
	{ value: true, label: "Included" },
	{ value: false, label: "On Top" },
];

export const FIXED_PRICE_OPTIONS = [
	{ value: false, label: "Tagessatz" },
	{ value: true, label: "Festpreis" },
];

export const MONTHLY_BILLING_OPTIONS = [
	{ value: true, label: "Monatlich" },
	{ value: false, label: "Individuell" },
];

export const SKILL_BASED_OPTIONS = [
	{ value: false, label: "Projektrolle" },
	{ value: true, label: "Skillklasse" },

];

export const USE_INFORMAL_SALUTATION_OPTIONS = [
	{ value: true, label: "Du" },
	{ value: false, label: "Sie" },
];