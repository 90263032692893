export type EmploymentType = {
	id: string;
	listPosition: number;
	name: string;
}


export enum EmploymentTypeName {
	Employed = "employed",
	Freelancer = "freelancer",
	Intern = "intern",
	Student = "student",
	Left = "left",
}

export enum EmploymentTypeId {
	Employed = "1",
	Freelancer = "2",
	Intern = "3",
	Student = "4",
	Left = "5",
}