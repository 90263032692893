import clsx from "clsx";
import type { PropsWithChildren } from "react";

interface Props {
	className?: string;
}

const ContentWrapper: React.FC<PropsWithChildren<Props>> = ({ className, children }) => (
	<section className={clsx("mx-auto max-w-7xl px-4 sm:px-6 lg:px-8", className)}>{children}</section>
);

export default ContentWrapper;
