import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_STAFF_MEMBERS_QUERY_KEY } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import CreateStaffMemberForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/CreateStaffMemberSidebar/components/CreateStaffMemberForm";

type CreateStaffMemberSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
};

const CreateStaffMemberSidebar: React.FunctionComponent<CreateStaffMemberSidebarProps> = ({
	isOpen,
	onClose,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(ALL_STAFF_MEMBERS_QUERY_KEY);
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<CreateStaffMemberForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose} />
		</Sidebar>
	);
};

export default CreateStaffMemberSidebar;
