import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import { FormSectionGroup } from "~/components/formElements/FormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createInvoice, updateInvoice } from "~/modules/billing/api/invoice/invoiceApiDispatchers.ts";
import type {
	CreateOrUpdateInvoiceData,
	CreateOrUpdateInvoiceFormData,
} from "~/modules/billing/api/invoice/invoiceTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateOrUpdateInvoiceFormProps = {
	initialValues: CreateOrUpdateInvoiceData
	onCancel: () => void;
	onSuccess: () => void;

};

const CreateOrUpdateInvoiceForm: React.FunctionComponent<CreateOrUpdateInvoiceFormProps> = ({
	initialValues,
	onSuccess,
	onCancel,
}) => {
	const [busy, setBusy] = useState(false);
	const isUpdateProcess = !!initialValues.invoiceId;
	const schema = useMemo(() => {
		return yup.object({
			invoiceDocumentCreatedAt: yup.date().nullable().default(null),
			invoicePaidAt: yup.date().nullable().default(null),
			invoiceSentAt: yup.date().nullable().default(null),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			invoiceDocumentCreatedAt: initialValues.invoiceDocumentCreatedAt,
			invoicePaidAt: initialValues.invoicePaidAt,
			invoiceSentAt: initialValues.invoiceSentAt,
		};
	}, [initialValues]);

	const {
		handleSubmit,
		formState: { errors, isValid, isDirty, isSubmitted },
		control,
	} = useForm<CreateOrUpdateInvoiceFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<CreateOrUpdateInvoiceFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
	});

	const onSubmit = async (data: CreateOrUpdateInvoiceFormData) => {
		setBusy(true);
		const invoiceDocumentCreatedAt = data.invoiceDocumentCreatedAt ? formatDateToYYYYMMDD(new Date(data.invoiceDocumentCreatedAt)) : null;
		const invoicePaidAt = data.invoicePaidAt ? formatDateToYYYYMMDD(new Date(data.invoicePaidAt)) : null;
		const invoiceSentAt = data.invoiceSentAt ? formatDateToYYYYMMDD(new Date(data.invoiceSentAt)) : null;
		try {
			if (initialValues.invoiceId) {
				const updateInvoiceData = {
					invoiceDocumentCreatedAt,
					invoicePaidAt,
					invoiceSentAt,
				};
				await updateInvoice({ invoiceId: initialValues.invoiceId, invoiceData: updateInvoiceData });
			} else {
				const createInvoiceData = {
					invoiceDocumentCreatedAt,
					invoicePaidAt,
					invoiceSentAt,
					orderId: initialValues.orderId,
					monthOfBilling: formatDateToYYYYMMDD(new Date(initialValues.monthOfBilling)),
				};
				await createInvoice(createInvoiceData);
			}
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>{initialValues.invoiceId ? "Rechnung aktualisieren" : "Rechnung erstellen"}</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
						<div className="col-span-full sm:col-start-1">
							<DatePickerInput
								control={control}
								name="invoiceDocumentCreatedAt"
								label="Erstellt am"
								error={errors.invoiceDocumentCreatedAt?.message}
							/>
						</div>
						<div className="col-span-full sm:col-start-1">
							<DatePickerInput
								control={control}
								name="invoiceSentAt"
								label="Verschickt am"
								error={errors.invoiceSentAt?.message}
							/>
						</div>
						<div className="col-span-full sm:col-start-1">
							<DatePickerInput
								control={control}
								name="invoicePaidAt"
								label="Bezahlt am"
								error={errors.invoicePaidAt?.message}
							/>
						</div>

					</div>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isUpdateProcess && !isValid || (!isUpdateProcess && !isValid && isSubmitted)}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateOrUpdateInvoiceForm;