import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useCallback, useRef, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import FormInputError from "~/components/formElements/FormInputError";
import Label from "~/components/formElements/Label";
import useAutoFocus from "~/hooks/useAutofocus.ts";

type BaseOption = {
	value: string | number;
	label: string;
	[key: string]: any;
};

type SharedPropsType<T extends BaseOption = BaseOption> = FormInputBaseVariants & {
	autoFocus?: boolean;
	allowNew?: boolean;
	disabled?: boolean;
	immediate?: boolean;
	label?: string;
	placeholder?: string;
	error?: string;
	optionsData: T[];
	optionRenderFn?: (option: T) => React.ReactElement<typeof ComboboxOption>;
};

type ComboBoxPlainProps<T extends BaseOption = BaseOption> = SharedPropsType<T> & {
	name?: string;
	errorMessage?: string;
	value?: T | T["value"] | null;
	onChange?: (value: T | T["value"] | null) => void;
};

export const ComboBoxPlain = <T extends BaseOption>({
	autoFocus,
	allowNew = true,
	disabled,
	errorMessage,
	immediate,
	inputSize,
	label,
	optionsData,
	name,
	placeholder = "",
	theme,
	value,
	onChange,
	optionRenderFn,
}: ComboBoxPlainProps<T>) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [query, setQuery] = useState("");
	const openButtonRef = useRef<HTMLButtonElement | null>(null);
	const filteredOptions =
		query === ""
			? optionsData
			: optionsData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));


	const getDisplayValue = useCallback((value: string | null) => {
		if (optionsData) {
			if (allowNew) {
				return value || "";
			}
			return optionsData.find((option) => option.value === value)?.label || "";
		}
		return "";
	}, [allowNew, optionsData]);

	useAutoFocus(autoFocus || false, false, inputRef);

	const defaultOnchangeHandler = useCallback((value: T["value"] | null) => {
		if (value === null) return null;
		return typeof value;
	}, []);

	const handleChange = onChange || defaultOnchangeHandler;

	return (
		<Combobox as="div"
				  immediate={immediate}
				  name={name}
				  value={value}
				  disabled={disabled}
				  onChange={handleChange}>
			<FormInput>
				{label && <Label>{label}</Label>}
				<div className="relative">
					<div className="relative">
						<ComboboxInput
							ref={inputRef}
							autoComplete="off"
							placeholder={placeholder}
							className={clsx(formInputBaseVariants({ inputSize, theme, disabled }))}
							onChange={(event) => setQuery(event.target.value)}
							displayValue={(value: string | null) => getDisplayValue(value)}
						/>
						<ComboboxButton ref={openButtonRef}
										className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
							<ChevronUpDownIcon className="size-5 text-gray-400"
											   aria-hidden="true" />
						</ComboboxButton>
					</div>
					{errorMessage && <FormInputError>{errorMessage}</FormInputError>}
					<ComboboxOptions
						className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md 
												bg-white text-base 
												shadow-lg ring-1 ring-black/5
												 focus:outline-none sm:text-sm`
						}>
						{allowNew && query.length > 0 && (
							<ComboboxOption
								value={query}
								className={({ focus }) =>
									clsx(
										"relative cursor-default select-none py-2 pl-3 pr-9 text-sm",
										focus ? "bg-accent-500 text-white" : "text-gray-900",
									)
								}
							>
								<span className="mr-1 rounded-md border border-white bg-accent-500 px-1.5 py-1 text-xs text-white">Neu</span> {query}
							</ComboboxOption>
						)}

						{filteredOptions.map((option) => (
							optionRenderFn
								? optionRenderFn(option)
								: (
									<ComboboxOption key={option.value}
													value={option.value}>
										{({ focus, selected }) => (
											<div className={clsx(
												"relative cursor-default select-none py-2 pl-3 pr-9",
												focus ? "bg-accent-500 text-white" : "text-gray-900",
											)}>
											  <span className={clsx("block max-w-full truncate", selected && "font-semibold")}>
												{option.label}
											  </span>
												{selected && (
													<span className={clsx(
														"absolute inset-y-0 right-0 flex items-center pr-4",
														focus ? "text-white" : "text-indigo-600",
													)}>
													  <CheckIcon className="size-5"
																 aria-hidden="true" />
													</span>
												)}
											</div>
										)}
									</ComboboxOption>
								)
						))}
					</ComboboxOptions>
				</div>
			</FormInput>
		</Combobox>
	);
};


type ComboBoxProps<T extends BaseOption = BaseOption> = SharedPropsType<T> & {
	name: string;
	control: Control<any>;
};

const ComboBox = <T extends BaseOption>({
	autoFocus,
	name,
	control,
	allowNew = true,
	disabled,
	immediate,
	optionsData,
	label,
	placeholder,
	optionRenderFn,
}: ComboBoxProps<T>) => {
	const { field: { onChange, value }, fieldState: { error } } = useController({
		name,
		control,
	});

	return (
		<ComboBoxPlain
			autoFocus={autoFocus}
			allowNew={allowNew}
			disabled={disabled}
			immediate={immediate}
			optionsData={optionsData}
			value={value}
			onChange={onChange}
			label={label}
			placeholder={placeholder}
			errorMessage={error?.message}
			optionRenderFn={optionRenderFn}
		/>
	);
};

export default ComboBox;