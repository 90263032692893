import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllLanguages } from "~/modules/country/api/language/languageApiDispatchers.ts";

const ENTITY_QUERY_KEY = "language";

export const ALL_LANGUAGES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllLanguages = (options?: QueriesOptions<any>) => useQuery(
	ALL_LANGUAGES_QUERY_KEY,
	() => getAllLanguages().then(getDataFromResponse),
	options,
);