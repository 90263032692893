import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import {
	STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY
} from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import { deleteStaffMemberEmployment } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsApiDispatchers.ts";
import { STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type { StaffMemberEmploymentWithDate } from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	employmentData: StaffMemberEmploymentWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteEmploymentModal: React.FunctionComponent<Props> = ({
	employmentData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!employmentData) return;
		try {
			setBusy(true);
			await deleteStaffMemberEmployment(employmentData.staffMemberId, employmentData.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY(employmentData.staffMemberId));
			await queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(employmentData.staffMemberId));
			queryClient.invalidateQueries(ALL_STAFF_MEMBERS_QUERY_KEY);
			queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);
			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(employmentData.staffMemberId));
			queryClient.invalidateQueries(STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(employmentData.staffMemberId));
			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(employmentData.staffMemberId));
			queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(employmentData.staffMemberId));
			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Eintrag aus Beschäftigungshistorie löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!employmentData && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>Anstellungsart</Label>
					<span className="text-base">{getEmploymentTypeDisplayNameById(employmentData.employmentTypeId as EmploymentTypeId)}</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base">{formatDateWithGermanMonth(employmentData.validFrom)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteEmploymentModal;
