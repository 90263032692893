import type Decimal from "decimal.js-light";
import type React from "react";
import type { UseFormWatch } from "react-hook-form";

import FormSection from "~/components/formElements/FormSection";
import Label from "~/components/formElements/Label";
import { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import { useAllHolidays } from "~/modules/absence/api/holiday/holidayQueries.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import { calculateVacationDaysFromTimespan } from "~/modules/absence/utils/vacationUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type VacationLedgerFormSectionProps = {
	watch: UseFormWatch<any>
	initialRemainingVacationDays: Decimal;
	workingSchedules: WorkingSchedule[];
};

const VacationLedgerFormSection: React.FunctionComponent<VacationLedgerFormSectionProps> = ({
																								watch,
																								initialRemainingVacationDays,
																								workingSchedules,
																							}) => {
	const absenceTypeId = watch("absenceTypeId");
	const startDate = watch("startDate");
	const endDate = watch("endDate");
	const firstDayIsHalf = watch("firstDayIsHalf");
	const lastDayIsHalf = watch("lastDayIsHalf");
	const { data: allHolidaysData } = useAllHolidays();

	const vacationDays = calculateVacationDaysFromTimespan({
		startDate,
		endDate,
		firstDayIsHalf,
		lastDayIsHalf,
		holidays: allHolidaysData!,
		workingSchedules,
	});

	const remainingVacationDays = initialRemainingVacationDays.minus(vacationDays);

	if(absenceTypeId !== AbsenceTypeId.Vacation) return null;

	return <FormSection title="Urlaubskonto">
		<div className="grid grid-cols-6 gap-x-6 gap-y-2">
			<div className="col-span-3 flex flex-col">
				<Label>Arbeitstage</Label>
				<div>{formatNumberWithComma(vacationDays.toNumber(),2)}</div>
			</div>
			<div className="col-span-3 flex flex-col">
				<Label>Verbleibende Urlaubstage</Label>
				<div>{formatNumberWithComma(remainingVacationDays.toNumber(),2)}</div>

			</div>

		</div>
	</FormSection>;
};

export default VacationLedgerFormSection;