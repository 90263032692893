import {ReactComponent as Icon} from "./font-color.svg"

type StopwatchIconProps = {
	className?: string;
};

const TextColorIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default TextColorIcon;