import type { Control, FieldErrors, FieldValues } from "react-hook-form";

import DatePickerInput from "~/components/formElements/DatePickerInput";
import type { FormSectionProps } from "~/components/formElements/FormSection";
import FormSection from "~/components/formElements/FormSection";

type TimingFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>;
	errors: FieldErrors<T>;
};

type TimingFormSectionFormDataType = {
	startDate: Date | null;
	endDate: Date | null;
};

const TimingFormSection = <T extends TimingFormSectionFormDataType>({
	control,
	errors,
	title,
}: TimingFormSectionProps<T>) => {
	return (
		<FormSection title={title}>
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<DatePickerInput
						name="startDate"
						label="Startdatum"
						control={control}
						error={errors.startDate?.message as string}
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						name="endDate"
						position="bottom-end"
						label="Enddatum"
						control={control}
						error={errors.endDate?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default TimingFormSection;
