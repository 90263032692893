import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type Decimal from "decimal.js-light";
import type React from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import { formatVacationDays } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";


type VacationLedgerTableRowProps = {
	comment: string | null;
	createdBy: string | null;
	isDeletable: boolean;
	isEditable: boolean;
	isEmploymentRelated: boolean;
	onDeleteClick: () => void;
	onEditClick: () => void;
	type: string;
	vacationDays: Decimal;
	validFrom: string;
};



const VacationLedgerTableRow: React.FunctionComponent<VacationLedgerTableRowProps> = ({
	comment,
	createdBy,
	isDeletable,
	isEditable,
	isEmploymentRelated,
	onDeleteClick,
	onEditClick,
	type,
	vacationDays,
	validFrom,
}) => {

	return (
		<tr className="bg-white hover:bg-gray-200">
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				{type || "--"}
			</td>
			<td className={clsx("whitespace-nowrap px-3 py-5 text-sm", isEmploymentRelated ? "font-medium text-gray-700": "text-gray-500")}>
				{comment || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{validFrom}</td>
			<td className={clsx("whitespace-nowrap px-3 py-5 text-sm font-bold", vacationDays.isNegative() ? "text-red-500" : "text-green-500")}>
				{formatVacationDays(vacationDays) || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{createdBy || "--"}
			</td>
			<td className="relative ml-auto whitespace-nowrap pr-3 text-end text-sm">
				{(isEditable || isDeletable) && <ButtonWithPopover
					theme="dark"
					items={[
						{
							label: "Bearbeiten",
							onClick: onEditClick,
							icon: PencilIcon,
						},
						{
							label: "Löschen",
							onClick: onDeleteClick,
							icon: TrashIcon,
						},
					]}
				/>}
			</td>
		</tr>
	);
};

export default VacationLedgerTableRow;