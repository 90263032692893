import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffingData,
	Staffing,
	UpdateStaffingData,
	UsersActiveStaffingType,
} from "~/modules/project/api/staffing/staffingTypes.ts";

interface CreateStaffingParamsType {
	projectId: string;
	staffingData: CreateStaffingData;
}

interface UpdateStaffingParamsType {
	projectId: string;
	staffingId: string;
	staffingData: UpdateStaffingData;
}

export function indexStaffingByProjectId({
	projectId,
}: {
	projectId: string;
}): AxiosApiResponsePromise<Staffing[]> {
	return axiosApi.get(`projects/${projectId}/staffings`);
}

export function createStaffing({
	projectId,
	staffingData,
}: CreateStaffingParamsType): AxiosApiResponsePromise<Staffing> {
	return axiosApi.post(`projects/${projectId}/staffings`, { ...staffingData });
}

export function getStaffing({
	projectId,
	staffingId,
}: {
	projectId: string;
	staffingId: string;
}): AxiosApiResponsePromise<Staffing> {
	return axiosApi.get(`projects/${projectId}/staffings/${staffingId}`);
}

export async function indexUsersActiveStaffings({
	userId,
}: {
	userId: string;
}): AxiosApiResponsePromise<UsersActiveStaffingType[]> {
	return axiosApi.get(`users/${userId}/staffings/active`);
}

export function updateStaffing({
	projectId,
	staffingId,
	staffingData,
}: UpdateStaffingParamsType): AxiosApiResponsePromise<Staffing> {
	return axiosApi.put(`/projects/${projectId}/staffings/${staffingId}`, { ...staffingData });
}

export function updateStaffingActivity({
	projectId,
	staffingId,
	isActive,
}: { projectId: string, staffingId: string, isActive: boolean }): AxiosApiResponsePromise<Staffing> {
	return axiosApi.patch(`/projects/${projectId}/staffings/${staffingId}/activity`, { isActive });
}


export function deleteStaffing({
	projectId,
	staffingId,
}: {
	projectId: string;
	staffingId: string;
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`/projects/${projectId}/staffings/${staffingId}`);
}
