import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useCompany } from "~/modules/client/api/company/companyQueries.ts";
import CompanyDetailsView from "~/modules/client/components/CompanyDetailsView";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

const ClientDetailsPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if (guard) return guard;
	const { companyId } = useParams();
	if (undefined === companyId) return null;

	const { data, options } = useAggregatedDataV2({
		allClients: useAllClients(),
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		company: useCompany(companyId),
	});

	const { allClients, allCompanyAvatarImages,company } = data;

	const companiesClients = useMemo(() => {
		if (allClients) {
			return allClients.filter(client => client.companyId === companyId).sort(byObjectProperty("displayName"));
		}
		return [];
	}, [allClients, companyId]);

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;
	return (
		<CompanyDetailsView
			allCompanyAvatarImages={allCompanyAvatarImages}
			companyData={company}
			companiesClients={companiesClients}
		/>
	);
};

export default ClientDetailsPage;
