import type { FunctionComponent } from "react";

import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import CompensationReportBaseCell
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/CompensationReportBaseCell";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	highlighted?: boolean;
	value: number | null;
};

const CompensationReportPercentageCell: FunctionComponent<Props> = ({ highlighted = false, value }) => {
	return <CompensationReportBaseCell highlighted={highlighted}>{value ? formatNumberWithComma(value / 100, 2, true) + " %": EMPTY_VALUE_PLACEHOLDER}</CompensationReportBaseCell>;
};

export default CompensationReportPercentageCell;