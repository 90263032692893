import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import Label from "~/components/formElements/Label";

type Props = PropsWithChildren & {
	label: string;
	hasTopPadding?: boolean;
	hasBottomBorder?: boolean;
	theme?: "light" | "dark";
};

const CardValueWithLabel: FunctionComponent<Props> = ({ children, label, hasBottomBorder = true, theme = "light" }) => {
	return <div className={clsx("flex flex-col py-3", hasBottomBorder && "border-b border-gray-100")}>
		<Label size="sm"
			   theme={theme}
			   lineHeight="none"
			   className="px-4">{label}</Label>
		<div className="px-4">
			{children}
		</div>
	</div>;
};

export default CardValueWithLabel;