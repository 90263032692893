import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { isDate } from "date-fns";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import Hint from "~/components/Hint";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { COMPANY_LOCATION_DEFAULT_ID } from "~/constants/form.ts";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import useEmploymentTypeSelectOptions, {
	publiclyAvailableEmploymentTypesFn,
} from "~/hooks/form/formOptionsData/useEmploymentTypeSelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { createInactiveUser } from "~/modules/user/api/user/userApiDispatchers.ts";
import type { CreateInactiveUserData, User } from "~/modules/user/api/user/userTypes.ts";
import UserEmploymentInformationFormSection
	from "~/modules/user/components/forms/formSections/UserEmploymentInformationFormSection";
import UserPersonalInformationFormSection
	from "~/modules/user/components/forms/formSections/UserPersonalInformationFormSection";
import type { PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onSuccess: (userData: User) => void;
	onCancel: () => void;
	userType?: "freelancer" | "internal";
};

type CreateInactiveUserFormData = Omit<CreateInactiveUserData, "dateOfBirth"> & {
	dateOfBirth: Date | null;
}

const CreateInactiveUserForm: React.FC<Props> = ({ onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			locationId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			email: yup.string()
				.email()
				.required()
				.uniqueEmail(emailComparisonData),
			employmentTypeId: yup.string().required(),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			lastName: yup.string().required(),
			nickname: yup.string().default(""),
			phone: yup.string().default(""),
			shortName: yup.string().required().default(""),
		});
	}, [emailComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			careerLevelId: "",
			locationId: COMPANY_LOCATION_DEFAULT_ID,
			dateOfBirth: null,
			email: "",
			employmentTypeId: "",
			firstName: "",
			genderId: "",
			lastName: "",
			nickname: "",
			phone: "",
		};
	}, []);


	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
	} = useForm<CreateInactiveUserFormData>({
		defaultValues,
		mode: "onSubmit",
		resolver: yupResolver<CreateInactiveUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const careerLevelSelectOptions = useCareerLevelOptions();
	const companyLocationSelectOptions = useCompanyLocationSelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const employmentTypeSelectOptions = useEmploymentTypeSelectOptions(publiclyAvailableEmploymentTypesFn);

	const onSubmit: SubmitHandler<CreateInactiveUserFormData> = async (data) => {
		setBusy(true);
		const selectedPermissions: PermissionNames[] = [];

		const dateOfBirth = isDate(data.dateOfBirth) ? formatDateToYYYYMMDD(data.dateOfBirth as Date) : null;

		const newUserData = { ...data, permissions: selectedPermissions, dateOfBirth };

		try {
			const newUserResponse = await createInactiveUser(newUserData);
			const userData = getDataFromResponse(newUserResponse);
			onSuccess(userData);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neuen inaktiven User anlegen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Hinweis">
						<Hint size="sm">
							<p>Es wird ein inaktiver User angelegt, der vorerst keinen Zugriff auf NORD.WORK hat. Er
								/ sie kann jedoch einem Staffing zugeordnet werden.</p>
							<p>Die Aktivierung kann nur durch einen User mit Admin-Rechten vorgenommen
								werden.</p>
						</Hint>
					</FormSection>
					<UserPersonalInformationFormSection<CreateInactiveUserFormData>
						control={control}
						genderSelectOptions={genderSelectOptions}
					/>
					<UserEmploymentInformationFormSection<CreateInactiveUserFormData>
						control={control}
						employmentTypeSelectOptions={employmentTypeSelectOptions}
						companyLocationSelectOptions={companyLocationSelectOptions}
						careerLevelSelectOptions={careerLevelSelectOptions}
					/>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateInactiveUserForm;
