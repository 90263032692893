import type { FunctionComponent } from "react";

import Section from "~/components/sections/Section";
import DocumentLinksSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection";
import SpecialAgreementsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/SpecialAgreementsSection";

type Props = { staffMemberId: string };

const StaffMemberDocumentsSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	return <Section>
		<div className="grid w-full grid-cols-3 gap-4">
			<DocumentLinksSection staffMemberId={staffMemberId} />

			<div className="col-span-2">
				<SpecialAgreementsSection staffMemberId={staffMemberId} />
			</div>

		</div>
	</Section>;
};

export default StaffMemberDocumentsSection;