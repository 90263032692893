import clsx from "clsx";
import type { FunctionComponent } from "react";

import { statusVariants } from "~/components/Status/statusVariants.ts";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import { getWorkingStatusNameById, getWorkingStatusThemeById } from "~/modules/project/utils/projectUtils.ts";

type Props = {
	displayedProjectsCount: number;
	myProjectsFilter: boolean;
	onChangeMyProjectsFilter: (state: any) => void;
	onChangeSearch: (state: any) => void;
	onChangeStatus: (state: any) => void;
	searchValue: string | undefined;
	statusFilter: string[];
	totalProjectsCount: number;
};

const ActiveFilters: FunctionComponent<Props> = ({
	displayedProjectsCount,
	myProjectsFilter,
	onChangeMyProjectsFilter,
	onChangeSearch,
	onChangeStatus,
	searchValue,
	statusFilter,
	totalProjectsCount,
}) => {
	const workingStatusOptions = useWorkingStatusOptions();
	return (statusFilter.length > 0 || !!searchValue || myProjectsFilter) ? (
		<div className="w-full bg-gray-100 shadow-2xl shadow-gray-100">
			<div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
				<h3 className="text-sm font-medium text-gray-500">
					Aktive Filter
					<span className="sr-only">, active</span>
				</h3>

				<div aria-hidden="true"
					 className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

				<div className="sm:ml-4 sm:mt-0">
					<div className="-m-1 flex flex-wrap items-center gap-x-1">
						{workingStatusOptions.filter(workingStatus => statusFilter.includes(workingStatus.value)).map((workingStatusOption,
							index) => {
							const theme = getWorkingStatusThemeById(workingStatusOption.value);
							return <span
								key={"statusFilter" + index}
								className={clsx(`inline-flex items-center 
										rounded-full
										py-1.5 pl-3 pr-2 
										text-sm font-medium`, statusVariants({
									theme,
									borderRadius: "full",
								}))}
							>
										{getWorkingStatusNameById(workingStatusOption.value)}
								<button
									type="button"
									className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
									onClick={() => onChangeStatus(workingStatusOption.value)}
								>
											<span className="sr-only">Remove filter for status option</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>;
						})}
						{myProjectsFilter && <span
							key={"myProjects"}
							className="inline-flex items-center rounded-full bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
						>
										<span>Meine Projekte</span>
										<button
											type="button"
											className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeMyProjectsFilter(false)}
										>
											<span className="sr-only">Remove filter for Meine Projekte</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>}

						{searchValue &&
							<span className="inline-flex items-center rounded-full bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
							>
										<span>{searchValue}</span>
										<button
											type="button"
											className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeSearch("")}
										>
											<span className="sr-only">Remove filter for {searchValue}</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>
						}
					</div>
				</div>
				<span className="ml-auto text-xs text-gray-400">{displayedProjectsCount} von {totalProjectsCount} Projekten sichtbar</span>
			</div>
		</div>
	) : null;
};

export default ActiveFilters;