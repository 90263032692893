import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import ComboBox from "~/components/formElements/ComboBox";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import useMentorSelectOptions from "~/modules/client/hooks/useMentorSelectOptions.ts";
import { updateStaffMembersMentor } from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateMentorFormData = {
	mentorStaffMemberId: string | null;
}

type UpdateContactDetailsFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateMentorForm: React.FunctionComponent<UpdateContactDetailsFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const mentorSelectOptions = useMentorSelectOptions(staffMemberCoreData.id);

	const schema = useMemo(() => {
		return yup.object({
			mentorStaffMemberId: yup.string().nullable().default(null),
		});
	}, []);
	const defaultValues = useMemo(() => {
		if (staffMemberCoreData) {
			return {
				mentorStaffMemberId: staffMemberCoreData.mentorStaffMemberId || "0",
			};
		}
		return {
			mentorStaffMemberId: null,
		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateMentorFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateMentorFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdateMentorFormData) => {
		const updateData = {
			mentorStaffMemberId: data.mentorStaffMemberId === "0" ? null : data.mentorStaffMemberId,
		};

		try {
			setIsBusy(true);
			await updateStaffMembersMentor(staffMemberCoreData.id, updateData);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Mentor
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<ComboBox optionsData={mentorSelectOptions}
						  allowNew={false}
						  name="mentorStaffMemberId"
						  label="Mitarbeiter"
						  control={control} />
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateMentorForm;
