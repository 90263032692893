import type { FunctionComponent } from "react";

import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import CompensationReportBaseCell
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/CompensationReportBaseCell";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type Props = {
	highlighted?: boolean;
	value: number | null;
};

const CompensationReportCurrencyCell: FunctionComponent<Props> = ({ highlighted = false, value }) => {
	return <CompensationReportBaseCell highlighted={highlighted}>{value ? formatCentsToCurrency(value) : EMPTY_VALUE_PLACEHOLDER}</CompensationReportBaseCell>;
};

export default CompensationReportCurrencyCell;