import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getInvoiceRecipient,
	indexInvoiceRecipientByClient,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientApiDispatchers.ts";

const ENTITY_QUERY_KEY = "invoiceRecipient";
export const INVOICE_RECIPIENT_QUERY_KEY = (invoiceRecipientId: string) => [ENTITY_QUERY_KEY, {invoiceRecipientId}];
export const CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, {clientId}];

export const useInvoiceRecipient = (clientId: string, invoiceRecipientId: string, options?: QueriesOptions<any>) => useQuery(
	INVOICE_RECIPIENT_QUERY_KEY(invoiceRecipientId),
	() => getInvoiceRecipient(clientId, invoiceRecipientId).then(getDataFromResponse),
	options,
);

export const useClientsInvoiceRecipients = (clientId: string, options?: QueriesOptions<any>) => useQuery(
	CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY(clientId),
	() => indexInvoiceRecipientByClient(clientId).then(getDataFromResponse),
	options,
);