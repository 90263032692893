import {ReactComponent as Icon} from "./type-italic.svg"

type StopwatchIconProps = {
	className?: string;
};

const TextItalicIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default TextItalicIcon;