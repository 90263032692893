import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	createClientEndpoint,
	getAllClientsEndpoint,
	getClientEndpoint,
	updateClientEndpoint,
} from "~/modules/client/api/client/clientApiEndpoints.ts";
import type { Client, CreateClientData, UpdateClientData } from "~/modules/client/api/client/clientTypes.ts";

export function getClient(clientId: string): AxiosApiResponsePromise<Client> {
	return axiosApi.get(getClientEndpoint(clientId));
}

export function getAllClients(): AxiosApiResponsePromise<Client[]> {
	return axiosApi.get(getAllClientsEndpoint);
}

export function createClient(data: CreateClientData): AxiosApiResponsePromise<Client> {
	return axiosApi.post(createClientEndpoint, { ...data });
}

export function updateClient(clientId: string, data: UpdateClientData): AxiosApiResponsePromise<Client> {
	return axiosApi.put(updateClientEndpoint(clientId), { ...data });
}

export function deleteClient(clientId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(updateClientEndpoint(clientId));
}

