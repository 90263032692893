import type { TableMeta } from "@tanstack/react-table";

export enum ChartTypeValue {
	sickDaysAndEmployeeCount = "sickDaysAndEmployeeCount",
	sickDaysPerEmployee = "sickDaysPerEmployee",
	fte = "fte"
}

export type SickDaysPerStaffMemberTableData = {
	staffMemberId: string;
	staffMemberFullName: string;
	months: Record<string, number>;
	total: number;
}

export type SickDaysPerStaffMemberTableMeta = TableMeta<SickDaysPerStaffMemberTableData> & {
	selectedYear: string;
}

export type HrStatisticsPageState = {
	selectedChartType: ChartTypeValue;
	selectedYear: string;
}