import { AdjustmentsHorizontalIcon, UserMinusIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";

import Button from "~/components/buttons/Button";

type Props = {
	hiddenUsersCount: number;
	onToggleMultiActionClick: (showAll: boolean) => void,
	setShowUserFilter: (showUserFilter: boolean) => void,
	showUserFilter: boolean,
};

const UserFilterButtons: FunctionComponent<Props> = ({
	hiddenUsersCount,
	onToggleMultiActionClick,
	setShowUserFilter,
	showUserFilter,
}) => {
	return <div className="flex items-center space-x-4">
		<Button theme="primary" onClick={() => setShowUserFilter(!showUserFilter)}>
			<AdjustmentsHorizontalIcon className="size-6 fill-white"/>
		</Button>
		{!showUserFilter && hiddenUsersCount > 0 &&
			<span className="text-sm text-gray-400">{hiddenUsersCount} Mitarbeiter:in{hiddenUsersCount > 1 ? "nen" : ""} ausgeblendet</span>}
		{showUserFilter && <><Button theme="white" onClick={() => onToggleMultiActionClick(true)}>
			<UserPlusIcon className="size-6 fill-accent-600"/>
		</Button>
			<Button theme="white" onClick={() => onToggleMultiActionClick(false)}>
				<UserMinusIcon className="size-6 fill-secondary-700"/>
			</Button>
		</>}
	</div>;
};

export default UserFilterButtons;