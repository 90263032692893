import { XMarkIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";
import type { Control } from "react-hook-form";

import Button from "~/components/buttons/Button";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import Input from "~/components/formElements/Input";
import type {
	StaffMembersChildrenFormValues,
} from "~/modules/humanResources/types/updateStaffMembersChildrenFormTypes.ts";

type props = {
	control: Control<StaffMembersChildrenFormValues>;
	onRemoveClick: () => void;
	index: number;
};

const StaffMemberChildFormItem: FunctionComponent<props> = ({
	control,
	onRemoveClick,
	index,
}) => {

	return <div className="relative grid grid-cols-2 gap-x-4 gap-y-2 rounded border border-gray-200 p-3 shadow">
		<Input
			control={control}
			name={`children.${index}.firstName`}
			label="Vorname"
			placeholder="Maria"
		/>
		<Input
			control={control}
			name={`children.${index}.lastName`}
			label="Nachname"
			placeholder="Muster"
		/>

		<Button className="absolute right-1 top-1.5"
				theme="white"
				onClick={onRemoveClick}
				size="sm"><XMarkIcon className=" size-5 fill-danger-500 hover:fill-danger-700" /></Button>
		<DatePickerInput name={`children.${index}.dateOfBirth`}
						 control={control}
						 label="Geburtsdatum" />
	</div>;
};

export default StaffMemberChildFormItem;