import { flexRender } from "@tanstack/react-table";
import type { Table as TableType } from "@tanstack/table-core/build/lib/types";
import clsx from "clsx";

import TableFooterCell from "~/components/Table/components/TableFooterCell";


type DefaultTableBodyContentProps<T> = {
	table: TableType<T>
	verticalBorders?: boolean,
	rounded?: boolean
};

const DefaultTableFooterContent = <T, >({ table }: DefaultTableBodyContentProps<T>) => {
	return <>
		<tr className="h-[0.5px]">
			<td colSpan={table.getVisibleFlatColumns().length}
				className="h-full bg-gray-200" />
		</tr>
		{table.getFooterGroups().map(footerGroup => {
			return <tr key={footerGroup.id}>
				{footerGroup.headers.map(header => {
					return <TableFooterCell key={header.id}
											textAlign={header.column.columnDef.meta?.cellTextAlign}
											className={clsx("group-hover:bg-gray-200", header.column.getIsPinned() && "sticky left-0 z-20 bg-white")}
					>
						{flexRender(header.column.columnDef.footer, header.getContext())}
					</TableFooterCell>;
				})}
			</tr>;
		})}

	</>;
};


export default DefaultTableFooterContent;