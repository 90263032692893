import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import CurrencyInput from "~/components/formElements/CurrencyInput";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useBonusTypeSelectOptions from "~/hooks/form/formOptionsData/useBonusTypeSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { BonusTypeId } from "~/modules/humanResources/api/bonusType/bonusTypes.ts";
import {
	createStaffMemberBonus,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusApiDispatchers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	amountCents: number;
	bonusTypeId: string;
	description: string;
	paidAt: Date
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberId: string;
};

const CreateBonusEntryForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	staffMemberId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const bonusTypeSelectOptions = useBonusTypeSelectOptions();
	const schema = useMemo(() => {
		return yup.object({
			amountCents: yup.number().required().min(0),
			bonusTypeId: yup.string().required(),
			description: yup.string().required(),
			paidAt: yup.date().required(),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			amountCents: 0,
			bonusTypeId: BonusTypeId.Yearly,
			description: "",
			paidAt: new Date(),
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {

		const bonusData = {
			...data,
			paidAt: formatDateToYYYYMMDD(data.paidAt),
		};

		try {
			setIsBusy(true);
			await createStaffMemberBonus(staffMemberId, bonusData);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Neuer Bonus
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Select name="bonusTypeId"
							control={control}
							label="Art"
							optionsData={bonusTypeSelectOptions}
					/>
					<Input name="description"
						   control={control}
						   label="Beschreibung" />

					<CurrencyInput control={control}
								   label="Wert"
								   name="amountCents"
								   min={0}
					/>

					<DatePickerInput name="paidAt"
									 control={control}
									 label="Ausgezahlt am" />

				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateBonusEntryForm;
