import type { FunctionComponent, PropsWithChildren } from "react";

import type { CardVariantProps } from "~/components/Card/cardVariants.ts";
import { cardVariants } from "~/components/Card/cardVariants.ts";

type Props = PropsWithChildren & CardVariantProps & { hasTitle?: boolean };

const CardHeader: FunctionComponent<Props> = ({
	border,
	children,
	hasTitle = false,
	height,
	padding,
	shadow,
	theme,
}) => {
	const { header } = cardVariants(
		{ border, theme, padding, shadow, hasTitle, height });
	return <div className={header()}>
		{children}
	</div>;
};

export default CardHeader;