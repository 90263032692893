import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMembersChildren,
} from "~/modules/humanResources/api/staffMemberChild/staffMemberChildApiDispatchers.ts";
import type { StaffMemberChild } from "~/modules/humanResources/api/staffMemberChild/staffMemberChildTypes.ts";
import StaffMemberChildFormItem
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateStaffMembersChildrenSidebar/components/UpdateStaffMembersChildrenForm/components/StaffMemberChildFormItem";
import type {
	StaffMembersChildrenFormValues,
} from "~/modules/humanResources/types/updateStaffMembersChildrenFormTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	childrenData: StaffMemberChild[] | null;
	staffMemberId: string;
};

const emptyChildData = {
	id: null,
	firstName: "",
	lastName: "",
	dateOfBirth: null as unknown as Date,
};

const UpdateStaffMembersChildrenForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	childrenData,
	staffMemberId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = useMemo(() => {
		return yup.object({
			children: yup.array().of(yup.object({
				id: yup.string().nullable().default(null),
				firstName: yup.string().required(),
				lastName: yup.string().required(),
				dateOfBirth: yup.date().required().default(null),
			})).required(),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			children: childrenData && childrenData.length > 0 ? childrenData.sort(byObjectProperty("dateOfBirth")).map(child => {
				return {
					dateOfBirth: new Date(child.dateOfBirth),
					firstName: child.firstName,
					lastName: child.lastName,
				};
			}) : [emptyChildData],
		};
	}, [childrenData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<StaffMembersChildrenFormValues>({
		defaultValues: defaultValues,
		resolver: yupResolver<StaffMembersChildrenFormValues>(schema),
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "children",
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
		enableInitially: true,
	});

	const onSubmit = useCallback(async (data: StaffMembersChildrenFormValues) => {

		try {
			setIsBusy(true);
			const updateData = data.children.map(
				(child) => {
					return {
						...child,
						dateOfBirth: formatDateToYYYYMMDD(child.dateOfBirth),
					};
				});

			await updateStaffMembersChildren({ staffMemberId, updateData });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.");
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Kinder
				</Headline>
			</SidebarHeader>

			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="flex flex-col gap-y-4">
					{fields.map((field, fieldIndex) => {
						return <StaffMemberChildFormItem
							key={field.id}
							control={control}
							onRemoveClick={() => remove(fieldIndex)}
							index={fieldIndex} />;
					})}
					<ButtonNewItem onClick={() => append(emptyChildData)}
								   size="sm" />
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable || isBusy}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>)
		;
};

export default UpdateStaffMembersChildrenForm;
