import type { Control, FieldValues } from "react-hook-form";

import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";

type ContactContactFormSectionProps<T extends FieldValues> = {
	control:Control<T>;
};

type ContactContactFormData = {
	emailBusiness: string | null;
	emailPrivate: string | null;
	mobilePhone: string | null;
	phone: string | null;
};

const ContactContactFormSection = <T extends ContactContactFormData>({
	control
}: ContactContactFormSectionProps<T>) => {
	return (
		<FormSection title="Kontaktdaten">
			<div className="grid grid-cols-6 gap-6">
				<div className="col-span-3">
					<Input
						control={control}
						name="emailBusiness"
						label="E-Mail business"
						placeholder="name@company.com"
					/>
				</div>
				<div className="col-span-3">
					<Input
						control={control}
						name="emailPrivate"
						label="E-Mail privat"
						placeholder="name@home.com"
					/>
				</div>
				<div className="col-span-3">

					<Input
						control={control}
						name="phone"
						label="Telefon"
						placeholder="+49 40 XXXX XXXX"
					/>
				</div>
				<div className="col-span-3">
					<Input
						control={control}
						name="mobilePhone"
						label="Telefon mobil"
						placeholder="+49 XXX XX XX XXX"
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default ContactContactFormSection;
