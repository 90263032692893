import type { VacationLedgerEntryType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";

export type Absence = {
	id: string;
	absenceTypeId: string;
	comment: string | null;
	createdAt: string;
	createdBy: string;
	startDate: string;
	endDate: string;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean
	staffMemberId: string;
	updatedAt: string;
	updatedBy: string | null;
	workDays: number;
	vacationLedgerEntry?: VacationLedgerEntryType;
}

export type CreateAbsenceData ={
	absenceTypeId: string;
	startDate: string;
	endDate: string;
	comment: string|null;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
}

export type UpdateAbsenceData =  {
	absenceTypeId: string;
	comment: string | null;
	startDate: string;
	endDate: string;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean
}
export enum AbsenceTypeId {
	Vacation = "1",
	Sick = "2",
	Holiday = "3",
	Training = "4",
	Sabbatical = "5",
	MaternityLeave = "6",
	ParentalLeave = "7",
	InternalEvent = "8",
	Special = "9",
}

export enum AbsenceTypeName {
	Vacation = "vacation",
	Sick = "sick",
	Holiday = "holiday",
	Training = "training",
	Sabbatical = "sabbatical",
	MaternityLeave = "maternity_leave",
	ParentalLeave = "parental_leave",
	InternalEvent = "internal_event",
	Special = "special",
}

export type AbsenceIcsData = {
	absenceTypeName: string;
	comment: string | null;
	endDate: Date;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
	startDate: Date;
	userEmail: string;
	userFullName: string;
}