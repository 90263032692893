import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { PropsWithChildren } from "react";
import type { Control} from "react-hook-form";
import { Controller } from "react-hook-form";

type CheckButtonProps = {
	name: string;
	control: Control<any>;
};

const CheckButton: React.FunctionComponent<PropsWithChildren<CheckButtonProps>> = ({
	name,
	control,
	children,
}) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value: checked } }) => (
				<label
					className={clsx(
						"flex gap-2",
						"cursor-pointer rounded p-2 text-sm",
						"transition-colors duration-200",
						checked
							? "bg-accent-500 text-white hover:bg-accent-600"
							: "bg-gray-100 text-gray-700 hover:bg-gray-200",
					)}
				>
					{checked ? <CheckCircleIcon className="size-5" /> : <div className="ml-0.5 flex items-center justify-center"><div className="size-4 rounded-full bg-gray-300"/></div>}
					<input
						className="hidden"
						type="checkbox"
						checked={checked}
						onChange={onChange}
						name={name}
					/>
					{children}
				</label>
			)}
		/>
	);
};

export default CheckButton;
