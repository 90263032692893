import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getUsersDeliverablesForAvailableStaffings,
} from "~/modules/timeTracking/api/deliverable/deliverableApiDispatchers.ts";

export const DELIVERABLE_BASE_QUERY_KEY = "deliverable";

export const USER_DELIVERABLES_QUERY_KEY = (userId: string) => [DELIVERABLE_BASE_QUERY_KEY, { userId }];

export const useUsersAvailableDeliverables = ({
	userId,
	options,
}: {
	userId: string;
	options?: QueriesOptions<any>;
}) =>
	useQuery(
		USER_DELIVERABLES_QUERY_KEY(userId),
		() => getUsersDeliverablesForAvailableStaffings({ userId }).then(getDataFromResponse),
		options,
	);
