import Sidebar from "~/components/Sidebar";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import type { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import UpdateClientContactPersonForm from "~/modules/client/components/contact/forms/UpdateClientContactPersonForm";
import LoadingPage from "~/pages/LoadingPage.tsx";

type UpdateContactPersonSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	contactPersonData: ClientContactPersonType;
};

const UpdateContactPersonSidebar: React.FunctionComponent<UpdateContactPersonSidebarProps> = ({
																								  isOpen,
																								  setOpen,
																								  contactPersonData,
																							  }) => {

	const {
		data: allClientsData,
		isLoading: allClientsIsLoading,
	} = useAllClients();

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			{allClientsIsLoading ? <LoadingPage /> :
				<UpdateClientContactPersonForm
					allClientsData={allClientsData || []}
					contactPersonData={contactPersonData}
					onSuccess={() => setOpen(false)}
					onCancel={() => setOpen(false)}
				/>
			}
		</Sidebar>
	);
};

export default UpdateContactPersonSidebar;
