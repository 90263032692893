import CreateWorkingScheduleForm from "components/forms/CreateWorkingScheduleForm";
import type React from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import {
	STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY,
} from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";

type CreateWorkingScheduleSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	staffMemberId: string,
	workingSchedulesMinDate: Date;
};

const CreateWorkingScheduleSidebar: React.FunctionComponent<CreateWorkingScheduleSidebarProps> = ({
	onClose,
	isOpen,
	staffMemberId,
	workingSchedulesMinDate,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId));
			onClose();
		},
		[onClose, queryClient, staffMemberId],
	);


	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<CreateWorkingScheduleForm
				onCancel={onClose}
				onSuccess={handleSuccess}
				staffMemberId={staffMemberId}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>
		</Sidebar>
	);
};

export default CreateWorkingScheduleSidebar;
