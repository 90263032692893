import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllClientContactPersons, getClientContactPerson,
	getClientContactPersonsByClient,
} from "~/modules/client/api/clientContactPerson/clientContactPersonApiDispatchers.ts";

const ENTITY_QUERY_KEY = "clientContactPerson";

export const ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];

export const CLIENT_CONTACT_PERSON_QUERY_KEY = (clientContactPersonId: string) => [ENTITY_QUERY_KEY, clientContactPersonId];

export const useClientContactPerson = (clientContactPersonId: string, options?: QueriesOptions<any>) => useQuery(
	CLIENT_CONTACT_PERSON_QUERY_KEY(clientContactPersonId),
	() => getClientContactPerson(clientContactPersonId).then(getDataFromResponse),
	options,
);

export const useAllClientContactPersons = (options?: QueriesOptions<any>) => useQuery(
	ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY,
	() => getAllClientContactPersons().then(getDataFromResponse),
	options,
);

export const useClientsContactPersons = (clientId: string, options?: QueriesOptions<any>) => useQuery(
	CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY(clientId),
	() => getClientContactPersonsByClient(clientId).then(getDataFromResponse),
	options,
);