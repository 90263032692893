import type React from "react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import {
	deleteStaffMemberCareerLevel,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelApiDispatchers.ts";
import {
	STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelQueries.ts";
import type {
	StaffMemberCareerLevelWithDate,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	careerLevelData: StaffMemberCareerLevelWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteCareerLevelModal: React.FunctionComponent<Props> = ({
	careerLevelData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!careerLevelData) return;
		try {
			setBusy(true);
			await deleteStaffMemberCareerLevel(careerLevelData.staffMemberId, careerLevelData.id);
			await queryClient.refetchQueries(STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY(careerLevelData.staffMemberId));
			await queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(careerLevelData.staffMemberId));
			queryClient.invalidateQueries(ALL_STAFF_MEMBERS_QUERY_KEY);
			queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Eintrag aus Karrierrestufenhistorie löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!careerLevelData && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>AnstellungsArt</Label>
					<span className="text-base">{getCareerLevelDisplayNameById(careerLevelData.careerLevelId as CareerLevelId)}</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base">{formatDateWithGermanMonth(careerLevelData.validFrom)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteCareerLevelModal;
