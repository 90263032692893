import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import Headline from "~/components/Headline";
import ImageUploadAndCropSidebar from "~/components/ImageUploadAndCropSidebar";
import Sidebar from "~/components/Sidebar";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { uploadCompanyLogo } from "~/modules/client/api/company/companyApiDispatchers.ts";
import { All_COMPANIES_QUERY_KEY, COMPANY_QUERY_KEY } from "~/modules/client/api/company/companyQueries.ts";
import { ALL_COMPANY_AVATAR_IMAGES_QUERY_KEY } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import type { OriginalImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

type Props = {
	companyId: string;
	isOpen: boolean;
	onClose: () => void;
	originalImage?: OriginalImage;
};

const CompanyLogoUploadSidebar: FunctionComponent<Props> = ({ isOpen, onClose, companyId, originalImage }) => {
	const queryClient = useQueryClient();
	const [busy, setBusy] = useState<boolean>(false);

	const handleClose = useCallback(() => {
		setBusy(false);
		onClose();
	}, [onClose]);
	const handleSubmit = async (formData: FormData) => {
		setBusy(true);

		try {
			await uploadCompanyLogo(companyId, formData);
			await queryClient.invalidateQueries(All_COMPANIES_QUERY_KEY);
			queryClient.refetchQueries(ALL_COMPANY_AVATAR_IMAGES_QUERY_KEY);
			await queryClient.refetchQueries(COMPANY_QUERY_KEY(companyId));
			handleClose();
		} catch (error) {
			console.error("Upload failed:", error);
			// Handle error (e.g., show error message)
		}
	};

	return (
		<Sidebar open={isOpen}
				 setOpen={handleClose}>
			<SidebarHeader>
				<Headline type="h3">Firmenlogo ändern</Headline>
			</SidebarHeader>
			{busy && <SidebarBusyOverlay showSpinner={true} />}

			{isOpen && <ImageUploadAndCropSidebar onSuccess={handleSubmit}
												  initialImage={originalImage}
												  onClose={handleClose} />}
		</Sidebar>
	);
};


export default CompanyLogoUploadSidebar;