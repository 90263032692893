import {
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	endOfYear,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
	startOfYear,
} from "date-fns";

import { AbsenceCalendarTimespanOption } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getWorkdayInMonthFromDate } from "~/utils/dateAndTimeUtils.ts";

export function getStartAndEndDateFromAbsenceOverviewTimespanOption(timespanOption: AbsenceCalendarTimespanOption,
	startDate: Date) {

	let endDate = new Date();
	switch (timespanOption) {
		case AbsenceCalendarTimespanOption.WEEK:
			startDate = startOfWeek(startDate);
			endDate = endOfWeek(startDate);
			break;
		case AbsenceCalendarTimespanOption.MONTH:
			startDate = startOfMonth(startDate);
			endDate = endOfMonth(startDate);
			break;
		case AbsenceCalendarTimespanOption.QUARTER:
			startDate = startOfQuarter(startDate);
			endDate = endOfQuarter(startDate);
			break;
		case AbsenceCalendarTimespanOption.YEAR:
			startDate = startOfYear(startDate);
			endDate = endOfYear(startDate);
			break;
	}
	return { startDate: getWorkdayInMonthFromDate(startDate), endDate: getWorkdayInMonthFromDate(endDate, true) };
}


