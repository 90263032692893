import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberData,
	StaffMember,
	StaffMemberUserData,
	UpdateSocialSecurityAndPensionInsuranceData,
	UpdateStaffMembersBankAccountData,
	UpdateStaffMembersCompanyLocationData,
	UpdateStaffMembersContactData,
	UpdateStaffMembersEmergencyContactData,
	UpdateStaffMembersHealthInsuranceData,
	UpdateStaffMembersMentorData,
	UpdateStaffMembersPersonalDetailsData,
	UpdateStaffMembersTaxDetailsData,
} from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";

const staffMemberBaseApiUrl = "staff-members";

export function indexStaffMembers(): AxiosApiResponsePromise<StaffMember[]> {
	return axiosApi.get(`${staffMemberBaseApiUrl}`);
}

export function indexStaffMembersEligibleForUserCreation(): AxiosApiResponsePromise<StaffMemberUserData[]> {
	return axiosApi.get(`${staffMemberBaseApiUrl}/missing-user`);
}

export function createStaffMember(data: CreateStaffMemberData): AxiosApiResponsePromise<void> {
	return axiosApi.post(`${staffMemberBaseApiUrl}`, data);
}

export function showStaffMember(staffMemberId: string): AxiosApiResponsePromise<StaffMember> {
	return axiosApi.get(`${staffMemberBaseApiUrl}/${staffMemberId}`);
}

export function updateStaffMembersBankAccount(staffMemberId: string,
	data: UpdateStaffMembersBankAccountData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/bank-account`, data);
}

export function updateStaffMembersCompanyLocation(staffMemberId: string,
	data: UpdateStaffMembersCompanyLocationData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/company-location`, data);
}

export function updateStaffMembersEmergencyContact(staffMemberId: string,
	data: UpdateStaffMembersEmergencyContactData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/emergency-contact`, data);
}

export function updateStaffMembersContact(staffMemberId: string,
	data: UpdateStaffMembersContactData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/contact`, data);
}

export function updateStaffMembersHealthInsurance(staffMemberId: string,
	data: UpdateStaffMembersHealthInsuranceData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/health-insurance`, data);
}

export function indexStaffMembersMentees(staffMemberId: string): AxiosApiResponsePromise<string[]> {
	return axiosApi.get(`${staffMemberBaseApiUrl}/${staffMemberId}/mentees`);
}

export function updateStaffMembersMentor(staffMemberId: string,
	data: UpdateStaffMembersMentorData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/mentor`, data);
}

export function updateStaffMembersPersonalDetails(staffMemberId: string,
	data: UpdateStaffMembersPersonalDetailsData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/personal-details`, data);
}

export function updateStaffMembersSocialSecurityAndPensionInsuranceDetails(staffMemberId: string,
	data: UpdateSocialSecurityAndPensionInsuranceData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/social-and-pension-insurance`, data);
}

export function updateStaffMembersTaxDetails(staffMemberId: string,
	data: UpdateStaffMembersTaxDetailsData): AxiosApiResponsePromise<void> {
	return axiosApi.patch(`${staffMemberBaseApiUrl}/${staffMemberId}/tax-details`, data);
}

export function uploadAvatarImage(staffMemberId: string, formData: FormData): AxiosApiResponsePromise<void> {
	return axiosApi.post(`${staffMemberBaseApiUrl}/${staffMemberId}/avatar`, formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
}

export function deleteAvatarImage(staffMemberId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${staffMemberBaseApiUrl}/${staffMemberId}/avatar`);
}