import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import CompanyLogo from "~/components/CompanyLogo";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import type { Location, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { PROJECT_BASE_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import type {
	SelectedStaffingDataType,
	UsersActiveStaffingType,
} from "~/modules/project/api/staffing/staffingTypes.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import {
	createTimeTracking,
	updateTimeTracking,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingApiDispatchers.ts";
import {
	PROJECTS_TIME_TRACKINGS_QUERY_KEY,
	TIME_TRACKINGS_BY_USER_QUERY_KEY,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import type { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import TimeTrackingFormSection from "~/modules/timeTracking/components/forms/formSections/TimeTrackingFormSection";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getStartAndEndOfMonthFormatted } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD, formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateTimeTrackingFormProps = {
	activeStaffings: UsersActiveStaffingType[];
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	date: Date | null,
	initialValues: TimeTrackingFormData;
	timeTrackingId?: string;
	locations: Location[];
	parentIsVisible: boolean;
	onSuccess: () => void;
	setCurrentTimeTrackingFormValues: (values: TimeTrackingFormData) => void;
	selectedStaffingData: SelectedStaffingDataType | null;
	selectedStaffingId: string | null;
	selectedTimeTrackingTypeId: string;
	selectedTimeTrackingTypeName: string;
	selectedUserId: User["id"];
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum | null) => void;
	users: User[];
};

const minutesMinMaxErrorMessage = "Bitte einen Wert zwischen 0 und 59 eingeben";
const hoursMinMaxErrorMessage = "Bitte einen Wert zwischen 0 und 24 eingeben";


const CreateOrUpdateTimeTrackingForm: React.FunctionComponent<CreateTimeTrackingFormProps> = ({
	availableDeliverables,
	availableLocations,
	date,
	initialValues,
	timeTrackingId,
	locations,
	onSuccess,
	parentIsVisible,
	setCurrentTimeTrackingFormValues,
	selectedStaffingData,
	selectedStaffingId,
	selectedTimeTrackingTypeId,
	selectedTimeTrackingTypeName,
	selectedUserId,
	setCurrentFormName,
	users,
}) => {

	const queryClient = useQueryClient();
	const [isBusy, setIsBusy] = useState(false);

	const isUpdateForm = timeTrackingId !== undefined;
	const defaultValues = useMemo(() => {
		return initialValues;
	}, [initialValues]);


	const schema = useMemo(() => {
		const textSchema = [TimeTrackingTypeId.Project].includes(selectedTimeTrackingTypeId as TimeTrackingTypeId) ? yup.string().required() : yup.string().nullable().default(null);
		const locationSchema = selectedTimeTrackingTypeId !== TimeTrackingTypeId.PartTime ? yup.string().required() : yup.string().nullable().default(null);
		return yup.object({
			hours: yup
				.number()
				.min(0, hoursMinMaxErrorMessage)
				.max(24, hoursMinMaxErrorMessage)
				.required()
				.test("minTime", "Bitte gib einen Wert ein.", function(value) {
					return !(value === 0 && this.parent.minutes === 0);
				}),
			minutes: yup.number().min(0, minutesMinMaxErrorMessage).max(59, minutesMinMaxErrorMessage).required(),
			locationName: locationSchema,
			text: textSchema,
		});
	}, [selectedTimeTrackingTypeId]);

	const { data: allCompanyAvatarImages } = useAllCompanyAvatarImages();
	const avatarImage =TimeTrackingTypeId.Project && selectedStaffingData && allCompanyAvatarImages?.find((image) => image.clientIds.includes(selectedStaffingData?.clientId));

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors, isValid, isSubmitted },
		trigger,
		reset,
	} = useForm<TimeTrackingFormData>({
		defaultValues,
		mode: "onChange",
		resolver: yupResolver<TimeTrackingFormData>(schema),
	});

	useEffect(() => {
		if (!parentIsVisible) {
			reset(createTimeTrackingFormDefaultValues);
		}
	}, [parentIsVisible, reset]);


	useEffect(() => {
		reset(initialValues);
	}, [initialValues, parentIsVisible, reset]);

	const onSubmit: SubmitHandler<TimeTrackingFormData> = async (data) => {
		setIsBusy(true);
		const timeTrackingDate = formatDateToYYYYMMDD(date!);
		const timeTrackingBaseData = {
			locationName: data.locationName || null,
			staffingId: selectedStaffingId,
			timeTrackingTypeId: selectedTimeTrackingTypeId!,
			minutes: data.hours * 60 + data.minutes,
			userId: selectedUserId,
			text: data.text || null,
		};

		if (selectedTimeTrackingTypeId === TimeTrackingTypeId.PartTime) {
			timeTrackingBaseData.locationName = null;
			timeTrackingBaseData.text = null;
		}

		if (selectedTimeTrackingTypeId !== TimeTrackingTypeId.Project) {
			timeTrackingBaseData.staffingId = null;
		}

		try {

			if (!isUpdateForm) {
				const createTimeTrackingData = {
					...timeTrackingBaseData,
					date: timeTrackingDate,
				};

				await createTimeTracking(selectedUserId, createTimeTrackingData);
			} else {
				await updateTimeTracking({ userId: selectedUserId, timeTrackingId, data: timeTrackingBaseData });
			}


			const timeTrackingFilterData = getStartAndEndOfMonthFormatted(new Date(timeTrackingDate));
			await queryClient.invalidateQueries(TIME_TRACKINGS_BY_USER_QUERY_KEY(selectedUserId, timeTrackingFilterData));
			queryClient.invalidateQueries([PROJECT_BASE_QUERY_KEY]);
			if (selectedStaffingData) {
				queryClient.invalidateQueries(PROJECTS_TIME_TRACKINGS_QUERY_KEY(selectedStaffingData.projectId));
			}
			onSuccess();
			reset();
		} catch (error) {
			console.log(error);
		}

		setIsBusy(false);
	};

	const handleFormSwitch = useCallback((newFormId: TimeTrackingFormNamesEnum | null) => {
		setCurrentTimeTrackingFormValues(getValues());
		setCurrentFormName(newFormId);
	}, [getValues, setCurrentFormName, setCurrentTimeTrackingFormValues]);

	const dayName = date ? date.toLocaleDateString("de-DE", { weekday: "short" }) : "";
	const formattedDate = date ? `${dayName}, ${formatDateWithGermanMonth(date, false)}` : "";

	return <>
		<SidebarHeader>
			<SidebarHeaderHeadline>
				{selectedTimeTrackingTypeId !== TimeTrackingTypeId.Project ?
					<span>Typ: {selectedTimeTrackingTypeName}</span> :
					<span className="flex items-center gap-x-2">{avatarImage && <CompanyLogo image={avatarImage} width="w-8" height="h-8"/> } {selectedStaffingData?.phaseTitle}</span>}
			</SidebarHeaderHeadline>
		</SidebarHeader>
		<div className="mb-2 flex w-full justify-between px-7 pt-2 text-xs font-bold text-gray-500">
			<span>{selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ? selectedStaffingData?.orderTitle : ""}</span>
			<span>{formattedDate}</span>
		</div>
		<SidebarContent>
			<form id={TimeTrackingFormNamesEnum.TIME_TRACKING_FORM}
				  onSubmit={handleSubmit(onSubmit)}
				  onKeyDown={preventSubmitOnEnter}
				  className="flex min-h-full w-full flex-col justify-start px-6"
			>
				<TimeTrackingFormSection
					availableDeliverables={availableDeliverables}
					availableLocations={availableLocations}
					control={control}
					errors={errors}
					isEditForm={isUpdateForm}
					isSubmitted={isSubmitted}
					locations={locations}
					selectedStaffingData={selectedStaffingData}
					selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
					setCurrentFormName={handleFormSwitch}
					setValue={setValue}
					selectedUserId={selectedUserId}
					trigger={trigger}
					users={users}
					watch={watch} />
			</form>
		</SidebarContent>
		<SidebarFooter>
			{(!isUpdateForm || selectedTimeTrackingTypeId === TimeTrackingTypeId.Project) &&
				<Button className="mr-auto"
						theme="none"
						onClick={() => handleFormSwitch(null)}>
					<ChevronLeftIcon className="size-6" />{isUpdateForm ? "Projektauswahl" : "Zurück"}

				</Button>}
			<SubmitButton formName={TimeTrackingFormNamesEnum.TIME_TRACKING_FORM}
						  busy={isBusy}
						  disabled={!isValid}>
				{!isUpdateForm ? t(normalizeTKey("form:buttonTexts.save")) : t(normalizeTKey("form:buttonTexts.update"))}
			</SubmitButton>
			<Button theme="none"
					onClick={onSuccess}>
				{t(normalizeTKey("form:buttonTexts.cancel"))}
			</Button>
		</SidebarFooter>
	</>;
};

export default CreateOrUpdateTimeTrackingForm;