import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Button from "components/buttons/Button";
import type React from "react";
import { useCallback, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton/SubmitButton";
import Headline from "~/components/Headline/Headline";
import Logo from "~/components/Logo/Logo";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import { appRoutes } from "~/constants/appRoute.ts";
import { requestPasswordRequest } from "~/modules/user/api/passwordReset/passwordResetApiDispatchers.ts";

type FormData = {
	email: string;
};

// @ToDo Validate email format via rehex, handle backend validation error
const RequestPasswordReset: React.FunctionComponent = () => {
	const [busy, setBusy] = useState(false);
	const [submitFailed, setSubmitFailed] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = useCallback(async (data) => {
		setBusy(true);

		try {
			await requestPasswordRequest(data.email);
			setSubmitSuccess(true);
		} catch (error) {
			console.log(error);
			setSubmitFailed(true);
		}

		setBusy(false);
	}, []);

	return (
		<div className="flex w-full max-w-md flex-col gap-4">
			<div className="ml-8 w-44 text-primary-500">
				<Logo />
			</div>
			<div className="relative rounded-md bg-white p-8 shadow-md">
				{busy && <SidebarBusyOverlay />}
				<div className="mb-4">
					<Headline
						type="h4"
						className="mb-4 text-primary-500">
						Passwort zurücksetzen
					</Headline>

				</div>
				{!submitSuccess && !submitFailed && (
					<>
						<p className="leading-6 text-gray-900">Bitte gib die mit Deinem Account verknüpfte E-Mail
							Adresse ein. Du erhälst dann einen
							Link, mit dem Du Dein Passwort ändern kannst.</p>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="mt-4 space-y-6">
							<div className="flex flex-col gap-4">
								<Input
									name="email"
									control={control}
									label="E-Mail"
									error={errors.email && "Bitte gib eine gültige E-Mail Adresse ein."}
								/>

								<div className="flex items-center justify-between">
									<SubmitButton busy={busy}>Link senden</SubmitButton>
								</div>
							</div>
						</form>
					</>
				)}
				{submitSuccess && (
					<>
						<div className="mb-2 flex flex-row items-center gap-x-2">
							<CheckCircleIcon className="h-20 fill-success-500" /><span>Die E-Mail wurde erfolgreich versendet. Bitte checke ggf. auch Deinen Spam-Ordner.</span>
						</div>
						<Button theme="none"
								to={appRoutes.login().path}>Zurück zum Login</Button>
					</>
				)}
				{submitFailed &&
					<div className="flex flex-row items-center gap-x-2">
						<XCircleIcon className="h-10 fill-danger-500" />
						<span>Das hat leider nicht geklappt...</span>
					</div>
				}
			</div>
		</div>
	);
};

export default RequestPasswordReset;
