import type { FunctionComponent } from "react";
import { useState } from "react";

import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import {
	useStaffMembersCompensationReportNotes,
} from "~/modules/humanResources/api/staffMemberCompensationReportNote/staffMemberCompensationReportNoteQueries.ts";
import UpdateCompensationReportNotesSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationReportNotes/components/UpdateCompensationReportNotesSidebar";
import { nl2br } from "~/utils/stringUtils.tsx";

type Props = {
	staffMemberId: string;
};

const CompensationReportNotes: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState<boolean>(false);
	const {
		data: compensationReportNotesData,
		isLoading: compensationReportNotesIsLoading,
	} = useStaffMembersCompensationReportNotes({ staffMemberId });

	const notes = compensationReportNotesData?.note ? nl2br(compensationReportNotesData?.note) : EMPTY_VALUE_PLACEHOLDER;

	return <><Card title="Bemerkungen Gehaltsreport"
				   headerButtonText="Bearbeiten"
				   onHeaderButtonClick={() => setShowUpdateSidebar(true)}
	>
		{compensationReportNotesIsLoading &&
			<div className="flex h-12 w-full items-center justify-center"><LoadingSpinnerFull theme="neutral" /></div>}
		{!compensationReportNotesIsLoading && notes}
	</Card>
		<UpdateCompensationReportNotesSidebar staffMemberId={staffMemberId}
											  key={showUpdateSidebar ? "open" : "closed"}
											  isOpen={showUpdateSidebar}
											  onClose={() => setShowUpdateSidebar(false)}
											  notes={compensationReportNotesData?.note || ""} />
	</>;
};

export default CompensationReportNotes;