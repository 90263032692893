import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateProjectData,
	Project, ProjectDescription,
	ProjectSummary,
	UpdateProjectData,
} from "~/modules/project/api/project/projectTypes.ts";


export function getProject(projectId: string): AxiosApiResponsePromise<Project> {
	return axiosApi.get(`projects/${projectId}`);
}

export function getAllProjects(): AxiosApiResponsePromise<ProjectSummary[]> {
	return axiosApi.get("projects");
}

export function getClientsProjects({ clientId }: { clientId: string }): AxiosApiResponsePromise<Project[]> {
	return axiosApi.get(`clients/${clientId}/projects`);
}

export function createProject(projectData: CreateProjectData): AxiosApiResponsePromise<Project> {
	return axiosApi.post("projects", { ...projectData });
}

export function updateProject({ projectId, projectData }: {
	projectId: string,
	projectData: UpdateProjectData
}): AxiosApiResponsePromise<Project> {
	return axiosApi.put(`projects/${projectId}`, { ...projectData });
}

export function deleteProject({ projectId }: {
	projectId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`projects/${projectId}`);
}

export function updateProjectDescription({ projectId, description }: {
	projectId: string,
	description: string
}): AxiosApiResponsePromise<Project> {
	return axiosApi.patch(`projects/${projectId}/description`, { description });
}

export function updateProjectsDeliverableTags({ projectId, tags }: {
	projectId: string,
	tags: string[]
}): AxiosApiResponsePromise<Project> {
	return axiosApi.patch(`projects/${projectId}/deliverable-tags`, { tags });
}

export function updateProjectsProductTags({ projectId, tagIds }: {
	projectId: string,
	tagIds: string[]
}): AxiosApiResponsePromise<Project> {
	return axiosApi.patch(`projects/${projectId}/product-tags`, { tagIds });
}

export function indexProjectDescriptions(): AxiosApiResponsePromise<ProjectDescription[]> {
	return axiosApi.get("projects/descriptions");
}


