import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberEmploymentData,
	StaffMemberEmployment,
	UpdateStaffMemberEmploymentData,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";

const employmentBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/employments`;

export function indexStaffMembersEmployments(staffMemberId: string): AxiosApiResponsePromise<StaffMemberEmployment[]> {
	return axiosApi.get(employmentBasePath(staffMemberId));
}

export function createStaffMemberEmployment(staffMemberId: string,
	employmentData: CreateStaffMemberEmploymentData): AxiosApiResponsePromise<void> {
	return axiosApi.post(employmentBasePath(staffMemberId), employmentData);
}

export function updateStaffMemberEmployment({ staffMemberId, employmentId, updateData }: {
	staffMemberId: string;
	employmentId: string;
	updateData: UpdateStaffMemberEmploymentData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${employmentBasePath(staffMemberId)}/${employmentId}`, updateData);
}

export function deleteStaffMemberEmployment(staffMemberId: string, employmentId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${employmentBasePath(staffMemberId)}/${employmentId}`);
}