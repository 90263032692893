import { t } from "i18next";
import { useMemo } from "react";

import { useAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeQueries.ts";
import type { EmploymentType } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


export const publiclyAvailableEmploymentTypesFn = (employmentType: EmploymentType) => [EmploymentTypeId.Freelancer, EmploymentTypeId.Intern, EmploymentTypeId.Student].includes(employmentType.id as EmploymentTypeId);

export const internalEmploymentTypesFn = (employmentType: EmploymentType) => ![EmploymentTypeId.Freelancer, EmploymentTypeId.Left].includes(employmentType.id as EmploymentTypeId);

export const internalEmploymentTypesIncludingLeftFn = (employmentType: EmploymentType) => employmentType.id !== EmploymentTypeId.Freelancer;

export const freelanceEmploymentTypeOnlyFn = (employmentType: EmploymentType) => employmentType.id === EmploymentTypeId.Freelancer;

const useEmploymentTypeSelectOptions = (filterFunction: (employmentType: EmploymentType) => boolean): FormInputOption[] => {
	const { data: employmentTypesData } = useAllEmploymentTypes();
	return useMemo(() => {
		if (employmentTypesData) {
			const filteredEmploymentTypes = employmentTypesData.filter(employmentType => filterFunction!(employmentType));
			return filteredEmploymentTypes.sort(byObjectProperty("listPosition")).map((employmentType) => {
				const label = t(`entities:employmentType.${employmentType.name}` as unknown as TemplateStringsArray);
				return createInputOptionData(label, employmentType.id);
			});

		}
		return [];

	}, [employmentTypesData, filterFunction]);
};

export default useEmploymentTypeSelectOptions;

