import clsx from "clsx";
import type { FunctionComponent } from "react";
import type { PropsWithChildren } from "react";

type TableWrapperProps = PropsWithChildren & {
	className?: string;
	rounded?: boolean
}

const TableWrapper: FunctionComponent<TableWrapperProps> = ({
	className,
	children,
	rounded,
}) => {

	if (rounded) {
		return <div className="inline-flex max-h-full w-full rounded-lg bg-white py-3 shadow">
			<div className="block max-h-full w-full overflow-y-auto">
				<table className={clsx("relative size-full max-w-full bg-white", className)}>{children}</table>
			</div>
		</div>;
	}

	return <div className="block max-h-full overflow-y-auto">
		<table className={clsx("relative size-full max-w-full overflow-y-auto bg-white", className)}>{children}</table>
	</div>;
};

export default TableWrapper;