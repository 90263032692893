import clsx from "clsx";
import type { FunctionComponent } from "react";

import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	label: string;
	minutes: number;
	showDot?: boolean,
	textSize?: "xs" | "sm",
	theme: "project" | "sales" | "internal" | "partTime" | "sum";
};

const TimeTrackingTotal: FunctionComponent<Props> = ({
	label,
	minutes,
	showDot=true,
	textSize = "xs",
	theme,
}) => {

	return <div className={clsx("flex items-center font-extrabold uppercase",
		textSize === "xs" ? "text-xs" : "text-sm font-extrabold",
		theme === "project" && "text-primary-400",
		theme === "sum" && "text-primary-500",
		theme === "sales" && "text-gray-600",
		theme === "internal" && "text-gray-500",
		theme === "partTime" && "text-success-dark-600",
	)}>
		<span>{label}: {formatNumberWithComma(minutesToWorkdays(minutes, 1), 1)} PT </span>
		{showDot &&
			<span className="flex h-full items-center justify-center"><span className="mx-1.5 size-1 rounded-full bg-primary-400 " /></span>}
	</div>;
};

export default TimeTrackingTotal;