import clsx from "clsx";
import type { FunctionComponent } from "react";
import { generatePath, NavLink } from "react-router-dom";

import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";

type Props = { projectData: Project, clientDisplayName: string };

const TaggedProjectListItem: FunctionComponent<Props> = ({ clientDisplayName, projectData }) => {
	return <NavLink to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId: projectData.id })}
					className="group mb-1 flex flex-col border border-gray-200 px-4 py-3">
			<div className={clsx("text-[1.375rem] font-bold text-primary-500",
				"max-w-full truncate transition-colors duration-200 group-hover:text-secondary-500",
			)}
			>
				{projectData.title}
			</div>
			<div className="text-sm text-primary-500">
				{clientDisplayName} | {projectData.projectNumber}
			</div>
	</NavLink>;
};

export default TaggedProjectListItem;