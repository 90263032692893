import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersBenefits,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitApiDispatchers.ts";

export const STAFF_MEMBER_BENEFIT_BASE_QUERY_KEY = "staffMembersBenefit";

export const STAFF_MEMBERS_BENEFITS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BENEFIT_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersBenefits = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_BENEFITS_QUERY_KEY(staffMemberId),
	() => indexStaffMembersBenefits(staffMemberId).then(getDataFromResponse),
	options,
);