import type Decimal from "decimal.js-light";
import type React from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY,
	STAFF_MEMBERS_ABSENCES_QUERY_KEY,
} from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import CreateAbsenceForm from "~/modules/absence/components/AbsencesView/components/CreateAbsenceForm";
import useAbsenceTypeOptions from "~/modules/absence/hooks/useAbsenceTypeOptions.tsx";

type CreateAbsenceSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	remainingVacationDays: Decimal;
	staffMemberId: string,
	userCanManageLongTermAbsences: boolean;
	workingSchedules: WorkingSchedule[];
};

const CreateAbsenceSidebar: React.FunctionComponent<CreateAbsenceSidebarProps> = ({
	onClose,
	isOpen,
	remainingVacationDays,
	staffMemberId,
	userCanManageLongTermAbsences,
	workingSchedules,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			queryClient.removeQueries(ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY);
			queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId));

			await queryClient.invalidateQueries(STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId));
			await queryClient.invalidateQueries(STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId));
			onClose();
		},
		[onClose, queryClient, staffMemberId],
	);

	const absenceTypeOptions = useAbsenceTypeOptions();

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<CreateAbsenceForm
				absenceTypeSelectOptions={absenceTypeOptions}
				onSuccess={handleSuccess}
				remainingVacationDays={remainingVacationDays}
				onCancel={onClose}
				workingSchedules={workingSchedules}
				staffMemberId={staffMemberId}
				userCanManageLongTermAbsences={userCanManageLongTermAbsences}
			/>
		</Sidebar>
	);
};

export default CreateAbsenceSidebar;
