import StaffMonthlyReportView from "modules/humanResources/components/StaffMonthlyReportView";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import { useMonthlyReport } from "~/modules/humanResources/api/staffMonthlyReport/staffMonthlyReportQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const StaffMonthlyReportPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanManagesStaffSalaries);
	if (guard) return guard;
	const [searchParams, setSearchParams] = useSearchParams();
	const year = searchParams.get("year");
	const month = searchParams.get("month");
	const currentYear = new Date().getFullYear().toString();
	const currentMonth = (new Date().getMonth() + 1).toString();

	const startOfMonthString = `${year}-${month?.padStart(2, "0")}-01`;
	const isValidDate = !!year && !!month && (
		parseInt(year) < parseInt(currentYear) ||
		(parseInt(year) === parseInt(currentYear) && parseInt(month) <= parseInt(currentMonth))
	);

	useEffect(() => {
		if (!isValidDate) {
			setSearchParams({ year: currentYear, month: currentMonth });
		}
	}, [isValidDate, currentYear, currentMonth, setSearchParams]);

	const {
		data: monthlyReportData,
		isLoading: monthlyReportIsLoading,
	} = useMonthlyReport({ startOfMonth: startOfMonthString, options: { enabled: isValidDate } });

	const {
		data: staffMembersData,
		isLoading: staffMembersIsLoading,
	} = useAllStaffMembers({ enabled: isValidDate });

	if (!isValidDate) {
		return null;
	}
	return <StaffMonthlyReportView isLoading={monthlyReportIsLoading || staffMembersIsLoading}
								   monthlyReportData={monthlyReportData}
								   selectedMonthString={startOfMonthString}
								   staffMembersData={staffMembersData}

	/>;
};

export default StaffMonthlyReportPage;