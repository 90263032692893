import BreadcrumbsSection from "components/Breadcrumbs";
import Button from "components/buttons/Button";
import { isAfter } from "date-fns";
import Decimal from "decimal.js-light";
import AbsencesTable from "modules/absence/components/AbsencesView/components/AbsencesTable";
import CreateAbsenceSidebar from "modules/absence/components/AbsencesView/components/CreateAbsenceSidebar";
import DeleteAbsenceModal from "modules/absence/components/AbsencesView/components/DeleteAbsenceModal";
import UpdateAbsenceSidebar from "modules/absence/components/AbsencesView/components/UpdateAbsenceSidebar";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentWrapper from "~/components/ContentWrapper";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import MainContent from "~/components/mainContent/MainContent";
import { useAuth } from "~/contexts/AuthContext";
import type { Absence, AbsenceIcsData } from "~/modules/absence/api/absence/absenceTypes.ts";
import { AbsenceTypeId, AbsenceTypeName } from "~/modules/absence/api/absence/absenceTypes.ts";
import type { AbsenceType } from "~/modules/absence/api/absenceType/absenceTypeTypes.ts";
import { LONG_TERM_ABSENCE_TYPE_IDS } from "~/modules/absence/api/config/absenceConfig.ts";
import type { VacationLedgerReportType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsenceViewStaffMemberSelect from "~/modules/absence/components/AbsencePageUserSelect";
import AbsencesTableRow
	from "~/modules/absence/components/AbsencesView/components/AbsencesTable/components/AbsencesTableRow";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import { AbsenceTabName } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { createIcsContent } from "~/modules/absence/utils/absencesViewUtils.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateRange, formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type AbsencesViewProps = {
	absences: Absence[];
	absenceTypes: AbsenceType[];
	staffMemberId: string,
	vacationLedgerReport: VacationLedgerReportType;
	workingSchedules: WorkingSchedule[];
};

const editableAbsenceTypes = [AbsenceTypeName.Training, AbsenceTypeName.InternalEvent, AbsenceTypeName.Special];

const AbsencesView: FunctionComponent<AbsencesViewProps> = ({
	absences,
	absenceTypes,
	staffMemberId,
	vacationLedgerReport,
	workingSchedules,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();
	const [showCreateAbsenceForm, setShowCreateAbsenceForm] = useState(false);
	const [absenceIdToUpdate, setAbsenceIdToUpdate] = useState<string | null>(null);
	const [absenceIdToDelete, setAbsenceIdToDelete] = useState<string | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));

	const { data: allUsers } = useAllUsers();

	const onDownloadIcsClick = useCallback((absence: Absence) => {
		const absenceTypeDisplayName = absenceTypes.find((absenceType) => absenceType.id === absence.absenceTypeId)?.name || "";
		const user = allUsers?.find((user) => user.id === absence.staffMemberId);
		if (!user) return;
		const icsData: AbsenceIcsData = {
			absenceTypeName: absenceTypeDisplayName,
			comment: absence.comment,
			endDate: new Date(absence.endDate),
			firstDayIsHalf: absence.firstDayIsHalf,
			lastDayIsHalf: absence.lastDayIsHalf,
			startDate: new Date(absence.startDate),
			userEmail: user.email,
			userFullName: user.fullName,
		};

		const icsContent = createIcsContent(icsData);
		const blob = new Blob([icsContent], { type: "text/calendar;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${absence.comment}.ics`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	}, [absenceTypes, allUsers]);

	const absenceTableRows = useMemo(() => {
		if (absences.length > 0) {
			return absences.sort(byObjectProperty("startDate", "desc")).map((absence) => {
				const isVacation = absence.absenceTypeId == AbsenceTypeId.Vacation;

				let absenceTypeDisplayName = "--";
				const absenceType = absenceTypes
					.find((absenceType) => absenceType.id === absence.absenceTypeId);
				if (absenceType) {
					absenceTypeDisplayName = t(normalizeTKey(`entities:absenceType.${absenceType.name}`));
				}

				const timeSpan = formatDateRange(new Date(absence.startDate), new Date(absence.endDate)) || "--";
				let vacationDays = null;

				if (isVacation) {
					let vacationDaysDecimal = new Decimal(absence.vacationLedgerEntry?.workDays || absence.workDays);
					if (vacationDaysDecimal.isNegative()) {
						vacationDaysDecimal = vacationDaysDecimal.negated();
					}
					vacationDays = vacationDaysDecimal.toString().replace(".", ",");
				}


				const isDeletable = userCanAdminVacations || isAfter(new Date(absence.startDate), new Date());

				let isEditable = userCanAdminVacations || isDeletable || editableAbsenceTypes.includes(absenceType?.name as AbsenceTypeName);

				isEditable = LONG_TERM_ABSENCE_TYPE_IDS.includes(absence.absenceTypeId as AbsenceTypeId) ? userCanAdminVacations : isEditable;

				return <AbsencesTableRow key={"absence-" + absence.id}
										 absenceType={absenceTypeDisplayName}
										 comment={absence.comment}
										 createdAt={formatDateWithGermanMonth(new Date(absence.createdAt))}
										 timespan={timeSpan}
										 vacationDays={vacationDays}
										 isDeletable={isDeletable}
										 isEditable={isEditable}
										 onDownloadIcsClick={() => onDownloadIcsClick(absence)}
										 onEditClick={() => setAbsenceIdToUpdate(absence.id)}
										 onDeleteClick={() => setAbsenceIdToDelete(absence.id)}
				/>;
			});
		}
		return [];
	}, [absences, absenceTypes, userCanAdminVacations, t, onDownloadIcsClick]);

	const absenceDataToUpdate = useMemo(() => {
		if (absenceIdToUpdate) {
			return absences.find(absence => absence.id === absenceIdToUpdate);

		}
		return null;
	}, [absenceIdToUpdate, absences]);

	const absenceDataToDelete = useMemo(() => {
		if (absenceIdToDelete && absenceTypes) {
			const absence = absences.find(absence => absence.id === absenceIdToDelete);
			if (absence) {
				const typeName = absenceTypes.find(absenceType => absenceType.id === absence.absenceTypeId)?.name || "";
				return {
					...absence,
					typeName,
				};
			}

		}
		return null;
	}, [absenceIdToDelete, absences, absenceTypes]);

	return (
		<MainContent>
			<BreadcrumbsSection pages={["Abwesenheiten"]}
								className="bg-white" />
			<PageHeading title={t("timetracking.title", "Abwesenheiten")} />
			<PageHeading.BottomBar>
				<div
					className="z-40 flex w-full items-center gap-2 gap-x-5 text-sm font-medium text-gray-700 hover:text-gray-900">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					<AbsenceViewStaffMemberSelect userIsVacationManager={userCanAdminVacations}
												  staffMemberId={staffMemberId}
												  currentSubPath={AbsenceTabName.absences} />
				</div>
				<div className="w-5" />
				<Button onClick={() => setShowCreateAbsenceForm(true)}>
					{t("projects.btnNewProject", "Neue Abwesenheit")}
				</Button>
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceTabName.absences} />
				</SectionHeading>
				<div>
					<AbsencesTable>
						{absenceTableRows}
					</AbsencesTable>
				</div>
			</ContentWrapper>
			<CreateAbsenceSidebar
				isOpen={showCreateAbsenceForm}
				onClose={() => setShowCreateAbsenceForm(false)}
				remainingVacationDays={remainingVacationDays}
				workingSchedules={workingSchedules}
				staffMemberId={staffMemberId}
				userCanManageLongTermAbsences={userCanAdminVacations}
			/>
			<UpdateAbsenceSidebar
				isOpen={!!absenceDataToUpdate}
				absenceData={absenceDataToUpdate}
				onClose={() => setAbsenceIdToUpdate(null)}
				remainingVacationDays={remainingVacationDays}
				staffMemberId={staffMemberId}
				userCanManageLongTermAbsences={userCanAdminVacations}
				workingSchedules={workingSchedules} />
			<DeleteAbsenceModal absenceData={absenceDataToDelete}
								isOpen={!!absenceDataToDelete}
								onCloseClick={() => setAbsenceIdToDelete(null)} />
		</MainContent>
	);
};

export default AbsencesView;