import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_PROJECTS_QUERY_KEY, PROJECT_QUERY_KEY, useProject } from "~/modules/project/api/project/projectQueries.ts";
import UpdateProjectForm from "~/modules/project/components/forms/UpdateProjectForm";
import { TIME_TRACKING_BASE_QUERY_KEY } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";

type AddProjectPhaseToProjectSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	projectId: string;
};

const UpdateProjectSidebar: FunctionComponent<AddProjectPhaseToProjectSidebarProps> = ({
	isOpen,
	setOpen,
	projectId,
}) => {
	const { data: projectData } = useProject({ projectId });

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries(PROJECT_QUERY_KEY(projectId));
		queryClient.invalidateQueries(TIME_TRACKING_BASE_QUERY_KEY);
		queryClient.invalidateQueries(ALL_PROJECTS_QUERY_KEY);
		setOpen(false);
	}, [projectId, queryClient, setOpen]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			{projectData && isOpen && (
				<UpdateProjectForm
					projectData={projectData}
					onSuccess={handleSuccess}
					onCancel={() => setOpen(false)}
				/>
			)}
		</Sidebar>
	);
};

export default UpdateProjectSidebar;
