import type { FunctionComponent } from "react";
import { useState } from "react";

import Card from "~/components/Card";
import { getHeadlessEditorInstance, getHtmlFromEditorState } from "~/components/TextEditor/richTextEditorUtils.ts";
import UpdateProjectDescriptionSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectDescriptionSection/components/UpdateProjectDescriptionSidebar";

type Props = {
	description: string | null;
	projectId: string;
};

const ProjectDescriptionSection: FunctionComponent<Props> = ({ description, projectId }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState<boolean>(false);
	const html = getHtmlFromEditorState({ editor: getHeadlessEditorInstance(), editorState: description || undefined });

	return <Card title="Referenztext"
				 headerButtonText="Bearbeiten"
				 onHeaderButtonClick={() => setShowUpdateSidebar(true)}>
		{description ? <div className="w-full text-sm"
							tabIndex={0}
							dangerouslySetInnerHTML={{ __html: html }} /> :
			<span className="text-sm text-gray-500">--</span>}
		<UpdateProjectDescriptionSidebar descriptionText={description}
										 isOpen={showUpdateSidebar}
										 onClose={() => setShowUpdateSidebar(false)}
										 projectId={projectId} />
	</Card>;
};

export default ProjectDescriptionSection;