import { ArrowDownTrayIcon, PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type React from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";

type AbsenceTableRowProps = {
	absenceType: string;
	createdAt: string,
	isDeletable: boolean;
	isEditable: boolean;
	comment: string | null;
	timespan: string;
	vacationDays: string | null;
	onDownloadIcsClick: () => void;
	onEditClick: () => void;
	onDeleteClick: () => void;
};

const AbsencesTableRow: React.FunctionComponent<AbsenceTableRowProps> = ({
	absenceType,
	createdAt,
	comment,
	timespan,
	vacationDays,
	isDeletable,
	isEditable,
	onDownloadIcsClick,
	onEditClick,
	onDeleteClick,
}) => {


	const popoverItems = [];

	if (isEditable) {
		popoverItems.push({
			label: "Bearbeiten",
			onClick: onEditClick,
			icon: PencilIcon,
		});
	}

	if (isDeletable) {
		popoverItems.push({
			label: "Löschen",
			onClick: onDeleteClick,
			icon: TrashIcon,
		});
	}

	popoverItems.push({
		label: "Download .ics",
		onClick: onDownloadIcsClick,
		icon: ArrowDownTrayIcon,
	});

	return (
		<tr className="bg-white hover:bg-gray-200">
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				{absenceType}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{comment || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{timespan}</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{vacationDays || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{createdAt}
			</td>
			<td className="relative ml-auto  items-center whitespace-nowrap pr-3">
				<div className="flex justify-end">
					<ButtonWithPopover
						theme="dark"
						items={popoverItems}
					/>
				</div>
			</td>
		</tr>
	);
};

export default AbsencesTableRow;