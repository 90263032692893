export interface TimeTrackingType {
	id: string;
	name: string;
}

export enum TimeTrackingTypeId {
	Project = "1",
	Internal = "2",
	Sales = "3",
	PartTime = "4",
}
