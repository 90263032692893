import type { QueriesOptions} from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllApprovalLikelihoods } from "~/modules/project/api/approvalLikelihood/approvalLikelihoodApiDispatchers.ts";

export const ALL_APPROVAL_LIKELIHOODS_QUERY_KEY = ["allApprovalLikelihoods"];

export const useAllApprovalLikelihoods= (options?: QueriesOptions<any>) => useQuery(
	ALL_APPROVAL_LIKELIHOODS_QUERY_KEY,
	() => getAllApprovalLikelihoods().then(getDataFromResponse),
	options,
);