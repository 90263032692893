import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import UpdateProjectPhaseForm from "~/modules/project/components/forms/UpdateProjectPhaseForm";
import { TIME_TRACKING_BASE_QUERY_KEY } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";

type AddProjectPhaseToProjectSidebarProps = {
	confirmedBudgetCents: number;
	confirmedManDays: number;
	isOpen: boolean;
	onClose: (open: boolean) => void;
	phaseHasConfirmedOrders: boolean;
	projectId: string;
	projectPhaseData: ProjectPhase;

};

const UpdateProjectPhaseSidebar: React.FunctionComponent<AddProjectPhaseToProjectSidebarProps> = ({
	confirmedBudgetCents,
	confirmedManDays,
	isOpen,
	onClose,
	phaseHasConfirmedOrders,
	projectId,
	projectPhaseData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		queryClient.invalidateQueries(PROJECT_QUERY_KEY(projectId));
		queryClient.invalidateQueries(TIME_TRACKING_BASE_QUERY_KEY);
		await queryClient.invalidateQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
		onClose(false);
	}, [queryClient, projectId, onClose]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose(!isOpen)}>
			<UpdateProjectPhaseForm
				confirmedBudgetCents={confirmedBudgetCents}
				confirmedManDays={confirmedManDays}
				phaseData={projectPhaseData}
				phaseHasConfirmedOrders={phaseHasConfirmedOrders}
				onSuccess={handleSuccess}
				onCancel={() => onClose(false)}
			/>
		</Sidebar>
	);
};

export default UpdateProjectPhaseSidebar;
