import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useCompanyValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useCompanyValidationComparisonData.ts";
import { updateCompany } from "~/modules/client/api/company/companyApiDispatchers.ts";
import type { Company, UpdateCompanyData } from "~/modules/client/api/company/companyTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateCompanyFormProps = {
	companyData: Company;
	onCancel: () => void;
	onSuccess: () => void;
};

interface UpdateCompanyFormData extends UpdateCompanyData {
}

const UpdateCompanyForm: React.FunctionComponent<UpdateCompanyFormProps> = ({ companyData, onCancel, onSuccess }) => {
	const [busy, setBusy] = useState(false);

	const { displayNameComparisonData, corporateNameComparisonData } =
		useCompanyValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			corporateName: yup.string().required().unique(corporateNameComparisonData, companyData.id),
			displayName: yup.string().required().unique(displayNameComparisonData, companyData.id),
		});
	}, [corporateNameComparisonData, displayNameComparisonData, companyData]);

	const defaultValues = useMemo(() => {
		return {
			corporateName: companyData.corporateName,
			displayName: companyData.displayName,
		};
	}, [companyData]);

	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, isSubmitted },
	} = useForm<UpdateCompanyFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateCompanyFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
	});

	const onSubmit = async (data: UpdateCompanyFormData) => {
		setBusy(true);
		try {
			await updateCompany({ companyId: companyData.id, data });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Kunden bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Kunde">
						<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
							<div className="col-span-full sm:col-start-1">
								<Input
									name="corporateName"
									control={control}
									label="Name Legaleinheit"
									placeholder="Grandioso Gmbh & Co. KG"
								/>
							</div>
							<div className="col-span-3 sm:col-start-1">
								<Input
									name="displayName"
									control={control}
									label="Kundenname"
									placeholder="Grandioso"
								/>
							</div>
						</div>
					</FormSection>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={!isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey('form:buttonTexts.save'))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey('form:buttonTexts.cancel'))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateCompanyForm;