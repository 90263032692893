import clsx from "clsx";
import type { FunctionComponent } from "react";

import type { ButtonSizeVariants} from "~/components/buttons/Button/buttonVariants.ts";
import { buttonVariants } from "~/components/buttons/Button/buttonVariants.ts";
import ExcelIcon from "~/components/icons/ExcelIcon";

type DownloadExcelButtonProps = {
	buttonText?: string
	disabled?: boolean
	onClick: () => void
	size?: ButtonSizeVariants
};

const DownloadExcelButton: FunctionComponent<DownloadExcelButtonProps> = ({ buttonText="Download", disabled = false, onClick, size }) => {
	return (
		<button type="button"
				disabled={disabled}
				className={clsx(buttonVariants({ theme: "secondary", size, disabled }), "min-w-24")}
				onClick={onClick}><ExcelIcon className="mr-0.5 size-5 fill-white" />{buttonText}<span className="w-0.5"/>
		</button>
	);
};

export default DownloadExcelButton;