import type { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import type { ApiResponse } from "~/api/apiResponseTypes.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { appRoutes } from "~/constants/appRoute.ts";
import { API_URL } from "~/constants/envConstants";
import { refreshEndpoint } from "~/modules/auth/api/auth/authApiEndpoints";
import type { TokenResponse } from "~/modules/auth/api/auth/authTypes.ts";
import { setIntendedLocation } from "~/services/intendedLocation";
import type {
	AuthTokens} from "~/services/tokenService";
import {
	ACCESS_TOKEN_KEY,
	deleteTokens,
	getAccessToken,
	getRefreshToken,
	REFRESH_TOKEN_KEY,
	setTokens,
} from "~/services/tokenService";

const baseConfig = {
	baseURL: API_URL,
	headers: { Accept: "application/json" },
};

const axiosApi = axios.create(baseConfig);

export const axiosApiNoInterceptors = axios.create(baseConfig);

axiosApi.interceptors.request.use((request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	if (request.headers) {
		request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
	}

	if (import.meta.env.VITE_SEND_XDEBUG_SESSION_START) {
		// add Xdebug start session parameter
		const fullUrl = request.baseURL + "/" + request.url;
		const url = new URL(fullUrl);
		url.searchParams.set("XDEBUG_SESSION_START", "PHPSTORM");
		request.url = url.toString();
	}

	return request;
});

export const refreshTokensAndSession = async () => {
	try {

		const tokenRefreshResponse:AxiosResponse<ApiResponse<TokenResponse>> = await axiosApiNoInterceptors.post(refreshEndpoint, {
			refreshToken: getRefreshToken(),
		});

		const responseData = getDataFromResponse(tokenRefreshResponse)
		const newTokens: AuthTokens = {
			accessToken: responseData[ACCESS_TOKEN_KEY],
			refreshToken: responseData[REFRESH_TOKEN_KEY],
		};

		setTokens(newTokens);
		return Promise.resolve();
	} catch (error) {
		deleteTokens();
		setIntendedLocation();
		window.location.href = appRoutes.login().path;
	}
};

createAuthRefreshInterceptor(axiosApi, refreshTokensAndSession);

export default axiosApi;
