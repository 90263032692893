import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

export type StaffMember = {
	addressLine1: string;
	addressLine2: string | null;
	avatarImage: AvatarImage | null;
	bankName: string | null;
	bankAccountOwner: string | null;
	bic: string | null;
	city: string | null;
	companyLocationId: string | null;
	currentCareerLevelId: string | null;
	currentEmploymentTypeId: string | null;
	countryId: string | null;
	dateOfBirth: string | null;
	emergencyContact: EmergencyContact | null;
	emailCompany: string | null;
	emailPrivate: string | null;
	firstName: string;
	fullName: string;
	genderId: string;
	healthInsurance: string | null;
	healthInsuranceNumber: string | null;
	healthInsuranceTypeName: HealthInsuranceTypeName | null;
	iban: string | null;
	id: string;
	lastName: string;
	mentorStaffMemberId: string | null;
	nickName: string | null;
	pensionInsuranceNumber: string | null;
	phone: string | null;
	socialSecurityNumber: string | null;
	taxClassId: string | null;
	taxIdNumber: string | null;
	userId: string | null;
	zipCode: string | null;
};

export type StaffMemberUserData = {
	avatarImage: AvatarImage | null;
	careerLevelId: string;
	dateOfBirth: string | null;
	email: string;
	employmentTypeId: string;
	firstName: string;
	genderId: string;
	lastName: string;
	locationId: string | null;
	phone: string | null;
	staffMemberId: string;
}

export enum HealthInsuranceTypeName {
	Private = "private",
	Public = "public",

}

export type EmergencyContact = {
	id: string;
	staffMemberId: string;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phone: string | null;
	personalRelation: string | null;
}

export type CreateStaffMemberData = {
	firstName: string;
	lastName: string;
	genderId: string;
}

export type UpdateStaffMembersBankAccountData = {
	bankName: string | null;
	bic: string | null;
	iban: string | null;
}

export type UpdateStaffMembersCompanyLocationData = {
	companyLocationId: string | null;
}

export type UpdateStaffMembersContactData = {
	emailCompany: string | null;
	emailPrivate: string | null;
	phone: string | null;
	addressLine_1: string | null;
	addressLine_2: string | null;
	city: string | null;
	countryId: string | null;
	zipCode: string | null;
}

export type UpdateStaffMembersEmergencyContactData = Omit<EmergencyContact, "staffMemberId" | "id" | "listPosition">

export type UpdateStaffMembersHealthInsuranceData = {
	healthInsurance: string | null;
	healthInsuranceNumber: string | null;
	healthInsuranceTypeName: string | null;
}

export type UpdateStaffMembersMentorData = {
	mentorStaffMemberId: string | null;
}

export type UpdateStaffMembersPersonalDetailsData = {
	firstName: string;
	lastName: string;
	genderId: string;
	dateOfBirth: string | null;
}

export type UpdateSocialSecurityAndPensionInsuranceData = {
	socialSecurityNumber: string | null;
	pensionInsuranceNumber: string | null;
}

export type UpdateStaffMembersTaxDetailsData = {
	taxIdNumber: string | null;
	taxClassId: string | null;
}
