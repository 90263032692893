import type { Control, FieldErrors, FieldValues, Path } from "react-hook-form";

import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import type { FormInputOption } from "~/types/form.ts";

type ContactPersoPropsTypenalInformationFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	genderSelectOptions: FormInputOption[];
};

type ContactPersonalInformationFormData = {
	firstName: string;
	lastName: string;
	nobilityTitle: string | null;
	title: string | null;
	dateOfBirth: Date | null;
	genderId: string;
};

const ContactPersonalInformationFormSection = <T extends ContactPersonalInformationFormData>({
	control,
	errors,
	genderSelectOptions,
}: ContactPersoPropsTypenalInformationFormSectionProps<T>) => {
	return (
		<FormSection title="Persönliche Daten">
			<div className="grid grid-cols-6 gap-6">
				<div className="col-span-3">
					<Input
						control={control}
						name="firstName"
						label="Vorname"
						placeholder="Max"
					/>
				</div>
				<div className="col-span-3">
					<Input
						control={control}
						name="lastName"
						label="Nachname"
						placeholder="Mustermann"
					/>
				</div>
				<div className="col-span-3">
					<Input
						control={control}
						name="title"
						label="Titel"
						placeholder="Prof. Dr. ..."
					/>
				</div>
				<div className="col-span-3">
					<Input
						control={control}
						name="nobilityTitle"
						label="Adelstitel"
						placeholder="Graf von ..."
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						label="Geburtsdatum"
						name="dateOfBirth"
						control={control}
						error={errors.dateOfBirth?.message as string}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="genderId"
						label={"Geschlecht"}
						error={errors.genderId?.message as Path<T>}
						optionsData={genderSelectOptions}
						control={control}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default ContactPersonalInformationFormSection;
