export type TaxClass = {
	id: string;
	name: string;
}

export enum TaxClassName{
	I = "1",
	II = "2",
	III = "3",
	IV = "4",
	V = "5",
}

export enum TaxClassId{
	I = "1",
	II = "2",
	III = "3",
	IV = "4",
	V = "5",
}


