import StaffStatisticsView from "modules/humanResources/components/StaffStatisticsView";
import type { FunctionComponent } from "react";

import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useSickDaysReport } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const StaffStatisticsPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanManageStaff);
	if (guard) return guard;

	const {
		data: sickDaysReportData,
		isLoading: sickDaysReportIsLoading,
	} = useSickDaysReport();

	return <StaffStatisticsView
		isLoading={sickDaysReportIsLoading}
		sickDaysReportData={sickDaysReportData} />;
};

export default StaffStatisticsPage;