import { t } from "i18next";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import PageHeading from "~/components/headings/PageHeading";
import MainContent from "~/components/mainContent/MainContent";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { STAFF_MEMBERS_INDEX_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import type { CompanyLocation } from "~/modules/client/api/companyLocation/companyLocationTypes.ts";
import type { CareerLevel } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { EmploymentType } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import CreateStaffMemberSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/CreateStaffMemberSidebar";
import StaffMembersIndexFilterBar
	from "~/modules/humanResources/components/StaffMembersIndexView/components/StaffMembersIndexFilterBar";
import StaffMembersTable from "~/modules/humanResources/components/StaffMembersIndexView/components/StaffMembersTable";
import StaffMemberTableRow
	from "~/modules/humanResources/components/StaffMembersIndexView/components/StaffMemberTableRow";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type AdminUsersViewProps = {
	allStaffMembers?: StaffMember[];
	allCareerLevelsData?: CareerLevel[];
	allEmploymentStatusesData?: EmploymentType[];
	allCompanyLocationsData: CompanyLocation[];
};

const defaultPageState: { employmentTypeFilter: string[], search: string } = {
	employmentTypeFilter: [],
	search: "",
};

const StaffMembersIndexView: React.FunctionComponent<AdminUsersViewProps> = ({
	allCareerLevelsData,
	allCompanyLocationsData,
	allEmploymentStatusesData,
	allStaffMembers,

}) => {
	const { pageState, setPageState } = usePageStateStorage({
		defaultState: defaultPageState,
		pageKey: STAFF_MEMBERS_INDEX_PAGE_STATE_KEY,
	});
	const [employmentTypeFilter, setEmploymentStatusFilter] = useState<string[]>(pageState.employmentTypeFilter);
	const [showCreateStaffMemberForm, setShowCreateStaffMemberForm] = useState(false);
	const [search, setSearch] = useState(pageState.search);
	const navigate = useNavigate();

	const filteredStaffMembers = useMemo(() => {
		if (allStaffMembers) {
			return allStaffMembers.filter((staffMember) => {
				const employmentTypeCheck = employmentTypeFilter.length > 0 ? employmentTypeFilter.includes(staffMember.currentEmploymentTypeId as string) : true;
				return employmentTypeCheck && staffMember.fullName.toLowerCase().includes(search.trim().toLowerCase());
			}).sort(byObjectProperty("firstName"));
		}

		return [];
	}, [search, employmentTypeFilter, allStaffMembers]);

	const allListItems = useMemo(() => {
		return filteredStaffMembers?.map((staffMember) => {
			const careerLevel = staffMember.currentCareerLevelId
				? getOneOfCollection(allCareerLevelsData, staffMember.currentCareerLevelId, "displayName")
				: EMPTY_VALUE_PLACEHOLDER;
			const employmentType = staffMember.currentEmploymentTypeId
				? getOneOfCollection(allEmploymentStatusesData, staffMember.currentEmploymentTypeId, "name")
				: null;

			const companyLocation = staffMember.companyLocationId
				? allCompanyLocationsData.find(location => location.id === staffMember.companyLocationId)?.location.displayName
				: null;

			return (
				<StaffMemberTableRow
					key={staffMember.id}
					avatarImage={staffMember.avatarImage}
					careerLevel={careerLevel}
					companyLocation={companyLocation || EMPTY_VALUE_PLACEHOLDER}
					employmentType={employmentType ? t(normalizeTKey(`entities:employmentType.${employmentType}`)) : EMPTY_VALUE_PLACEHOLDER}
					email={staffMember.emailCompany || EMPTY_VALUE_PLACEHOLDER}
					firstName={staffMember.firstName}
					lastName={staffMember.lastName}
					onRowClick={() => navigate(generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
						staffMemberId: staffMember.id,
						tabId: StaffMemberDetailsTabId.CoreData,
					}))}
				/>
			);
		});
	}, [filteredStaffMembers, allCareerLevelsData, allEmploymentStatusesData, allCompanyLocationsData, navigate]);

	const handleStatusFilterChange = useCallback((employmentTypeId: string) => {
		if (null === employmentTypeId) {
			setEmploymentStatusFilter([]);
		} else {
			setEmploymentStatusFilter((prevState) => {
				return prevState.includes(employmentTypeId)
					? prevState.filter((optionValue) => optionValue !== employmentTypeId)
					: [...prevState, employmentTypeId];
			});
		}
	}, []);

	useEffect(() => {
		setPageState({ employmentTypeFilter, search });
	}, [search, employmentTypeFilter, setPageState]);

	const filterSettingsAreVisibile = employmentTypeFilter.length > 0 || !!search;

	return (
		<MainContent>
			<BreadcrumbsSection pages={["HR", "Mitarbeiter"]}
								className="bg-white" />

			<PageHeading title="Alle Mitarbeiter" />
			<PageHeading.BottomBar>
				<StaffMembersIndexFilterBar
					employmentTypeFilter={employmentTypeFilter}
					onEmploymentStatusFilterChange={handleStatusFilterChange}
					search={search}
					setSearch={setSearch}
					setShowCreateUserForm={setShowCreateStaffMemberForm}
					totalUserCount={allStaffMembers?.length || 0}
					visibleUserCount={filteredStaffMembers?.length || 0}
				/>
			</PageHeading.BottomBar>

			<ContentWrapper>
				<StaffMembersTable useExtraMargin={filterSettingsAreVisibile}>{allListItems}</StaffMembersTable>
			</ContentWrapper>
			<CreateStaffMemberSidebar isOpen={showCreateStaffMemberForm}
									  onClose={() => setShowCreateStaffMemberForm(false)} />
		</MainContent>
	);
};

export default StaffMembersIndexView;
