import { useMemo } from "react";

import { useStaffMembersVacationEntitlements } from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import type { UniqueDateValidatorArrayObject } from "~/utils/form/validationRules.ts";


export const useUsersVacationEntitlementsValidationComparisonData = (staffMemberId: string): UniqueDateValidatorArrayObject[] => {
	const {
		data: vacationEntitlementsData,
	} = useStaffMembersVacationEntitlements({staffMemberId});

	return useMemo(() => {
		if (vacationEntitlementsData) {
			return vacationEntitlementsData.map((entitlement) => {
				return {
					id: entitlement.id,
					value: new Date(entitlement.validFrom),
				};
			});
		}

		return [];
	}, [vacationEntitlementsData]);
};