import clsx from "clsx";
import type { PropsWithChildren } from "react";

import HourCount from "~/modules/timeTracking/components/components/Day/components/HourCount";
import type {
	TimeTrackingBarThemes} from "~/modules/timeTracking/components/components/Day/components/TimeTrackingBar/timeTrackingBarVariants.ts";
import {
	timeTrackingBarVariants,
} from "~/modules/timeTracking/components/components/Day/components/TimeTrackingBar/timeTrackingBarVariants.ts";

export type BarTheme = "gray" | "gray-light" | "accent" | "accent-light" | "primary" | "secondary";

interface TimeTrackingBarProps extends React.HTMLProps<HTMLDivElement> {
	theme?: TimeTrackingBarThemes;
	hours?: string | number;
	className?: string;
	width?: number;
	onClick?: () => void;
}

const TimeTrackingBar: React.FC<PropsWithChildren<TimeTrackingBarProps>> = ({
																				children,
																				theme ,
																				hours,
																				className,
																				width = 100,
																				onClick,
																			}) => {
	const { barWrapper, barInner } = timeTrackingBarVariants({ theme });

	return (

		<div
			className={clsx(
				barWrapper(),
				undefined !== onClick && "cursor-pointer",
				className,
			)}
			style={{
				width: `${width}%`,
			}}
			onClick={onClick}
		>
			<div className={barInner()}>{children}</div>
			{hours && width > 5 && <HourCount theme={theme}>{hours}</HourCount>}
		</div>
	);
};

export default TimeTrackingBar;
