import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationQueries.ts";
import CreateTotalCompensationEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/CreateTotalCompensationEntrySidebar/components/CreateTotalCompensationEntryForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string;
};

const CreateTotalCompensationEntrySidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	staffMemberId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY(staffMemberId));
		onClose();
	}, [onClose, queryClient, staffMemberId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<CreateTotalCompensationEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberId={staffMemberId} />
		</Sidebar>
	);
};

export default CreateTotalCompensationEntrySidebar;
