import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import { ALL_DELIVERABLE_TAGS_QUERY_KEY } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import type { DeliverableTag } from "~/modules/project/api/deliverableTags/deliverableTagsTypes.ts";
import { PROJECT_BASE_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import MergeDeliverableTagsForm
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/MergeDeliverableTagsSidebar/components/MergeDeliverableTagsForm";

type MergeDeliverableTagsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	deliverableTagData: DeliverableTag | null
};

const MergeDeliverableTagsSidebar: FunctionComponent<MergeDeliverableTagsSidebarProps> = ({
	isOpen,
	onClose,
	deliverableTagData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(ALL_DELIVERABLE_TAGS_QUERY_KEY);
		queryClient.invalidateQueries(PROJECT_BASE_QUERY_KEY);
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && deliverableTagData && <MergeDeliverableTagsForm onSuccess={handleSuccess}
																	   onCancel={onClose}
																	   deliverableTagData={deliverableTagData}
			/>}
		</Sidebar>
	);
};

export default MergeDeliverableTagsSidebar;
