import type { SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Table from "~/components/Table";
import type { SickDaysReportData } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportTypes.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import sickDaysPerEmployeeTableColumnDefs
	from "~/modules/humanResources/components/StaffStatisticsView/components/SickDaysPerEmployeeTable/sickDaysPerEmployeeTableColumnDefs.tsx";
import type { SickDaysPerStaffMemberTableData } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import type { SickDaysPerStaffMemberTableMeta } from "~/modules/humanResources/types/staffStatisticsTypes.ts";

type Props = {
	sickDaysReportData?: SickDaysReportData;
	selectedYear: string;
};

const SickDaysPerEmployeeTable: FunctionComponent<Props> = ({ sickDaysReportData, selectedYear }) => {
	const { data: staffMembersData} = useAllStaffMembers();
	const [sorting, setSorting] = useState<SortingState>([{ id: "staffMemberFullName", desc: false }]);
	const tableData:SickDaysPerStaffMemberTableData[]|null = useMemo(() => {
		if (selectedYear && sickDaysReportData && staffMembersData) {
			const yearData = sickDaysReportData[selectedYear];
			return Object.keys(yearData).reduce((acc, month) => {
				Object.keys(yearData[month].sickDays.staffMembers).forEach(staffMemberId => {
					const existingEntry = acc.find(entry => entry.staffMemberId === staffMemberId);
					const sickDays = yearData[month].sickDays.staffMembers[staffMemberId];
					if(!existingEntry){
						acc.push({
							staffMemberId: staffMemberId,
							staffMemberFullName: staffMembersData.find(staffMember => staffMember.id === staffMemberId)!.fullName,
							months: {[month]: sickDays},
							total: sickDays,
						});
					}
					else{
						existingEntry.months[month] = yearData[month].sickDays.staffMembers[staffMemberId];
						existingEntry.total += sickDays;
					}
				});
				return acc;

			}, [] as SickDaysPerStaffMemberTableData[]);
		}
		return [];
	}, [selectedYear, sickDaysReportData, staffMembersData]);

	const table = useReactTable({
		data: tableData,
		columns: sickDaysPerEmployeeTableColumnDefs,
		state: {
			sorting,
			columnPinning: {
				left: ["staffMemberFullName"],
			},
		},
		meta: {
			selectedYear
		} as SickDaysPerStaffMemberTableMeta,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return <Table table={table} verticalBorders={true}/>;
};

export default SickDaysPerEmployeeTable;