import { useElementSize } from "@mantine/hooks";
import clsx from "clsx";
import { differenceInBusinessDays } from "date-fns";
import Decimal from "decimal.js-light";
import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";

import { useAuth } from "~/contexts/AuthContext";
import type { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import AbsenceBar from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceBar";
import AbsenceOverviewHeader from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader";
import ToggleUserButton from "~/modules/absence/components/AbsenceCalendarView/components/ToggleUserButton";
import VerticalGridLines from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines";
import type {
	AbsenceCalendarEvent,
	AbsenceCalendarTimespanOption,
} from "~/modules/absence/types/absenceOverviewTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";
import { toggleItemInArray } from "~/utils/arrayUtils.ts";

type AbsenceCalendarViewProps = {
	allEmployees: (StaffMember & { absences: AbsenceCalendarEvent[] })[];
	endDate: Date;
	hiddenStaffMemberIds: string[];
	isLoading: boolean;
	selectedDisplayTimespanWeeks: AbsenceCalendarTimespanOption;
	setSelectedDisplayTimespanWeeks: (value: AbsenceCalendarTimespanOption) => void;
	setHiddenUserIds: DispatchStateAction<string[]>;
	setStartDate: (value: Date) => void;
	setTypeFilter: DispatchStateAction<AbsenceTypeId[]>
	startDate: Date;
	typeFilter: AbsenceTypeId[];
};

const AbsenceCalendarView: FunctionComponent<AbsenceCalendarViewProps> = ({
	allEmployees,
	endDate,
	hiddenStaffMemberIds,
	selectedDisplayTimespanWeeks,
	setHiddenUserIds,
	setSelectedDisplayTimespanWeeks,
	setStartDate,
	setTypeFilter,
	startDate,
	typeFilter,
}) => {
	const {user} = useAuth();

	const {
		ref: timelineContainerRef,
		width: timelineContainerWidth,
	} = useElementSize();

	const {
		ref: timelineHeaderContainerRef,
		height: timelineHeaderContainerHeight,
	} = useElementSize();
	const [showUserToggle, setShowUserToggle] = useState<boolean>(false);
	const timelineContainerWidthPx = new Decimal(timelineContainerWidth).toDecimalPlaces(2);
	const timelineContainerHeightPx = timelineContainerRef.current ? new Decimal(timelineContainerRef.current?.scrollHeight) : new Decimal(0);
	const timelineHeaderContainerHeightPx = timelineHeaderContainerRef.current ? new Decimal(timelineHeaderContainerHeight) : new Decimal(0);
	const nameColumnWidthRem = "13rem";
	const rowBaseClasses = "h-8";
	const daysDisplayed = differenceInBusinessDays(endDate, startDate) + 1;

	const verticalDividerWidthPx = 1;
	const dayWidthPx = new Decimal(timelineContainerWidthPx.dividedBy(daysDisplayed)).toDecimalPlaces(2);

	const handleToggleUser = (userId: string) => {
		setHiddenUserIds(currentState => {
			return toggleItemInArray(currentState, userId);
		});
	};

	const handToggleMultiActionClick = useCallback((showAll:boolean) => {
			if(showAll) {
				setHiddenUserIds([]);
			} else {
				setHiddenUserIds(allEmployees.map(employee => employee.id));

			}
	}, [allEmployees, setHiddenUserIds]);

	return <div className="grid h-screen w-full grid-rows-[auto_auto_1fr] p-4">
		<AbsenceOverviewHeader
			endDate={endDate}
			hiddenUsersCount={hiddenStaffMemberIds.length}
			onToggleMultiActionClick={handToggleMultiActionClick}
			setShowUserFilter={setShowUserToggle}
			selectedDisplayTimespanWeeks={selectedDisplayTimespanWeeks}
			setSelectedDisplayTimespanWeeks={setSelectedDisplayTimespanWeeks}
			setStartDate={setStartDate}
			setTypeFilter={setTypeFilter}
			showUserFilter={showUserToggle}
			startDate={startDate}
			typeFilter={typeFilter}
		/>
		<div className="flex h-8 flex-row">
			<div style={{ width: nameColumnWidthRem }}
				 className="shrink-0" />
			<div ref={timelineHeaderContainerRef}
				 className="relative">
				<VerticalGridLines dayWidthPx={dayWidthPx.toNumber()}
								   containerWidth={timelineContainerWidthPx}
								   containerHeight={timelineHeaderContainerHeightPx}
								   dividerWidthPx={verticalDividerWidthPx}
								   startDate={startDate}
								   endDate={endDate}
								   selectedDisplayTimespanWeeks={selectedDisplayTimespanWeeks}
								   setSelectedTimespanWeeks={setSelectedDisplayTimespanWeeks}
								   showSegmentTexts={true}
								   setStartDate={setStartDate}
				/>
			</div>
		</div>
		<div className="relative flex flex-row overflow-y-auto overflow-x-hidden">
			<div style={{ width: nameColumnWidthRem }}
				 className="shrink-0">
				{allEmployees.filter(user => showUserToggle ? true : !hiddenStaffMemberIds.includes(user.id)).map((employee) => {
					return <div key={"name" + employee.id}
								className={clsx(rowBaseClasses, "flex items-center whitespace-nowrap pr-4 text-sm font-bold odd:bg-gray-200", !showUserToggle ? "pl-2" : "")}>
						{showUserToggle ?
							<ToggleUserButton checked={!hiddenStaffMemberIds.includes(employee.id)}
											  onClick={() => handleToggleUser(employee.id)}>{employee.fullName}</ToggleUserButton> : employee.fullName}
					</div>;
				})}
			</div>
			<div className="relative w-full"
				 ref={timelineContainerRef}>
				<VerticalGridLines dayWidthPx={dayWidthPx.toNumber()}
								   containerWidth={timelineContainerWidthPx}
								   containerHeight={timelineContainerHeightPx}
								   dividerWidthPx={verticalDividerWidthPx}
								   startDate={startDate}
								   endDate={endDate}
								   selectedDisplayTimespanWeeks={selectedDisplayTimespanWeeks}
				/>
				{allEmployees.filter(user => showUserToggle ? true : !hiddenStaffMemberIds.includes(user.id)).map((employee) => {
					return <div key={"events" + employee.id}
								className={clsx(rowBaseClasses, "relative shrink even:bg-gray-200")}>
						{employee.absences.map((absence) => {
							return <AbsenceBar key={absence.id}
											   endDate={endDate}
											   startDate={startDate}
											   dayWidthPx={dayWidthPx}
											   isUsersEvent={user!.staffMemberId === absence.staffMemberId}
											   muted={showUserToggle && hiddenStaffMemberIds.includes(employee.id)}
											   absence={absence}
											   showDetails={true}
											   containerWidthPx={timelineContainerWidthPx}
											   offsetPerDayPx={verticalDividerWidthPx} />;
						})}
					</div>;
				})}
			</div>
		</div>

	</div>;
};

export default AbsenceCalendarView;