import type { FunctionComponent } from "react";

import Hint from "~/components/Hint";

const NoEligibleStaffMembersFoundMessage: FunctionComponent = () => {
	return <Hint size="sm"
				 theme="warning">
		<p>Neue User-Accounts für interne Mitarbeiter:innen können nur auf Basis eines in der HR-Sektion bestehenden Mitarbeiterprofils
			erzeugt werden.</p>
		<p className="mt-4">In dem Profil müssen mindestens folgende Informationen
			hinterlegt worden sein, damit ein User angelegt werden kann:</p>
		<ul className="ml-4 mt-4 list-disc">
			<li>Anstellungsart</li>
			<li>Karrierestufe</li>
		</ul>
		<p className="mt-4 font-bold">Es wurden keine Profile ohne verknüpften User-Account gefunden, die diese
			Voraussetzungen erfüllen. Bitte wende Dich an das HR-Department.</p>
	</Hint>;
};

export default NoEligibleStaffMembersFoundMessage;