import {ReactComponent as Icon} from "./list-ol.svg"

type StopwatchIconProps = {
	className?: string;
};

const NumberedListIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default NumberedListIcon;