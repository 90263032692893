import { useMemo } from "react";

import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { sanitizeEmailForComparison, sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import type { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";

type UserValidationComparisonData = {
	emailComparisonData: UniqueValidatorArrayObject[];
	shortNameComparisonData: UniqueValidatorArrayObject[];
}

export const useUserValidationComparisonData = (): UserValidationComparisonData => {
	const {
		data: allUserData,
	} = useAllUsers();

	const { emailComparisonData, shortNameComparisonData } = useMemo(() => {
		if (allUserData) {
			return {
				emailComparisonData: allUserData.map((user) => {
					return {
						id: user.id,
						value: sanitizeEmailForComparison(user.email),
					};
				}),

				shortNameComparisonData: allUserData.map((user) => {
					return {
						id: user.id,
						value: sanitizeStringForComparison(user.shortName),
					};
				}),

			};
		}
		return {
			emailComparisonData: [], shortNameComparisonData: [],

		};
	}, [allUserData]);

	return {
		emailComparisonData,
		shortNameComparisonData,
	};
};