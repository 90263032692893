import { t } from "i18next";
import { useMemo } from "react";

import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useAbsenceTypeOptions = (): FormInputOption[] => {
	const { data: allAbsenceTypeData } = useAllAbsenceTypes();
	return useMemo(() => {
		if (allAbsenceTypeData) {
			return allAbsenceTypeData.sort(byObjectProperty("listPosition")).map((absenceType) => {
				const label = t(`entities:absenceType.${absenceType.name}` as unknown as TemplateStringsArray);
				return createInputOptionData(label, absenceType.id);
			});
		}
		return [];
	}, [allAbsenceTypeData]);
};

export default useAbsenceTypeOptions;

