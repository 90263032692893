

export type Setting = {
	id: string;
	key: SettingKey;
	value: string;
	type: string;
	comment: string;
}

export enum SettingKey{
	EmploymentEntitiesMinDate = "employmentEntitiesMinDate",
	DefaultVacationEntitlementDays = "defaultVacationEntitlementDays",
}