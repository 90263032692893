import parseISO from "date-fns/parseISO";
import Decimal from "decimal.js-light";
import AbsenceCalendarView from "modules/absence/components/AbsenceCalendarView";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import usePageStateStorage from "~/hooks/usePageStateStorage";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAbsencesInTimespan } from "~/modules/absence/api/absence/absenceQueries.ts";
import { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import type { AbsenceCalendarPageState } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { AbsenceCalendarTimespanOption } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getStartAndEndDateFromAbsenceOverviewTimespanOption } from "~/modules/absence/utils/absenceCalendarUtils.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

const defaultPageState: AbsenceCalendarPageState = {
	hiddenStaffMemberIds: [],
	typeFilter: [AbsenceTypeId.Vacation],
	selectedDisplayTimespanWeeks: AbsenceCalendarTimespanOption.QUARTER,
};

const AbsenceCalendarPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanViewAbsences);
	if (guard) return guard;

	const { pageState, setPageState } = usePageStateStorage({
		pageKey: "absenceOverviewPage",
		defaultState: defaultPageState,
	});
	const [typeFilter, setTypeFilter] = useState<AbsenceTypeId[]>(pageState.typeFilter);
	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [hiddenStaffMemberIds, setHiddenUserIds] = useState<string[]>(pageState.hiddenStaffMemberIds);
	const { t } = useTranslation();
	const [selectedDisplayTimespanWeeks, setSelectedDisplayTimespanWeeks] = useState<AbsenceCalendarTimespanOption>(pageState.selectedDisplayTimespanWeeks);

	const { startDate, endDate } = useMemo(() => {
		setPageState({
			selectedDisplayTimespanWeeks,
			hiddenStaffMemberIds,
			typeFilter,
		});
		return getStartAndEndDateFromAbsenceOverviewTimespanOption(selectedDisplayTimespanWeeks, selectedStartDate);
	}, [setPageState, selectedDisplayTimespanWeeks, hiddenStaffMemberIds, typeFilter, selectedStartDate]);

	const {
		data: absenceData,
		isLoading: absencesIsLoading,
	} = useAbsencesInTimespan({ startDate: formatDateToYYYYMMDD(startDate), endDate: formatDateToYYYYMMDD(endDate) });

	const { data: allAbsenceTypes, isLoading: absenceTypesIsLoading } = useAllAbsenceTypes();

	const { data: staffMembersData, isLoading: usersIsLoading } = useAllStaffMembers();

	const allEmployees = useMemo(() => {
		if (staffMembersData && absenceData && allAbsenceTypes) {
			return staffMembersData
				.filter((staffMember) => ![EmploymentTypeId.Left, EmploymentTypeId.Freelancer].includes(staffMember.currentEmploymentTypeId as EmploymentTypeId))
				.map((staffMember) => {
					return {
						...staffMember,
						absences: absenceData.filter((absence) => absence.staffMemberId === staffMember.id && (typeFilter.length === 0 || typeFilter.includes(absence.absenceTypeId as AbsenceTypeId)))
							.map((absence) => {
								const absenceType = allAbsenceTypes.find((absenceType) => absenceType.id === absence.absenceTypeId);
								const absenceTypeDisplayName = !absenceType ? "n/a" : t(normalizeTKey(`entities:absenceType.${absenceType.name}`));
								return {
									...absence,
									absenceTypeName: absenceTypeDisplayName,
									staffMemberFullName: staffMember.fullName,
									staffMemberEmail: staffMember.emailCompany,
									startDate: parseISO(absence.startDate),
									endDate: parseISO(absence.endDate),
									workDays: new Decimal(absence.workDays),
								};

							}),
					};
				})
				.sort(byObjectProperty("firstName", "asc"));

		}
		return [];
	}, [staffMembersData, absenceData, allAbsenceTypes, t, typeFilter]);

	if (usersIsLoading || absenceTypesIsLoading || absencesIsLoading) return <LoadingPage />;

	return <AbsenceCalendarView
		allEmployees={allEmployees}
		endDate={endDate}
		hiddenStaffMemberIds={hiddenStaffMemberIds}
		isLoading={absencesIsLoading || !absenceData}
		selectedDisplayTimespanWeeks={selectedDisplayTimespanWeeks}
		setSelectedDisplayTimespanWeeks={setSelectedDisplayTimespanWeeks}
		setHiddenUserIds={setHiddenUserIds}
		setTypeFilter={setTypeFilter}
		setStartDate={setSelectedStartDate}
		startDate={startDate}
		typeFilter={typeFilter}
	/>;
};

export default AbsenceCalendarPage;