import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import ComboBox from "~/components/formElements/ComboBox";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateStaffMembersContact } from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateContactDetailsFormData = {
	emailCompany: string | null;
	emailPrivate: string | null;
	phone: string | null;
	addressLine1: string | null;
	addressLine2: string | null;
	zipCode: string | null;
	city: string | null;
	countryId: string | null;
}

type UpdateContactDetailsFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateContactDetailsForm: React.FunctionComponent<UpdateContactDetailsFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const countrySelectOptions = useCountrySelectOptions();
	const schema = useMemo(() => {
		return yup.object({
			emailCompany: yup.string().nullable().email().default(null),
			emailPrivate: yup.string().nullable().email().default(null),
			phone: yup.string().nullable().default(null),
			addressLine1: yup.string().nullable().default(null),
			addressLine2: yup.string().nullable().default(null),
			zipCode: yup.string().nullable().default(null),
			city: yup.string().nullable().default(null),
			countryId: yup.string().nullable().default(null),
		});
	}, []);
	const defaultValues = useMemo(() => {
		if (staffMemberCoreData) {
			return {
				emailCompany: staffMemberCoreData.emailCompany,
				emailPrivate: staffMemberCoreData.emailPrivate,
				phone: staffMemberCoreData.phone,
				addressLine1: staffMemberCoreData.addressLine1,
				addressLine2: staffMemberCoreData.addressLine2,
				zipCode: staffMemberCoreData.zipCode,
				city: staffMemberCoreData.city,
				countryId: staffMemberCoreData.countryId,
			};
		}
		return {
			emailCompany: null,
			emailPrivate: null,
			phone: null,
			addressLine1: null,
			addressLine2: null,
			zipCode: null,
			city: null,
			countryId: null,
		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateContactDetailsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateContactDetailsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdateContactDetailsFormData) => {
		const updateData = {
			...data,
			addressLine_1: data.addressLine1,
			addressLine_2: data.addressLine2,
		}
		try {
			setIsBusy(true);
			await updateStaffMembersContact(staffMemberCoreData.id, updateData);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Kontakt / Addresse
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}

				<FormSection title="Kontaktdaten">
					<div className="grid grid-cols-2 gap-4">
						<Input control={control}
							   name="emailCompany"
							   label="E-Mail geschäftlich"
							   placeholder="vorname.nachname@nordnord.com"
						/>
						<Input control={control}
							   name="emailPrivate"
							   label="E-Mail privat"
							   placeholder="vorname.nachname@exmaple.com"
						/>
						<Input control={control}
							   name="phone"
							   label="Telefon"
							   placeholder="+49 123 4567890"
						/>
					</div>
				</FormSection>
				<FormSection title="Adresse">
					<div className="grid grid-cols-3 gap-4">
						<div className="col-span-3">
							<Input control={control}
								   name="addressLine1"
								   label="Straße und Hausnummer"
								   placeholder="Musterstraße 123"
							/>
						</div>
						<div className="col-span-3">
							<Input control={control}
								   name="addressLine2"
								   label="Adresszusatz"
								   placeholder="Zusatz"
							/>
						</div>
						<Input control={control}
							   name="zipCode"
							   label="PLZ"
							   placeholder="12345"
						/>
						<div className="col-span-2">
							<Input control={control}
								   name="city"
								   label="Stadt"
								   placeholder="Musterstadt"
							/>
						</div>
						<div className="col-span-3">
							<ComboBox control={control}
									  allowNew={false}
									  name="countryId"
									  label="Land"
									  optionsData={countrySelectOptions}
									  placeholder="Land auswählen"
							/>
						</div>
					</div>
				</FormSection>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateContactDetailsForm;
