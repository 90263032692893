import { createColumnHelper } from "@tanstack/react-table";
import Decimal from "decimal.js-light";

import type { MonthlyUtilisationTableData } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

const columnHelper = createColumnHelper<MonthlyUtilisationTableData>();

export const monthlyUtilisationColumnDefs = [
	columnHelper.accessor("staffMemberDisplayName", {
		header: "Mitarbeiter:in",
		meta:{
			cellTextAlign: "left",
		}
	}),
	columnHelper.accessor("ftePercentages", {
		header: "FTE",
		cell: props => {
			return props.getValue().map((percentage: number) => {
				return formatNumberWithComma(percentage / 10000, 2, true);
			}).join(" / ");
		},
	}),
	columnHelper.accessor("regularWorkDays", {
		header: "Reguläre Arbeitstage Monat",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			return formatNumberWithComma(value, 1, true);
		},
	}),
	columnHelper.accessor("longTermAbsenceDays", {
		header: "Abzug Sabattical,\nMutterschutz,\nElternzeit",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 1, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			return formattedValue;
		},
	}),
	columnHelper.display({
		header: "Verbleibende Arbeitstage (FTE)",
		cell: props => {
			const regularWorkDays = new Decimal(props.row.original.regularWorkDays);
			const longTermAbsenceDays = new Decimal(props.row.original.longTermAbsenceDays);
			const ftePercentages = props.row.original.ftePercentages;
			const value = regularWorkDays.minus(longTermAbsenceDays).mul(ftePercentages[0] / 10000).toNumber();
			return formatNumberWithComma(value, 2, true);
		},
	}),
	columnHelper.accessor("vacationDays", {
		header: "Abzugsfähige\nUrlaubstage\n(laut Arbeitszeitmodell)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			return <div className="text-gray-500">{formatNumberWithComma(value, 1, true)}</div>;
		},
	}),
	columnHelper.accessor("vacationDaysFte", {
		header: "Abzug Urlaubstage (FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 2, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			return formattedValue;
		},
	}),
	columnHelper.accessor("workDaysTargetFte", {
		header: "Arbeitstage Soll (FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			return formatNumberWithComma(value, 2, true);
		},
		footer: props => {
			const total = props.table.getCoreRowModel().rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("workDaysTargetFte") || 0));
			}, new Decimal(0));
			return "∑ " +formatNumberWithComma(total.toNumber(), 2, true);
		},
	}),
	columnHelper.accessor("projectMinutesTracked", {
		header: "Tage erfasst (Projekte)",
		cell: props => {
			const value = new Decimal(props.getValue()).div(480).toNumber();
			return formatNumberWithComma(value, 2, true);
		},
		footer: props => {
			const total = props.table.getCoreRowModel().rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("projectMinutesTracked") || 0));
			}, new Decimal(0));
			const value = total.div(480).toNumber();
			return "∑ " +formatNumberWithComma(value, 2, true);
		},
	}),
	columnHelper.accessor("projectUtilizationPercentage", {
		header: "Projektauslastung",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			return formatNumberWithComma(value, 2, true) + " %";
		},
		meta:{
			cellTextAlign: "right",
		},
		footer: props => {
			const rows = props.table.getCoreRowModel().rows;
			const totalWorkDaysTargetFte = rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("workDaysTargetFte") || 0));
			}, new Decimal(0));
			const totalProjectMinutesTracked = rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("projectMinutesTracked") || 0));
			}, new Decimal(0));

			// Calculate utilization from footer sums
			const utilizationFromSums = totalProjectMinutesTracked.div(480).div(totalWorkDaysTargetFte).mul(100);

			// Calculate average utilization
/*			const validRows = rows.filter(row => !!row.getValue("workDaysTargetFte"));
			const averageUtilization = validRows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("projectUtilizationPercentage") || 0));
			}, new Decimal(0)).div(validRows.length);*/

			return (
				<div>
					<div>∑ {formatNumberWithComma(utilizationFromSums.toNumber(), 2, true)} %</div>
				</div>
			);
		},
	}),
];