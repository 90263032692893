import { yupResolver } from "@hookform/resolvers/yup";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProductTagValidationComparisonData,
} from "~/modules/project/api/productTags/hooks/useProductTagValidationComparisonData.ts";
import { createProductTag } from "~/modules/project/api/productTags/productTagsApiDispatchers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface CreateProductTagFormData {
	displayName: string;
}

type CreateProductTagFormProps = {

	onSuccess: () => void;
	onCancel: () => void;

};

const CreateProductTagForm: FunctionComponent<CreateProductTagFormProps> = ({
	onSuccess,
	onCancel,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const validationComparisonData = useProductTagValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			displayName: yup.string().required().unique(validationComparisonData),
		});
	}, [validationComparisonData]);

	const defaultValues = useMemo(() => {
		return {};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<CreateProductTagFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateProductTagFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: CreateProductTagFormData) => {
		try {
			setIsBusy(true);
			await createProductTag(data);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.");
		}
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">Neuer Produkt Tag</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<Input control={control}
					   name="displayName"
					   label="Name"
				/>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateProductTagForm;
