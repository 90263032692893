import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllStaffMemberChildren,
} from "~/modules/humanResources/api/staffMemberChild/staffMemberChildApiDispatchers.ts";

export const STAFF_MEMBER_CHILD_BASE_QUERY_KEY = "staffMemberChild";

export const STAFF_MEMBERS_CHILDREN_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_CHILD_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersChildren = ({ staffMemberId, options }: { staffMemberId: string, options?: QueriesOptions<any> }) => useQuery(
	STAFF_MEMBERS_CHILDREN_QUERY_KEY(staffMemberId),
	() => getAllStaffMemberChildren(staffMemberId).then(getDataFromResponse),
	options,
);