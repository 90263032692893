import {tv} from "tailwind-variants";

import type {VariantProps} from "~/types/tailwindVariants";

export const headlineVariants = tv({
    base: "",
    variants: {
        type: {
            h1: "text-5xl",
            h2: "text-3xl",
            h3: "text-2xl tracking-tight",
            h4: "text-xl tracking-tighter",
            h5: "text-base tracking-tight",
        },
        weight: {
            normal: "font-normal",
            bold: "font-bold"
        },
        color: {
            default: "text-primary-500",
            muted: "text-gray-600",
            none: ""
        }

    },
    defaultVariants: {
        type: "h1",
        weight: "bold",
        color: "default",
    },
});


export type HeadlineVariantProps = VariantProps<typeof headlineVariants>;


