import { flexRender } from "@tanstack/react-table";
import type { Table as TableType } from "@tanstack/table-core/build/lib/types";
import clsx from "clsx";
import { Fragment } from "react";

import TableHeaderCell from "~/components/Table/components/TableHeaderCell";
import type { TableHeaderBgTheme } from "~/components/Table/tableTypes.ts";


type DefaultTableHeaderContentProps<T> = {
	bgColor?: TableHeaderBgTheme,
	table: TableType<T>,
	rounded?: boolean
};

const DefaultTableHeaderContent = <T, >({ bgColor = "light", rounded = true, table }: DefaultTableHeaderContentProps<T>) => {
	return table.getHeaderGroups().map((headerGroup) => (
		<Fragment key={headerGroup.id}>
			<tr>
				{headerGroup.headers.map((header, index) => {
					return <TableHeaderCell key={index}
											bgColor={bgColor}
											cellClassName={clsx(header.column.getIsPinned() && "sticky left-0 z-20", bgColor === "light" ? "bg-white" : "bg-gray-500")}
											canSort={header.column.getCanSort()}
											widthPercentage={header.column.columnDef.meta?.widthPercentage}
											textAlign={header.column.columnDef.meta?.headerTextAlign}
											isSorted={header.column.getIsSorted()}
											rounded={rounded}
											onSortClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
					>
						{flexRender(
							header.column.columnDef.header,
							header.getContext())}
					</TableHeaderCell>;
				})}
			</tr>
			<tr className="h-[0.5px]">
				<td colSpan={table.getVisibleFlatColumns().length}
					className="h-full bg-gray-200" />
			</tr>
		</Fragment>
	));
};

export default DefaultTableHeaderContent;