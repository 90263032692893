export enum GenderIds {
	Male = "male",
	Female = "female",
	Diverse = "divers",
}

/*export enum PermissionIds {
	CanManageAbsences = "manage-absences",
	CanManageProjects = "manage-projects",
	CanManageInvoices = "manage-invoices",
	CanManageUsers = "manage-users",
	CanManageTimeTrackings = "manage-time-trackings",
}

export enum RoleIds {
	Admin = "admin",
	ProjectManager = "project-manager",
	InvoiceManager = "invoice-manager",
	TeamMember = "team-member",
	HrManager = "hr-manager",
}*/

export enum WorkingStatusId {
	Quotation = "1",
	Active = "2",
	Hold = "3",
	Closed = "4",
	Cancelled = "5",
}

export enum TimeTrackingTypeId {
	Project = "1",
	Internal = "2",
	Sales = "3",
	PartTime = "4",
}
