import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const hintVariants = tv({
	slots: {
		wrapper: "rounded-md",
		title: "font-medium",
	},
	variants: {
		size: {
			sm:{
				wrapper: "p-2 text-sm",
			},
			default: {
				wrapper: "p-4 text-base",
			},
		},
		theme: {
			info: {
				wrapper: "bg-primary-500 bg-opacity-5 text-primary-900",
			},
			success: {
				wrapper: "bg-green-50 text-green-800",
			},
			error: {
				wrapper: "bg-red-50 text-red-800",
			},
			warning: {
				wrapper: "bg-yellow-50 text-yellow-800",
			},
		},
	},
	defaultVariants: {
		theme: "info",
		size: "default",
	},
});


export type HintVariantProps = VariantProps<typeof hintVariants>;
export type HintThemeVariants = "info" | "success" | "error" | "warning";
export type HintSizeVariants = "default" | "sm";
