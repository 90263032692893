import Decimal from "decimal.js-light";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import MonthlyUtilisationBarChart
	from "~/modules/reports/components/UtilisationReportsView/components/TotalUtilisationChartSection/MonthlyUtilisationBarChart";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = { utilisationReportData: YearlyUtilisationReport, year: string };

const TotalUtilisationChartSection: FunctionComponent<Props> = ({ utilisationReportData, year }) => {
	const [projectUtilisationOnly, setProjectUtilisationOnly] = useState<boolean>(true);
	/*const totalUtilisationFormatted = new Decimal(projectUtilisationOnly ? utilisationReportData.projectUtilizationPercentage : utilisationReportData.utilizationPercentage).toDecimalPlaces(2).toString();*/

	const [totalUtilisationFormatted, projectUtilisationFormatted] = useMemo(() => {
		let averageTotalUtilisationFormatted = "0,0";
		let averageProjectUtilisationFormatted = "0,0";
		const numMonths = Object.keys(utilisationReportData.monthlyBreakdown).length;
		const sumMonthlyUtilisations = Object.values(utilisationReportData.monthlyBreakdown).reduce((acc, month) => {
			return acc.add(new Decimal(month.utilizationPercentage));
		}, new Decimal(0));
		averageTotalUtilisationFormatted = formatNumberWithComma(new Decimal(sumMonthlyUtilisations).div(numMonths).toNumber(), 2);

		const sumMonthlyProjectUtilisations = Object.values(utilisationReportData.monthlyBreakdown).reduce((acc, month) => {
			return acc.add(new Decimal(month.projectUtilizationPercentage));
		}, new Decimal(0));
		averageProjectUtilisationFormatted = formatNumberWithComma(new Decimal(sumMonthlyProjectUtilisations).div(numMonths).toNumber(), 2);

		return [averageTotalUtilisationFormatted, averageProjectUtilisationFormatted];
	}, [utilisationReportData]);

	return <div className="flex flex-col pt-4">
		<Card>
			<div className="relative flex gap-x-4">
				<div className="absolute right-0">
					<SwitchPlain checked={projectUtilisationOnly}
								 onChange={setProjectUtilisationOnly}
								 labelOn="Projektauslastung"
								 labelOff="Gesamtauslastung" />
				</div>
				<div className="flex min-w-24 shrink-0 flex-col items-center justify-center">
					<div className="flex items-center text-xl font-bold text-gray-600">
						{projectUtilisationOnly ? projectUtilisationFormatted :totalUtilisationFormatted} %
					</div>
					<div className="flex items-center text-sm font-light text-gray-500">
						Ø {year}
					</div>
				</div>
				<div className="min-h-40 w-full">
					<MonthlyUtilisationBarChart reportData={utilisationReportData}
												projectOnly={projectUtilisationOnly} />
				</div>
			</div>
		</Card>
	</div>;
};

export default TotalUtilisationChartSection;