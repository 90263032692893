import clsx from "clsx";
import type React from "react";

type UsersTableProps = {
	children?: React.ReactNode;
	useExtraMargin?: boolean;
};

const UsersTable: React.FunctionComponent<UsersTableProps> = ({ children, useExtraMargin=false }) => {
	return (
		<table className={clsx("min-w-full border-separate border-spacing-y-2", useExtraMargin && "mt-10")}>
			<thead>
				<tr>
					<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
						Name
					</th>
					<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
						Karrierestufe / Anstellungsstatus
					</th>
					<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
						Standort
					</th>
					<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
						Status
					</th>
					<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
						<span className="sr-only">Edit</span>
					</th>
				</tr>
			</thead>
			<tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
		</table>
	);
};

export default UsersTable;
