import { MantineProvider } from "@mantine/core";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { setLocale } from "yup";

import axiosApi from "~/api/axiosApi";
import { AuthContextProvider } from "~/contexts/AuthContext";
import { CommonDataLoadingProvider } from "~/contexts/CommonDataContext/CommonDataContext";
import MainRouter from "~/pages/mainRouter/MainRouter";
import { getAccessToken } from "~/services/tokenService";
import { getCustomValidationErrorMessages } from "~/utils/form/validationErrorMessages";
import { addCustomValidators } from "~/utils/form/validationRules.ts";

const App: React.FC = () => {
	const accessToken = getAccessToken();
	axiosApi.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

	useEffect(() => {
		// Initialise custom yup features
		setLocale(getCustomValidationErrorMessages());
		addCustomValidators();
	}, []);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				refetchOnMount: true,
				refetchOnReconnect: false,
				retry: false,
				staleTime: 86400000,
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider accessToken={accessToken}>
				<CommonDataLoadingProvider>
					<MantineProvider>
						<MainRouter />
						<ReactQueryDevtools initialIsOpen={false} />
					</MantineProvider>
				</CommonDataLoadingProvider>
			</AuthContextProvider>
		</QueryClientProvider>
	);
};

export default App;
