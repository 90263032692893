import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import FormInputError from "~/components/formElements/FormInputError";
import type { ForwardedRef, FunctionComponent } from "react";
import { forwardRef, Fragment, useCallback, useMemo, useState } from "react";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import Label from "~/components/formElements/Label";

type SharedProps = FormInputBaseVariants & {
	disabled?: boolean;
	label?: string;
	error?: string;
	defaultValue?: number;
};

type MinuteSelectPlainProps = SharedProps & {
	name?: string;
	value?: number | null;
	onChange?: (value: number) => void;
};

export const MinuteSelectPlain = forwardRef(
	(
		{
			defaultValue,
			disabled,
			error,
			label,
			name,
			onChange,
			value,
		}: MinuteSelectPlainProps,
		ref: ForwardedRef<any>,
	) => {
		const [internalValue, setInternalValue] = useState<number>(defaultValue || 0);
		const currentValue = value !== undefined ? value : internalValue;
		const setCurrentValue = onChange || setInternalValue;

		const minuteSelectOptions = useMemo(() => {
			return [{
				label: "00",
				value: 0,
			},
				{
					label: "15",
					value: 15,
				},
				{
					label: "30",
					value: 30,
				},
				{
					label: "45",
					value: 45,
				},
			];
		}, []);

		const getSelectedLabel = useCallback(
			(value: number | null): string => {
				if (!value) {
					return minuteSelectOptions[0].label;
				}
				return (
					minuteSelectOptions.find((option) => option.value === value)!.label
				);
			},
			[minuteSelectOptions],
		);

		return (
			<Listbox name={name}
					 value={currentValue}
					 onChange={setCurrentValue}
					 disabled={disabled}
					 ref={ref}>
				{({ open }) => (
					<FormInput>
						{label && <Label>{label}</Label>}
						<div className="relative">
							<ListboxButton
								className="block w-full min-w-[4.5rem] rounded-md bg-white px-3 py-2.5 pr-8
								text-center text-xl text-primary-900
								ring-1 ring-inset ring-gray-300 focus:ring-2
								focus:ring-inset focus:ring-blue-500"
							>
								<span className="block truncate">{getSelectedLabel(value as number)}</span>
								{!disabled && (
									<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
										<ChevronUpDownIcon className="size-5 text-gray-400"
														   aria-hidden="true" />
									</span>
								)}
							</ListboxButton>
							{error && <FormInputError>{error}</FormInputError>}
							{minuteSelectOptions?.length > 0 && (
								<Transition
									show={open}
									as={Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<ListboxOptions
										className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
											text-sm
											shadow-lg
											ring-1 ring-black/5
											focus:outline-none"
									>
										{minuteSelectOptions.map((option, index) => (
											<ListboxOption
												key={`${option.value}_${index}`}
												className={({ focus }) =>
													clsx(
														focus ? "bg-accent-500 text-white" : "text-gray-900",
														"relative cursor-default select-none py-2 pl-3 pr-9",
													)
												}
												value={option.value}
											>
												{({ selected, focus }) => (
													<>
														<span
															className={clsx(
																selected ? "font-semibold" : "font-normal",
																"block truncate",
															)}
														>
															{option.label}
														</span>
														{selected ? (
															<span
																className={clsx(
																	focus ? "text-white" : "text-indigo-600",
																	"absolute inset-y-0 right-0 flex items-center pr-4",
																)}
															>
																<CheckIcon className="size-5"
																		   aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</ListboxOption>
										))}
									</ListboxOptions>
								</Transition>
							)}
						</div>
					</FormInput>
				)}
			</Listbox>
		);
	},
);

MinuteSelectPlain.displayName = "MinuteSelectPlain";

type SelectProps = SharedProps & {
	name: string;
	control: any; // from react-hook-form
	rules?: any; // validation rules from react-hook-form
};

export const MinuteSelect: FunctionComponent<SelectProps> = ({ control, rules, defaultValue, name, ...props }) => {
	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});

	return <MinuteSelectPlain {...field} {...props} error={error?.message || props.error} />;
};

export default MinuteSelect;
