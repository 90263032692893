import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import ComboBox from "~/components/formElements/ComboBox";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import BudgetFormSection from "~/components/formSections/BudgetFormSection";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import PlaceOfPerformanceFormSection from "~/components/formSections/PlaceOfPerformanceFormSection";
import TitleFormSection from "~/components/formSections/TitleFormSection";
import RadioGroup from "~/components/formElements/RadioGroup";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useApprovalLikelihoodOptions from "~/hooks/form/formOptionsData/useApprovalLikelihoodOptions.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import useUserSelectOptions, { allActiveUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectPhaseTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectPhaseTitleValidationComparisonData.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { updateProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import type { ProjectPhase, UpdateProjectPhaseData } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { WorkingStatusId } from "~/types/entityIds.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToZero } from "~/utils/form/formUtils.ts";

type CreateProjectPhaseFormProps = {
	confirmedBudgetCents: number;
	confirmedManDays: number;
	phaseData: ProjectPhase;
	phaseHasConfirmedOrders: boolean;
	onSuccess: () => void;
	onCancel: () => void;
};

interface UpdateProjectPhaseFormData extends Omit<UpdateProjectPhaseData, "projectId"> {
}

const UpdateProjectPhaseForm: React.FC<CreateProjectPhaseFormProps> = ({
		confirmedBudgetCents,
		confirmedManDays,
		phaseData,
		phaseHasConfirmedOrders,
		onSuccess,
		onCancel,
	}) => {
		//const { t } = useTranslation();
		const [busy, setBusy] = useState(false);
		const titleComparisonData = useProjectPhaseTitleValidationComparisonData(phaseData.projectId);
		const { data: locationData } = useAllLocations();

		const schema = useMemo(() => {
			return yup.object({
				approvalLikelihoodId: yup.string().required(),
				budgetCentsPlanned: yup.number().required(),
				comment: yup.string().default(null),
				countryId: yup.string().required(),
				locationName: yup.string().required(),
				manDaysPlanned: yup.number().required().transform(transformEmptyDateValueToZero).min(1, "Dieses Feld darf nicht leer sein"),
				phaseManagedBy: yup.string().required(),
				phaseLeadBy: yup.string().nullable().default(null),
				title: yup.string().required().unique(titleComparisonData, phaseData.id),
			});
		}, [titleComparisonData, phaseData]);

		const defaultValues = useMemo(() => {
			const budgetCentsPlanned = phaseHasConfirmedOrders ? confirmedBudgetCents : phaseData.budgetCentsPlanned || 0;
			const manDaysPlanned = phaseHasConfirmedOrders ? confirmedManDays : parseFloat(phaseData.manDaysPlanned) ||0;

			const defaultValues = {
				approvalLikelihoodId: phaseData.approvalLikelihoodId || "1",
				budgetCentsPlanned,
				comment: phaseData.comment || "",
				countryId: !phaseData.countryId ? "" : phaseData.countryId,
				locationName: "",
				manDaysPlanned,
				phaseManagedBy: phaseData.phaseManagedBy || "",
				phaseLeadBy: phaseData.phaseLeadBy || "",
				title: phaseData.title,
			};
			if (locationData) {
				const location = locationData.find((location) => location.id === phaseData.locationId);
				if (location) {
					defaultValues.locationName = location.displayName;
				}
			}

			return defaultValues;
		}, [phaseData, locationData]);

		const {
			handleSubmit,
			control,
			formState: { errors, isDirty, isValid, isSubmitted },
			trigger,
			watch,
		} = useForm<UpdateProjectPhaseFormData>({
			mode: "onChange",
			defaultValues: defaultValues,
			resolver: yupResolver<UpdateProjectPhaseFormData>(schema),
		});

		const ignoreIds = useMemo(() => {
			return [phaseData.phaseManagedBy, phaseData.phaseLeadBy].filter(Boolean) as string[];
		}, [phaseData.phaseManagedBy, phaseData.phaseLeadBy]);

		const approvalLikelihoodOptions = useApprovalLikelihoodOptions();
		const countrySelectOptions = useCountrySelectOptions();
		const managerSelectOptions = useUserSelectOptions({
			filterFunction: allActiveUsersFilterFn,
			ignoreIds,
		});
		const locationOptions = useLocationOptions();

		const showApprovalLikelihoodInput = phaseData.workingStatusId === WorkingStatusId.Quotation;
		const formIsSubmittable = useFormIsSubmittable({
			isDirty,
			isSubmitted,
			isValid,
			isLoading: busy,
			enableInitially: false,
		});

		useEffect(() => {
			trigger();
		}, [trigger]);

		const onSubmit: SubmitHandler<UpdateProjectPhaseFormData> = async (data: UpdateProjectPhaseFormData) => {
			setBusy(true);

			const updatedPhaseData = {
				...data,
				projectId: phaseData.projectId,
			};

			try {
				await updateProjectPhase({
					projectId: phaseData.projectId,
					projectPhaseId: phaseData.id,
					phaseData: updatedPhaseData,
				});
				onSuccess();
			} catch (error) {
				console.log(error);
			}
		};

		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				onKeyDown={preventSubmitOnEnter}
				className="flex min-h-full w-full flex-col justify-start"
			>
				<SidebarHeader>
					<SidebarHeaderHeadline>Projektphase bearbeiten</SidebarHeaderHeadline>
				</SidebarHeader>
				<SidebarContent>
					{busy && <SidebarBusyOverlay />}
					<FormSectionGroup>
						<TitleFormSection
							control={control}
							title="Name" />

						{showApprovalLikelihoodInput && <FormSection title="Bewertung">
							<div className="grid grid-cols-6 gap-x-6 gap-y-2">
								<div className="col-span-6">
									<RadioGroup
										control={control}
										name="approvalLikelihoodId"
										options={approvalLikelihoodOptions}
										label="Bewertung"
									/>
								</div>
							</div>
						</FormSection>}
						<BudgetFormSection control={control}
										   watch={watch}
										   showsConfirmedValues={phaseHasConfirmedOrders}
										   title={phaseHasConfirmedOrders ? "Budget":"Plandaten"}
										   errors={errors} />

						<PlaceOfPerformanceFormSection
							errors={errors}
							control={control}
							locationOptions={locationOptions}
							countrySelectOptions={countrySelectOptions}
						/>

						<FormSection title="Angebot Manager">
							<div className="grid grid-cols-6 gap-x-6 gap-y-2">
								<div className="col-span-6">
									<ComboBox
										allowNew={false}
										name="phaseManagedBy"
										optionsData={managerSelectOptions}
										control={control}
									/>
								</div>
							</div>
						</FormSection>
						<FormSection title="Projektleitung">
							<div className="grid grid-cols-6 gap-x-6 gap-y-2">
								<div className="col-span-6">
									<ComboBox
										allowNew={false}
										name="phaseLeadBy"
										optionsData={managerSelectOptions}
										control={control}
									/>
								</div>
							</div>
						</FormSection>
						<CommentFormSection
							control={control}
							placeholder={"Hier können allgemeine Anmerkungen zur Projektphase hinterlegt werden."}
							label="Kommentar"
							title="Anmerkungen"
						/>
					</FormSectionGroup>
				</SidebarContent>
				<SidebarFooter>
					<SubmitButton busy={busy}
								  disabled={!formIsSubmittable}>
						{t(normalizeTKey("form:buttonTexts.save"))}
					</SubmitButton>
					<Button theme="none"
							onClick={onCancel}>
						{t(normalizeTKey("form:buttonTexts.cancel"))}
					</Button>
				</SidebarFooter>
			</form>
		);
	}
;

export default UpdateProjectPhaseForm;
