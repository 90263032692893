import type { Control, FieldValues } from "react-hook-form";

import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import Input from "~/components/formElements/Input";
import type { PaymentTargetDaysInputFormData } from "~/components/formElements/PaymentTargetInput/paymentTargetDaysInputTypes.ts";
import RadioGroup from "~/components/formElements/RadioGroup";
import { PAYMENT_TARGET_DAYS_OPTIONS } from "~/constants/form.ts";

type PaymentTargetDaysInputProps<T extends FieldValues> = FormInputBaseVariants & {
	className?: string;
	control: Control<T>;
	showCustomPaymentTargetInput: boolean;
};

const PaymentTargetDaysInput = <T extends PaymentTargetDaysInputFormData>({
	className,
	control,
	showCustomPaymentTargetInput,
}: PaymentTargetDaysInputProps<T>) => {
	return (
		<div className={className}>
			<RadioGroup
				control={control}
				label="Zahlungsziel"
				name="paymentTargetDays"
				options={PAYMENT_TARGET_DAYS_OPTIONS}
				numCols={3}
			/>
			{showCustomPaymentTargetInput && (
				<div className="mt-4">
					<Input
						placeholder="90 Tage"
						name="paymentTargetDaysCustom"
						type="number"
						label="Individuelles Zahlungsziel"
						control={control}
					/>
				</div>
			)}
		</div>
	);
};

export default PaymentTargetDaysInput;
