import clsx from "clsx";

type VacationEntitlementssTableProps = {
	children?: React.ReactNode;
	compactView?: boolean;
};

const VacationEntitlementsTable: React.FunctionComponent<VacationEntitlementssTableProps> = ({ children }) => {
	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col"
					className={clsx("py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900")}>
					Gültig ab
				</th>
				<th scope="col"
					className={clsx("py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900")}>
					Urlaubstage (Vollzeit)
				</th>

				<th scope="col"
					className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>
			</tr>
			</thead>
			<tbody className="divide-y divide-gray-200">{children}</tbody>
		</table>
	);
};


export default VacationEntitlementsTable;
