import { useMemo } from "react";

import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import type { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";

type CompanyValidationComparisonData = {
	displayNameComparisonData: UniqueValidatorArrayObject[];
	corporateNameComparisonData: UniqueValidatorArrayObject[];
	shortNameComparisonData: UniqueValidatorArrayObject[];
}

export const useCompanyValidationComparisonData = (): CompanyValidationComparisonData => {
	const {
		data: allCompaniesData,
	} = useAllCompanies();

	const { displayNameComparisonData, corporateNameComparisonData, shortNameComparisonData } = useMemo(() => {
		if (allCompaniesData) {
			return {
				displayNameComparisonData: allCompaniesData.map((company: Company) => {
					return {
						id: company.id,
						value: sanitizeStringForComparison(company.displayName),
					};
				}),
				corporateNameComparisonData: allCompaniesData.map((company: Company) => {
					return {
						id: company.id,
						value: sanitizeStringForComparison(company.corporateName),
					};
				}),
				shortNameComparisonData: allCompaniesData.map((company: Company) => {
					return {
						id: company.id,
						value: sanitizeStringForComparison(company.shortName),
					};
				}),
			};
		}
		return {
			displayNameComparisonData: [],
			corporateNameComparisonData: [],
			shortNameComparisonData: [],
		};
	}, [allCompaniesData]);

	return {
		displayNameComparisonData,
		corporateNameComparisonData,
		shortNameComparisonData,
	};
};