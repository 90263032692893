import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { GetUsersMonthlyClosingsFilterType, MonthlyClosingType } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingTypes.ts";

export function getUsersMonthlyClosings(userId: string, filter?: GetUsersMonthlyClosingsFilterType): AxiosApiResponsePromise<MonthlyClosingType[]> {
	return axiosApi.get(`users/${userId}/monthly-closings`, { params: filter });
}

export function createMonthlyClosing({ userId, month }: {
	userId: string,
	month: string
}): AxiosApiResponsePromise<MonthlyClosingType> {
	return axiosApi.post(`users/${userId}/monthly-closings`, { month });
}

export function deleteMonthlyClosing({ userId, monthlyClosingId }: {
	userId: string,
	monthlyClosingId: string
}): AxiosApiResponsePromise<MonthlyClosingType> {
	return axiosApi.delete(`users/${userId}/monthly-closings/${monthlyClosingId}`);
}
