import VacationLedgerView from "modules/absence/components/VacationLedgerView";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedData } from "~/hooks/useAggregatedData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import {
	useStaffMembersVacationLedgerEntries, useStaffMembersVacationLedgerReport,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import type { VacationLedgerEntryType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";
import { sortArray } from "~/utils/sortFunctions.ts";


const VacationLedgerPage: React.FunctionComponent = () => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences, "staffMember");
	if (viewGuard) return viewGuard;
	const { staffMemberId } = useParams();

	const { data, options } = useAggregatedData([
		{
			hook: () => useStaffMembersVacationLedgerEntries({
				staffMemberId: staffMemberId || "",
				options: { enabled: !!staffMemberId },
			}),
			name: "vacationLedgerEntries",
		},
		{ hook: () => useAllAbsenceTypes(), name: "absenceTypes" },
		{ hook: () => useAllUsers(), name: "users" },
		{
			hook: () => useStaffMembersVacationLedgerReport({
				staffMemberId: staffMemberId || "",
				options: { enabled: !!staffMemberId },
			}),
			name: "vacationLedgerReport",
		},
	]);
	const {
		vacationLedgerEntries,
		vacationLedgerReport,
		users,
	} = useMemo(() => data, [data]);

	const availableYears = useMemo(() => {
		const currentYear = new Date().getFullYear().toString();
		if (vacationLedgerEntries) {
			return sortArray(vacationLedgerEntries.reduce((years: string[], entry: VacationLedgerEntryType) => {
				const entryYear = new Date(entry.validFrom).getFullYear().toString();
				if (!years.includes(entryYear)) years.push(entryYear);
				return years;
			}, [currentYear]), "desc");
		}
		return [vacationLedgerEntries];
	}, [vacationLedgerEntries]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	if (!staffMemberId) return null;

	return <VacationLedgerView
		availableYears={availableYears}
		staffMemberId={staffMemberId}
		users={users}
		vacationLedgerEntries={vacationLedgerEntries}
		vacationLedgerReport={vacationLedgerReport}
	/>;
};

export default VacationLedgerPage;