import type React from "react";

import Headline from "~/components/Headline";

const Error404Page: React.FC = () => {


	return (
		<div id="error-page" className="flex h-screen flex-col items-center justify-center gap-8">
			<Headline type="h1">404</Headline>
			<p>Diese Seite gibt es nicht.</p>
			{/*
            <p className="text-slate-400">
				<i>{errorMessage}</i>
			</p>
            */}
		</div>
	);
};

export default Error404Page;
