import type { Table as TableType } from "@tanstack/react-table";
import clsx from "clsx";
import DefaultTableBodyContent from "components/Table/components/DefaultTableBodyContent";
import DefaultTableHeaderContent from "components/Table/components/DefaultTableHeaderContent";
import EmptyTableMessage from "components/Table/components/EmptyTableMessage";
import type { PropsWithChildren } from "react";

import DefaultTableFooterContent from "~/components/Table/components/DefaultTableFooterContent";
import TableBody from "~/components/Table/components/TableBody";
import TableFooter from "~/components/Table/components/TableFooter";
import TableHeader from "~/components/Table/components/TableHeader";
import TableWrapper from "~/components/Table/components/TableWrapper";
import type { TableHeaderBgTheme } from "~/components/Table/tableTypes.ts";


type TableProps<T> = PropsWithChildren & {
	fixedWidth?: boolean
	hasFilterBar?: boolean,
	headerBg?: TableHeaderBgTheme,
	horizontalBorders?: boolean,
	rounded?: boolean,
	table: TableType<T>,
	verticalBorders?: boolean,
};

const Table = <T, >({
	fixedWidth,
	hasFilterBar = false,
	headerBg = "light",
	horizontalBorders = true,
	table,
	rounded = true, children,
	verticalBorders = false,
}: TableProps<T>) => {
	const hasVisibleRows = table.getRowModel().rows.length > 0 || table.getFilteredRowModel()?.rows.length > 0;
	const hasFooter = table.getFooterGroups().length > 0;
	return <TableWrapper rounded={rounded}
						 className={clsx(fixedWidth && hasVisibleRows && "table-fixed")}
	>
		<TableHeader bgColor={headerBg}
					 hasFilterBar={hasFilterBar}>
			{children}
			<DefaultTableHeaderContent rounded={rounded}
									   table={table} />
		</TableHeader>
		<TableBody horizontalBorders={horizontalBorders}>
			<DefaultTableBodyContent table={table}
									 verticalBorders={verticalBorders} />
			<EmptyTableMessage table={table} />
		</TableBody>
		{hasFooter && <TableFooter>
			<DefaultTableFooterContent table={table}
									   verticalBorders={verticalBorders} />
		</TableFooter>}
	</TableWrapper>;
};

export default Table;