import type React from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteAvatarImage } from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import { STAFF_MEMBER_QUERY_KEY } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";

type DeleteStaffingModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	staffMemberId: string;
};

const DeleteAvatarImageModal: React.FunctionComponent<DeleteStaffingModalProps> = ({
	isOpen,
	onCloseClick,
	staffMemberId,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleCloseClick = useCallback(() => {
		setBusy(false);
		setError("");
		onCloseClick();
	}, [onCloseClick]);

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteAvatarImage(staffMemberId);
			await queryClient.refetchQueries(STAFF_MEMBER_QUERY_KEY(staffMemberId));

			handleCloseClick();
		} catch (error) {
			console.error("Delete failed:", error);
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: "Profilbild löschen",
		bodyText: `Möchtest Du das Profilbild wirklich löschen?`,
		deleteButtonText: `Profilbild löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={handleCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteAvatarImageModal;
