import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Switch from "~/components/formElements/Switch";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { createUserLocation } from "~/modules/location/api/location/locationApiDispatchers.ts";
import { USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import type { CreateUserLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";


type CreateUserLocationForm = {
	userId: string,
	projectId: string | null | undefined,
	projectTitle: string | null | undefined,
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum) => void;
	timeTrackingTypeId: string,
};


type CreateUserLocationFormData = {
	displayName: string;
	isFavorite: boolean;
};
const CreateUserLocationForm: React.FunctionComponent<CreateUserLocationForm> = ({
	userId,
	projectId,
	projectTitle,
	setCurrentFormName,
	timeTrackingTypeId,
}) => {
	const queryClient = useQueryClient();
	const [busy, setBusy] = useState(false);
	const schema = useMemo(() => {
		return yup.object().shape({
			displayName: yup.string().required(),
			isFavorite: yup.boolean().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			displayName: "",
			isFavorite: false,
		};
	}, []);

	const {
		control,
		handleSubmit,
		formState: { isValid, isSubmitted },
	} = useForm<CreateUserLocationFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<CreateUserLocationFormData>(schema),
	});
	let titleDeliverableFor = "";
	switch (timeTrackingTypeId) {
		case("1"):
			titleDeliverableFor = `Projekt "${projectTitle}"`;
			break;
		case("2"):
			titleDeliverableFor = "Erfassungen vom Typ \"Intern\"\"";
			break;
		case("3"):
			titleDeliverableFor = "Erfassungen vom Typ \"Sales\"\"";
			break;
	}

	const onSubmit = async (formData: CreateUserLocationFormData) => {
		setBusy(true);
		try {

			const locationData: CreateUserLocationType = {
				locationName: formData.displayName,
				isFavorite: formData.isFavorite,
				projectId: projectId,
				timeTrackingTypeId: timeTrackingTypeId,
			};

			await createUserLocation({ userId, locationData });
			await queryClient.invalidateQueries(USER_LOCATIONS_QUERY_KEY(userId));
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		} catch (error) {
			console.log(error);
		}
	};


	return (

		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Ort für {titleDeliverableFor} erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<div className="grid grid-cols-6 gap-x-6 gap-y-2">
					<div className="col-span-6">
						<Input
							name="displayName"
							autoFocus={true}
							label={"Ort"}
							placeholder="Hamburg"
							control={control}
						/>
					</div>
					<div className="mt-4">
						<Switch name={"isFavorite"}
							control={control}
							label={"Favorit"} />
					</div>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<Button className="mr-auto"
					theme="accent"
					onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					<ChevronLeftIcon className="mx-2 size-6" />
				</Button>
				<FormHasErrorsHint show={isSubmitted && !isValid}
					className="mr-2" />
				<SubmitButton busy={busy}
					disabled={!isValid || busy}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
					onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateUserLocationForm;
