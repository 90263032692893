import {ReactComponent as Icon} from "./excelIcon.svg"

type ExcelIconProps = {
	className?: string;
};

const ExcelIcon: React.FunctionComponent<ExcelIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default ExcelIcon;