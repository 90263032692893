import type { FunctionComponent } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Headline from "~/components/Headline";
import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";


interface BillableProjectCardProps extends React.PropsWithChildren {
	projectId: string;
	projectNumber: string;
	projectTitle: string;
}

const BillableProjectCard: FunctionComponent<BillableProjectCardProps> = ({
	projectId,
	projectNumber,
	projectTitle,
	children,
}) => {
	return <div className="w-full rounded bg-gray-200 p-4">
		<div className="mb-2 flex justify-between">
			<NavLink to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId })}>
				<Headline type="h4"
						  className="text-gray-700 hover:text-gray-500">
					{projectTitle} ({projectNumber})
				</Headline>
			</NavLink>
		</div>
		<div className="flex flex-col gap-y-4">
			{children}
		</div>
	</div>;
};


export default BillableProjectCard;