import { DocumentTextIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent, PropsWithChildren } from "react";


type Props = PropsWithChildren & {
	title: string;
	url: string;
}
const DocumentListItem: FunctionComponent<Props> = ({ children, title, url }) => {
	return <div className="group flex w-full flex-row items-center">
		<a href={url}
		   target="_blank"
		   className="flex w-full flex-row items-center gap-x-4 rounded border border-gray-300 p-2 text-sm text-gray-800 group-hover:border-danger-500 group-hover:text-danger-500"
		   rel="noreferrer">
			<DocumentTextIcon className="size-6 fill-white stroke-gray-500 group-hover:stroke-danger-500" />
			{title}

		</a>
		<div>
			{children}
		</div>
	</div>;
};

export default DocumentListItem;