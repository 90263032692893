import { t } from "i18next";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import Avatar from "~/components/Avatar";
import Button from "~/components/buttons/Button";
import SidebarBackButton from "~/components/Sidebar/components/SidebarBackButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import {
	indexStaffMembersEligibleForUserCreation,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMemberUserData } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import EligibleStaffMembersMessage
	from "~/modules/user/components/components/CreateUserSidebar/components/StaffMemberSelector/EligibleStaffMembersMessage";
import NoEligibleStaffMembersFoundMessage
	from "~/modules/user/components/components/CreateUserSidebar/components/StaffMemberSelector/NoEligibleStaffMembersFoundMessage";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	setSelectedStaffMemberData: (data: StaffMemberUserData | null) => void;
	onBackClick: () => void;
	onCancelClick: () => void;
};

const StaffMemberSelector: FunctionComponent<Props> = ({ setSelectedStaffMemberData, onBackClick, onCancelClick }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [eligibleStaffMemberData, setEligibleStaffMemberData] = useState<StaffMemberUserData[] | undefined>(undefined);

	useAsyncEffect(async () => {
		setIsLoading(true);
		try {
			const response = await indexStaffMembersEligibleForUserCreation();
			setEligibleStaffMemberData(getDataFromResponse(response));
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}

	}, []);

	const staffMemberListItems = useMemo(() => {
		if (eligibleStaffMemberData) {
			return eligibleStaffMemberData.sort(byObjectProperty("firstName")).map((staffMember) => {
				const careerLevel = getCareerLevelDisplayNameById(staffMember.careerLevelId as CareerLevelId);
				const employmentType = getEmploymentTypeDisplayNameById(staffMember.employmentTypeId as EmploymentTypeId);
				return <button key={staffMember.staffMemberId}
							   className="group flex w-full rounded-lg border border-gray-300 no-underline hover:bg-primary-500"
							   onClick={() => setSelectedStaffMemberData(staffMember)}>
					<div className="py-5 pl-4 pr-3 text-sm">
						<div className="flex items-center">
							<Avatar firstName={staffMember.firstName}
									lastName={staffMember.lastName}
									image={staffMember.avatarImage}
							/>
							<div className="ml-4 flex flex-col">
								<div className="text-left font-medium text-gray-900 group-hover:text-white">{`${staffMember.firstName} ${staffMember.lastName}`}</div>
								<div className="mt-1 text-gray-500 group-hover:text-gray-300">{careerLevel + " / " + employmentType}</div>
							</div>
						</div>
					</div>
				</button>;
			});
		}
		return [];
	}, [eligibleStaffMemberData, setSelectedStaffMemberData]);

	return (
		<div className="flex min-h-full w-full flex-col justify-start">
			<SidebarHeader>
				<SidebarHeaderHeadline>Mitarbeiter:in auswählen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{isLoading ? <SidebarBusyOverlay /> :
					<>{!isLoading && staffMemberListItems.length === 0 ?
						<NoEligibleStaffMembersFoundMessage />
						: <EligibleStaffMembersMessage />}
						<div className="mt-8 flex flex-col gap-y-4">
							{!isLoading && staffMemberListItems}
						</div>
					</>}
			</SidebarContent>
			<SidebarFooter>
				<SidebarBackButton onClick={onBackClick} />
				<Button theme="none"
						onClick={onCancelClick}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</div>);
};

export default StaffMemberSelector;