import type { FunctionComponent } from "react";

import Button from "~/components/buttons/Button";

type Props = {
	onClose: () => void
};

const StatusUpdateSuccessMessage: FunctionComponent<Props> = ({ onClose }) => {
	return <div className="px-8 py-6">
		<div className="mb-4 text-center text-xl font-bold text-gray-800">Status erfolgreich
			geändert!
		</div>
		<div className="flex flex-col space-y-4">
			<Button className="justify-center"
					onClick={onClose}>
				Schliessen
			</Button>
		</div>
	</div>;
};

export default StatusUpdateSuccessMessage;