import { useMemo } from "react";

import type { FormInputOption } from "~/types/form.ts";
import { getMonthNameFromNumber } from "~/utils/dateAndTimeUtils.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";


const useMonthSelectOptions = (): FormInputOption[] => {
	return useMemo(() => {
		const options: FormInputOption[] = [];

		for (let i = 0; i < 12; i++) {
			const monthName = getMonthNameFromNumber(i);
			options.push(createInputOptionData(monthName, i.toString()));
		}
		return options;

	}, []);
};

export default useMonthSelectOptions;

