import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import type { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & { onEditClick: () => void, onDeleteClick?: () => void, imageType: "avatar" | "logo" };

const AvatarEditButton: FunctionComponent<Props> = ({ children, onEditClick, onDeleteClick, imageType = "avatar" }) => {
	const buttonContent = <>
		{children}
		<div className="absolute bottom-[4px] right-[-7px] size-6">
			<div className="relative">
				<PencilIcon className="absolute size-6 stroke-white stroke-[4px]" />
				<PencilIcon className="absolute size-6 stroke-black" />
			</div>
		</div>
	</>;

	if (!onDeleteClick) {
		return <button type="button"
					   className="relative"
					   onClick={onEditClick}>
			{buttonContent}
		</button>;
	}

	const imageLabel = imageType === "avatar" ? "Profilbild" : "Logo";

	return <Popover className="relative flex items-center">
		<PopoverButton className="relative">
			{buttonContent}
		</PopoverButton>
		<PopoverPanel anchor="bottom start"
					  className="absolute right-0 z-40 mt-2 w-56 origin-top-right scale-100 rounded-md bg-white opacity-100 shadow-lg ring-1 ring-black/5 transition duration-200 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
			<div className="flex flex-col">
				<button type="button"
						onClick={onEditClick}
						className="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
				>
					<PencilIcon className="mr-3 size-5 text-gray-400"
								aria-hidden="true" />
					<span>{imageLabel} bearbeiten</span>
				</button>
				<button type="button"
						onClick={onDeleteClick}
						className="flex cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
				>
					<TrashIcon className="mr-3 size-5 text-gray-400"
								aria-hidden="true" />
					<span>{imageLabel} löschen</span>
				</button>
			</div>
		</PopoverPanel>
	</Popover>;
};

export default AvatarEditButton;