import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageQueries.ts";
import type {
	StaffMemberVariableCompensationPercentageWithDate,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageTypes.ts";
import UpdateVariableCompensationPercentageEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateVariableCompensationPercentageEntrySidebar/components/UpdateVariableCompensationPercentageEntryForm";

type CreateEmploymentSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	variableCompensationPercentageData: StaffMemberVariableCompensationPercentageWithDate | null;
};

const UpdateVariableCompensationPercentageEntrySidebar: FunctionComponent<CreateEmploymentSidebarProps> = ({
	isOpen,
	onClose,
	variableCompensationPercentageData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!variableCompensationPercentageData) return;
		await queryClient.refetchQueries(STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY(variableCompensationPercentageData.staffMemberId));
		onClose();
	}, [variableCompensationPercentageData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{variableCompensationPercentageData && <UpdateVariableCompensationPercentageEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				variableCompensationPercentageEntryData={variableCompensationPercentageData} />}
		</Sidebar>
	);
};

export default UpdateVariableCompensationPercentageEntrySidebar;
