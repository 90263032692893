import type { SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { endOfMonth, isAfter, isBefore, isSameMonth, isSameYear, startOfMonth } from "date-fns";
import type React from "react";
import { useEffect, useMemo, useState } from "react";

import Card from "~/components/Card";
import ContentWrapper from "~/components/ContentWrapper";
import DefaultTableBodyContent from "~/components/Table/components/DefaultTableBodyContent";
import DefaultTableHeaderContent from "~/components/Table/components/DefaultTableHeaderContent";
import EmptyTableMessage from "~/components/Table/components/EmptyTableMessage";
import TableBody from "~/components/Table/components/TableBody";
import TableFooter from "~/components/Table/components/TableFooter";
import TableHeader from "~/components/Table/components/TableHeader";
import TableWrapper from "~/components/Table/components/TableWrapper";
import { PROJECT_DETAILS_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import DateFilter
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/components/DateFilter";
import DownloadProjectsTimeTrackingsTableDataButton
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/components/DownloadProjectsTimeTrackingsTableDataButton";
import MultiUserFilter
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/components/MultiUserFilter";
import PhaseFilter
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/components/PhaseFilter";
import {
	projectsTimeTrackingTableColumnDefs,
} from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable/projectsTimeTrackingsTableColumnDefs.tsx";
import type {
	ProjectDetailsTimeTrackingsPageState,
	ProjectsTimeTrackingsTableData,
} from "~/modules/project/types/projectViewTypes.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import type { ColumnFilterType } from "~/types/tanstackTableTypes.ts";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";
import { formatHoursAndMinutes } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import {
	createColumnFilterInputHandler,
	getColumnFilterValueForKey,
	sanitiseColumnFilters,
} from "~/utils/tanStackTableUtils.ts";

type ProjectsTimeTrackingsTableProps = {
	availableUserIds: string[];
	maxDate: Date | null;
	minDate: Date | null;
	orders: Order[];
	phases: ProjectPhase[];
	projectData: Project;
	tableData: ProjectsTimeTrackingsTableData[];
	users: User[];
};

const defaultPageState: ProjectDetailsTimeTrackingsPageState = {
	columnFilters: [{ id: "userId", value: [] },
		{ id: "phaseId", value: [] },
		{
			id: "date",
			value: [startOfMonth(new Date()), endOfMonth(new Date())],
		},
	],
	sorting: [{ id: "date", desc: true }],
};

const ProjectsTimeTrackingsTable: React.FunctionComponent<ProjectsTimeTrackingsTableProps> = ({
	availableUserIds,
	maxDate,
	minDate,
	phases,
	projectData,
	tableData,
}) => {
	const { pageState, setPageState } = usePageStateStorage(
		{ pageKey: PROJECT_DETAILS_PAGE_STATE_KEY(projectData.id), defaultState: defaultPageState });

	const [columnFilters, setColumnFilters] = useState<ColumnFilterType[]>(
		sanitiseColumnFilters(defaultPageState.columnFilters, pageState.columnFilters, ["date"]));

	const [sorting, setSorting] = useState<SortingState>(pageState.sorting);
	const [useVariableDateRange, setUseVariableDateRange] = useState<boolean>(false);

	const handleColumnFilterInputChange = createColumnFilterInputHandler(columnFilters, setColumnFilters);

	useEffect(() => {
		setPageState({ columnFilters, sorting });
	}, [columnFilters, setPageState, sorting]);

	useEffect(() => {
		if (!minDate || !maxDate || !columnFilters) return;
		const dateFilter = columnFilters.find((filter) => filter.id === "date");
		if (dateFilter && Array.isArray(dateFilter.value) && dateFilter.value.length === 2) {
			const startDate = dateFilter.value[0];
			const endDate = dateFilter.value[1];
			let newStartDate = startDate;
			let newEndDate = endDate;
			let updateDates = false;
			if (useVariableDateRange) {
				if (isBefore(startDate, minDate)) {
					newStartDate = minDate;
					updateDates = true;
				}
				if (isAfter(endDate, maxDate)) {
					newEndDate = maxDate;
					updateDates = true;
				}
			} else {
				if (isBefore(startDate, minDate) && !isSameMonth(startDate, minDate) && !isSameYear(startDate, minDate)) {
					newStartDate = minDate;
					updateDates = true;
				}

				if (isAfter(endDate, maxDate) && !isSameMonth(endDate, maxDate) && !isSameYear(endDate, maxDate)) {
					updateDates = true;
					newEndDate = maxDate;
				}
			}

			if (updateDates) {

				handleColumnFilterInputChange("date", [newStartDate, newEndDate]);
			}
		}
	}, [columnFilters, handleColumnFilterInputChange, maxDate, minDate, useVariableDateRange]);

	const table = useReactTable({
		data: tableData,
		columns: projectsTimeTrackingTableColumnDefs,
		state: {
			columnFilters,
			sorting,
		},
		meta: {
			emptyTableDataMessage: "Für dieses Projekt sind keine Erfassungen vorhanden",
		},
		onSortingChange: setSorting,
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		sortDescFirst: true,
	});

	const [filteredTotalCents, filteredTotalMinutes, filteredTableData] = useMemo(() => {
		const filteredTableData = table.getFilteredRowModel().rows.map(row => row.original);

		const { filteredTotalCents, filteredTotalMinutes } = filteredTableData.reduce((acc: {
				filteredTotalCents: number,
				filteredTotalMinutes: number
			},
			timeTracking: ProjectsTimeTrackingsTableData) => {
			return {
				filteredTotalCents: acc.filteredTotalCents + timeTracking.totalAmountCents,
				filteredTotalMinutes: acc.filteredTotalMinutes + timeTracking.minutesTracked,
			};
		}, { filteredTotalCents: 0, filteredTotalMinutes: 0 });

		return [filteredTotalCents, filteredTotalMinutes, filteredTableData];
	}, [table, columnFilters]);

	const startDate = getColumnFilterValueForKey("date", columnFilters)[0];
	const endDate = getColumnFilterValueForKey("date", columnFilters)[1];
	const userIds = getColumnFilterValueForKey("userId", columnFilters);
	const phaseIds = getColumnFilterValueForKey("phaseId", columnFilters);
	const tableHasVisibleRows = table.getRowModel().rows.length > 0 || table.getFilteredRowModel().rows.length > 0;

	return <div className="flex max-h-full flex-col overflow-hidden">
		<ContentWrapper className="isolate z-30 w-full pt-4">
			<Card padding="none">
			<div className="flex flex-row items-center justify-start gap-x-8 px-4 py-2">
				<MultiUserFilter availableUserIds={availableUserIds}
								 filterIds={getColumnFilterValueForKey("userId", columnFilters)}
								 onChange={(value) => handleColumnFilterInputChange("userId", value)} />
				<PhaseFilter projectPhases={phases}
							 filterIds={getColumnFilterValueForKey("phaseId", columnFilters)}
							 onChange={(value) => handleColumnFilterInputChange("phaseId", value)} />
				<DateFilter
					maxDate={maxDate}
					minDate={minDate}
					filterValue={getColumnFilterValueForKey("date", columnFilters)}
					onChange={(value) => handleColumnFilterInputChange("date", value)}
					setUseVariableDateRange={setUseVariableDateRange}
					useVariableDateRange={useVariableDateRange}
				/>
				<div className="ml-auto">
					<DownloadProjectsTimeTrackingsTableDataButton
						includedUserIds={userIds.length > 0 ? userIds : availableUserIds}
						endDate={endDate}
						filteredTableData={filteredTableData}
						projectTitle={projectData.title}
						projectPhaseIds={phaseIds}
						projectPhases={phases}
						startDate={startDate}
					/>
				</div>
			</div>
			</Card>
		</ContentWrapper>

		<ContentWrapper className="w-full overflow-hidden">
			<div className="h-full pb-10 pt-4">
			<TableWrapper className={tableHasVisibleRows ?"table-fixed":""} rounded={true}>
				<TableHeader>
					<DefaultTableHeaderContent table={table} />
				</TableHeader>
				<TableBody>
					<DefaultTableBodyContent table={table} />
					<EmptyTableMessage table={table} />
				</TableBody>
				<TableFooter>
					<tr>
						<td colSpan={projectsTimeTrackingTableColumnDefs.length}>
							<div className="flex w-full flex-row justify-between gap-x-4 border-t border-gray-200 bg-white px-4 pt-3 text-sm text-gray-500 ">
								<div>Zeige {filteredTableData.length} von {tableData.length} Erfassungen</div>
								<div className="ml-auto mr-2 font-bold ">Gesamt:
								</div>
								<div key={"totalMinutes" + filteredTotalMinutes}
									 className="mx-2 font-bold ">{formatNumberWithComma(filteredTotalMinutes / 60 / 8, 2)} Tage
									({formatHoursAndMinutes(filteredTotalMinutes)})
								</div>
								<div key={"totalCents" + filteredTotalCents}
									 className="min-w-32 text-right font-bold">{formatNumberToCurrency(filteredTotalCents)} €
								</div>
							</div>
						</td>
					</tr>
				</TableFooter>
			</TableWrapper>
			</div>
		</ContentWrapper>
	</div>;
};

export default ProjectsTimeTrackingsTable;