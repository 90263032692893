import { PencilIcon } from "@heroicons/react/24/outline";
import CompanyLogoUploadSidebar
	from "modules/client/components/CompanyDetailsView/components/CompanyLogoUploadSidebar";
import { useState } from "react";
import { generatePath } from "react-router-dom";

import AvatarEditButton from "~/components/AvatarEditButton";
import BreadcrumbsSection from "~/components/Breadcrumbs";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import CompanyLogo from "~/components/CompanyLogo";
import ContentWrapper from "~/components/ContentWrapper";
import MainContent from "~/components/mainContent/MainContent";
import { heroSectionBackgroundColor } from "~/components/sections/HeroSection";
import Section from "~/components/sections/Section";
import { appRoutes } from "~/constants/appRoute.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import ClientItem from "~/modules/client/components/ClientItem";
import DeleteCompanyLogoModal from "~/modules/client/components/CompanyDetailsView/components/DeleteCompanyLogoModal";
import UpdateCompanySidebar from "~/modules/client/components/CompanyDetailsView/components/UpdateCompanySidebar";
import CreateClientSidebar from "~/modules/client/components/CreateClientSidebar";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";

interface ClientViewProps {
	allCompanyAvatarImages: AvatarImage[];
	companyData: Company;
	companiesClients: Client[];
}

const CompanyDetailsView: React.FC<ClientViewProps> = ({
	allCompanyAvatarImages,
	companyData,
	companiesClients,
}) => {
	const [showCreateClientSidebar, setShowCreateClientSidebar] = useState<boolean>(false);
	const [showUpdateCompanySidebar, setShowUpdateCompanySidebar] = useState<boolean>(false);
	const [showImageUploadModal, setShowImageUploadModal] = useState<boolean>(false);
	const [showDeleteCompanyLogoModal, setShowDeleteCompanyLogoModal] = useState<boolean>(false);
	const avatarImage = allCompanyAvatarImages.find((image) => image.id === companyData.avatarImageId);
	return (
		<MainContent>
			<BreadcrumbsSection pages={[appRoutes.clients(), companyData.displayName]}
								className={heroSectionBackgroundColor} />
			<Section className="bg-white pb-2">
				<div className="flex w-full justify-between py-2">
					<div className="flex items-center gap-x-4">
						<AvatarEditButton onEditClick={() => setShowImageUploadModal(true)}
										  onDeleteClick={avatarImage ? () => setShowDeleteCompanyLogoModal(true) : undefined}
										  imageType="logo"
						>
							<CompanyLogo width="w-12"
										 height="h-12"
										 displayName={companyData.shortName}
										 image={avatarImage}
							/>
						</AvatarEditButton>
						<div className="flex flex-col">
							<h1 className="text-3xl font-bold">{companyData.displayName}</h1>
							<div className="text-sm text-gray-500">{companyData.corporateName}</div>
						</div>
					</div>
					<ButtonWithPopover theme="dark"
									   items={[{
										   label: "bearbeiten",
										   onClick: () => setShowUpdateCompanySidebar(true),
										   icon: PencilIcon,
									   }]} />
				</div>
			</Section>

			<ContentWrapper className="max-h-full overflow-hidden pt-8">
				<div className="grid h-full grid-cols-1 gap-6 overflow-y-auto sm:grid-cols-2 lg:grid-cols-3">
					{companiesClients
						.map(({ id, displayName, city }) => (
							<ClientItem
								displayName={displayName}
								city={city || ""}
								href={generatePath(appRoutes.client().path, { clientId: id })}
								key={`client-${id}`}
							/>
						))}
					<ButtonNewItem
						size="sm"
						onClick={() => setShowCreateClientSidebar(true)}
					/>
				</div>
			</ContentWrapper>

			<CompanyLogoUploadSidebar companyId={companyData.id}
									  isOpen={showImageUploadModal}
									  originalImage={avatarImage?.original}
									  onClose={() => setShowImageUploadModal(false)} />

			<UpdateCompanySidebar isOpen={showUpdateCompanySidebar}
								  setOpen={() => setShowUpdateCompanySidebar(false)}
								  companyId={companyData.id} />
			<CreateClientSidebar selectedCompanyId={companyData.id}
								 isOpen={showCreateClientSidebar}
								 onClose={() => setShowCreateClientSidebar(false)} />
			<DeleteCompanyLogoModal isOpen={showDeleteCompanyLogoModal}
									onCloseClick={() => setShowDeleteCompanyLogoModal(false)}
									companyId={companyData.id} />

		</MainContent>
	);
};

export default CompanyDetailsView;
