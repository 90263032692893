import clsx from "clsx";
import type { PropsWithChildren } from "react";

type EditorToolbarButtonProps = PropsWithChildren & {
	disabled?: boolean;
	isActive?: boolean;
	onClick: () => void;
};

const EditorToolbarButton: React.FunctionComponent<EditorToolbarButtonProps> = ({
	children,
	disabled,
	isActive = false,
	onClick,
}) => {
	return <button type="button"
				   onClick={onClick}
				   className={clsx("m-1 flex items-center justify-center rounded-md p-1 hover:bg-gray-100", isActive ? "bg-blue-100 hover:bg-blue-200" : "bg-transparent", disabled && "pointer-events-none cursor-not-allowed opacity-50")}
				   aria-label="Format Bullet list"
	>
		{children}
	</button>;
};

export default EditorToolbarButton;