import { useMemo } from "react";

import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData,getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

const useLocationOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: locationData } = useAllLocations();
	return useMemo(() => {
		if (locationData) {
			const options = locationData.sort(byObjectProperty("displayName")).map((location) => {
				return createInputOptionData(location.displayName, location.displayName);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [locationData, includeCtaOption]);
};

export default useLocationOptions;

