import type { FunctionComponent} from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import {
	CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import type { InvoiceRecipientType } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import CreateOrUpdateInvoiceRecipientForm
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/CreateOrUpdateInvoiceRecipientForm";

type UpdateInvoiceRecipientSidebarProps = {
	invoiceRecipientData: InvoiceRecipientType | null;
	isOpen: boolean;
	onClose: () => void;
};

const UpdateInvoiceRecipientSidebar: FunctionComponent<UpdateInvoiceRecipientSidebarProps> = ({
	invoiceRecipientData,
	isOpen,
	onClose,
}) => {
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(async () => {
		if (!invoiceRecipientData) return;
		await queryClient.invalidateQueries(CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY(invoiceRecipientData.id));
		onClose();
	}, [invoiceRecipientData, onClose, queryClient]);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<>
				{!!invoiceRecipientData && <CreateOrUpdateInvoiceRecipientForm
					initialValues={invoiceRecipientData}
					clientId={invoiceRecipientData.clientId}
					onSuccess={handleSuccess}
					onCancelClick={onClose} />}
			</>
		</Sidebar>
	);
};

export default UpdateInvoiceRecipientSidebar;