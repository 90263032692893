import { format } from "date-fns";
import { de } from "date-fns/locale";

import GridSegmentText
	from "~/modules/absence/components/AbsenceCalendarView/components/VerticalGridLines/components/GridSegmentText";
import type { GridSegmentType } from "~/modules/absence/types/absenceOverviewTypes.ts";
import { getWorkDaysInterval } from "~/utils/dateAndTimeUtils.ts";

type MonthGridLinesProps = {
	dayWidthPx: number;
	dividerWidthPx: number;
	onSegmentClick?: (date: Date) => void;
	startDate: Date;
	endDate: Date;
	showMonthNames: boolean;
}

const MonthGridLines: React.FunctionComponent<MonthGridLinesProps> = ({
	dayWidthPx,
	dividerWidthPx,
	onSegmentClick,
	endDate,
	startDate,
	showMonthNames,
}) => {
	const segments: GridSegmentType[] = [];
	const workDaysInterval = getWorkDaysInterval(startDate, endDate);
	const currentMonth = new Date().getMonth();
	workDaysInterval.forEach((date, index) => {
		const dayOfMonth = date.getDate();
		const segment: GridSegmentType = {
			isCurrentDate: date.getMonth() === currentMonth,
			offsetLeft: index * dayWidthPx,
			text: format(date, "MMM", { locale: de }),
			startDate: date,
			width: 0,
		};

		if (dayOfMonth === 1) {
			segment.width = workDaysInterval.filter((workday) => workday.getMonth() === date.getMonth()).length * dayWidthPx;
			segments.push(segment);
		} else if (dayOfMonth <= 3) {

			// Check if the date is the first workday of the month
			// Get the previous day
			const prevDay = workDaysInterval[index - 1];

			// Check if the previous day was in a different month and was a non-workday
			if (!prevDay || prevDay.getMonth() !== date.getMonth()) {
				segment.width = workDaysInterval.filter((workday) => workday.getMonth() === date.getMonth()).length * dayWidthPx;
				segments.push(segment);
			}
		}
	});

	return segments.map((segment, index) => {
		return <div key={index}
					className="absolute top-0 h-full bg-gray-300"
					style={{ left: segment.offsetLeft, width: dividerWidthPx }}>
			{showMonthNames && <GridSegmentText onClick={onSegmentClick}
												startDate={segment.startDate}
												highlighted={segment.isCurrentDate}
												widthPx={segment.width || 0}>{segment.text}</GridSegmentText>}
		</div>;
	});

};

export default MonthGridLines;