import type { QueriesOptions } from "react-query";
import { useQuery } from "react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersDocumentLinks,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkApiDispatchers.ts";

export const STAFF_MEMBER_DOCUMENT_LINK_BASE_QUERY_KEY = "staffMembersDocumentLinks";

export const STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_DOCUMENT_LINK_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersDocumentLinks = ({ staffMemberId, options }: {
	staffMemberId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY(staffMemberId),
	() => indexStaffMembersDocumentLinks(staffMemberId).then(getDataFromResponse),
	options,
);