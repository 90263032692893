import { useMemo } from "react";

import { useAllTaxClasses } from "~/modules/humanResources/api/taxClass/taxClassQueries.ts";
import type { TaxClassId } from "~/modules/humanResources/api/taxClass/taxClassTypes.ts";
import { getTaxClassDisplayNameById } from "~/modules/humanResources/utils/taxClassUtils.ts";
import type { FormInputOption } from "~/types/form.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useTaxClassOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: taxClassesData } = useAllTaxClasses();

	return useMemo(() => {
		if (taxClassesData) {
			const taxClassesDataSorted = [...taxClassesData].map(taxClass => {
				return {
					...taxClass,
					displayName: getTaxClassDisplayNameById(taxClass.id as TaxClassId),
				};

			}).sort(byObjectProperty("displayName"));
			return mapDataToInputOptionsData(taxClassesDataSorted, "id", "displayName", includeCtaOption);
		}
		return [];
	}, [taxClassesData, includeCtaOption]);
};

export default useTaxClassOptions;

