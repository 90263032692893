import Button from "components/buttons/Button";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import { useState } from "react";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { generateInvoiceFile } from "~/modules/billing/api/invoice/invoiceApiDispatchers.ts";

type FileInfoType = {
	fileName: string;
	downloadUrl: string;
}

type DownloadInvoiceButtonProps = {
	orderId: string;
	month: string;
};

const DownloadInvoiceButton: React.FunctionComponent<DownloadInvoiceButtonProps> = ({ orderId, month }) => {
	const [fileInfo, setFileInfo] = useState<FileInfoType | null>();
	const [isGenerating, setIsGenerating] = useState(false);

	const handleGenerateInvoiceClick = async () => {
		setIsGenerating(true);
		try {
			const response = await generateInvoiceFile({ orderId, month });
			const fileInfo = getDataFromResponse(response);
			setFileInfo(fileInfo);
			setIsGenerating(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDownloadClick=()=>{
		setFileInfo(null);
	}

	return <div className="w-full">
		{!fileInfo ? <Button size="sm" theme="primary" className="my-1.5 w-full justify-center" onClick={handleGenerateInvoiceClick}
							 disabled={isGenerating}>{!isGenerating ? "Rechnungsdokument erzeugen" :
				<LoadingSpinner theme="neutral" scale={0.7} />}</Button> :
			<Button as="a" size="sm" theme="accent" className="my-1.5 w-full justify-center"
			   href={fileInfo.downloadUrl}
			   download={fileInfo.fileName}
			   onClick={handleDownloadClick}
			>Rechnung herunterladen</Button>}
	</div>;
};

export default DownloadInvoiceButton;