import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import {
	useStaffMembersDocumentLinks,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkQueries.ts";
import type {
	StaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkTypes.ts";
import CreateDocumentLinkEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection/components/CreateDocumentLinkEntrySidebar";
import DeleteDocumentLinkEntryModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection/components/DeleteDocumentLinkEntryModal";
import DocumentListItem
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection/components/DocumentListItem";
import UpdateDocumentLinkEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection/components/UpdateDocumentLinkEntrySidebar";
import TimelineEmptyMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TimelineEmptyMessage";

type Props = { staffMemberId: string };

const DocumentLinksSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateDocumentLinkSidebar, setShowCreateDocumentLinkSidebar] = useState<boolean>(false);
	const [entryDataToUpdate, setEntryDataToUpdate] = useState<StaffMemberDocumentLink | null>(null);
	const [entryDataToDelete, setEntryDataToDelete] = useState<StaffMemberDocumentLink | null>(null);
	const {
		data: documentLinksData,
		isLoading: documentLinksIsLoading,
	} = useStaffMembersDocumentLinks({ staffMemberId });

	const documentLinks = useMemo(() => {
		if (documentLinksData !== undefined) {
			return documentLinksData.map((documentLink) => {

				const popovverItems = [
					{
						label: "Bearbeiten",
						onClick: () => setEntryDataToUpdate(documentLink),
						icon: PencilIcon,
					},
					{
						label: "Löschen",
						onClick: () => setEntryDataToDelete(documentLink),
						icon: TrashIcon,
					}];

				return <DocumentListItem key={documentLink.id}
										 title={documentLink.title}
										 url={documentLink.url}>
					<ButtonWithPopover items={popovverItems}
									   theme="dark" />

				</DocumentListItem>;
			});
		}
		return [];
	}, [documentLinksData]);

	return <><Card
		title="Dokumente"
		onHeaderButtonClick={() => setShowCreateDocumentLinkSidebar(true)}
		headerButtonText="Neuer Eintrag">
		<div className="flex flex-col gap-y-4">
			{documentLinksIsLoading ?
				<LoadingSpinnerFull theme="neutral" /> : documentLinks.length > 0 ? documentLinks :
					<TimelineEmptyMessage />}
		</div>
	</Card>
		<CreateDocumentLinkEntrySidebar isOpen={showCreateDocumentLinkSidebar}
										onClose={() => setShowCreateDocumentLinkSidebar(false)}
										staffMemberId={staffMemberId} />
		<DeleteDocumentLinkEntryModal documentLinkData={entryDataToDelete}
									  onCloseClick={() => setEntryDataToDelete(null)}
									  isOpen={!!entryDataToDelete} />
		<UpdateDocumentLinkEntrySidebar isOpen={!!entryDataToUpdate}
										onClose={() => setEntryDataToUpdate(null)}
										documentLinkData={entryDataToUpdate} />
	</>;
};

export default DocumentLinksSection;