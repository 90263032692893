import { t } from "i18next";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import Card from "~/components/Card";
import ContentWrapper from "~/components/ContentWrapper";
import { ADMIN_USERS_INDEX_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import AdminUsersFilterBar
	from "~/modules/admin/components/AdminView/components/AdminUsersSection/components/AdminUsersFilterBar";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import type { Permission } from "~/modules/auth/api/auth/authTypes.ts";
import { useAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationQueries.ts";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeQueries.ts";
import { useAllGenders } from "~/modules/humanResources/api/gender/genderQueries.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import type { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import CreateUserSidebar from "~/modules/user/components/components/CreateUserSidebar";
import UpdatePasswordSidebar from "~/modules/user/components/components/UpdatePasswordSidebar";
import UpdateUserSidebar from "~/modules/user/components/components/UpdateUserSidebar";
import UpdateUserStatusSidebar from "~/modules/user/components/components/UpdateUserStatusSidebar";
import UsersTable from "~/modules/user/components/components/UsersTable";
import UserTableRow from "~/modules/user/components/components/UserTableRow";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const defaultPageState: {
	employmentTypeFilter: string[],
	search: string
} = {
	employmentTypeFilter: [],
	search: "",
};

const AdminUsersSection: React.FunctionComponent = () => {
	const { pageState, setPageState } = usePageStateStorage({
		defaultState: defaultPageState,
		pageKey: ADMIN_USERS_INDEX_PAGE_STATE_KEY,
	});
	const [employmentTypeFilter, setEmploymentStatusFilter] = useState<string[]>(pageState.employmentTypeFilter);
	const [selectedUserEditId, setSelectedUserEditId] = useState<string | null>(null);
	const [userIdToUpdateStatusFor, setUserIdToUpdateStatusFor] = useState<string | null>(null);
	const [userIdToUpdatePasswordFor, setUserIdToUpdatePasswordFor] = useState<null | string>(null);
	const [showCreateUserForm, setShowCreateUserForm] = useState(false);
	const [search, setSearch] = useState(pageState.search);

	const { data, options: { loadingPercent: usersLoadingPercent } } = useAggregatedDataV2({
		allUsersData: useAllUsers(),
		allCareerLevelsData: useAllCareerLevels(),
		companyLocations: useAllCompanyLocations(),
		allLocationsData: useAllLocations(),
		allEmploymentStatusesData: useAllEmploymentTypes(),
		genders: useAllGenders(),
		permissions: useAllPermissions(),
	});

	const {
		allCareerLevelsData,
		allLocationsData,
		allEmploymentStatusesData,
		allUsersData,
	} = data;

	const filteredUsers = useMemo(() => {
		if (allUsersData) {
			return (allUsersData as UserWithConfidentialInformation[]).filter((user) => {
				const employmentTypeCheck = employmentTypeFilter.length > 0 ? employmentTypeFilter.includes(user.employmentTypeId as string) : true;
				return employmentTypeCheck && user.fullName.toLowerCase().includes(search.trim().toLowerCase());
			}).sort(byObjectProperty("lastName"));
		}

		return [];
	}, [search, employmentTypeFilter, allUsersData]);

	const userDataEdit = useMemo(() => {
		if (allUsersData && selectedUserEditId) {
			return getOneOfCollection(allUsersData, selectedUserEditId);
		}
	}, [allUsersData, selectedUserEditId]);

	const userDataStatusUpdate = useMemo(() => {
		if (allUsersData && userIdToUpdateStatusFor) {
			return getOneOfCollection(allUsersData, userIdToUpdateStatusFor);
		}
	}, [allUsersData, userIdToUpdateStatusFor]);

	const allUserCards = useMemo(() => {
		return filteredUsers?.map((user) => {
			const careerLevel = user.careerLevelId
				? getOneOfCollection(allCareerLevelsData, user.careerLevelId, "displayName")
				: "n/a";
			const employmentType = user.employmentTypeId
				? getOneOfCollection(allEmploymentStatusesData, user.employmentTypeId, "name")
				: "n/a";
			let companyLocationName = "";

			if (user.locationId) {
				const location = getOneOfCollection(allLocationsData, user.locationId);

				if (location) {
					companyLocationName = location.displayName;
				}
			}
			const permissionNames = user.permissions.map((permission: Permission) => {
				return t(`entities:permission.${permission.name}`, "Unbekannte Berechtigung");
			});

			return (
				<UserTableRow
					key={user.id}
					avatarImage={user.avatarImage}
					careerLevel={careerLevel}
					companyLocation={companyLocationName}
					employmentType={t(normalizeTKey(`entities:employmentType.${employmentType}`))}
					email={user.email}
					firstName={user.firstName}
					isActive={user.isActive}
					lastName={user.lastName}
					shortName={user.shortName}
					permissionNames={permissionNames}
					onEditClick={() => {
						setSelectedUserEditId(user.id);
					}}
					onUpdateStatusClick={() => {
						setUserIdToUpdateStatusFor(user.id);
					}}
					onUpdatePasswordClick={() => setUserIdToUpdatePasswordFor(user.id)}
				/>
			);
		});
	}, [filteredUsers, allCareerLevelsData, allLocationsData, allEmploymentStatusesData, setSelectedUserEditId, setUserIdToUpdateStatusFor]);


	const handleStatusFilterChange = useCallback((employmentTypeId: string) => {
		if (null === employmentTypeId) {
			setEmploymentStatusFilter([]);
		} else {
			setEmploymentStatusFilter((prevState) => {
				return prevState.includes(employmentTypeId)
					? prevState.filter((optionValue) => optionValue !== employmentTypeId)
					: [...prevState, employmentTypeId];
			});
		}
	}, []);

	useEffect(() => {
		setPageState({ employmentTypeFilter, search });
	}, [search, employmentTypeFilter, setPageState]);

	return (
		<ContentWrapper className="relative w-full overflow-hidden pb-10 pt-4">
			<Card>
			<AdminUsersFilterBar
				employmentTypeFilter={employmentTypeFilter}
				onEmploymentStatusFilterChange={handleStatusFilterChange}
				search={search}
				setSearch={setSearch}
				setShowCreateUserForm={setShowCreateUserForm}
				totalUserCount={allUsersData?.length || 0}
				visibleUserCount={filteredUsers?.length || 0}
			/>
			</Card>
			{usersLoadingPercent < 100 ? <LoadingPage pcent={usersLoadingPercent} /> :
				<div className="mt-4 max-h-[90%] overflow-y-auto">
					<UsersTable useExtraMargin={employmentTypeFilter.length > 0}>{allUserCards}</UsersTable>
				</div>
			}
			<CreateUserSidebar
				key={showCreateUserForm ? "create-user-form" : "create-user-form-closed"}
				isOpen={showCreateUserForm}
				closeSidebar={() => {
					setShowCreateUserForm(false);
				}}
			/>

			<UpdateUserSidebar
				key={selectedUserEditId || "update-user-closed"}
				isOpen={null !== selectedUserEditId}
				closeSidebar={() => setSelectedUserEditId(null)}
				userData={userDataEdit}
			/>

			<UpdateUserStatusSidebar
				key={userIdToUpdateStatusFor || "update-user-status-closed"}
				isOpen={null !== userIdToUpdateStatusFor}
				closeSidebar={() => setUserIdToUpdateStatusFor(null)}
				userData={userDataStatusUpdate}
			/>
			<UpdatePasswordSidebar
				key={userIdToUpdatePasswordFor || "update-password-closed"}
				isOpen={!!userIdToUpdatePasswordFor}
				onClose={() => setUserIdToUpdatePasswordFor(null)}
				userId={userIdToUpdatePasswordFor}
			/>
		</ContentWrapper>
	);
};

export default AdminUsersSection;
