export enum UtilisationReportViewTabId {
	Utilisation = "utilisation",
}

export type MonthlyUtilisationTableData = {
	staffMemberDisplayName: string;
	workDaysTargetFte: string;
	longTermAbsenceDays: string;
	vacationDays: string;
	vacationDaysFte: string;
	projectMinutesTracked: number;
	projectUtilizationPercentage: string;
	regularWorkDays: string;
	ftePercentages: number[];
};