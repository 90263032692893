import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";

export function getPhaseIdFromTabId(tabId: string): number {
	return parseInt(tabId.split("-")[1]);
}

export function isPhaseTabId(tabId: string): boolean {
	return tabId.startsWith(ProjectDetailsTabName.Phase);
}

export function generatePhaseTabId(phaseId: number): string {
	return ProjectDetailsTabName.Phase + phaseId;
}