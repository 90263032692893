import { useCallback, useEffect, useRef, useState } from "react";

type BroadcastChannelData =
	| string
	| number
	| boolean
	| Record<string, unknown>
	| undefined
	| null;

function useBroadcastChannel<T extends BroadcastChannelData = string>(
	channelName: string,
	handleMessage?: (event: MessageEvent) => void,
	handleMessageError?: (event: MessageEvent) => void,
): (data: T) => void {
	const [channel] = useState<BroadcastChannel | null>(
		typeof window !== "undefined" && "BroadcastChannel" in window
			? new BroadcastChannel(channelName + "-channel")
			: null,
	);

	useChannelEventListener(channel, "message", handleMessage);
	useChannelEventListener(channel, "messageerror", handleMessageError);

	return useCallback((data: T) => channel?.postMessage(data), [channel]);
}

function useChannelEventListener<K extends keyof BroadcastChannelEventMap>(
	channel: BroadcastChannel | null,
	event: K,
	handler?: (e: BroadcastChannelEventMap[K]) => void,
) {
	const callbackRef = useRef(handler);
	if (callbackRef.current !== handler) {
		callbackRef.current = handler;
	}

	useEffect(() => {
		const callback = callbackRef.current;
		if (!channel || !callback) {
			return;
		}

		channel.addEventListener(event, callback);
		return () => channel.removeEventListener(event, callback);
	}, [channel, event]);
}

export default useBroadcastChannel;