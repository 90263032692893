import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import { FormSectionGroup } from "~/components/formElements/FormSection";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import TimingFormSection from "~/components/formSections/TimingFormSection";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { DEFAULT_CURRENCY_ID, PAYMENT_TARGET_DAYS_DEFAULT_VALUE } from "~/constants/form.ts";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientsInvoiceRecipientSelectOptions
	from "~/hooks/form/formOptionsData/useClientsInvoiceRecipientsSelectOptions.ts";
import useCurrencySelectOptions from "~/hooks/form/formOptionsData/useCurrencySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useOrderTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useOrderTitleValidationComparisonData.ts";
import { createOrder } from "~/modules/project/api/order/orderApiDispatchers.ts";
import type { CreateOrderData } from "~/modules/project/api/order/orderTypes.ts";
import OrderAccountingFormSection from "~/modules/project/components/forms/formSections/OrderAccountingFormSection";
import OrderBudgetFormSection from "~/modules/project/components/forms/formSections/OrderBudgetFormSection";
import OrderForeignCountryFormSection
	from "~/modules/project/components/forms/formSections/OrderForeignCountryFormSection";
import OrderInvoiceRecipientSection from "~/modules/project/components/forms/formSections/OrderInvoiceRecipientSection";
import OrderStatusFormSection from "~/modules/project/components/forms/formSections/OrderStatusFormSection";
import OrderTitleAndNumberFormSection
	from "~/modules/project/components/forms/formSections/OrderTitleAndNumberFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { convertLocalToUTCDate, formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

export type OrderDefaultValuesFromClientType = {
	invoiceByMail: boolean;
	paymentTargetDays: number | null;
	isVatExempt: boolean;
	travelExpensesIncluded: boolean;
};

type CreateOrderFormProps = {
	clientId: string;
	onSuccess: () => void;
	onCancel: () => void;
	projectId: string;
	projectPhaseId: string;
	defaultValuesFromClient: OrderDefaultValuesFromClientType;
};

interface CreateOrderFormData
	extends Omit<
		CreateOrderData,
		| "projectId"
		| "projectPhaseId"
		| "confirmedAt"
		| "clientContactPersonId"
		| "startDate"
		| "endDate"
		| "paymentTargetDays"
		| "manDays"
	> {
	clientContactPersonId: string | null;
	confirmed: boolean;
	manDays:number;
	endDate: Date | null;
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
	startDate: Date | null;
}

const CreateOrderForm: React.FC<CreateOrderFormProps> = ({
	onSuccess,
	onCancel,
	clientId,
	projectId,
	projectPhaseId,
	defaultValuesFromClient,
}) => {
	const [busy, setBusy] = useState(false);
	const titleComparisonData = useOrderTitleValidationComparisonData({ projectId, projectPhaseId });
	const schema = useMemo(() => {
		return yup
			.object({
				budgetCents: yup.number().required(),
				comment: yup.string().default(null),
				confirmed: yup.boolean().required(),
				clientContactPersonId: yup.string().nullable().default(null),
				currencyId: yup.string().required(),
				endDate: yup
					.date()
					.required()
					.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
				invoiceByMail: yup.boolean().required(),
				invoiceOrderNumberRequired: yup.boolean().required(),
				invoiceRecipientId: yup.string().nullable().default(null),
				isBilledMonthly: yup.boolean().required(),
				isFixedPrice: yup.boolean().required(),
				isForeignCountry: yup.boolean().required(),
				isSkillBased: yup.boolean().required(),
				manDays: yup.number().required(),
				orderNumber: yup.string().default(null),
				paymentTargetDays: yup.number().required(),
				paymentTargetDaysCustom: yup.number()
					.nullable()
					.when("paymentTargetDays", (field1Value: number[], schema) => {
						return field1Value[0] === 0
							? schema.required()
							: schema.nullable();
					}),
				startDate: yup.date().required(),
				title: yup.string().required().unique(titleComparisonData),
				travelExpensesIncluded: yup.boolean().required(),
			})
			.test("atLeastOneField", "Bitte wähle einen Kontakt oder zentralen Rechnungsempfänger aus.", (value) =>
				Boolean(value.clientContactPersonId || value.invoiceRecipientId),
			);
	}, [titleComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			budgetCents: 0,
			clientContactPersonId: "",
			comment: "",
			confirmed: false,
			currencyId: DEFAULT_CURRENCY_ID,
			endDate: null,
			invoiceByMail: defaultValuesFromClient.invoiceByMail || true,
			invoiceOrderNumberRequired: true,
			invoiceRecipientId: "",
			isBilledMonthly: true,
			isFixedPrice: false,
			isForeignCountry: false,
			isSkillBased: false,
			manDays: 0,
			orderNumber: "",
			paymentTargetDays: PAYMENT_TARGET_DAYS_DEFAULT_VALUE,
			paymentTargetDaysCustom: null,
			startDate: null,
			title: "",
			travelExpensesIncluded: defaultValuesFromClient.travelExpensesIncluded || false,
		};
	}, [defaultValuesFromClient.invoiceByMail, defaultValuesFromClient.travelExpensesIncluded]);
	const {
		handleSubmit,
		control,
		formState: { errors, isSubmitted, isValid, isDirty },
		watch,
		setValue,
		trigger,
	} = useForm<CreateOrderFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateOrderFormData>(schema),
	});
	const formIsSubmittable = useFormIsSubmittable({ isDirty, isValid, isSubmitted, isLoading: busy });
	const showCustomPaymentTargetInput = watch("paymentTargetDays") === 0;
	const showCurrencyInput = !!watch("isForeignCountry");

	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(clientId);
	const clientsInvoiceRecipientSelectOptions = useClientsInvoiceRecipientSelectOptions(clientId);
	const currencySelectOptions = useCurrencySelectOptions();

	useEffect(() => {
		if (showCustomPaymentTargetInput) {
			trigger("paymentTargetDaysCustom");
		}
	}, [showCustomPaymentTargetInput, trigger]);

	const onSubmit: SubmitHandler<CreateOrderFormData> = async (data: CreateOrderFormData) => {
		setBusy(true);
		const orderData = {
			...data,
			startDate: formatDateToYYYYMMDD(data.startDate!),
			endDate: formatDateToYYYYMMDD(data.endDate!),
			projectId: projectId,
			projectPhaseId: projectPhaseId,
			confirmedAt: data.confirmed ? convertLocalToUTCDate(new Date()) : null,
		};

		try {
			await createOrder({ projectId, orderData });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	const isForeignCountry = watch("isForeignCountry");

	useEffect(() => {
		if (isSubmitted) {
			trigger("currencyId");
		}
	}, [isForeignCountry, trigger, isSubmitted]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neue Bestellung</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}

				<FormSectionGroup>
					<OrderTitleAndNumberFormSection control={control} />
					<OrderStatusFormSection control={control} />
					<OrderBudgetFormSection control={control} watch={watch}/>
					<TimingFormSection control={control}
									   errors={errors}
									   title={"Laufzeit"} />

					<OrderAccountingFormSection
						control={control}
						errors={errors}
						showCustomPaymentTargetInput={showCustomPaymentTargetInput}
					/>

					<OrderInvoiceRecipientSection
						control={control}
						clientContactPersonSelectOptions={clientContactPersonSelectOptions}
						// @ts-expect-error - needs to be fixed
						errorMessage={errors[""]?.message}
						invoiceRecipientSelectOptions={clientsInvoiceRecipientSelectOptions}
						setValue={setValue}
						trigger={trigger}
						watch={watch}
					/>
					<OrderForeignCountryFormSection
						control={control}
						currencySelectOptions={currencySelectOptions}
						showCurrencySelect={showCurrencyInput}
						errors={errors}
					/>

					<CommentFormSection
						control={control}
						label="Kommentar"
						title="Anmerkungen" />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint
					show={isSubmitted && !isValid}
					className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateOrderForm;
