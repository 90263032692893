import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";

type Props = { workingScheduleData: Omit<WorkingSchedule, "validFrom"> }

const WorkingScheduleWeek: FunctionComponent<Props> = ({
	workingScheduleData,
}) => {
	const dayElements = useMemo(() => {
		const days = [
			{ label: "Montag", isActive: workingScheduleData.monday },
			{ label: "Dienstag", isActive: workingScheduleData.tuesday },
			{ label: "Mittwoch", isActive: workingScheduleData.wednesday },
			{ label: "Donnerstag", isActive: workingScheduleData.thursday },
			{ label: "Freitag", isActive: workingScheduleData.friday }];

		return days.map(day => {
			return <div key={day.label}
						className={clsx("flex w-24 items-center justify-center rounded py-1 text-sm", day.isActive ? "bg-green-500 text-white" : "bg-gray-200 text-gray-400")}>
				{day.label}
			</div>;
		});
	}, [workingScheduleData]);
	return <div className="flex flex-row items-center justify-between gap-x-2">
		{dayElements}
	</div>;
};

export default WorkingScheduleWeek;