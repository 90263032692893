import { t } from "i18next";
import { useMemo } from "react";

import { useAllBonusTypes } from "~/modules/humanResources/api/bonusType/bonusTypeQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useBonusTypeSelectOptions = (): FormInputOption[] => {
	const { data: bonusTypeData } = useAllBonusTypes();
	return useMemo(() => {
		if (bonusTypeData) {
			return bonusTypeData.sort(byObjectProperty("listPosition")).map((bonusType) => {
				const label = t(normalizeTKey(`entities:bonusType.${bonusType.name}`));
				return createInputOptionData(label, bonusType.id);
			});
		}
		return [];
	}, [bonusTypeData]);
};

export default useBonusTypeSelectOptions;

