import { Navigate, useParams } from "react-router-dom";

import { useAuth } from "~/contexts/AuthContext";
import type { HasAnyPermissionParamsType } from "~/contexts/AuthContext/AuthContext.tsx";

const userViewGuard = (permissionName: HasAnyPermissionParamsType,
	ownerShipPermissionName?: HasAnyPermissionParamsType,
	ownershipType? : "user"|"staffMember")=> {
	const { hasAnyPermission } = useAuth();
	const { user } = useAuth();
	const { userId:userIdParam, staffMemberId:staffMemberIdParam } = useParams();
	const permissionCheckPassed = hasAnyPermission(permissionName);
	const navigateTo = <Navigate to={"/"} />;

	if(!user){
		return navigateTo;
	}

	if (!ownerShipPermissionName && permissionCheckPassed) {
		return null;
	} else if (permissionCheckPassed && ownerShipPermissionName) {
		let paramToCheck = userIdParam;
		let userPropertyToCheck = user.id;

		if(ownershipType === "staffMember"){
			userPropertyToCheck = user.staffMemberId || "";
			paramToCheck = staffMemberIdParam;
		}

		if(!user || !userPropertyToCheck){
			return navigateTo;
		}

		if(paramToCheck === userPropertyToCheck){
			return null;
		}

		if (hasAnyPermission(ownerShipPermissionName)) {
			return null;
		}
	}
	return navigateTo;

};

export default userViewGuard;
