import type { Control, FieldValues } from "react-hook-form";

import type { FormSectionProps } from "~/components/formElements/FormSection";
import FormSection from "~/components/formElements/FormSection";
import Switch from "~/components/formElements/Switch";
import Hint from "~/components/Hint";

type CommentFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>
	isActive: boolean;
};

type UserStatusFormSection = {
	isActive: boolean;
};

const UserStatusFormSection = <T extends UserStatusFormSection>({
	control,
	isActive,
}: CommentFormSectionProps<T>) => {
	return <FormSection title="NORD.WORK User Status">
		<Switch name="isActive"
				label={isActive ? "Aktiv" : "Inaktiv"}
				control={control} />
		{isActive && (
			<div className="mt-4">
				<Hint size="sm">
					Als &quot;aktiv&quot; angelegte User erhalten automatisch eine Einladungs-E-Mail,
					mithilfe
					derer sie ihr Passwort festlegen und sich danach anmelden können.
				</Hint>
			</div>
		)}
	</FormSection>;
};

export default UserStatusFormSection;
