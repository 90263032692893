import type { Control, FieldValues } from "react-hook-form";
import type { Path } from "react-hook-form";
import type { UseFormWatch } from "react-hook-form";

import CurrencyInput from "~/components/formElements/CurrencyInput";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type OrderBudgetFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	placeholder?: string;
	watch:UseFormWatch<T>
};

type OrderBudgetFormSectionType = {
	manDays: number;
	budgetCents: number;
};

const OrderBudgetFormSection = <T extends OrderBudgetFormSectionType>({ control, watch }: OrderBudgetFormSectionProps<T>) => {
	const manDaysPlanned = watch("manDays" as Path<T>);
	const budgetCentsPlanned = watch("budgetCents" as Path<T>);
	const averageDailyRateCents = manDaysPlanned && budgetCentsPlanned ? budgetCentsPlanned / manDaysPlanned : undefined;

	return (
		<FormSection title="Budget">
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<Input
						name="manDays"
						control={control}
						label="Personentage"
						type={"number"}

					/>
				</div>
				<div className="col-span-3">
					<CurrencyInput
						name={"budgetCents"}
						control={control}
						label="Budget (EUR)"
					/>
				</div>
				<div className="col-span-6 text-sm text-gray-500">
					Ø Tagessatz: {averageDailyRateCents ? formatCentsToCurrency(averageDailyRateCents) : "--"}
				</div>
			</div>
		</FormSection>
	);
};

export default OrderBudgetFormSection;
