import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useTaxClassOptions from "~/hooks/form/formOptionsData/useTaxClassOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateStaffMembersTaxDetails } from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	taxClassId: string | null;
	taxIdNumber: string | null;
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateTaxDetailsForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const taxClassesOptions = useTaxClassOptions(true);
	const schema = useMemo(() => {
		return yup.object({
			taxClassId: yup.string().nullable().default(null),
			taxIdNumber: yup.string().nullable().default(null),
		});
	}, []);

	const defaultValues = useMemo(() => {
		if (staffMemberCoreData) {
			return {
				taxClassId: staffMemberCoreData.taxClassId,
				taxIdNumber: staffMemberCoreData.taxIdNumber,
			};
		}
		return {
			taxClassId: null,
			taxIdNumber: null,

		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {

		try {
			setIsBusy(true);
			await updateStaffMembersTaxDetails(staffMemberCoreData.id, data);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Steuerdaten
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}

				<Input control={control}
					   name="taxIdNumber"
					   label="Steuer-ID"
					   placeholder="1234567890"
				/>
				<div className="h-4" />
				<Select control={control}
						name="taxClassId"
						label="Steuerklasse"
						optionsData={taxClassesOptions}

				/>

			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateTaxDetailsForm;
