import { yupResolver } from "@hookform/resolvers/yup";
import { startOfDay, startOfYear } from "date-fns";
import Decimal from "decimal.js-light";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import CurrencyInput from "~/components/formElements/CurrencyInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import MonthPickerInput from "~/components/formElements/MonthPickerInput";
import SubmitButton from "~/components/formElements/SubmitButton";
import Hint from "~/components/Hint";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createVacationEntitlement,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementApiDispatchers.ts";
import {
	useUsersVacationEntitlementsValidationComparisonData
} from "~/modules/absence/hooks/useUsersVacationEntitlementsValidationComparisonData.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateVacationEntitlementFormData = {
	workDays: number;
	validFrom: Date;
};

type CreateVacationEntitlementFormProps = {
	defaultEntitlementDays: number;
	minDate: Date;
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberId: string;
};

const CreateVacationEntitlementForm: React.FunctionComponent<CreateVacationEntitlementFormProps> = ({
	defaultEntitlementDays,
	minDate,
	onSuccess,
	onCancel,
	staffMemberId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const comparisonData = useUsersVacationEntitlementsValidationComparisonData(staffMemberId);

	const schema = useMemo(() => {
		return yup.object({
			workDays: yup.number().required().min(1, "Required field"),
			validFrom: yup.date().required().min(startOfDay(minDate), "Required field").uniqueDay(comparisonData),
		});
	}, [comparisonData, minDate]);

	const defaultValues = useMemo(() => {
		return {
			workDays: defaultEntitlementDays,
			validFrom: startOfYear(new Date()),
		};
	}, [defaultEntitlementDays]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<CreateVacationEntitlementFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateVacationEntitlementFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: CreateVacationEntitlementFormData) => {
		const workDays = new Decimal(data.workDays/100).toNumber();

		try {
			setIsBusy(true);
			await createVacationEntitlement({ staffMemberId, data:{validFrom:formatDateToYYYYMMDD(data.validFrom), workDays} });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neuer Urlaubsanspruch</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="flex w-full flex-col justify-start gap-y-8">
					<div className={"grid grid-cols-2 gap-x-4"}>
						<CurrencyInput
							name="workDays"
							control={control}
							label="Anspruch in Arbeitstagen (Vollzeit)"
						/>
						<MonthPickerInput name="validFrom"
										  control={control}
										  label="Gültig ab"
										  clearable={false}
										  minDate={minDate} />
					</div>
					<Hint size="sm">
						<p>Der Urlaubsanspruch wird am Anfang des Jahres automatisiert in das Urlaubskonto übertragen.</p>
						<p>Sollte es im laufenden Jahr zu einer Änderung des Urlaubsanspruchs kommen, muss die Korrektur
							der verbleibenden Urlaubstage manuell in Form einer Gutschrift / eines Abzugs im
							Urlaubskonto vorgenommen werden.</p>
					</Hint>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Urlaubsanspruch erstellen
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateVacationEntitlementForm;
