import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Hint from "~/components/Hint";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateProjectPhaseStatus } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import type { UpdateProjectPhaseStatusData } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateProjectPhaseStatusFormProps = {
	currentStatusId: string;
	onSuccess: (newStatusId: string) => void;
	onCancel: () => void;
	phaseHasTimeTrackings: boolean;
	projectId: string;
	projectPhaseId: string;
};

const UpdateProjectPhaseStatusForm: React.FunctionComponent<UpdateProjectPhaseStatusFormProps> = ({
	currentStatusId,
	onCancel,
	onSuccess,
	phaseHasTimeTrackings,
	projectPhaseId,
	projectId,
}) => {
	const [busy, setBusy] = useState(false);
	const workingStatusOptions = useWorkingStatusOptions();

	const schema = useMemo(() => {
		return yup.object({
			workingStatusId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			workingStatusId: currentStatusId,
		};

	}, [currentStatusId]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
		watch,
	} = useForm<UpdateProjectPhaseStatusData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProjectPhaseStatusData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	const onSubmit = async (data: UpdateProjectPhaseStatusData) => {
		try {
			setBusy(true);
			await updateProjectPhaseStatus({ projectId, projectPhaseId, data });
			onSuccess(data.workingStatusId);
		} catch (error) {
			console.log(error);
		}
	};

	const selectedStatusId = watch("workingStatusId");
	const showTimeTrackingsWarning = currentStatusId !== "5" && phaseHasTimeTrackings && selectedStatusId === "5";

	const actionListElements = useMemo(() => {
		const listElements = [];

		if (selectedStatusId === currentStatusId) {
			return [];
		}

		if (["4", "5"].includes(selectedStatusId)) {
			listElements.push("Alle aktiven Staffings dieser Phase werden auf \"Inaktiv\" gesetzt.");
		}

		return listElements;
	}, [selectedStatusId, currentStatusId]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Status der Phase ändern</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}

				<Select control={control}
						name="workingStatusId"
						label="Neuer status"
						optionsData={workingStatusOptions} />


				{actionListElements.length > 0 && !showTimeTrackingsWarning && <div className="ml-1 mt-2">
					<p className="text-sm text-gray-800">Durch diese Statusänderung werden folgende
						Aktionen automatisch ausgeführt:</p>
					<ul className="list-inside list-disc text-sm text-gray-800">
						{actionListElements.map((element, index) => <li key={index}>{element}</li>)}
					</ul>
				</div>
				}
				{showTimeTrackingsWarning &&
					<>
						<div className="h-4" />
						<Hint size="sm"
							  theme="error">Diese Statusänderung ist nicht möglich, da auf dieser Phase bereits Zeiten
							erfasst wurden.</Hint>
					</>
				}
			</SidebarContent>
			<SidebarFooter>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || showTimeTrackingsWarning}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateProjectPhaseStatusForm;