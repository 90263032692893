import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import CurrencyInput from "~/components/formElements/CurrencyInput";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMemberBenefit,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitApiDispatchers.ts";
import type {
	StaffMemberBenefitWithDate,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	amountCents: number;
	description: string;
	endDate: Date | null;
	startDate: Date;
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	benefitData: StaffMemberBenefitWithDate;
};

const UpdateBenefitEntryForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	benefitData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = useMemo(() => {
		return yup.object({
			amountCents: yup.number().required().min(0),
			description: yup.string().required(),
			endDate: yup.date().nullable().default(null),
			startDate: yup.date().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			amountCents: benefitData.amountCents,
			description: benefitData.description,
			endDate: benefitData.endDate ? benefitData.endDate : null,
			startDate: benefitData.startDate,
		};
	}, [benefitData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {
			const updateData = {
				...data,
				startDate: formatDateToYYYYMMDD(data.startDate),
				endDate: data.endDate ? formatDateToYYYYMMDD(data.endDate) : null,
			};

			try {
				setIsBusy(true);
				await updateStaffMemberBenefit({
					staffMemberId: benefitData.staffMemberId, benefitId: benefitData.id,
					updateData,
				});
				onSuccess();
			} catch (error) {
				const apiError = handleApiError(error);
				console.log(apiError);
				setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
			}
		},
		[benefitData.id, benefitData.staffMemberId, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Benefit aktualisieren
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<CurrencyInput control={control}
								   label="Monatlicher Wert"
								   name="amountCents"
								   min={0}
					/>
					<Input name="description"
						   control={control}
						   label="Beschreibung" />
					<DatePickerInput name="startDate"
									 control={control}
									 label="Startdatum" />
					<DatePickerInput name="endDate"
									 control={control}
									 label="Enddatum" />
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateBenefitEntryForm;
