import type { FunctionComponent } from "react";
import { Fragment } from "react";

import type { MonthlyReportData } from "~/modules/project/types/projectViewTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	itemData: MonthlyReportData;
	showTimeTrackings: boolean
};

const MonthlyTimeTrackingReportListItem: FunctionComponent<Props> = ({ itemData, showTimeTrackings }) => {
	const totalDaysFormatted = formatNumberWithComma(itemData.totalDays.toNumber(), 2);
	return <>
		<div>{itemData.userDisplayName}</div>
		<div className="text-right">{formatCentsToCurrency(itemData.dailyRateCents)}</div>
		<div className="text-right">{totalDaysFormatted}</div>
		<div className="text-right">{formatCentsToCurrency(itemData.totalCents.toNumber())}</div>
		{showTimeTrackings &&
			<>
				<div className="col-span-3 mb-4 mt-2 grid grid-cols-6 gap-x-2 rounded-lg text-sm">
					<div className="text-left text-sm text-gray-500">Datum</div>
					<div className="col-span-3 text-left text-sm text-gray-500">Aufgabe</div>
					<div className="text-left text-sm text-gray-500">Ort</div>
					<div className="text-right text-sm text-gray-500">Stunden</div>
					{itemData.timeTrackings.map((timeTracking, index) => {
						return <Fragment key={index}>
							<div className="text-left text-gray-600">{formatDateWithGermanMonth(timeTracking.date)}</div>
							<div className="col-span-3 text-gray-600">{timeTracking.text}</div>
							<div className="text-gray-600">{timeTracking.locationDisplayName}</div>
							<div className="text-right text-gray-600">{formatNumberWithComma(timeTracking.hours.toNumber(), 2)}</div>
						</Fragment>;
					})}
					<div className="col-span-6 mt-1 text-right text-gray-600">∑ {formatNumberWithComma(itemData.totalHours.toNumber(), 2)} </div>
				</div>
				<div />
			</>
		}

	</>;
};

export default MonthlyTimeTrackingReportListItem;