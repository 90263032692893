import type { Control, FieldValues } from "react-hook-form";

import type { FormSectionProps } from "~/components/formElements/FormSection";
import FormSection from "~/components/formElements/FormSection";
import Textarea from "~/components/formElements/Textarea";

type CommentFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>
	placeholder?: string;
	label?: string;
	title: string;
};

type CommentFormSectionFormDataType = {
	comment: string | null;
};

const CommentFormSection = <T extends CommentFormSectionFormDataType>({
	control,
	label,
	placeholder,
	title,
}: CommentFormSectionProps<T>) => {
	return (
		<FormSection title={title}>
			<Textarea
				control={control}
				name="comment"
				label={label}
				placeholder={placeholder}
			/>
		</FormSection>
	);
};

export default CommentFormSection;
