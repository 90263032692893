import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import type Decimal from "decimal.js-light";
import type { FunctionComponent} from "react";
import { useMemo } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";

type VacationEntitlementssTableRowProps = {
	workingDays: Decimal;
	validFrom: string;
	isDeletable: boolean;
	isEditable: boolean;
	onEditClick: () => void;
	onDeleteClick: () => void;
};

const VacationEntitlementsTableRow: FunctionComponent<VacationEntitlementssTableRowProps> = ({
	workingDays,
	validFrom,
	isDeletable,
	isEditable,
	onEditClick,
	onDeleteClick,
}) => {
	const menuItems = useMemo(() => {
		const items = [
			{
				label: "Bearbeiten",
				onClick: onEditClick,
				icon: PencilIcon,
			},
		];
		if (isDeletable) {
			items.push({
				label: "Löschen",
				onClick: onDeleteClick,
				icon: TrashIcon,
			});
		}
		return items;
	}, [isDeletable, onDeleteClick, onEditClick]);

	return (
		<tr className="bg-white">
			<td className={"whitespace-nowrap py-5 pl-4 pr-3 text-sm"}>
				{validFrom}
			</td>
			<td className={"whitespace-nowrap py-5 pl-4 pr-3 text-center text-sm"}>
				{workingDays.toString()}
			</td>

			{(isEditable) && <td className="relative whitespace-nowrap pl-3 pr-4 text-right text-sm">
				<ButtonWithPopover
					theme="dark"
					items={menuItems}
				/>
			</td>}
		</tr>
	);
};

export default VacationEntitlementsTableRow;