import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import type { CardVariantProps } from "~/components/Card/cardVariants.ts";
import { cardVariants } from "~/components/Card/cardVariants.ts";

type Props = PropsWithChildren & { hasTitle?: boolean, className?: string } & CardVariantProps;

const CardWrapper: FunctionComponent<Props> = ({
	border,
	children,
	className,
	hasTitle = false,
	height,
	padding,
	shadow,
	theme,
}) => {
	const { container } = cardVariants(
		{ border, theme, padding, shadow, hasTitle, height });
	return <div className={clsx(container(), className)}>
		{children}
	</div>;
};

export default CardWrapper;