import type { FunctionComponent } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";

const ProtectedRoutes: FunctionComponent = () => {
	const { user, isCheckingAuthStatus } = useAuth();
	return !isCheckingAuthStatus && user ? <Outlet /> : <Navigate to={appRoutes.login().path} />;
};

export default ProtectedRoutes;
