import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const buttonNewItemVariants = tv({
	slots: {
		button: `relative flex w-full items-center justify-center
				rounded-lg border border-dashed border-gray-300
				text-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500
				focus:ring-offset-2`,
		icon: "text-current",
	},
	variants: {
		theme: {
			stacked: {
				button: "flex-col",
			},
			inline: {
				button: "flex-row gap-1",
			},
		},
		size: {
			sm: {
				button: "p-1",
				icon: "size-6",
			},
			default: {
				button: "p-4",
				icon: "size-12",
			},
		},
		disabled: {
			true: {
				button: "pointer-events-none opacity-30",
			},
			false:{
				button: "hover:border-gray-400 hover:text-gray-500"
			}
		},
	},
	defaultVariants: {
		size: "default",
		theme: "stacked",
	},
});


export type ButtonNewItemVariantProps = VariantProps<typeof buttonNewItemVariants>;
export type ButtonNewItemSizeVariantProps = "sm" | "default";
export type ButtonNewItemThemeVariantProps = "stacked" | "inline";
