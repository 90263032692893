import type { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import Headline from "~/components/Headline";
import Section from "~/components/sections/Section";
import { HR_STATISTICS_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import type { SickDaysReportData } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportTypes.ts";
import HrMainPageTabs from "~/modules/humanResources/components/HrMainPageTabs";
import ChartTypeSwitch from "~/modules/humanResources/components/StaffStatisticsView/components/ChartTypeSwitch";
import SickDaysPerEmployeeTable
	from "~/modules/humanResources/components/StaffStatisticsView/components/SickDaysPerEmployeeTable";
import type { HrStatisticsPageState } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import { ChartTypeValue } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
	isLoading: boolean;
	sickDaysReportData?: SickDaysReportData
};

type ProcessedChartData = {
	chartData: {
		[year: string]: ChartData<"bar">;
	};
	maxValue: number;
}

const defaultPageState: HrStatisticsPageState = {
	selectedChartType: ChartTypeValue.sickDaysAndEmployeeCount,
	selectedYear: new Date().getFullYear().toString(),

};

const StaffStatisticsView: FunctionComponent<Props> = ({ isLoading, sickDaysReportData }) => {
	const { pageState, setPageState } = usePageStateStorage({ pageKey: HR_STATISTICS_PAGE_STATE_KEY, defaultState: defaultPageState });

	const [selectedChartType, setSelectedChartType] = useState<ChartTypeValue>(pageState.selectedChartType);
	const [selectedYear, setSelectedYear] = useState<string>(pageState.selectedYear);

	useEffect(() => {
		setPageState(prevState => ({ ...prevState, selectedChartType, selectedYear }));
	}, [selectedChartType, selectedYear, setPageState]);

	const processedChartData = useMemo(() => {
		if (sickDaysReportData) {
			const years = Object.keys(sickDaysReportData);
			const processedData: ProcessedChartData["chartData"] = {};
			let maxValue = 0;

			years.forEach(year => {
				const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
				const sickDays = months.map(month => sickDaysReportData[year][month]?.sickDays.total || 0);
				const staffCounts = months.map(month => sickDaysReportData[year][month]?.staffCount || 0);
				const ftes = months.map(month => sickDaysReportData[year][month]?.fte || 0);

				let dataSets: ChartDataset<"bar">[] = [];

				switch (selectedChartType) {
					case ChartTypeValue.sickDaysAndEmployeeCount:
						maxValue = Math.max(maxValue, ...sickDays, ...staffCounts);
						dataSets = [
							{
								label: "Krankheitstage",
								data: sickDays,
								backgroundColor: "rgb(239,68,68)",
							},
							{
								label: "Mitarbeiter:innen",
								data: staffCounts,
								backgroundColor: "rgb(67,152,238)",
							},
						];
						break;
					/*					case ChartTypeValue.sickDaysPerEmployee:
											maxValue = Math.max(maxValue, ...ratios);
											dataSets = [
												{
													label: "Krankheitstage pro Mitarbeiter",
													data: ratios,
													backgroundColor: "rgb(40,164,133)",
												},
											];
											break;*/
					case ChartTypeValue.fte:
						maxValue = Math.max(maxValue, ...ftes, ...staffCounts);
						dataSets = [
							{
								label: "Mitarbeiter:innen",
								data: staffCounts,
								backgroundColor: "rgb(67,152,238)",
							},
							{
								label: "FTE",
								data: ftes,
								backgroundColor: "rgb(30,53,96)",
							},
						];
						break;
				}


				processedData[year] = {
					labels: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktoober", "November", "Dezember"],
					datasets: dataSets,
				};
			});

			return { chartData: processedData, maxValue };
		}
		return null;
	}, [sickDaysReportData, selectedChartType]);

	const chartOptions: ChartOptions<"bar"> = {
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				max: processedChartData?.maxValue,
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: false,
				text: "Krankheitstage",
			},
		},
	};

	const yearSelectOptions = useMemo(() => {
		if (sickDaysReportData) {
			return Object.keys(sickDaysReportData).sort((a, b) => parseInt(b) - parseInt(a)).map(year => ({
				value: year,
				label: year,
			}));

		}
		return [];
	}, [sickDaysReportData]);

	const showTableElements = !isLoading && selectedChartType === ChartTypeValue.sickDaysPerEmployee;
	return (
		<div className="grid h-screen grid-rows-[auto_auto_auto_1fr] overflow-hidden">
			<BreadcrumbsSection pages={["HR", "Reports"]}
								className="bg-white" />
			<Section className="z-50 bg-white pb-4 pt-2">
				<div className="flex w-full items-center justify-between gap-x-4">
					<Headline type="h2">HR-Reports</Headline>

					<ChartTypeSwitch selectedChartType={selectedChartType}
									 setSelectedChartType={setSelectedChartType} />
					{showTableElements &&
						<SelectPlain value={selectedYear}
									 optionsData={yearSelectOptions}
									 onChange={setSelectedYear} />
					}
				</div>
			</Section>
			<Section>
				<HrMainPageTabs selectedTabName="statistics" />
			</Section>
			<div className={clsx("mx-auto max-w-7xl px-2 pb-10 pt-4 sm:px-6 lg:px-8",
				!showTableElements ? "size-full overflow-y-auto" : "max-h-full w-full overflow-hidden")}>

				{isLoading && <LoadingPage />}
				{!isLoading && processedChartData && selectedChartType !== ChartTypeValue.sickDaysPerEmployee &&
					<div className="grid grid-cols-2 gap-8">
						{Object.entries(processedChartData.chartData).reverse().map(([year, yearData]) => (
							<div key={year}>
								<h2>{year}</h2>
								<Bar options={chartOptions}
									 data={yearData} />
							</div>
						))}
					</div>}
				{showTableElements &&
					<SickDaysPerEmployeeTable sickDaysReportData={sickDaysReportData}
											  selectedYear={selectedYear} />}
			</div>

		</div>
	);
};

export default StaffStatisticsView;