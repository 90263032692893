import { CheckCircleIcon, PencilIcon, TrashIcon, XCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { FunctionComponent} from "react";
import { useMemo } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";

type WorkingSchedulesTableRowProps = {
	workingDays: boolean[];
	validFrom: string;
	isDeletable: boolean;
	isEditable: boolean;
	onEditClick: () => void;
	onDeleteClick: () => void;
	compactView?: boolean;
};

const WorkingScheduleTableRow: FunctionComponent<WorkingSchedulesTableRowProps> = ({
	workingDays,
	validFrom,
	isDeletable,
	isEditable,
	onEditClick,
	onDeleteClick,
	compactView,
}) => {
	const workingDayCells = workingDays.map((workingDay, index) => {
		return (
			<td
				key={index}
				className={clsx(
					"text-sm",
					workingDay ? "text-green-500" : "text-red-500",
				)}
			>
				<div className="flex justify-center">
					{workingDay ? <CheckCircleIcon className="size-5" /> : <XCircleIcon className="size-5" />}
				</div>
			</td>
		);
	});

	const menuItems = useMemo(() => {
		const items = [];
		if(isEditable){
			items.push({
				label: "Bearbeiten",
				onClick: onEditClick,
				icon: PencilIcon,
			});
		}

		if (isDeletable) {
			items.push({
				label: "Löschen",
				onClick: onDeleteClick,
				icon: TrashIcon,
			});
		}
		return items;
	}, [isDeletable, isEditable, onDeleteClick, onEditClick]);

	return (
		<tr className={clsx(!compactView ? "bg-white": "bg-transparent")}>
			<td className={clsx("whitespace-nowrap text-sm", !compactView ? "py-5 pl-4 pr-3": "py-1 pl-0")}>
				{validFrom}
			</td>
			<>
				{workingDayCells}
			</>

			{menuItems.length > 0 && !compactView && <td className="relative whitespace-nowrap pl-3 pr-4 text-right text-sm">
				<ButtonWithPopover
					theme="dark"
					items={menuItems}
				/>
			</td>}
		</tr>
	);
};

export default WorkingScheduleTableRow