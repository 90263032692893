import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import clsx from "clsx";
import debounce from "debounce";
import type { EditorState, LexicalEditor } from "lexical";
import { $getRoot } from "lexical";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import { editorBaseConfig } from "~/components/TextEditor/editorConfig.ts";
import type { EditorOptions } from "~/components/TextEditor/editorTypes.ts";
import { getHtmlFromEditorState } from "~/components/TextEditor/richTextEditorUtils.ts";
import useOnClickOutside from "~/hooks/useOnClickOutside.ts";

import AutoLinkPlugin from "./components/AutoLinkPlugin";
import ListMaxIndentLevelPlugin from "./components/ListMaxIndentLevelPlugin";
import ToolbarBasicPlugin from "./components/ToolbarBasicPlugin/ToolbarBasicPlugin.tsx";


function Placeholder({ text = "Text eingeben...", relative = false }) {
	return <div className={clsx(relative ? "editor-placeholder-inline" : "editor-placeholder")}>{text}</div>;
}

type EditorProps = {
	control: Control<any>
	name: string;
	placeholder?: string;
	readOnly?: boolean;
	usePreview?: boolean;
	options?: EditorOptions
};

export default function Editor({
	name,
	control,
	readOnly = false,
	options,
	placeholder,
	usePreview = false,
}: EditorProps) {
	const {
		field: { onChange, value },
		formState: { isDirty: formIsDirty },
	} = useController({ name, control });

	// since react hook formInputs does not provide a way to listen to a from reset
	const [editorIsInitialised, setEditorIsInitialised] = useState(false);
	const [initialValue, setInitialValue] = useState(null);
	const [currentValue, setCurrentValue] = useState<string | null>(null);
	const [showPreview, setShowPreview] = useState(usePreview);
	const previewRef = useRef<HTMLDivElement>(null);
	const editorOuterContainerRef = useRef<HTMLDivElement>(null);

	const controllerValue = value;

	const baseConfig = { editable: !readOnly };
	const editorConfig: InitialConfigType = options?.config ? { ...options.config, ...baseConfig } : { ...editorBaseConfig, ...baseConfig };

	const editor = useRef<null | LexicalEditor>(null);
	const handleOnChange = useCallback((editorState: EditorState) => {
		const editorStateJSON = editorState.toJSON();
		const jsonString = JSON.stringify(editorStateJSON);
		onChange(jsonString);
		setCurrentValue(jsonString);
	}, [onChange]);

	const handleOutsideClick = useCallback(() => {
		if (usePreview && !showPreview) {
			setShowPreview(true);
		}
		return null;
	}, [usePreview, showPreview]);

	useEffect(() => {
		// attach an on focus event listener to the editor root element
		if (usePreview && !showPreview) {
			setEditorIsInitialised(false);
		}

		if (usePreview && previewRef.current !== null) {
			if (showPreview) {
				previewRef.current.addEventListener("focusin", () => {
					setShowPreview(false);
				});
			} else {
				previewRef.current.removeEventListener("focusin", () => {
					setShowPreview(true);
				});
			}
		}
		return () => {
			if (usePreview && previewRef.current) {
				previewRef.current.removeEventListener("focusin", () => {
					setShowPreview(false);
				});
			}
		};
	}, [usePreview, showPreview]);


	useOnClickOutside(editorOuterContainerRef, handleOutsideClick);
	useEffect(() => {
		const editorInput = editor.current?._rootElement;
		const editorContainer = editorOuterContainerRef.current;

		const handleFocusOut = (event: FocusEvent) => {
			const relatedTarget = event.relatedTarget as HTMLElement;

			if (
				editorContainer &&
				relatedTarget &&
				!editorContainer.contains(relatedTarget)
			) {
				setShowPreview(true);
			}
		};

		if (usePreview && editorInput && !showPreview) {
			editorInput.addEventListener("focusout", handleFocusOut);
		}

		return () => {
			if (editorInput) {
				editorInput.removeEventListener("focusout", handleFocusOut);
			}
		};
	}, [usePreview, showPreview]);


	useEffect(() => {
		if (editor.current && !showPreview) {
			// handle situation when the editor is initialised when the preview is focused
			if (usePreview && !editorIsInitialised && currentValue && !showPreview) {
				const parsedState = editor.current?.parseEditorState(currentValue);
				editor.current.setEditorState(parsedState);
				setEditorIsInitialised(true);
				// editor.current.focus();
			} else if (!editorIsInitialised && controllerValue !== undefined) {
				let parsedState = null;
				try {
					parsedState = editor.current?.parseEditorState(controllerValue);
				} catch (error) { /* empty */
				}

				if (parsedState && !parsedState.isEmpty()) {
					editor.current.setEditorState(parsedState);
				}

				setEditorIsInitialised(true);
				setInitialValue(controllerValue);
				// editor.current.focus();
			}
			// handle formInputs reset
			else if (editorIsInitialised && !formIsDirty && (initialValue === controllerValue || controllerValue !== currentValue)) {
				let parsingFailed = false;
				try {
					const parsedState = editor.current?.parseEditorState(controllerValue);
					editor.current.setEditorState(parsedState);
				} catch (error) {
					parsingFailed = true;
				}
				if (parsingFailed) {
					editor.current?.update(() => {
						$getRoot().clear();
					});
				}
			}
		}

	}, [showPreview, usePreview, controllerValue, editorIsInitialised, formIsDirty, initialValue, currentValue]);

	const previewHtml = useMemo(() => {
		if (usePreview && showPreview) {
			if (controllerValue) {
				return getHtmlFromEditorState({ editorState: JSON.parse(controllerValue) });
			}
		}
		return "";
	}, [usePreview, showPreview, controllerValue]);

	return (
		<>
			{!showPreview ? <LexicalComposer initialConfig={editorConfig}>
					<div ref={editorOuterContainerRef}
						 className="grid h-full grid-rows-[auto_1fr] overflow-y-hidden"
					>
						{!readOnly && <ToolbarBasicPlugin options={options?.formatOptions} />}
						<div className="editor-inner h-full overflow-y-auto rounded-b">
							<RichTextPlugin
								contentEditable={<ContentEditable className="editor-input overflow-y-auto" />}
								placeholder={<Placeholder text={placeholder} />}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<HistoryPlugin />
							{/*<TreeViewPlugin />*/}
							<EditorRefPlugin editorRef={editor} />
							<AutoFocusPlugin />
							<TabIndentationPlugin />
							<ListPlugin />
							<LinkPlugin />
							<AutoLinkPlugin />
							<ListMaxIndentLevelPlugin maxDepth={options?.maxIndentLevel || 5} />
							<OnChangePlugin onChange={debounce(handleOnChange, 300)}
							/>
						</div>
					</div>
				</LexicalComposer> :
				(previewHtml ? <div ref={previewRef}
									className="editor-input w-full bg-white"
									tabIndex={0}
									dangerouslySetInnerHTML={{ __html: previewHtml }} /> : <div ref={previewRef}
																								className="editor-input flex w-full items-center rounded bg-white"
																								tabIndex={0}
				><Placeholder text={placeholder}
							  relative={true} /></div>)}
		</>
	);
}
