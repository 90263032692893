import clsx from "clsx";
import BreadcrumbsSection from "components/Breadcrumbs";
import { t } from "i18next";
import CreateOrUpdateInvoiceSidebar from "modules/billing/components/CreateOrUpdateInvoiceSidebar";
import MonthlyBillingReportFilterBar
	from "modules/billing/components/MonthlyBillingReportView/components/MonthlyBillingReportFilterBar";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import ContentWrapper from "~/components/ContentWrapper";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import MainContent from "~/components/mainContent/MainContent";
import type {
	MonthAndYearNavigationHandlerPropsType,
} from "~/components/MonthAndYearNavigation/MonthAndYearNavigation.tsx";
import NoEntriesFound from "~/components/NoEntriesFound";
import { appRoutes } from "~/constants/appRoute.ts";
import { MONTHLY_INVOICING_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import type { CreateOrUpdateInvoiceData } from "~/modules/billing/api/invoice/invoiceTypes.ts";
import { useMonthlyBillingReport } from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportQueries.ts";
import BillableProjectCard from "~/modules/billing/components/MonthlyBillingReportView/components/BillableProjectCard";
import BillableProjectPhase
	from "~/modules/billing/components/MonthlyBillingReportView/components/BillableProjectCard/components/BillableProjectPhase";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import type { FormInputOption } from "~/types/form.ts";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type MonthlyBillingReportViewProps = {
	currentMonth: string;
	currentYear: string;
	month: string;
	monthSelectOptions: FormInputOption[];
	year: string;
	yearSelectOptions: FormInputOption[];
};

const defaultPageState: { statusFilter: string[]; search: string } = {
	statusFilter: [],
	search: "",
};

const MonthlyBillingReportView: React.FunctionComponent<MonthlyBillingReportViewProps> = ({
	currentMonth,
	currentYear,
	month,
	monthSelectOptions,
	year,
	yearSelectOptions,
}) => {
	const navigate = useNavigate();
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: MONTHLY_INVOICING_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});
	const [statusFilter, setStatusFilter] = useState<string[]>(pageState.statusFilter);
	const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
	const [search, setSearch] = useState<string>(pageState.search || "");

	const currentMonthFormatted = formatDateToYYYYMMDD(new Date(parseInt(year), parseInt(month), 1));
	const getPagePath = ({ month, year }: { month: string; year: string }) => {
		return generatePath(appRoutes.monthlyBillingReport().path) + `?year=${year}&month=${month}`;
	};

	const { data: monthlyReportData, isLoading: reportIsLoading } =
		useMonthlyBillingReport({
			month: currentMonthFormatted,
			options: { enabled: !!currentMonthFormatted },
		});

	const { data: allUsersData } = useAllUsers();

	const filteredOrders = useMemo(() => {
		if (monthlyReportData) {
			return monthlyReportData.orders.filter(order => !statusFilter.length || statusFilter.includes(order.invoicingStatus));
		}
		return [];
	}, [monthlyReportData, statusFilter]);

	const createOrUpdateInvoiceData = useMemo((): CreateOrUpdateInvoiceData | undefined => {
		if (monthlyReportData && selectedOrderId) {
			const orderData = monthlyReportData.orders.find(order => order.id === selectedOrderId);
			if (orderData) {
				const invoiceData = orderData.invoice;
				const monthOfBilling = invoiceData?.monthOfBilling ? formatDateToYYYYMMDD(new Date(invoiceData?.monthOfBilling)) : currentMonthFormatted;

				return {
					invoiceId: invoiceData?.id || undefined,
					orderId: orderData.id,
					monthOfBilling,
					invoiceDocumentCreatedAt: invoiceData?.invoiceDocumentCreatedAt ? new Date(invoiceData?.invoiceDocumentCreatedAt) : null,
					invoicePaidAt: invoiceData?.invoicePaidAt ? new Date(invoiceData?.invoicePaidAt) : null,
					invoiceSentAt: invoiceData?.invoiceSentAt ? new Date(invoiceData?.invoiceSentAt) : null,
				};
			}
		}
		return undefined;
	}, [monthlyReportData, selectedOrderId, currentMonthFormatted]);

	useEffect(() => {
		setPageState({ statusFilter, search });
	}, [setPageState, statusFilter, search]);

	const projectCards = useMemo(() => {
		if (monthlyReportData && allUsersData && filteredOrders) {
			return monthlyReportData.projects.filter(project=>!search ? true: project.companyDisplayName.toLowerCase().includes(search.toLowerCase())).sort(byObjectProperty("projectTitle")).map((project) => {
				const projectsPhases = monthlyReportData.projectPhases.filter((phase) => phase.projectId === project.id);
				if (!projectsPhases) return null;
				const phaseIds = projectsPhases.map((phase) => phase.id);
				const projectsOrders = filteredOrders.filter((order) => phaseIds.includes(order.projectPhaseId) && (!statusFilter.length || statusFilter.includes(order.invoicingStatus)));
				if (projectsOrders.length === 0) {
					return null;
				}
				const baseKey = `${project.id}-`;
				return (
					<BillableProjectCard
						key={baseKey}
						projectId={project.id}
						projectTitle={project.projectTitle}
						projectNumber={project.projectNumber}
					>
						{projectsPhases.sort(byObjectProperty("phaseTitle")).map((projectPhase) => {
							const phasesOrders = projectsOrders.filter((order) => order.projectPhaseId === projectPhase.id);
							if (!phasesOrders) return null;
							return phasesOrders.sort(byObjectProperty("orderTitle")).map((order) => {
								const managerId = projectPhase.phaseManagedBy || project.projectManagedBy;
								const manager = allUsersData.find((user) => user.id === managerId);
								const managerFullName = manager ? manager.fullName : "n/a";

								return (
									<BillableProjectPhase
										allUsersData={allUsersData}
										key={baseKey + projectPhase.id + order.id}
										comment={order.comment}
										companyName={project.companyDisplayName}
										hasFreelancers={order.hasFreelancers ? "Ja" : "Nein"}
										manager={managerFullName}
										monthlyClosing={order.allStaffingsClosedForMonth ? "Ja" : "Nein"}
										orderId={order.id}
										orderTitle={order.orderTitle}
										phaseTitle={projectPhase.phaseTitle}
										projectTitle={project.projectTitle}
										projectNumber={project.projectNumber}
										setSelectedOrderId={setSelectedOrderId}
										staffedUsers={order.staffedUsers}
										month={currentMonthFormatted}
										status={order.invoicingStatus}
										total={formatNumberToCurrency(order.orderTotalCents)}
									/>
								);
							});
						})}
					</BillableProjectCard>
				);
			}).filter(Boolean);
		}

		return null;
	}, [monthlyReportData, filteredOrders, currentMonthFormatted, allUsersData, setSelectedOrderId, statusFilter, search]);

	const handleMonthOrYearSelectChange = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		navigate(getPagePath({ year, month }));
	};

	const handleGoToPrevOrNextMonthClick = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		navigate(getPagePath({ year, month }));
	};

	const handleGoToTodayClick = useCallback(() => {
		navigate(
			getPagePath({
				year: currentYear,
				month: (parseInt(currentMonth) + 1).toString(),
			}),
		);
	}, [currentMonth, currentYear, navigate]);

	const handleStatusFilterChange = (value: string | null) => {
		if (null === value) {
			setStatusFilter([]);
		} else {
			setStatusFilter((prevState) => {
				return prevState.includes(value)
					? prevState.filter((optionValue) => optionValue !== value)
					: [...prevState, value];
			});
		}
	};

	return (
		<MainContent>
			<BreadcrumbsSection pages={[appRoutes.monthlyBillingReport()]}
								className="bg-white" />
			<PageHeading title={t("monthlyBilling:pageTitle", "Monatliche Abrechnung")}></PageHeading>

			<PageHeading.BottomBar>
				<div className="flex w-full items-center justify-between gap-2 text-sm font-medium text-gray-700 hover:text-gray-900">
					<MonthlyBillingReportFilterBar displayedOrdersCount={filteredOrders.length}
												   month={month}
												   monthSelectOptions={monthSelectOptions}
												   onChangeStatus={handleStatusFilterChange}
												   onGoToNextMonthClick={handleGoToPrevOrNextMonthClick}
												   onGoToPrevMonthClick={handleGoToPrevOrNextMonthClick}
												   onGoToTodayClick={handleGoToTodayClick}
												   onMonthSelectChange={handleMonthOrYearSelectChange}
												   onYearSelectChange={handleMonthOrYearSelectChange}
												   search={search}
												   setSearch={setSearch}
												   statusFilter={statusFilter}
												   totalOrdersCount={monthlyReportData?.orders.length || 0}
												   year={year}
												   yearSelectOptions={yearSelectOptions} />
				</div>
			</PageHeading.BottomBar>
			<ContentWrapper>
				{reportIsLoading ? (
					<LoadingPage />
				) : (
					<div className={clsx("flex flex-col gap-y-4", statusFilter.length > 0 || search
						? "mt-20"
						: "mt-4")}>
						{projectCards?.length ? (
							projectCards
						) : statusFilter.length === 0 ? (
							<div className="mt-4 flex w-full justify-center">Keine Daten vorhanden</div>
						) : (
							<div className="mt-4">
								<NoEntriesFound />
							</div>
						)}
					</div>
				)}
			</ContentWrapper>
			<CreateOrUpdateInvoiceSidebar isOpen={!!createOrUpdateInvoiceData}
										  setOpen={() => setSelectedOrderId(null)}
										  currentMonthFormatted={currentMonthFormatted}
										  initialValues={createOrUpdateInvoiceData}
			/>
		</MainContent>
	);
};
export default MonthlyBillingReportView;
