import {tv} from "tailwind-variants";

import type {VariantProps} from "~/types/tailwindVariants";

export const sideBarVariants = tv({
    slots: {
        innerWrapper: "pointer-events-auto relative w-screen",
        contentContainer: "relative flex h-full min-h-full flex-col overflow-hidden bg-white shadow-xl",
    },
    variants: {
        width: {
            auto: {
                innerWrapper: "w-auto"
            },
            md: {
                innerWrapper: "max-w-xl"
            },
            lg: {
                innerWrapper: "max-w-2xl"
            },
            xl: {
                innerWrapper: "max-w-4xl"
            }
        },
        theme:{
            light:{
                contentContainer: "bg-white"
            },
            medium:{
              contentContainer: "bg-gray-100"
            },
            dark:{
                contentContainer: "bg-gray-400"
            }
        }
    },
    defaultVariants: {
        width: "lg",
        theme:"light"
    },
});


export type SideBarVariantProps = VariantProps<typeof sideBarVariants>;
