import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import Button from "components/buttons/Button";
import { add, getISOWeek, sub } from "date-fns";
import { useCallback } from "react";

import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import type { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import AbsenceTypeFilter
	from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/AbsenceTypeFilter";
import UserFilterButtons
	from "~/modules/absence/components/AbsenceCalendarView/components/AbsenceOverviewHeader/components/UserFilterButtons";
import { AbsenceCalendarTimespanOption } from "~/modules/absence/types/absenceOverviewTypes.ts";
import type { FormInputOption } from "~/types/form.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";

type AbsenceOverviewHeaderProps = {
	endDate: Date,
	hiddenUsersCount: number;
	onToggleMultiActionClick: (selectAll: boolean) => void;
	selectedDisplayTimespanWeeks: AbsenceCalendarTimespanOption;
	setShowUserFilter: DispatchStateAction<boolean>;
	setSelectedDisplayTimespanWeeks: (value: AbsenceCalendarTimespanOption) => void;
	setStartDate: (value: Date) => void;
	setTypeFilter: DispatchStateAction<AbsenceTypeId[]>
	showUserFilter: boolean;
	startDate: Date,
	typeFilter: AbsenceTypeId[];
};

const AbsenceOverviewHeader: React.FunctionComponent<AbsenceOverviewHeaderProps> = ({
	endDate,
	hiddenUsersCount,
	onToggleMultiActionClick,
	selectedDisplayTimespanWeeks,
	setShowUserFilter,
	setSelectedDisplayTimespanWeeks,
	setStartDate,
	setTypeFilter,
	startDate,
	showUserFilter,
	typeFilter,
}) => {
	const weekSelectOptions: FormInputOption[] = [
		{
			value: AbsenceCalendarTimespanOption.WEEK,
			label: "Woche",
		},
		{
			value: AbsenceCalendarTimespanOption.MONTH,
			label: "Monat",
		},
		{
			value: AbsenceCalendarTimespanOption.QUARTER,
			label: "Quartal",
		},
		{
			value: AbsenceCalendarTimespanOption.YEAR,
			label: "Jahr",
		},
	];

	const calendarWeekStartDate = getISOWeek(startDate);
	const calendarWeekEndDate = getISOWeek(endDate);
	const calendarWeek = calendarWeekStartDate === calendarWeekEndDate ? "KW " + calendarWeekStartDate : `KW ${calendarWeekStartDate} - KW ${calendarWeekEndDate}`;
	const displayedTimespan = formatDateRange(startDate, endDate);
	const displayedTimespanLabel = selectedDisplayTimespanWeeks === AbsenceCalendarTimespanOption.YEAR ? displayedTimespan : `${displayedTimespan} / ${calendarWeek}`;

	const handlePrevButtonClick = useCallback(() => {
		switch (selectedDisplayTimespanWeeks) {
			case AbsenceCalendarTimespanOption.WEEK:
				setStartDate(sub(startDate, { weeks: 1 }));
				break;
			case AbsenceCalendarTimespanOption.MONTH:
				setStartDate(sub(startDate, { months: 1 }));
				break;
			case AbsenceCalendarTimespanOption.QUARTER:
				setStartDate(sub(startDate, { months: 3 }));
				break;
			case AbsenceCalendarTimespanOption.YEAR:
				setStartDate(sub(startDate, { years: 1 }));
				break;
		}
	}, [startDate, selectedDisplayTimespanWeeks, setStartDate]);

	const handleNextButtonClick = useCallback(() => {
		switch (selectedDisplayTimespanWeeks) {
			case AbsenceCalendarTimespanOption.WEEK:
				setStartDate(add(startDate, { weeks: 1 }));
				break;
			case AbsenceCalendarTimespanOption.MONTH:
				setStartDate(add(startDate, { months: 1 }));
				break;
			case AbsenceCalendarTimespanOption.QUARTER:
				setStartDate(add(startDate, { months: 3 }));
				break;
			case AbsenceCalendarTimespanOption.YEAR:
				setStartDate(add(startDate, { years: 1 }));
				break;
		}
	}, [startDate, selectedDisplayTimespanWeeks, setStartDate]);

	return <div className="z-30 mb-2 grid w-full grid-cols-3">
		<UserFilterButtons hiddenUsersCount={hiddenUsersCount}
						   showUserFilter={showUserFilter}
						   setShowUserFilter={setShowUserFilter}
						   onToggleMultiActionClick={onToggleMultiActionClick} />
		{!showUserFilter && <><div className="flex w-full items-center justify-center font-bold text-primary-700 ">{displayedTimespanLabel} </div>

		<div className="flex flex-row items-center justify-end">
			<div className="mr-10 flex flex-row items-center">
				<AbsenceTypeFilter absenceTypeFilter={typeFilter}
								   onChange={setTypeFilter} />
			</div>
			<Button size="sm"
					theme="none"
					onClick={handlePrevButtonClick}>
				<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
			</Button>
			<div className="min-w-32">
				<SelectPlain optionsData={weekSelectOptions}
							 value={selectedDisplayTimespanWeeks}
							 onChange={setSelectedDisplayTimespanWeeks} />
			</div>
			<Button size="sm"
					theme="none"
					onClick={handleNextButtonClick}>
				<ChevronRightIcon className="size-5 hover:fill-accent-600" />
			</Button>
		</div></>}
	</div>;
};

export default AbsenceOverviewHeader;